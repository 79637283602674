import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  SAVE_SIDE_PROFILE_ERROR,
  GET_UNIVERSITY_ABOUT,
  GET_PROFILE,
  UPDATE_PROFILE,
  SAVE_INTAKE_DATES,
  GET_INTAKE_DATES,
  SAVE_APPLICATION_FORM,
  GET_APPLICATION_FORM,
  GET_PROFILE_STATUS,
  GET_EP_SAVED_SEARCH,
  DELETE_SAVED_SEARCH,
  GET_PP_SAVED_SEARCH,
  DELETE_DOCS,
  GET_PROFILE_FLAGS,
  GET_EDUCATION_SUB_LEVEL,
  PROGRAM_COUNTRY,
  GET_LOGIN_LOGS
} from "../constants";
import {
  baseUrl,
  DELETE_DOCS_API,
  DELETE_SAVED_SEARCH_API,
  GET_APPLICATION_FORM_API,
  GET_EDUCATION_SUBLEVEL_API,
  GET_EP_SAVED_SEARCH_API,
  GET_INTAKE_DATES_API,
  GET_PP_SAVED_SEARCH_API,
  GET_PROFILE_API,
  GET_PROFILE_FLAGS_API,
  GET_PROFILE_STATUS_API,
  GET_PROGRAM_COUNTRY_API,
  SAVE_APPLICATION_FORM_API,
  SAVE_INTAKE_DATES_API,
  SIDE_PROFILE,
  LOGIN_LOGS,
  UNIVERSITY_ABOUT,
  UPDATE_PROFILE_API
} from "../../Api";
import { toast } from "react-toastify";
import axios from "axios";
import { 
  saveSideProfileSuccess,
  saveSideProfileError,
  getUniversityAboutSuccess,
  getUniversityAboutError,
  getProfileSuccess,
  getProfileError,
  updateProfileSuccess,
  updateProfileError,
  saveIntakeDatesSuccess,
  saveIntakeDatesError,
  getIntakeDatesSuccess,
  getIntakeDatesError,
  saveApplicationFormSuccess,
  saveApplicationFormError,
  getApplicationFormSuccess,
  getApplicationFormError,
  getProfileStatusSuccess,
  getProfileStatusError,
  getEpSavedSearchSuccess,
  getEpSavedSearchError,
  deleteSavedSearchSuccess,
  deleteSavedSearchError,
  handleSuccessResponse,
  handleErrorResponse,
  getPpSavedSearchSuccess,
  getPpSavedSearchError,
  deleteDocsSuccess,
  deleteDocsError,
  getProfileFlagsSuccess,
  getProfileFlagsError,
  getEducationSublevelSuccess,
  getEducationSublevelError,
  programCountrySuccess,
  getLoginLogsSuccess,
  getLoginLogsError,
  programCountryError
} from '../actions';

function saveSideProfileApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SIDE_PROFILE,
    data: params
  });
}

function* saveSideProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(saveSideProfileApi, payload);
    yield put(saveSideProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(saveSideProfileError(e))
    if (reject) reject(e)
  }
}

export function* saveSideProfileActionWatcher() {
  yield takeLatest(SAVE_SIDE_PROFILE, saveSideProfile)
}

function getLoginLogsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + LOGIN_LOGS + "?id=" + params.student_id + "&date=" + params.date + "&per_page="+ params.limit+ "&page="+ params.page,
  });
}

function* getLoginLogs(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getLoginLogsApi,payload);
    yield put(getLoginLogsSuccess(response.data));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(getLoginLogsError(e))
    handleErrorResponse(e.response);
    if (reject) reject()
  }
}

export function* getLoginLogsActionWatcher() {
  yield takeLatest(GET_LOGIN_LOGS, getLoginLogs)
}
function getUniversityAboutApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + UNIVERSITY_ABOUT + "/" + params.univ_id,
    data: params
  });
}

function* getUnivesityAbout(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getUniversityAboutApi, payload);
    yield put(getUniversityAboutSuccess(response.data));
    if (resolve) resolve()
  } catch (e) {
    yield put(getUniversityAboutError(e))
    if (reject) reject()
  }
}

export function* getUniversityAboutActionWatcher() {
  yield takeLatest(GET_UNIVERSITY_ABOUT, getUnivesityAbout)
}

function getProfileApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROFILE_API + "/" + params.univ_id,
    data: params
  });
}

function* getProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProfileApi, payload);
    yield put(getProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getProfileError(e))
    if (reject) reject(e)
  }
}

export function* getProfileActionWatcher() {
  yield takeLatest(GET_PROFILE, getProfile)
}

function updateProfileApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + UPDATE_PROFILE_API,
    data: params
  });
}

function* updateProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(updateProfileApi, payload);
    yield put(updateProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(updateProfileError(e))
    if (reject) reject(e)
  }
}

export function* updateProfileActionWatcher() {
  yield takeLatest(UPDATE_PROFILE, updateProfile)
}

function saveIntakeDatesApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SAVE_INTAKE_DATES_API,
    data: params
  });
}

function* saveIntakeDates(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(saveIntakeDatesApi, payload);
    yield put(saveIntakeDatesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(saveIntakeDatesError(e))
    if (reject) reject(e)
  }
}

export function* saveIntakeDatesActionWatcher() {
  yield takeLatest(SAVE_INTAKE_DATES, saveIntakeDates)
}

function getIntakeDatesApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_INTAKE_DATES_API
  });
}

function* getIntakeDates(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getIntakeDatesApi, payload);
    yield put(getIntakeDatesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getIntakeDatesError(e))
    if (reject) reject(e)
  }
}

export function* getIntakeDatesActionWatcher() {
  yield takeLatest(GET_INTAKE_DATES, getIntakeDates)
}

function saveApplicationFormApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SAVE_APPLICATION_FORM_API,
    data: params
  });
}

function* saveApplicationForm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(saveApplicationFormApi, payload);
    yield put(saveApplicationFormSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(saveApplicationFormError(e))
    if (reject) reject(e)
  }
}

export function* saveApplicationFormActionWatcher() {
  yield takeLatest(SAVE_APPLICATION_FORM, saveApplicationForm)
}

function getApplicationFormApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_APPLICATION_FORM_API + "/" + params.id
  });
}

function* getApplicationForm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getApplicationFormApi, payload);
    yield put(getApplicationFormSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getApplicationFormError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationFormActionWatcher() {
  yield takeLatest(GET_APPLICATION_FORM, getApplicationForm)
}

function getProfileStatusApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROFILE_STATUS_API + "/" + params.id
  });
}

function* getProfileStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProfileStatusApi, payload);
    yield put(getProfileStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getProfileStatusError(e))
    if (reject) reject(e)
  }
}

export function* getProfileStatusWatcher() {
  yield takeLatest(GET_PROFILE_STATUS, getProfileStatus)
}

function getEpSavedSearchApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_EP_SAVED_SEARCH_API,
    params
  });
}

function* getEpSavedSearch(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getEpSavedSearchApi, payload);
    yield put(getEpSavedSearchSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getEpSavedSearchError(e))
    if (reject) reject(e)
  }
}

export function* getEpSavedSearchWatcher() {
  yield takeLatest(GET_EP_SAVED_SEARCH, getEpSavedSearch)
}

function deleteSavedSearchApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + DELETE_SAVED_SEARCH_API,
    data: params
  });
}

function* deleteSavedSearch(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteSavedSearchApi, payload);
    handleSuccessResponse(response.data)
    yield put(deleteSavedSearchSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(deleteSavedSearchError(e))
    if (reject) reject(e)
  }
}

export function* deleteSavedSearchWatcher() {
  yield takeLatest(DELETE_SAVED_SEARCH, deleteSavedSearch)
}

function getPpSearchApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PP_SAVED_SEARCH_API,
    params
  });
}

function* getPpSearch(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getPpSearchApi, payload);
    handleSuccessResponse(response.data)
    yield put(getPpSavedSearchSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(getPpSavedSearchError(e))
    if (reject) reject(e)
  }
}

export function* getPpSearchWatcher() {
  yield takeLatest(GET_PP_SAVED_SEARCH, getPpSearch)
}

function deleteDocsApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + DELETE_DOCS_API,
    data: params
  });
}

function* deleteDocs(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteDocsApi, payload);
    handleSuccessResponse(response.data)
    yield put(deleteDocsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(deleteDocsError(e))
    if (reject) reject(e)
  }
}

export function* deleteDocsWatcher() {
  yield takeLatest(DELETE_DOCS, deleteDocs)
}

function getProfileFlagsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROFILE_FLAGS_API,
    params: params
  });
}

function* getProfileFlags(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProfileFlagsApi, payload);
    handleSuccessResponse(response.data)
    yield put(getProfileFlagsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(getProfileFlagsError(e))
    if (reject) reject(e)
  }
}

export function* getProfileFlagsWatcher() {
  yield takeLatest(GET_PROFILE_FLAGS, getProfileFlags)
}

function getEducationSublevelApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_EDUCATION_SUBLEVEL_API+"/"+params.country_id,
    params: params
  });
}

function* getEducationSublevel(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getEducationSublevelApi, payload);
    handleSuccessResponse(response.data)
    yield put(getEducationSublevelSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(getEducationSublevelError(e))
    if (reject) reject(e)
  }
}

export function* getEducationSublevelWatcher() {
  yield takeLatest(GET_EDUCATION_SUB_LEVEL, getEducationSublevel)
}

function getProgramCountryApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROGRAM_COUNTRY_API,
    params: params
  });
}

function* getProgramCountry(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getProgramCountryApi, payload);
    yield put(programCountrySuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(programCountryError(e))
    if (reject) reject(e)
  }
}

export function* getProgramCountryWatcher() {
  yield takeLatest(PROGRAM_COUNTRY, getProgramCountry)
}