import {
  GET_APPLICATION_DETAILS_SUCCESS,
  GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS,
  GET_APPLICATION_NOTE_COUNT_SUCCESS,
  GET_APPLICATION_STATUS_LIST_SUCCESS,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_PROGRAM_LIST_SUCCESS,
  GET_STUDENT_TYPES_LIST_SUCCESS,
  GET_STUDY_LEVEL_SUCCESS,
} from "../constants";

import { generateApplicationStatusString } from "../../common/applicationHelper";

import config from "../../config.json";

const baseurl = `${config.baseurl}`;

const initialState = {
  departmentList: null,
  programList: null,
  studyLevelList: null,
  statusList: null,
  chatCount: 0,
  messageCount: 0,
  applicationStatus: null,
  appStatusString: "",
  appStatusSequence: 0,
  studentProfilePic: null,
  applicationStatusSequence: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  applicationNoteCount: 0,
  studentTypes: [],
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENT_LIST:
      return { ...state };
    case GET_DEPARTMENT_LIST_SUCCESS:
      return { ...state, departmentList: action.payload };
    case GET_PROGRAM_LIST_SUCCESS:
      return { ...state, programList: action.payload };
    case GET_STUDY_LEVEL_SUCCESS:
      return { ...state, studyLevelList: action.payload };
    case GET_APPLICATION_STATUS_LIST_SUCCESS:
      return { ...state, statusList: action.payload };
    case GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS:
      return {
        ...state,
        chatCount: action.payload.data.comment_count,
        messageCount: action.payload.data.message_count,
      };
    case GET_APPLICATION_STATUS_SUCCESS:
      let status = null;
      let applicationStatus = null;
      let appStatusString = "";
      let appStatusSequence = 0;

      if (action.payload.data.status && action.payload.data.status.app_status) {
        status = action.payload.data.status.app_status;
      }
      if (status) {
        let currentStatus = generateApplicationStatusString(
          status.student_status
        );

        applicationStatus = status;
        appStatusString = currentStatus;
        appStatusSequence = parseFloat(status.sequence_id);
      }

      return {
        ...state,
        applicationStatus,
        appStatusString,
        appStatusSequence,
      };
    case GET_APPLICATION_DETAILS_SUCCESS:
      let updates = {};
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.app_status
      ) {
        let currentStatus = generateApplicationStatusString(
          action.payload.data.app_status.university_status
        );

        updates.applicationStatus = action.payload.data.app_status;
        updates.appStatusString = currentStatus;
        updates.appStatusSequence = parseFloat(
          action.payload.data.app_status.sequence_id
        );
      }

      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.student &&
        action.payload.data.student.app_student_details
      ) {
        if (action.payload.data.student.app_student_details.filename) {
          updates.studentProfilePic =
            baseurl + action.payload.data.student.app_student_details.filename;
        }
      }
      if (updates) {
        return {
          ...state,
          ...updates,
        };
      }
    case GET_APPLICATION_NOTE_COUNT_SUCCESS:
      let applicationNoteCount = 0;
      if (action.payload && action.payload.success) {
        applicationNoteCount = action.payload.data;
      }
      return {
        ...state,
        applicationNoteCount,
      };
    case GET_STUDENT_TYPES_LIST_SUCCESS:
      let studentTypes = [];
      if (action.payload && action.payload.data && action.payload.data.length) {
        studentTypes = action.payload.data;
      }
      return {
        ...state,
        studentTypes,
      };
    default:
      return state;
  }
}
