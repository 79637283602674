import "./AppUploadDocument.css";
import Addmore from "../Addmore/Addmore";
import Pricon from "../../assets/img/student/folder-icon.svg";
import Bluefolder from "../../assets/img/student/blue-folder.svg";
import dwnicon from "../../assets/img/download.png";
import dlteicon from "../../assets/img/delete.svg";
import React from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  InputGroup,
  InputGroupText

} from "reactstrap";
import config from "../../config.json";
import gDriveIcon from "../../assets/img/student/gDriveIcon.svg";
import Closeicon from "../../assets/img/close-btn.svg";
import keyboard_arrow_down from "../../assets/img/student/keyboard_arrow_down.png";
import FolderNotchOpen from "../../assets/img/student/FolderNotchOpen.png";
import { GOOGLE_CLIENT_ID, GOOGLE_DEV_KEY } from "../../constant/config";
import GooglePicker from "react-google-picker";
import { FileDrop } from "react-file-drop";
import FormError from "../../common/FormError";
import SuccessIcon from "../../assets/img/correct-green2.png";
import CompleteProfileMessage from "./CompleteProfileMessage";
import CommentPopover from "../ApplicationAddComment/component/CommentPopover";
import DeleteIcon from "../../assets/img/icons/modal_close_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/close-btn.svg";
import "./AppUploadDocument.scss";
import Dropzone from "react-dropzone";
import { ReactComponent as UploadcloudIcon } from "../../assets/img/icons/upload_icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/img/close-btn.svg";
import { ApplicationDocumentList } from "../commonComponents/ApplicationDocumentsList";
// import Infoicon from "../../assets/img/student/info-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/img/student/info-icon.svg";
import { ReactComponent as RemoveIcons} from "../../assets/img/icons/remove_icon.svg";
import { ReactComponent as UploadIcon} from "../../assets/img/icons/upload_icon.svg";



const styles = {
  border: "1px solid black",
  width: 600,
  color: "black",
  padding: 10,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: 20,
};

function template() {
  const imgpath = `${config.baseurl}`;
  return (
    <div className="upload-docment">
      <div className="tab-wrap destination-box box newBg test">
        {/* <CompleteProfileMessage
          {...this.props}
          show={this.state.showCompleteProfileMessage}
          toggleShowHide={() => {
            this.setState({
              showCompleteProfileMessage: false,
            });
          }}
        /> */}
        <Row className="justify-content-center">
          {this.props.applicationFormId ? (
            <Col sm={12} md={5}>
              <div className="pr-header mb-10">
                <h3 className="title text-center">
                  Upload Supporting Documents
                </h3>
              </div>
            </Col>
          ) : (
            <Col sm={12} md={5}>
              <div className="pr-header">
                <h3 className="title">Documents Required</h3>
              </div>
              <div className="mt-3">
                <p>Please Attach Files To Complete Your Application</p>
              </div>
            </Col>
          )}
        </Row>
        {/*<div className="upload-btn-wrapper">
                {this.state.disabled === true ? null : (
                  <Button color="primary" className="blue-addmore">
                    + Add files
                  </Button>
                )}
                <Input
                  type="file"
                  id="upfile"
                  className=""
                  accept="image/*,application/pdf"
                  onChange={this.onFileChange}
                  name="upload_photo[]"
                />

                <div>{this.state.filename}</div>
              </div>
              <h6
                style={{ color: "red", marginLeft: "20px", paddingTop: "10px" }}
              >
                {this.state.errors.fileLength}
              </h6>*/}

        {this.props.applicationFormId ? (
          <>
            <Row className="justify-content-center uploadDocs">
              {this.state.supportingDocuments &&
                this.state.supportingDocuments.length ? (
                this.state.supportingDocuments.map((val, index) => (
                  <Col xs={10} key={index + "listdiv"}>
                    <Row>
                      <Col xs={12} md={4} className="text-right">
                       <span className="docsName">{val.document_name}
                        {val?.document_type !== 1
                          ? null
                          : (<>
                            <UncontrolledTooltip
                              placement="top"
                              target={"tip-" + index}
                              placementPrefix="bs-tooltip"
                            >Application will not be processed if you do not upload this file
                            </UncontrolledTooltip>
                            {/* <img src={Infoicon} alt="infoicon"  /> */}
                            <InfoIcon className="infoIcon" id={"tip-" + index} />

                          </>)
                        }
                        </span>
                      </Col>

                      <Col xs={12} md={8}>
                        
                        {val.selectedFile || val.document ? (
                          <>
                            <InputGroup className="fileInput">
                              <input
                                type={"text"}
                                defaultValue={val.selectedFile
                                  ? val.selectedFile
                                  : val.document
                                    ? val.document.orgfilename
                                    : ""}

                              />
                              <InputGroupText className="iconBox">
                                <RemoveIcons className="inputIcon" onClick={() => this.removeSelectedFile(index)} />
                              </InputGroupText>
                            </InputGroup>
                          </>
                        ) : (
                          <InputGroup className="fileInput upload mb-20">
                            <Input type={"file"} onChange={(e) => this.fileChange(e.target.files, val.id, index)} />
                            <InputGroupText className="iconBox">
                              <UploadIcon className="inputIcon upload" />
                            </InputGroupText>
                          </InputGroup>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ))
              ) : (
                <>
                  {this.props.showComment ||
                    this.props.showSaveSubmit == false ? null : (
                    <Col sm={12} md={5}>
                      <Dropzone
                        onDrop={(files) => {
                          this.fileChange(files);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dragNdrop">
                            <div
                              {...getRootProps({
                                className: "dropzone",
                              })}
                            >
                              <input
                                multiple={true}
                                {...getInputProps({
                                  // accept: "application/pdf",
                                })}
                                onChange={(event) => {
                                  let file = event.target.files;
                                  this.fileChange(file);
                                }}
                              />
                              <span>
                                <UploadcloudIcon /> Upload or drop your files
                                here
                              </span>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Col>
                  )}

                  <Col sm={12}></Col>

                  <Col sm={12}>
                    {this.state.existingApplicationPdfs &&
                      this.state.existingApplicationPdfs.length ? (
                      <ApplicationDocumentList
                        fileList={this.state.existingApplicationPdfs}
                        showRemove={
                          this.props.showComment ||
                            this.props.showSaveSubmit == false
                            ? false
                            : true
                        }
                        showSaveSubmit={this.props.showSaveSubmit}
                        onRemove={this.onRemoveExisting}
                        showSize={false}
                        progress={this.state.applicationPdfsProgress}
                      />
                    ) : null}
                    <ApplicationDocumentList
                      fileList={this.state.applicationPdfs}
                      showRemove={true}
                      onRemove={this.onRemove}
                      showProgress={true}
                      progress={this.state.applicationPdfsProgress}
                      showSaveSubmit={this.props.showSaveSubmit}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        ) : (
          <>
            <div className="row docs-box pl-0 mt-4">
              <div className="mand-label text-center">Mandatory</div>
              <div className="col-md-11 mt-3 mb-3">
                {this.props.showComment ? (
                  <CommentPopover {...this.props} id={"english_exam"} />
                ) : null}
                <p>
                  Certified evidence of English language proficiency like IELTS,
                  TOEFL, PTE and ELICOS, etc.
                </p>
                <Row>
                  <Col xs="12" md="10">
                    <Row>
                      {Array.isArray(this.state.docIetsToefl) &&
                        this.state.docIetsToefl.map((docs, index) => (
                          <Col xs="6" md="3" key={index}>
                            <div className="file-view d-flex">
                              <a
                                target="_blank"
                                href={`${imgpath}${docs.filename}`}
                              >
                                {docs.orgfilename}
                              </a>
                              <span className="ml-3">
                                {!this.props.showComment ? (
                                  <img
                                    src={Closeicon}
                                    alt="home-icon"
                                    onClick={this.deleteFile}
                                    data-column={docs.id}
                                    className="uni-icon pr-2 file-view-close"
                                  />
                                ) : null}
                              </span>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xs="12" md="2" className="text-right">
                    {this.props.draftPage && (
                      <div className="upload-btn-wrapper profile-btn align-middle">
                        {!this.props.showComment ? (
                          <Button
                            color="primary"
                            className=""
                            onClick={this.handleAddFileModal1}
                          >
                            + Add files
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>

                <Modal
                  centered
                  isOpen={this.state.fileModalOpen1}
                  className={"primaryModal"}
                  toggle={() => this.setState({ fileModalOpen1: false })}
                  size="md"
                >
                  <ModalBody className="addfilepopup">
                    <div
                      className="closeIcon"
                      onClick={() => this.setState({ fileModalOpen1: false })}
                    >
                      <CloseIcon />
                    </div>
                    <h2>
                      <img src={FolderNotchOpen} alt="FolderNotchOpen" /> Add
                      Files
                    </h2>

                    <input
                      type="file"
                      name="myfile"
                      multiple="true"
                      onChange={this.handleBrowseFromDevice}
                      ref={(ref) => (this.fileSelectionRef.current = ref)}
                      style={{
                        display: "none",
                      }}
                    />

                    <div className="drop-img-sec">
                      <div className="">
                        <FileDrop
                          onDrop={(files, event) =>
                            this.handleDropFiles(files, event)
                          }
                        >
                          <UncontrolledDropdown setActiveFromChild>
                            <DropdownToggle caret>Browse files</DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>
                                <div className="upload-btn-wrapper">
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      this.fileSelectionRef.current.click();
                                    }}
                                  >
                                    <img
                                      src={FolderNotchOpen}
                                      alt="FolderNotchOpen"
                                    />
                                    <span>From Device</span>
                                  </button>
                                </div>
                              </DropdownItem>
                              {/* <DropdownItem>
                                  <div
                                    className="upload-btn-wrapper"
                                    onClick={() =>
                                      this.handleBrowseFrom(`Google Drive`)
                                    }
                                  >
                                    <button className="btn">
                                      <GooglePicker
                                        clientId={GOOGLE_CLIENT_ID}
                                        developerKey={GOOGLE_DEV_KEY}
                                        scope={[
                                          "https://www.googleapis.com/auth/drive.readonly",
                                        ]}
                                        onChange={(data) =>
                                          this.handleGPickerFileChange(data)
                                        }
                                        onAuthenticate={(token) =>
                                          console.log("oauth token:", token)
                                        }
                                        onAuthFailed={(data) =>
                                          console.log("on auth failed:", data)
                                        }
                                        multiselect={true}
                                        navHidden={true}
                                        authImmediate={false}
                                        viewId={"DOCS"}
                                      >
                                        <img src={gDriveIcon} alt="gDriveIcon" />{" "}
                                        <span>From Google Drive</span>
                                        <div
                                          className="google"
                                          style={{ zIndex: "2000" }}
                                        />
                                      </GooglePicker>
                                    </button>
                                  </div>
                                </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          OR Drag and drop files here
                        </FileDrop>
                      </div>
                      {this.state.selectedFiles.length ? (
                        <>
                          <span className="fileList">File Upload</span>
                          <ul>
                            {this.state.selectedFiles.map((val, index) => (
                              <li key={index}>
                                {val.name}
                                <img
                                  src={DeleteIcon}
                                  className="fileDeleteIcon"
                                  onClick={() => {
                                    let { selectedFiles } = this.state;
                                    selectedFiles.splice(index, 1);

                                    this.setState({
                                      selectedFiles,
                                    });
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : null}

                      {this.state.errors.fileLength && (
                        <FormError error={this.state.errors.fileLength} />
                      )}
                    </div>
                    <div className="form-group">
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle caret>
                          {this.state.fileTypes
                            .filter(
                              (val) => val.value == this.state.uploadDocType
                            )
                            .map((val) => val.label)}
                        </DropdownToggle>

                        <DropdownMenu>
                          {this.state.fileTypes.map((val, index) => (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                this.handleUploadDocTypeChange(val.value);
                              }}
                            >
                              {val.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {this.state.errors.docType && (
                        <FormError error={this.state.errors.docType} />
                      )}
                    </div>
                  </ModalBody>

                  <ModalFooter className="addfilepopup">
                    <div className="text-center pp-btn">
                      <Button
                        outline
                        color="primary"
                        onClick={this.handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" onClick={this.handleUploadFiles}>
                        Upload
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>

                <Modal
                  isOpen={this.state.profileFilledSuccessModalOpen}
                  toggle={this.toggleSuccess}
                  className={
                    "modal-md" +
                    "register-popup sucess-pop profile-success-pop" +
                    " " +
                    this.props.className
                  }
                >
                  <ModalBody className="profile-success-pop-body">
                    <div className="modal_header mb-4 ">
                      <a href="#/Viewprofile">
                        <span>
                          <img
                            src={Closeicon}
                            alt="close-icon"
                            className="uni-icon"
                          />
                        </span>
                      </a>
                    </div>
                    <div className="px-5 pt-3 pb-5 text-center">
                      <div className="mb-3 sucess-head">
                        <img className="mr-6" src={SuccessIcon} alt="Success" />
                      </div>
                      <div className="mb-4 sucess-text-profile">
                        You have successfully create your profile.
                        <br />
                        <br />
                      </div>
                      <a href="#/Viewprofile">
                        <Button
                          color="primary"
                          className="profile-success mx-auto"
                        >
                          BACK TO MY PROFILE
                        </Button>
                      </a>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.successUpload}
                  toggle={this.toggleSuccess}
                  className={
                    "" +
                    "register-popup sucess-pop profile-success-pop" +
                    " " +
                    this.props.className
                  }
                >
                  <ModalBody className="modal-saveSearch">
                    <div className="modal_header mb-4 ">
                      <a onClick={() => this.closeSaveSearches()}>
                        <span>
                          <img
                            src={Closeicon}
                            alt="close-icon"
                            className="uni-icon"
                            height="17"
                            width="17"
                          />
                        </span>
                      </a>
                    </div>
                    <div className="px-5 pt-3 pb-5 text-center">
                      <div className="mb-3 saveSearchFont-header">
                        {/* <img className="mr-6" src={Infoicon} alt="Success" />&nbsp;&nbsp; */}
                        <h3>Thank you for submitting your application.</h3>
                        <br />
                      </div>
                      <div className="sucess-text-saveSearch">
                        It is under review with the admin. <br />
                        We will contact you within 24-48 hours.
                        <br />
                        <br />
                      </div>
                      {/* <div className="col-12 d-flex filter-bottom flex-column">
                          <div className="row">
                            <div className="col-md-12 flex2">
                              <Button
                                color="primary"
                                className="score-save beforesave "
                                type="submit"
                                onClick={() => this.closeSaveSearches()}
                                style={{ marginLeft: "15px" }}
                              >
                                SAVE
                              </Button>
                              <Button
                                color="primary"
                                className="score-save beforesave reset"
                                type="submit"
                                onClick={() => this.closeSaveSearches()}
                                style={{ marginLeft: "20px" }}
                              >
                                NO, LATER
                              </Button>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.fileModalOpenQuesAnsrs}
                  className={
                    "addfile-pop-outer modal-lg max-250 " + this.props.className
                  }
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({ fileModalOpenQuesAnsrs: false })
                    }
                  />
                  <ModalBody className="addfilepopup student-popup text-center">
                    <h2>+ Add Files two</h2>

                    <div className="add_file_sec">
                      <div
                        className="add-file-header d-flex justify-content-between align-items-center"
                        onClick={() => {
                          this.setState((prevState) => ({
                            fileDropdownOpen: !prevState.fileDropdownOpen,
                          }));
                        }}
                      >
                        <h2>Browse files</h2>
                        <div className="afh_icon">
                          <img
                            src={keyboard_arrow_down}
                            alt="keyboard_arrow_down"
                          />
                        </div>
                      </div>
                      {this.state.fileDropdownOpen ? (
                        <div className="add-file-content">
                          <ul>
                            <li>
                              <div className="upload-btn-wrapper">
                                <button className="btn">
                                  <img
                                    src={FolderNotchOpen}
                                    alt="FolderNotchOpen"
                                  />
                                  <span>From Device</span>
                                </button>
                                <input
                                  type="file"
                                  name="myfile"
                                  multiple="true"
                                  onChange={this.handleBrowseFromDevice}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <h3> OR </h3>
                    <div className="drop-img-sec">
                      <div className="text-center" style={styles}>
                        <FileDrop
                          onDrop={(files, event) =>
                            this.handleDropFiles(files, event)
                          }
                        >
                          Drag & Drop files here
                        </FileDrop>
                      </div>
                      {this.state.filename ? (
                        <ul>
                          {/* {this.state.filename.map((doc, index) => (
                            <li key={index.toString()}>{doc}</li>
                          ))} */}
                          {this.state.filename}
                        </ul>
                      ) : null}
                      {this.state.errors.fileLength && (
                        <FormError error={this.state.errors.fileLength} />
                      )}
                    </div>
                    {/*<div className="form-group  text-center">
                      <div className="select-wrapper">
                        <Input
                          type="select"
                          id="uploadDocType"
                          onChange={this.handleUploadDocTypeChange}
                          className=""
                          value={this.state.uploadDocType}
                          style={{ textTransform: "uppercase" }}
                        >
                          <option value="">Type of Document</option>
                          <option value="doc_medical_records">
                            Medical Records
                          </option>
                          <option value="doc_academic_records">
                            Academic Records
                          </option>
                          <option value="doc_entry_exam_results">
                            Entry Exam Results
                          </option>
                          <option value="doc_personal_documents">
                            Personal documents
                          </option>
                          <option value="doc_immigration_documents">
                            Immigration documents
                          </option>
                          <option value="doc_other">Other</option>
                        </Input>
                      </div>
                      {this.state.errors.docType && (
                        <FormError error={this.state.errors.docType} />
                      )}
                    </div> */}
                    <Input
                      type="hidden"
                      id="type_cat"
                      value={this.state.type_cat}
                    />
                    <div className="text-center pp-btn">
                      <Button
                        color="secondary"
                        // disabled={!this.state.formData}
                        onClick={this.handleUploadFilesQuesAnsr}
                        className="upload-btn"
                      >
                        Upload
                      </Button>
                      <Button
                        color="secondary"
                        className="cancle-btn"
                        onClick={this.handleCancelUpload}
                      >
                        Cancel
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.profileFilledSuccessModalOpen}
                  toggle={this.toggleSuccess}
                  className={
                    "modal-md" +
                    "register-popup sucess-pop profile-success-pop" +
                    " " +
                    this.props.className
                  }
                >
                  <ModalBody className="profile-success-pop-body">
                    <div className="modal_header mb-4 ">
                      <a href="#/Viewprofile">
                        <span>
                          <img
                            src={Closeicon}
                            alt="close-icon"
                            className="uni-icon"
                          />
                        </span>
                      </a>
                    </div>
                    <div className="px-5 pt-3 pb-5 text-center">
                      <div className="mb-3 sucess-head">
                        <img className="mr-6" src={SuccessIcon} alt="Success" />
                      </div>
                      <div className="mb-4 sucess-text-profile">
                        You have successfully create your profile.
                        <br />
                        <br />
                      </div>
                      <a href="#/Viewprofile">
                        <Button
                          color="primary"
                          className="profile-success mx-auto"
                        >
                          BACK TO MY PROFILE
                        </Button>
                      </a>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>

            <div className="row docs-box pl-0 mt-4">
              <div className="mand-label text-center">Mandatory</div>
              <div className="col-md-11 mt-3 mb-3">
                {this.props.showComment ? (
                  <CommentPopover {...this.props} id={"year_education"} />
                ) : null}
                <p>
                  Certified documented evidence of Year 12 education r
                  equivalent (with certified translation, if not in English)
                </p>
                <Row>
                  <Col xs="12" md="10">
                    <Row>
                      {Array.isArray(this.state.docYear12Edu) &&
                        this.state.docYear12Edu.map((docs, index) => (
                          <Col xs="6" md="3" key={index}>
                            <div className="file-view d-flex">
                              <a
                                target="_blank"
                                href={`${imgpath}${docs.filename}`}
                              >
                                {docs.orgfilename}
                              </a>
                              <span className="ml-3">
                                {!this.props.showComment ? (
                                  <img
                                    src={Closeicon}
                                    alt="home-icon"
                                    onClick={this.deleteFile}
                                    data-column={docs.id}
                                    className="uni-icon pr-2 file-view-close"
                                  />
                                ) : null}
                              </span>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xs="12" md="2" className="text-right">
                    {this.props.draftPage && (
                      <div className="upload-btn-wrapper profile-btn align-middle">
                        {!this.props.showComment ? (
                          <Button
                            color="primary"
                            className=""
                            onClick={this.handleAddFileModal2}
                          >
                            + Add files
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="row docs-box pl-0 mt-4">
              <div className="mand-label text-center">Mandatory</div>
              <Col className="col-md-11 mt-3 mb-3">
                {this.props.showComment ? (
                  <CommentPopover {...this.props} id={"qualifications"} />
                ) : null}
                <p>
                  Certified documented evidence of other previous qualifications
                  if applicable (with certified translation, if not in English)
                </p>
                <Row>
                  <Col xs="12" md="10">
                    <Row>
                      {Array.isArray(this.state.preQualiApp) &&
                        this.state.preQualiApp.map((docs, index) => (
                          <Col xs="6" md="3" key={index}>
                            <div className="file-view d-flex">
                              <a
                                target="_blank"
                                href={`${imgpath}${docs.filename}`}
                              >
                                {docs.orgfilename}
                              </a>
                              <span className="ml-3">
                                {!this.props.showComment ? (
                                  <img
                                    src={Closeicon}
                                    alt="home-icon"
                                    onClick={this.deleteFile}
                                    data-column={docs.id}
                                    className="uni-icon pr-2 file-view-close"
                                  />
                                ) : null}
                              </span>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xs="12" md="2" className="text-right">
                    {this.props.draftPage && (
                      <div className="upload-btn-wrapper profile-btn align-middle">
                        {!this.props.showComment ? (
                          <Button
                            color="primary"
                            className=""
                            onClick={this.handleAddFileModal3}
                          >
                            + Add files
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </div>

            <div className="row docs-box pl-0 mt-4">
              <div className="mand-label text-center">Mandatory</div>
              <div className="col-md-11 mt-3 mb-3">
                {this.props.showComment ? (
                  <CommentPopover {...this.props} id={"passport"} />
                ) : null}
                <p>Certified copy of Passport</p>
                <Row>
                  <Col xs="12" md="10">
                    <Row>
                      {Array.isArray(this.state.certifPassport) &&
                        this.state.certifPassport.map((docs, index) => (
                          <Col xs="6" md="3" key={index}>
                            <div className="file-view d-flex">
                              <a
                                target="_blank"
                                href={`${imgpath}${docs.filename}`}
                              >
                                {docs.orgfilename}
                              </a>
                              <span className="ml-3">
                                {!this.props.showComment ? (
                                  <img
                                    src={Closeicon}
                                    alt="home-icon"
                                    onClick={this.deleteFile}
                                    data-column={docs.id}
                                    className="uni-icon pr-2 file-view-close"
                                  />
                                ) : null}
                              </span>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xs="12" md="2" className="text-right">
                    {this.props.draftPage && (
                      <div className="upload-btn-wrapper profile-btn align-middle">
                        {!this.props.showComment ? (
                          <Button
                            color="primary"
                            className=""
                            onClick={this.handleAddFileModal4}
                          >
                            + Add files
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="personal-box-inner mt-4 mb-5">
              {/* {(Array.isArray(this.state.uniQuestUploadArr) &&
            this.state.uniQuestUploadArr.length) ||
          (Array.isArray(this.state.uniQuestUploadArr) &&
            this.state.uniQuestUploadArr.length) ? (
            <div className="pr-header">
              <h3>Other Information</h3>
            </div>
          ) : null}


          {Array.isArray(this.state.uniQuestUploadArr) &&
            this.state.uniQuestUploadArr.map((uniQuestUpload, index) =>
              uniQuestUpload.file_status == 3 ? (
                <div
                  className="row col-sm-12 ml-0 mr-0 docs-box pl-0 mt-4"
                  key={index}
                >
                  <div className="mand-label text-center">Mandatory</div>
                  <div className="col-md-8 mt-4">
                    <div>{uniQuestUpload.question}</div>
                    <div className="row ml-0">
                      <div className="mt-1 file-view p-2 mr-1">
                        <a
                          target="_blank"
                          href={`${imgpath}${"/student_uploads/documents/"}${
                            uniQuestUpload.answers
                          }`}
                        >
                          {(() => {
                            if (
                              uniQuestUpload.uni_app_que_id ==
                                uniQuestUpload.id &&
                              uniQuestUpload.tab_name == "documents" &&
                              uniQuestUpload.file_status == 3 &&
                              localStorage.getItem("studentid") ==
                                uniQuestUpload.student_id
                            ) {
                              return uniQuestUpload.answers;
                            }
                            return null;
                          })()}
                        </a>

                        {(() => {
                          if (
                            uniQuestUpload.uni_app_que_id ==
                              uniQuestUpload.id &&
                            uniQuestUpload.tab_name == "documents" &&
                            localStorage.getItem("studentid") ==
                              uniQuestUpload.student_id
                          ) {
                            return (
                              <span className="ml-3">
                                <img
                                  src={Closeicon}
                                  alt="home-icon"
                                  onClick={this.deleteQuesAnsrsFile}
                                  data-column={uniQuestUpload.aid}
                                  className="uni-icon pr-2 file-view-close"
                                />
                              </span>
                            );
                          }
                          return null;
                        })()}
                      </div>
                    </div>

                    <div className="upload-btn-wrapper profile-btn align-middle">
                      <Button
                        color="primary"
                        className="qusAnsrMandtry"
                        onClick={this.handleAddQuesAnsrsMandtry}
                        data-id={uniQuestUpload.id}
                      >
                        + Add files
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null
            )}

          {Array.isArray(this.state.uniQuestUploadArr) &&
            this.state.uniQuestUploadArr.map((uniQuestUpload, index) =>
              uniQuestUpload.file_status == 2 ? (
                <div
                  className="row col-sm-12 ml-0 mr-0 docs-box pl-0 mt-4"
                  key={index}
                >
                  <div className="mand-label-optional text-center">
                    Optional
                  </div>
                  <div className="col-md-8 mt-4">
                    <div>{uniQuestUpload.question}</div>
                    <div className="row ml-0">
                      <div className="mt-1 file-view p-2 mr-1">
                        <a
                          target="_blank"
                          href={`${imgpath}${"/student_uploads/documents/"}${
                            uniQuestUpload.answers
                          }`}
                        >
                          {(() => {
                            if (
                              uniQuestUpload.uni_app_que_id ==
                                uniQuestUpload.id &&
                              uniQuestUpload.tab_name == "documents" &&
                              uniQuestUpload.file_status == 2 &&
                              localStorage.getItem("studentid") ==
                                uniQuestUpload.student_id
                            ) {
                              return uniQuestUpload.answers;
                            }
                            return null;
                          })()}
                        </a>

                        {(() => {
                          if (
                            uniQuestUpload.uni_app_que_id ==
                              uniQuestUpload.id &&
                            uniQuestUpload.tab_name == "documents" &&
                            localStorage.getItem("studentid") ==
                              uniQuestUpload.student_id
                          ) {
                            return (
                              <span className="ml-3">
                                <img
                                  src={Closeicon}
                                  alt="home-icon"
                                  onClick={this.deleteQuesAnsrsFile}
                                  data-column={uniQuestUpload.aid}
                                  className="uni-icon pr-2 file-view-close"
                                />
                              </span>
                            );
                          }
                          return null;
                        })()}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    <div className="upload-btn-wrapper profile-btn align-middle">
                      <Button
                        color="primary"
                        className=""
                        onClick={this.handleAddQuesAnsrsMandtry}
                        data-id={uniQuestUpload.id}
                      >
                        + Add files
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null
            )} */}

              {this.state.appDocumentsExtra &&
                this.state.appDocumentsExtra.length
                ? this.state.appDocumentsExtra.map((val, index) => (
                  <div className="row docs-box pl-0 mt-4" key={index}>
                    <div
                      className={`${val.question_details.file_status == 2
                        ? "mand-label-optional"
                        : "mand-label"
                        } text-center`}
                    >
                      {val.question_details.file_status == 2
                        ? "Optional"
                        : "Mandatory"}
                    </div>
                    <div className="col-md-11 mt-3 mb-3">
                      <p>{val.question_details.question}</p>
                      <Row>
                        <Col xs="12" md="10">
                          <Row>
                            {val.documents && val.documents.length
                              ? val.documents.map((val, index) => (
                                <Col xs="6" md="3" key={index}>
                                  <div className="file-view d-flex">
                                    <a
                                      target="_blank"
                                      href={`${imgpath}${val.filename}`}
                                    >
                                      {val.orgfilename}
                                    </a>
                                    <span className="ml-3">
                                      {!this.props.showComment ? (
                                        <img
                                          src={Closeicon}
                                          alt="home-icon"
                                          onClick={this.deleteFile}
                                          className="uni-icon pr-2 file-view-close"
                                        />
                                      ) : null}
                                    </span>
                                  </div>
                                </Col>
                              ))
                              : null}
                          </Row>
                        </Col>

                        <Col xs="12" md="2" className="text-right">
                          <div className="upload-btn-wrapper profile-btn align-middle">
                            {this.props.draftPage &&
                              !this.props.showComment ? (
                              <Button
                                color="primary"
                                className=""
                                onClick={(e) => {
                                  this.handleExtraModal(
                                    e,
                                    val.question_details.id
                                  );
                                }}
                              >
                                + Add files
                              </Button>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </>
        )}

        {/*<div className="row col-sm-12 ml-0 mr-0 docs-box pl-0 mt-4">
              <div className="mand-label text-center">Mandatory</div>
                <div className="col-md-8 mt-4">
                  <div>Certified documented evidence of other previous qualifications if applicable (with certified translation, if not in English)</div>
                  <div className="mt-1 file-view p-2">
                    {/*<a href="">English language proficiency.jpg</a>
                    <span className="ml-3">
                        <img src={Closeicon} alt="home-icon" onClick={this.closepop3} className="uni-icon pr-2 file-view-close" />
                    </span> */}
        {/*</div>
                </div>
                <div className="col-md-3 mt-4">
                  <div className="upload-btn-wrapper profile-btn align-middle float-right">
                    <Button
                      color="primary"
                      className=""
                      onClick={this.handleAddFileModal}
                    >
                      + Add files
                    </Button>
                  </div>
                </div>
            </div>

            <hr className="mt-4 mb-4" /> */}

        {/*<div className="row col-sm-12 ml-0 mr-0 docs-box pl-0 mt-4">
              <div className="mand-label-optional text-center">Optional</div>
                <div className="col-md-8 mt-4">
                  <div>Certified documented evidence of other previous qualifications if applicable (with certified translation, if not in English)</div>
                  <div className="row ml-0">
                    {Array.isArray(this.state.optinalDocs) &&
                      this.state.optinalDocs.map((docs, index) =>
                        <div className="mt-1 file-view p-2 mr-1">
                          <a target="_blank" href={`${imgpath}${docs.filename}`}>{docs.orgfilename}</a>
                          <span className="ml-3">
                              <img src={Closeicon} alt="home-icon" onClick={this.deleteFile}  data-column={docs.id} className="uni-icon pr-2 file-view-close" />
                          </span>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <div className="upload-btn-wrapper profile-btn align-middle float-right">
                    <Button
                      color="primary"
                      className=""
                      onClick={this.handleAddFileModal6}
                    >
                      + Add files
                    </Button>
                  </div>
                </div>
            </div> */}

        {/*
            <div className="row">
              <div className="col-sm-10 lib-add mt-4">
                <h4 className="d-inline-block pr-md-5 pr-3 upload-doc-title">
                  Folder
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12 row">
                <div className="col-md-3 col-lg-3 col-xl-3 lib-box">
                  <div className="media personal">
                    <img className="mr-3" src={Pricon} alt="file name" />
                    <div
                      className="media-body"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleFolderChange("1")}
                    >
                      <h5 className="mt-0">Personal</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 lib-box">
                  <div className="media transcripts">
                    <img className="mr-3" src={Pricon} alt="file name" />
                    <div
                      className="media-body"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleFolderChange("2")}
                    >
                      <h5 className="mt-0">Transcripts</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-10 lib-add mt-4 pb-0">
                <h4 className="d-inline-block pr-md-5 pr-3 upload-doc-title">
                  Files
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 row">
                {this.state.showtranscripts ? (
                  <React.Fragment>
                    {Array.isArray(this.state.trans) &&
                      this.state.trans.map((trans, index) => (
                        <div
                          key={index.toString()}
                          className="col-md-3 col-lg-3 col-xl-3 lib-box mt-3"
                        >
                          <div className="media flex-column pl-0">
                            <div className="media-lib text-center mt-4">
                              <img
                                className=""
                                src={Bluefolder}
                                alt="file name"
                                width="90"
                              />
                            </div>
                            <div className="media-body text-center">
                              <a
                                href={imgpath + trans.filename}
                                target="_blank"
                                id="down_img"
                              >
                                <h5 className="mt-0 mb-0">
                                  {trans.orgfilename}
                                </h5>
                              </a>
                            </div>
                            <div className="col-12">
                              {this.state.disabled === false ? (
                                <div className="row float-right">
                                  <a target="_blank" download>
                                    <img
                                      className="ml-2 mt-2 mb-2"
                                      src={dwnicon}
                                      alt="file name"
                                      width="10"
                                    />
                                  </a>
                                  <a onClick={this.onClickDelete(trans.id)}>
                                    <img
                                      className="m-2"
                                      src={dlteicon}
                                      alt="file name"
                                      width="10"
                                    />
                                  </a>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                {this.state.showpersonal ? (
                  <React.Fragment>
                    {Array.isArray(this.state.pers) &&
                      this.state.pers.map((pers, index) => (
                        <div
                          key={index.toString()}
                          className="col-md-3 col-lg-3 col-xl-3 lib-box mt-3"
                        >
                          <div className="media flex-column pl-0">
                            <div className="media-lib text-center mt-4">
                              <img
                                className=""
                                src={Bluefolder}
                                alt="file name"
                                width="90"
                              />
                            </div>
                            <div className="media-body text-center">
                              <a
                                href={imgpath + pers.filename}
                                target="_blank"
                                id="down_img"
                              >
                                <h5 className="mt-0 mb-0">
                                  {pers.orgfilename}
                                </h5>
                              </a>
                            </div>
                            <div className="col-12">
                              {this.state.disabled === false ? (
                                <div className="row float-right">
                                  <a target="_blank" download>
                                    <img
                                      className="ml-2 mt-2 mb-2"
                                      src={dwnicon}
                                      alt="file name"
                                      width="10"
                                    />
                                  </a>
                                  <a onClick={this.onClickDelete(pers.id)}>
                                    <img
                                      className="m-2"
                                      src={dlteicon}
                                      alt="file name"
                                      width="10"
                                    />
                                  </a>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                {Array.isArray(this.state.docs) &&
                  this.state.docs.map((docs, index) => (
                    <div
                      key={index.toString()}
                      className="col-md-3 col-lg-3 col-xl-3 lib-box mt-3"
                    >
                      <div className="media flex-column pl-0">
                        <div className="media-lib text-center mt-4">
                          <img
                            className=""
                            src={Bluefolder}
                            alt="file name"
                            width="90"
                          />
                        </div>
                        <div className="media-body text-center">
                          <a
                            href={imgpath + docs.filename}
                            target="_blank"
                            id="down_img"
                          >
                            <h5 className="mt-0 mb-0">{docs.orgfilename}</h5>
                          </a>
                        </div>
                        <div className="col-12">
                          {this.state.disabled === false ? (
                            <div className="row float-right">
                              <a target="_blank" download>
                                <img
                                  className="ml-2 mt-2 mb-2"
                                  src={dwnicon}
                                  alt="file name"
                                  width="10"
                                />
                              </a>
                              <a onClick={this.onClickDelete(docs.id)}>
                                <img
                                  className="m-2"
                                  src={dlteicon}
                                  alt="file name"
                                  width="10"
                                />
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end mr-3">
              {this.state.disabled === false ? (
                <Button
                  color="primary"
                  className="score-save"
                  type="submit"
                  onClick={this.onSubmit}
                >
                  Upload
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="score-back mx-4 aftersave"
                  onClick={this.prevTab}
                >
                  BACK
                </Button>
              )}
            </div> */}
        {this.props.draftPage && (
          <div className="col-12 d-flex justify-content-center mb-30">
            {/* {!this.props.showComment ? (
              <>
                <Button
                  color="primary"
                  outline
                  onClick={this.onSubmit.bind(this)}
                >
                  SAVE
                </Button>

                <Button
                  color="primary"
                  className="ml-20"
                  onClick={() => {
                    this.props.sendApplication();
                  }}
                >
                  SUBMIT
                </Button>
              </>
            ) : null} */}
          </div>
        )}

        {this.props.showComment || this.props.showSaveSubmit == false ? null : (
          <div className="bottomSaveButton">
            <button
              type="button"
              class="beforesave btn btn-secondary"
              onClick={this.onSubmit.bind(this)}
            >
              Save & Exit
            </button>

            <button
              type="button"
              class="beforesave btn btn-primary"
              onClick={() => {
                this.props.sendApplication();
              }}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default template;
