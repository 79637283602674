import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ViewMessage from "./views/Inbox/ViewMessage/viewMessageInbox";
import MakePayment from "./views/Payment/MakePayment";
import CreateMeeting from "./views/Meetings/CreateMeeting";
import ViewMeeting from "./views/Meetings/ViewMeeting";
import LeaveReview from "./views/LeaveReview/LeaveReview";
import { isLoggedIn } from './utils/utils';
import CommentListElement from './views/ApplicationAddComment/index';

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Exam = React.lazy(() => import("./views/Exam/Exam"));
const Answer = React.lazy(() => import("./views/Exam/Answer"));
const Question = React.lazy(() => import("./views/Exam/Question"));
const Examresult = React.lazy(() => import("./views/Exam/Examresult"));
const Examresultmultiple = React.lazy(() => import("./views/Exam/Examresultmultiple"));
const Examwrittenanswer = React.lazy(() => import("./views/Exam/Examwrittenanswer"));

const AddPayment = React.lazy(() => import("./views/Paymentdetails/AddPayment.js"))
const PaymentInfo = React.lazy(() => import("./views/Paymentdetails/PaymentInfo.js"))

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (

    <Route
      {...rest}
      render={
        (props) => {
          const isAuth = isLoggedIn();
          var url = (JSON.stringify(props.location.pathname));
          var urls = JSON.parse(url);
          if (isAuth) {
            if (urls === "/studentprofile") {
              return <DefaultLayout {...props} />;
            }
            else if (urls === "/Librarygeneral") {
              return <DefaultLayout {...props} />;
            }
            else if (urls === "/Librarygre") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Payment") {
              return <DefaultLayout {...props} />;
            }
            else if (urls.includes("/make-payment")) {
              return <MakePayment {...props} />;
            }
            else if (urls.includes("/add-payment")) {
              return <AddPayment {...props} />;
            }
            else if (urls.includes("/payment-info")) {
              return <PaymentInfo {...props} />;
            }
            else if (urls === "/Librarygmat") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/StaffLibraryieltss") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Libraryvideo") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Librarygrevid") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Librarygmatvid") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Libraryieltsvid") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/institutelist") {
              return <DefaultLayout {...props} />;
            } else if (urls.includes("/universityabout")) {
              return <DefaultLayout {...props} />;
            } else if (urls === "/preferedpartnerlist") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/draftapplication") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/sendapplication") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Acceptedapplication") {
              return <DefaultLayout {...props} />;
            } else if (urls.includes("/sendapplicationinprocess")) {
              return <DefaultLayout {...props} />;
            } else if (urls.includes("offerLetter")) {
              return <DefaultLayout {...props} />;
            } else if (urls === "/profiledashboard") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/changepassword") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/paymentDetails") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/profilenotification") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/savedsearch") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/profilewishlist") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/profilewishlistinstitute") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/inboxmail") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/sentmail") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/outboxmail") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/maildraft") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/starredmail") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/composemail") {
              return <DefaultLayout {...props} />;
            } else if (urls.includes("/applications")) {
              return <DefaultLayout {...props} />;
            } else if (urls.includes("/Applications")) {
              return <DefaultLayout {...props} />;
            } else if (urls === "/composemail") {
              return <DefaultLayout {...props} />;
            } else if (urls === "/studentlibrary") {
              return <DefaultLayout {...props} />;
            } else if (urls.includes('Academicbeginner')) {
              return <DefaultLayout {...props} />;
            } else if (urls.includes('viewtutorial')) {
              return <DefaultLayout {...props} />;
            } else if (urls === "/Exam") {
              return <Exam {...props} showLoader={() => { }} hideLoader={() => { }} />;
            } else if (urls === "/practicalExamStart") {
              return <Answer {...props} />;
            } else if (urls === "/Question") {
              return <Question {...props} />;
            } else if (urls === "/Examresult") {
              return <Examresult {...props} />;
            } else if (urls === "/ViewResults") {
              return <Examresultmultiple {...props} />;
            } else if (urls === "/Examwrittenanswer") {
              return <Examwrittenanswer {...props} />;
            }
            else if (urls.includes("/create-meeting")) {
              return <CreateMeeting {...props} />;
            }
            else if (urls.includes("/view-meeting")) {
              return <ViewMeeting {...props} />;
            }
            else if (urls.includes("/LeaveReview")) {
              return <LeaveReview {...props} />;
            }
            else if (urls.includes("/viewMessageInbox")) {
              return <ViewMessage {...props} />;
            }
            else if (urls.includes("/application-comments")) {
              return <CommentListElement {...props} />;
            }
            else {
              return (<Redirect to={{ pathname: "/", state: { from: props.location } }} />);
            }
          }
          else {
            return (<Redirect to={{ pathname: "/", state: { from: props.location } }} />);
          }
        }}
    />
  );
};

export default ProtectedRoute;
