import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_ERROR,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL,
  ONBOARDING_PROCESS,
  ONBOARDING_PROCESS_SUCCESS,
  ONBOARDING_PROCESS_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  AGENT_LIST_ERROR,
  AGENT_LIST_SUCCESS,
  AGENT_LIST,
	SAVE_PP_SEARCH_ERROR,
	SAVE_PP_SEARCH_SUCCESS,
	SAVE_PP_SEARCH,
	EP_LIST_ERROR,
	EP_LIST_SUCCESS,
	EP_LIST,
	UPDATE_EP_FAVOURITE_ERROR,
	UPDATE_EP_FAVOURITE_SUCCESS,
	UPDATE_EP_FAVOURITE,
	UPDATE_PROGRAM_ERROR,
	UPDATE_PROGRAM_FAVOURITE_ERROR,
	UPDATE_PROGRAM_FAVOURITE_SUCCESS,
	UPDATE_PROGRAM_FAVOURITE,
	GET_AGENT_PROFILE_ERROR,
	GET_AGENT_PROFILE_SUCCESS,
	GET_AGENT_PROFILE,
	GET_AGENT_REVIEWS_ERROR,
	GET_AGENT_REVIEWS_SUCCESS,
	GET_AGENT_REVIEWS,
	LIKE_DISLIKE_COMMENT,
	LIKE_DISLIKE_COMMENT_SUCCESS,
	LIKE_DISLIKE_COMMENT_ERROR,
	LIKE_DISLIKE_REVIEW,
	LIKE_DISLIKE_REVIEW_SUCCESS,
	LIKE_DISLIKE_REVIEW_ERROR,
	UPDATE_COMMENT_ERROR,
	UPDATE_COMMENT_SUCCESS,
	UPDATE_COMMENT,
	DELETE_COMMENT_ERROR,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT,
	GET_UNIVERSITY_PROFILE_ERROR,
	GET_UNIVERSITY_PROFILE_SUCCESS,
	GET_UNIVERSITY_PROFILE,
	GET_UNIVERSITY_INTAKES_ERROR,
	GET_UNIVERSITY_INTAKES_SUCCESS,
	GET_UNIVERSITY_INTAKES,
	GET_PROGRAM_DETAILS_FILTERS_SUCCESS,
	GET_PROGRAM_DETAILS_FILTERS_ERROR,
	GET_PROGRAM_DETAILS_FILTERS,
	CHECK_ELIGIBILTY_ERROR,
	CHECK_ELIGIBILTY_SUCCESS,
	CHECK_ELIGIBILTY,
	GET_APPLICATION_ADVICE,
	GET_APPLICATION_ADVICE_SUCCESS,
	GET_APPLICATION_ADVICE_ERROR,
	CHECK_APPLIED_STATUS_ERROR,
	CHECK_APPLIED_STATUS_SUCCESS,
	CHECK_APPLIED_STATUS
} from "../constants";

export function getCountryListAction(session, resolve, reject) {
  return {
    type: GET_COUNTRY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getCountryListSuccess(data) {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: data
  };
}

export function getCountryListError(error) {
  return {
    type: GET_COUNTRY_LIST_ERROR,
    payload: error
  };
}

export function getCurrencyListAction(session, resolve, reject) {
  return {
    type: GET_CURRENCY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getCurrencyListSuccess(data) {
  return {
    type: GET_CURRENCY_LIST_SUCCESS,
    payload: data
  };
}

export function getCurrencyListError(error) {
  return {
    type: GET_CURRENCY_LIST_ERROR,
    payload: error
  };
}

export function showHideUpdatePasswordModalAction(session, resolve, reject) {
  return {
    type: SHOW_HIDE_UPDATE_PASSWORD_MODAL,
    payload: session,
    resolve,
    reject
  };
}

export function onBoardingProcessAction(session, resolve, reject) {
	return {
		type: ONBOARDING_PROCESS,
		payload: session,
		resolve,
		reject
	};
}

export function onBoardingProcessSuccess(data) {
	return {
		type: ONBOARDING_PROCESS_SUCCESS,
		payload: data
	};
}

export function onBoardingProcessError(error) {
	return {
		type: ONBOARDING_PROCESS_ERROR,
		payload: error
	};
}

export function forgotPasswordAction(session, resolve, reject) {
	return {
		type: FORGOT_PASSWORD,
		payload: session,
		resolve,
		reject
	};
}

export function forgotPasswordSuccess(data) {
	return {
		type: FORGOT_PASSWORD_SUCCESS,
		payload: data
	};
}

export function forgotPasswordError(error) {
	return {
		type: FORGOT_PASSWORD_ERROR,
		payload: error
	};
}

export function resetPasswordAction(session, resolve, reject) {
	return {
		type: RESET_PASSWORD,
		payload: session,
		resolve,
		reject
	};
}

export function resetPasswordSuccess(data) {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: data
	};
}

export function resetPasswordError(error) {
	return {
		type: RESET_PASSWORD_ERROR,
		payload: error
	};
}

export function agentListAction(session, resolve, reject) {
	return {
		type: AGENT_LIST,
		payload: session,
		resolve,
		reject
	};
}

export function agentListSuccess(data) {
	return {
		type: AGENT_LIST_SUCCESS,
		payload: data
	};
}

export function agentListError(error) {
	return {
		type: AGENT_LIST_ERROR,
		payload: error
	};
}

export function savePpSearchAction(session, resolve, reject) {
	return {
		type: SAVE_PP_SEARCH,
		payload: session,
		resolve,
		reject
	};
}

export function savePpSearchSuccess(data) {
	return {
		type: SAVE_PP_SEARCH_SUCCESS,
		payload: data
	};
}

export function savePpSearchError(error) {
	return {
		type: SAVE_PP_SEARCH_ERROR,
		payload: error
	};
}

export function getEpListAction(session, resolve, reject) {
	return {
		type: EP_LIST,
		payload: session,
		resolve,
		reject
	};
}

export function getEpListSuccess(data) {
	return {
		type: EP_LIST_SUCCESS,
		payload: data
	};
}

export function getEpListError(error) {
	return {
		type: EP_LIST_ERROR,
		payload: error
	};
}

export function updateEpFavouriteAction(session, resolve, reject) {
	return {
		type: UPDATE_EP_FAVOURITE,
		payload: session,
		resolve,
		reject
	};
}

export function updateEpFavouriteSuccess(data) {
	return {
		type: UPDATE_EP_FAVOURITE_SUCCESS,
		payload: data
	};
}

export function updateEpFavouriteError(error) {
	return {
		type: UPDATE_EP_FAVOURITE_ERROR,
		payload: error
	};
}

export function updateProgramFavouriteAction(session, resolve, reject) {
	return {
		type: UPDATE_PROGRAM_FAVOURITE,
		payload: session,
		resolve,
		reject
	};
}

export function updateProgramFavouriteSuccess(data) {
	return {
		type: UPDATE_PROGRAM_FAVOURITE_SUCCESS,
		payload: data
	};
}

export function updateProgramFavouriteError(error) {
	return {
		type: UPDATE_PROGRAM_FAVOURITE_ERROR,
		payload: error
	};
}

export function getAgentProfileAction(session, resolve, reject) {
	return {
		type: GET_AGENT_PROFILE,
		payload: session,
		resolve,
		reject
	};
}

export function getAgentProfileSuccess(data) {
	return {
		type: GET_AGENT_PROFILE_SUCCESS,
		payload: data
	};
}

export function getAgentProfileError(error) {
	return {
		type: GET_AGENT_PROFILE_ERROR,
		payload: error
	};
}

export function getAgentReviewsAction(session, resolve, reject) {
	return {
		type: GET_AGENT_REVIEWS,
		payload: session,
		resolve,
		reject
	};
}

export function getAgentReviewsSuccess(data) {
	return {
		type: GET_AGENT_REVIEWS_SUCCESS,
		payload: data
	};
}

export function getAgentReviewsError(error) {
	return {
		type: GET_AGENT_REVIEWS_ERROR,
		payload: error
	};
}

export function likeDislikeReviewCommentAction(session, resolve, reject) {
	return {
		type: LIKE_DISLIKE_COMMENT,
		payload: session,
		resolve,
		reject
	};
}

export function likeDislikeReviewCommentSuccess(data) {
	return {
		type: LIKE_DISLIKE_COMMENT_SUCCESS,
		payload: data
	};
}

export function likeDislikeReviewCommentError(error) {
	return {
		type: LIKE_DISLIKE_COMMENT_ERROR,
		payload: error
	};
}

export function likeDislikeReviewAction(session, resolve, reject) {
	return {
		type: LIKE_DISLIKE_REVIEW,
		payload: session,
		resolve,
		reject
	};
}

export function likeDislikeReviewSuccess(data) {
	return {
		type: LIKE_DISLIKE_REVIEW_SUCCESS,
		payload: data
	};
}

export function likeDislikeReviewError(error) {
	return {
		type: LIKE_DISLIKE_REVIEW_ERROR,
		payload: error
	};
}

export function updateCommentAction(session, resolve, reject) {
	return {
		type: UPDATE_COMMENT,
		payload: session,
		resolve,
		reject
	};
}

export function updateCommentSuccess(data) {
	return {
		type: UPDATE_COMMENT_SUCCESS,
		payload: data
	};
}

export function updateCommentError(error) {
	return {
		type: UPDATE_COMMENT_ERROR,
		payload: error
	};
}

export function deleteCommentAction(session, resolve, reject) {
	return {
		type: DELETE_COMMENT,
		payload: session,
		resolve,
		reject
	};
}

export function deleteCommentSuccess(data) {
	return {
		type: DELETE_COMMENT_SUCCESS,
		payload: data
	};
}

export function deleteCommentError(error) {
	return {
		type: DELETE_COMMENT_ERROR,
		payload: error
	};
}

export function getUniversityProfileAction(session, resolve, reject) {
	return {
		type: GET_UNIVERSITY_PROFILE,
		payload: session,
		resolve,
		reject
	};
}

export function getUniversityProfileSuccess(data) {
	return {
		type: GET_UNIVERSITY_PROFILE_SUCCESS,
		payload: data
	};
}

export function getUniversityProfileError(error) {
	return {
		type: GET_UNIVERSITY_PROFILE_ERROR,
		payload: error
	};
}

export function getIntakesAction(session, resolve, reject) {
	return {
		type: GET_UNIVERSITY_INTAKES,
		payload: session,
		resolve,
		reject
	};
}

export function getIntakesSuccess(data) {
	return {
		type: GET_UNIVERSITY_INTAKES_SUCCESS,
		payload: data
	};
}

export function getIntakesError(error) {
	return {
		type: GET_UNIVERSITY_INTAKES_ERROR,
		payload: error
	};
}

export function getProgramDetailsFiltersAction(session, resolve, reject) {
	return {
		type: GET_PROGRAM_DETAILS_FILTERS,
		payload: session,
		resolve,
		reject
	};
}

export function getProgramDetailsFiltersSuccess(data) {
	return {
		type: GET_PROGRAM_DETAILS_FILTERS_SUCCESS,
		payload: data
	};
}

export function getProgramDetailsFiltersError(error) {
	return {
		type: GET_PROGRAM_DETAILS_FILTERS_ERROR,
		payload: error
	};
}

export function checkEligibiltyAction(session, resolve, reject) {
	return {
		type: CHECK_ELIGIBILTY,
		payload: session,
		resolve,
		reject
	};
}

export function checkEligibiltySuccess(data) {
	return {
		type: CHECK_ELIGIBILTY_SUCCESS,
		payload: data
	};
}

export function checkEligibiltyError(error) {
	return {
		type: CHECK_ELIGIBILTY_ERROR,
		payload: error
	};
}

export function getApplicationAdviceAction(session, resolve, reject) {
	return {
		type: GET_APPLICATION_ADVICE,
		payload: session,
		resolve,
		reject
	};
}

export function getApplicationAdviceSuccess(data) {
	return {
		type: GET_APPLICATION_ADVICE_SUCCESS,
		payload: data
	};
}

export function getApplicationAdviceError(error) {
	return {
		type: GET_APPLICATION_ADVICE_ERROR,
		payload: error
	};
}

export function checkAppliedStatusAction(session, resolve, reject) {
	return {
		type: CHECK_APPLIED_STATUS,
		payload: session,
		resolve,
		reject
	};
}

export function checkAppliedStatusSuccess(data) {
	return {
		type: CHECK_APPLIED_STATUS_SUCCESS,
		payload: data
	};
}

export function checkAppliedStatusError(error) {
	return {
		type: CHECK_APPLIED_STATUS_ERROR,
		payload: error
	};
}