import React from "react"
import moment from "moment"
import { Button, Collapse } from "reactstrap"
import { confirmAlert } from "react-confirm-alert"
import AddComment from "./AddComment"
import { ReactComponent as DeleteIcon } from "../../../assets/img/delete-red-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/img/edit-icon.svg";
import { ReactComponent as RplyIcon } from "../../../assets/img/rply-icon.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/img/collapse-icon.svg";

class CommentListElement extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inEdit: false,
      replyTo: 0,
      showReplies: false
    }
  }

  toggleReplies = () => {
    this.setState({
      showReplies: !this.state.showReplies
    })
  }

  render() {
    let {
      profile,
      studentId,
      val
    } = this.props

    return (
      <li
        key={this.props.index}
        className={this.props.replies ? '' : 'commentBox'}
      >
        <div className="commentTop">
          {
            val.user_id == studentId
              && val.user_type == 2 ? (
              <span
                className={`avatar`}
                style={{
                  backgroundColor: `${profile && profile.color_code
                    ? profile.color_code : "lightgray"}`
                }}
              >
                {
                  profile && profile ? (
                    profile.first_name.charAt(0).toUpperCase()
                  ) : ""
                }
              </span>
            ) : (
              <span
                className={`avatar`}
                style={{
                  backgroundColor: `${val.sender && val.sender.color_code
                    ? val.sender.color_code : "lightgray"}`
                }}
              >
                {
                  val.sender && val.sender.first_name ? (
                    val.sender.first_name.charAt(0).toUpperCase()
                  ) : ""
                }
              </span>
            )
          }

          <div
            className="commentUser"
          >
            {
              val.user_id == studentId
                && val.user_type == 2 ? "Me" : (
                `${val.sender?.first_name} ${val.sender?.last_name}`
              )
            }
          </div>

          <div className="commentDate">
            {
              moment.utc(val.created_at).format('h:m a, d MMM')
            }
          </div>
        </div>

        {
          this.state.inEdit ? (
            <AddComment
              inEdit={true}
              commentId={val.id}
              addComment={this.props.addComment}
              onCancel={() => {
                this.setState({
                  inEdit: false
                })
              }}
              comment={val.comment}
            />
          ) : (
            <>
              <div
                className="commentText"
              >
                {val.comment}
              </div>

              {
                val.child_comment && val.child_comment.length ? (
                  <>
                    <Collapse isOpen={this.state.showReplies}>
                      <ul>
                        {
                          val.child_comment.map((val, index) => (
                            <CommentListElement
                              val={val}
                              index={index}
                              key={index}
                              studentId={this.props.studentId}
                              profile={this.props.profile}
                              replies={true}
                            />
                          ))
                        }
                      </ul>
                    </Collapse>

                    {/* <span
                      className="pointer"
                      onClick={this.toggleReplies}
                    >
                      {
                        this.state.showReplies ? 'Hide' : `Show (${val.child_comment.length})`
                      }
                    </span> */}
                  </>
                ) : null
              }
              <div className="commentActionWrap">
                {
                  val.child_comment && val.child_comment.length ? (
                    <span
                      className={`collpaseBtn ${this.state.showReplies ? 'hideBtn' : 'showBtn'}`}
                      onClick={this.toggleReplies}
                    >
                      <CollapseIcon />
                      {
                        this.state.showReplies ? 'Hide' : `Show (${val.child_comment.length})`
                      }
                    </span>
                  ) : null
                }
                {
                  this.state.replyTo || this.props.replies ? null : (
                    <>
                      <div className="commentAction">
                        {
                          val.user_id == studentId
                            && val.user_type == 2 ? (
                            <>
                              <Button
                                onClick={() => {
                                  confirmAlert({
                                    title: '',
                                    message: 'Are you sure you want to delete this comment?',
                                    buttons: [
                                      {
                                        label: 'Yes',
                                        onClick: () => {
                                          this.props.deleteComment(val.id)
                                        }
                                      },
                                      {
                                        label: 'No',
                                        onClick: () => { }
                                      }
                                    ]
                                  })
                                }}
                                className="deleteBtn"
                              >
                                <DeleteIcon /> Delete
                              </Button>

                              <Button
                                onClick={() => {
                                  this.setState({
                                    inEdit: true
                                  })
                                }}
                                className="editBtn"
                              >
                                <EditIcon /> Edit
                              </Button>
                            </>
                          ) : null
                        }
                        <Button
                          onClick={() => {
                            this.setState({
                              replyTo: val.id
                            })
                          }}
                          className="rplyBtn"
                        >
                          <RplyIcon /> Reply
                        </Button>
                      </div>
                    </>
                  )
                }
              </div>
            </>
          )
        }

        {
          this.state.replyTo ? (
            <AddComment
              replyTo={this.state.replyTo}
              addComment={this.props.addComment}
              onCancel={() => {
                this.setState({
                  replyTo: 0
                })
              }}
            />
          ) : null
        }
      </li>
    )
  }
}

export default CommentListElement
