import React from "react";
import Infoicon from "../../assets/img/student/info-icon.svg";
import Closeicon from "../../assets/img/close-btn.svg";

let CompleteProfileMessage = (props) => {
  return (
    props.show && !props.showComment ? (
      <div className="complete-box flex-column" style={{ minHeight: 'auto' }}>
        <div className="row">
          <div className="com-top col-10">
            <img
              src={Infoicon}
              alt="home-icon"
              className="uni-icon pr-2"
            />{" "}
            <span className="align-middle" />
            Please complete your application form and attach the documents required
          </div>
          <div className="com-top col-2 d-flex justify-content-end">
            <img
              src={Closeicon}
              alt="home-icon"
              onClick={props.toggleShowHide}
              className="uni-icon pr-2"
            />
          </div>
        </div>
      </div>
    ) : null
  )
}

export default CompleteProfileMessage;