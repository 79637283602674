import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import configureStore from './store';

// const stripePromise = loadStripe(
//   "pk_test_51GqceMFwABZjvaaQrUJYn0NdiPi4nc2De99t3M2kFTs3by4eZe9xlcVW3v98PpJ0Vhjj1UZ2hvLSqjMqemImb6vM00ZMDnAGYQ"
// );

// ReactDOM.render(
//   <React.Fragment>
//     <Elements stripe={stripePromise}>
//       <App />
//     </Elements>
	
//   </React.Fragment>,
 
//   document.getElementById("root")
// );

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);
serviceWorker.unregister(
    
);
