import { put, takeLatest, all, call } from "redux-saga/effects";
import { DELETE_DOCUMENTS, GET_DOCUMENTS, STORE_DOCUMENTS } from "../constants";
import { baseUrl, STUDENT_DOCUMENTS_API } from "../../Api";
import { toast } from "react-toastify";
import axios from "axios";
import { setDocumentsAction } from "../actions";

function getDocumentsApi(params) {
  return axios.request({
    method: "GET",
    url: baseUrl + STUDENT_DOCUMENTS_API,
    params: params,
  });
}

function* getDocuments(action) {
  let { payload, resolve, reject } = action;
  try {
    let response = yield call(getDocumentsApi, payload);
    yield put(setDocumentsAction(response.data.data ?? []));
    if (resolve) resolve(response.data);
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* getDocumentsWatcher() {
  yield takeLatest(GET_DOCUMENTS, getDocuments);
}

function storeDocumentsApi(params) {
  return axios.request({
    method: "POST",
    url: baseUrl + STUDENT_DOCUMENTS_API,
    data: params,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function* storeDocuments(action) {
  let { payload, resolve, reject } = action;
  try {
    let response = yield call(storeDocumentsApi, payload);
    if (resolve) resolve(response.data);
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* storeDocumentsWatcher() {
  yield takeLatest(STORE_DOCUMENTS, storeDocuments);
}

function deleteDocumentsApi(id) {
  return axios.request({
    method: "DELETE",
    url: baseUrl + STUDENT_DOCUMENTS_API + "/" + id,
    // params: params
  });
}

function* deleteDocuments(action) {
  let { payload, resolve, reject } = action;
  try {
    let response = yield call(deleteDocumentsApi, payload);
    if (resolve) resolve(response.data);
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* deleteDocumentsWatcher() {
  yield takeLatest(DELETE_DOCUMENTS, deleteDocuments);
}
