import "./Addmore.css";
import React from "react";
import { Button, Input } from "reactstrap";

function template() {
  const { questionsattended } = this.state;
  const { customClass } = this.props;
  return (
    <div className={`Addmore ${customClass}`}>
      <div className="col-12 per-box py-4">
        {Array.isArray(questionsattended) &&
          questionsattended.map((data, index) => (
            <div className="destination-box format" key={index.toString()}>
              <div>
                <h2 className="uni-label">
                  {this.state.handlequestion[index]}
                </h2>
              </div>
              {this.state.selectedOption[index] == 0 ? (
                <div>
                  <h2 className="uni-label">
                    {this.state.handlequestion[index]}
                  </h2>
                  <Input
                    disabled={this.state.disabled}
                    type="text"
                    className=""
                    placeholder="Type here"
                    onChange={(evt) => this.handleQuestionName(evt, index)}
                    value={this.state.handleanswers[index]}
                  />
                </div>
              ) : this.state.selectedOption[index] == 1 ? (
                <div>
                  <h2 className="uni-label">
                    {this.state.handlequestion[index]}
                  </h2>
                  <Input
                    disabled={this.state.disabled}
                    type="select"
                    id="edulevel"
                    onChange={(evt) => this.handleQuestionName(evt, index)}
                    className=" edulevel"
                    value={this.state.handleanswers[index]}
                  >
                    <option value="">Select</option>
                    <option value={this.state.typeoption1[index]}>
                      {this.state.typeoption1[index]}
                    </option>
                    <option value={this.state.typeoption2[index]}>
                      {this.state.typeoption2[index]}
                    </option>
                  </Input>
                </div>
              ) : this.state.selectedOption[index] == 2 ? (
                <div>
                  <h2 className="uni-label">
                    {this.state.handlequestion[index]}
                  </h2>
                  <Input
                    disabled={this.state.disabled}
                    type="select"
                    id="edulevel"
                    onChange={(evt) => this.handleQuestionName(evt, index)}
                    className="multiple-select edulevel"
                    multiple
                    value={this.state.handleanswers[index]}
                  >
                    <option value="">Select</option>
                    <option value={this.state.typeoption3[index]}>
                      {this.state.typeoption3[index]}
                    </option>
                    <option value={this.state.typeoption4[index]}>
                      {this.state.typeoption4[index]}
                    </option>
                    <option value={this.state.typeoption5[index]}>
                      {this.state.typeoption5[index]}
                    </option>
                    <option value={this.state.typeoption6[index]}>
                      {this.state.typeoption6[index]}
                    </option>
                    {this.state.addOnChange[index]
                      ? this.state.addmoreinput[index].map(
                          (input, sectionIndex) => (
                            <option value={input} key={sectionIndex.toString()}>
                              {input}
                            </option>
                          )
                        )
                      : null}
                  </Input>
                </div>
              ) : null}
            </div>
          ))}
      </div>

      <div className="col-12 d-flex justify-content-end save">
        {this.state.disabled === false ? null : (
          <Button
            color="primary"
            className="score-save"
            type="submit"
            onClick={this.onSubmit}
          >
            SAVE
          </Button>
        )}
      </div>
    </div>
  );
}
export default template;
