import axios from "axios";
import { toast } from "react-toastify";
import config from '../config.json';
import { MESSAGES } from "../constant/messages";
const baseurl = `${config.baseurl}/`;
export default (contentType, data, setResponse, path) => {
    axios({
        url: baseurl + path,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': contentType
        }
    }).then((response) => {
        setResponse(response.data);
    }).catch((error) => {
        console.log('log patj', path, error);
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
        setResponse("error");
    })
}