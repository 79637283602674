import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_PAYMENT_PAID_LIST,
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_LIST,
  GET_PAYMENT_DETAILS,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_PENDING_STUDENT_FEES,
	SAVE_CARD,
  GET_INVOICE_PAYMENT_PROOF,
  GET_PREPAID_FEES_COMMISSION_LIST,
  GET_POSTPAID_FEES_COMMISSION_LIST,
	GET_DEFAULT_CARD,
	GET_INVOICE_DETAILS,
	STRIPE_PAYMENT,
	GET_INVOICE_APP
} from "../constants";
import {
  baseUrl,
  GET_PAYMENT_PAID_LIST_API,
  CREATE_PAYMENT_METHOD_API,
  GET_PAYMENT_LIST_API,
  GET_PAYMENT_DETAILS_API,
  UPDATE_PAYMENT_METHOD_API,
  DELETE_PAYMENT_METHOD_API,
  GET_PENDING_STUDENT_FEES_API,
	SAVE_CARD_API,
  GET_INVOICE_PAYMENT_PROOF_API,
  GET_PREPAID_FEES_COMMISSION_LIST_API,
  GET_POSTPAID_FEES_COMMISSION_LIST_API,
	GET_DEFAULT_CARD_API,
	GET_INVOICE_DETAILS_API,
	STRIPE_PAYMENT_API,
	GET_INVOICE_APP_API
} from "../../Api";
import axios from "axios";
import {
	getPaymentPaidListError,
	getPaymentPaidListSuccess,
	createPaymentMethodSuccess,
	createPaymentMethodError,
	getPaymentListSuccess,
	getPaymentListError,
	getPaymentDetailsSuccess,
	getPaymentDetailsError,
	updatePaymentMethodSuccess,
	updatePaymentMethodError,
	deletePaymentMethodSuccess,
	deletePaymentMethodError,
	getPendingStudentFeesSuccess,
	getPendingStudentFeesError,
	saveCardError,
	saveCardSuccess,
	getInvoicePaymentProofSuccess,
	getInvoicePaymentProofError,
	getPrepaidFeesCommissionListSuccess,
	getPrepaidFeesCommissionListError,
	getPostpaidFeesCommissionListSuccess,
	getPostpaidFeesCommissionListError,
	getDefaultCardSuccess,
	getDefaultCardError,
	getInvoiceDetailsSuccess,
	getInvoiceDetailsError,
	stripePaymentSuccess,
	stripePaymentError,
	getInvoiceAppSuccess,
	getInvoiceAppError
} from '../actions';
import { handleErrorResponse } from '../actions';

function getPaymentPaidListActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PAYMENT_PAID_LIST_API,
	  params: params
	});
}

function* getPaymentPaidListAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPaymentPaidListActionApi, payload);
		yield put(getPaymentPaidListSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPaymentPaidListError(e))
		if (reject) reject(e)
	}
}

export function* getPaymentPaidListActionWatcher() {
	yield takeLatest(GET_PAYMENT_PAID_LIST, getPaymentPaidListAction)
}

function createPaymentMethodActionApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + CREATE_PAYMENT_METHOD_API,
	  data: params,
	  validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 423
      },
	});
}

function* createPaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(createPaymentMethodActionApi, payload);
		yield put(createPaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(createPaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* createPaymentMethodActionWatcher() {
	yield takeLatest(CREATE_PAYMENT_METHOD, createPaymentMethodAction)
}

function getPaymentListActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PAYMENT_LIST_API,
	  params: params
	});
}

function* getPaymentListAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPaymentListActionApi, payload);
		yield put(getPaymentListSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPaymentListError(e))
		if (reject) reject(e)
	}
}

export function* getPaymentListActionWatcher() {
	yield takeLatest(GET_PAYMENT_LIST, getPaymentListAction)
}

function getPaymentDetailsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PAYMENT_DETAILS_API + "/" + params.payment_id
	});
}

function* getPaymentDetailsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPaymentDetailsActionApi, payload);
		yield put(getPaymentDetailsSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPaymentDetailsError(e))
		if (reject) reject(e)
	}
}

export function* getPaymentDetailsActionWatcher() {
	yield takeLatest(GET_PAYMENT_DETAILS, getPaymentDetailsAction)
}

function updatePaymentMethodActionApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + UPDATE_PAYMENT_METHOD_API,
	  data: params,
	  validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 423
      },
	});
}

function* updatePaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(updatePaymentMethodActionApi, payload);
		yield put(updatePaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(updatePaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* updatePaymentMethodActionWatcher() {
	yield takeLatest(UPDATE_PAYMENT_METHOD, updatePaymentMethodAction)
}

function deletePaymentMethodActionApi(params) {
	return axios.request({
	  method: "DELETE",
	  url: baseUrl + DELETE_PAYMENT_METHOD_API + "/" + params.payment_id
	});
}

function* deletePaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(deletePaymentMethodActionApi, payload);
		yield put(deletePaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(deletePaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* deletePaymentMethodActionWatcher() {
	yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethodAction)
}

function getPendingStudentFeesActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PENDING_STUDENT_FEES_API,
	  params: params
	});
}

function* getPendingStudentFeesAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPendingStudentFeesActionApi, payload);
		yield put(getPendingStudentFeesSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPendingStudentFeesError(e))
		if (reject) reject(e)
	}
}

export function* getPendingStudentFeesActionWatcher() {
	yield takeLatest(GET_PENDING_STUDENT_FEES, getPendingStudentFeesAction)
}

function saveCardApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + SAVE_CARD_API,
	  data: params
	});
}

function* saveCardAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(saveCardApi, payload);
		yield put(saveCardSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response)
		yield put(saveCardError(e))
		if (reject) reject(e)
	}
}

export function* saveCardWatcher() {
	yield takeLatest(SAVE_CARD, saveCardAction)
}

function getInvoicePaymentProofApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_INVOICE_PAYMENT_PROOF_API,
    params: params
  });
}

function* getInvoicePaymentProof(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getInvoicePaymentProofApi, payload);

    yield put(getInvoicePaymentProofSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getInvoicePaymentProofError(e))
    if (reject) reject(e)
  }
}

export function* getInvoicePaymentProofWatcher() {
  yield takeLatest(GET_INVOICE_PAYMENT_PROOF, getInvoicePaymentProof)
}

function getPrepaidFeesCommissionListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl+GET_PREPAID_FEES_COMMISSION_LIST_API,
	  params: params
	});
}

function* getPrepaidFeesCommissionList(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;

	try {
	  let response = yield call(getPrepaidFeesCommissionListApi, payload);

	  yield put(getPrepaidFeesCommissionListSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(getPrepaidFeesCommissionListError(e))
	  if (reject) reject(e)
	}
}

export function* getPrepaidFeesCommissionListWatcher() {
	yield takeLatest(GET_PREPAID_FEES_COMMISSION_LIST, getPrepaidFeesCommissionList)
}


function getPostpaidFeesCommissionListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl+GET_POSTPAID_FEES_COMMISSION_LIST_API,
	  params: params
	});
}

function* getPostpaidFeesCommissionList(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;

	try {
	  let response = yield call(getPostpaidFeesCommissionListApi, payload);

	  yield put(getPostpaidFeesCommissionListSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(getPostpaidFeesCommissionListError(e))
	  if (reject) reject(e)
	}
}

export function* getPostpaidFeesCommissionListWatcher() {
	yield takeLatest(GET_POSTPAID_FEES_COMMISSION_LIST, getPostpaidFeesCommissionList)
}

function getDefaultCardApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl+GET_DEFAULT_CARD_API,
	  params: params
	});
}

function* getDefaultCard(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;

	try {
	  let response = yield call(getDefaultCardApi, payload);

	  yield put(getDefaultCardSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response);
	  yield put(getDefaultCardError(e))
	  if (reject) reject(e)
	}
}

export function* getDefaultCardWatcher() {
	yield takeLatest(GET_DEFAULT_CARD, getDefaultCard)
}

function getInvoiceDetailsApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl+GET_INVOICE_DETAILS_API+"/"+params.invoice_id,
	  params: params
	});
}

function* getInvoiceDetails(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;

	try {
	  let response = yield call(getInvoiceDetailsApi, payload);

	  yield put(getInvoiceDetailsSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response);
	  yield put(getInvoiceDetailsError(e))
	  if (reject) reject(e)
	}
}

export function* getInvoiceDetailsWatcher() {
	yield takeLatest(GET_INVOICE_DETAILS, getInvoiceDetails)
}

function stripePaymentApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl+STRIPE_PAYMENT_API,
	  data: params
	});
}

function* stripePayment(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;

	try {
	  let response = yield call(stripePaymentApi, payload);

	  yield put(stripePaymentSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response);
	  yield put(stripePaymentError(e))
	  if (reject) reject(e)
	}
}

export function* stripePaymentWatcher() {
	yield takeLatest(STRIPE_PAYMENT, stripePayment)
}


function getInvoiceAppApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl+GET_INVOICE_APP_API + "/" + params.app_id,
	});
}

function* getInvoiceApp(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getInvoiceAppApi, payload);

	  yield put(getInvoiceAppSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response);
	  yield put(getInvoiceAppError(e))
	  if (reject) reject(e)
	}
}

export function* getInvoiceAppWatcher() {
	yield takeLatest(GET_INVOICE_APP, getInvoiceApp)
}