import { GET_DOCUMENTS_SUCCESS } from "../constants";

const initialState = {
  supportingDocs: [],
};

export default function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS_SUCCESS:
      return { ...state, supportingDocs: action.payload };
    default:
      return state;
  }
}
