import {
	CALENDER_EVENT_LIST,
	CALENDER_EVENT_LIST_ERROR,
	CALENDER_EVENT_LIST_SUCCESS,
	GET_MONTH_EVENT_LIST,
	GET_MONTH_EVENT_LIST_ERROR,
	GET_MONTH_EVENT_LIST_SUCCESS
} from "../constants";

export function calendarEventListAction(session, resolve, reject){
	return {
	  type: CALENDER_EVENT_LIST,
	  payload: session,
	  resolve,
	  reject
	};
}

export function calendarEventListSuccess(data) {
	return {
	  type: CALENDER_EVENT_LIST_SUCCESS,
	  payload: data
	};
}

export function calendarEventListError(error) {
	return {
	  type: CALENDER_EVENT_LIST_ERROR,
	  payload: error
	};
}

export function getMonthEventListAction(session, resolve, reject){
	return {
	  type: GET_MONTH_EVENT_LIST,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getMonthEventListSuccess(data) {
	return {
	  type: GET_MONTH_EVENT_LIST_SUCCESS,
	  payload: data
	};
}

export function getMonthEventListError(error) {
	return {
	  type: GET_MONTH_EVENT_LIST_ERROR,
	  payload: error
	};
}
