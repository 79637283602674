import "./AppEducation.css";
import Addmore from "../Addmore/Addmore";
import React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../common/FormError";
import CompleteProfileMessage from "../AppUploadDocument/CompleteProfileMessage";
import CommentPopover from "../ApplicationAddComment/component/CommentPopover";
import ApplicationQa from "../commonComponents/ApplicationQa/index.js";

function template() {
  const { errors } = this.state;
  const prods = this.state.edusublevel.map((ub, index) => {
    const sublevel = ub.sublevel.map((sublevel, i) => {
      return (
        <option key={i.toString()} value={sublevel.id}>
          {sublevel.educationlevel_name}
        </option>
      );
    });
    return (
      <React.Fragment key={index.toString()}>
        <option value="" className="boldclass" disabled>
          {ub.mainlevel}
        </option>
        {sublevel}
      </React.Fragment>
    );
  });

  const edutab = () => {
    this.setState({ EduaddMore: false, selectedQuestion1: null });
  };

  return (
    <div className="tab-wrap appeducation newBg">
      <div className="row">
        <div className="col-12">
          {/* <CompleteProfileMessage
            show={this.state.showCompleteProfileMessage}
            toggleShowHide={() => {
              this.setState({
                showCompleteProfileMessage: false
              })
            }}
          /> */}
          <div
            style={{
              display: this.state.EduaddMore == false ? "block" : "none",
            }}
          >
            <div className="personal-box-inner box">
              <div className="row activeTitle">
                <div className="col-8">
                  <h3>Most Recent Education</h3>
                </div>

                <div className="col-4 text-right right-btn-align">
                  {/* {this.state.edit ? (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={this.onSubmit.bind(this)}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={() => {
                        this.setState({
                          edit: true,
                        });
                      }}
                    >
                      EDIT
                    </Button>
                  )} */}
                </div>
              </div>

              <div className="destination-box">
                {this.state.edit ? null : <div className="preview"></div>}
                <div className="row">
                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover
                        {...this.props}
                        id={"country_of_education"}
                      />
                    ) : null}

                    <FormGroup>
                      <Label className="uni-label">Country of Education</Label>
                      <Input
                        disabled={
                          localStorage.getItem("fromApplicationType") === "Sent"
                            ? true
                            : false
                        }
                        type="select"
                        id="educountryid"
                        onChange={this.handleEduCountryIdChange}
                        className=""
                        value={this.state.educountryid}
                      >
                        <option value="">Select</option>
                        {this.state.country.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormError error={this.state.errors.educountryid} />
                  </div>

                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover
                        {...this.props}
                        id={"highest_level_education"}
                      />
                    ) : null}

                    <FormGroup>
                      <Label className="uni-label">
                        Highest Level of Education
                      </Label>
                      <Input
                        disabled={
                          localStorage.getItem("fromApplicationType") === "Sent"
                            ? true
                            : false
                        }
                        type="select"
                        id="highedulevel"
                        name="highedulevel"
                        onChange={this.handleTargetChange}
                        className=""
                        value={this.state.highedulevel}
                      >
                        <option value="">Select</option>
                        {prods}
                      </Input>
                    </FormGroup>
                    <FormError error={this.state.errors.highedulevel} />
                  </div>

                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover {...this.props} id={"grade_scheme"} />
                    ) : null}
                    <FormGroup>
                      <Label className="uni-label">Grading Scheme</Label>
                      <Input
                        disabled={
                          localStorage.getItem("fromApplicationType") === "Sent"
                            ? true
                            : false
                        }
                        type="select"
                        id="gradingscheme"
                        name="gradingscheme"
                        onChange={this.handleTargetChange}
                        className=""
                        value={this.state.gradingscheme}
                      >
                        <option value="">Select</option>
                        {this.state.grade.map((grade) => (
                          <option key={grade.id} value={grade.id}>
                            {grade.grading_scheme}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormError error={this.state.errors.gradingscheme} />
                  </div>
                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover {...this.props} id={"grade_scale_out"} />
                    ) : null}
                    <FormGroup>
                      <Label className="uni-label">Grade Scale (Out of)</Label>
                      <Input
                        disabled={
                          localStorage.getItem("fromApplicationType") === "Sent"
                            ? true
                            : false
                        }
                        type="select"
                        id="gradingscale"
                        name="gradingscale"
                        onChange={this.handleTargetChange}
                        className=""
                        value={this.state.gradingscale}
                      >
                        <option value="">Select</option>
                        {this.state.graderange.map((graderange) => (
                          <option key={graderange.id} value={graderange.id}>
                            {graderange.grade_range}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormError error={this.state.errors.gradingscale} />
                  </div>

                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover {...this.props} id={"grade_average"} />
                    ) : null}
                    <FormGroup>
                      <Label className="uni-label">Grade Average</Label>
                      <Input
                        disabled={
                          localStorage.getItem("fromApplicationType") === "Sent"
                            ? true
                            : false
                        }
                        type="number"
                        className="form-control "
                        placeholder="Enter Grade Average"
                        onChange={this.handleTargetChange}
                        value={this.state.gradeaverage}
                        id="gradeaverage"
                        name="gradeaverage"
                        onInput={(e) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 6);
                        }}
                      />
                    </FormGroup>
                    <FormError error={this.state.errors.gradeaverage} />
                  </div>
                </div>

                {/*<div className="form-check my-3 pl-0">
                <Label className="check-name">
                  <input
                    disabled={
                      localStorage.getItem("fromApplicationType") === "Sent"
                        ? true
                        : false
                    }
                    type="checkbox"
                    className="form-check-input "
                    // defaultChecked={this.state.recentcheck}
                    checked={this.state.recent_summ}
                    id="mostrecent"
                    onChange={this.handleMostRecentChange}
                    value={this.state.recentcheck}
                  />
                  <span className="checkmark" />
                </Label>
                <label className="form-check-label pl-3">
                  Graduated from most recent school
                </label>
              </div> */}

                <div className="form-check mb-3 mt-3">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    // defaultChecked={this.state.recentcheck}
                    checked={this.state.recent_summ2}
                    id="mostrecent"
                    onChange={this.handleMostRecentChange}
                    value={this.state.recentcheck2}
                  />
                  <label className="form-check-label">
                    Graduated from most recent school
                  </label>
                </div>
              </div>
            </div>

            {this.state.recentcheck == true ? (
              <div className="personal-box-inner box">
                {this.state.awardedon.map((data, index) => (
                  <div key={index.toString()}>
                    {this.maxAndMinDateHandler(this.state.awardedon, "max") ==
                      data.toLocaleString() ? (
                      <React.Fragment>
                        <h3>Recent Schools Attended</h3>

                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Country of Education
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="edulevel"
                                name="edulevel"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                className=" edulevel"
                                value={this.state.edulevel[index]}
                              >
                                <option value="">
                                  Select Level of Education
                                </option>
                                {this.state.elevel.map((elevel) => (
                                  <option key={elevel.id} value={elevel.id}>
                                    {elevel.educationlevel_name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Level of Education
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                onChange={(evt) =>
                                  this.handleInsticountryChange(evt, index)
                                }
                                id="insticountry"
                                className=" insticountry"
                                value={this.state.insticountry[index]}
                              >
                                <option value="">Select Country</option>
                                {this.state.country.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.country_name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Name
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  institutename"
                                placeholder="Enter INSTITUTE NAME"
                                value={this.state.institutename[index]}
                                name="institutename"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Grading Scheme
                              </Label>
                              <div className="select-wrapper">
                                <Input
                                  type="select"
                                  id="gradingscheme"
                                  onChange={this.handleGradingSchemeChange}
                                  className=""
                                  placeholder="Other..."
                                  value={this.state.gradingscheme}
                                >
                                  <option value="">Other...</option>
                                  {this.state.grade.map((grade) => (
                                    <option value={grade.id} key={grade.id}>
                                      {grade.grading_scheme}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                            </FormGroup>
                            {this.state.errors.gradingscheme && (
                              <FormError
                                error={this.state.errors.gradingscheme}
                                className="error"
                              />
                            )}
                          </div>

                          {/*<div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                PRIMARY LANGUAGE OF INSTRUCTION
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  langinstruction"
                                placeholder="Enter Language of Instruction"
                                name="langinstruction"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.langinstruction[index]}
                              />
                            </FormGroup>
                          </div> */}
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Attended Institution From (Optional) (Optional)
                              </Label>

                              <DatePicker
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                selected={this.state.attendedfrom[index]}
                                onChange={(date) =>
                                  this.handleDateChange(date, index)
                                }
                                dateFormat="yyyy-MM-dd"
                                id="attendedfrom"
                                className=" attendedfrom"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Attended Institution to (Optional) (Optional)
                              </Label>

                              <DatePicker
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                selected={this.state.attendedto[index]}
                                onChange={(date) =>
                                  this.handleDateChange(date, index)
                                }
                                dateFormat="yyyy-MM-dd"
                                className=" attendedto"
                                s
                                id="attendedto"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Degree Awarded
                              </Label>

                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  awardeddegree"
                                placeholder="Enter Degree Awarded"
                                name="awardeddegree"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.awardeddegree[index]}
                                id="awardeddegree"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Degree Awarded on
                              </Label>

                              <DatePicker
                                selected={this.state.awardedon[index]}
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                onChange={(date) => {
                                  this.handleAwardedonChange(date, index);
                                }}
                                dateFormat="yyyy-MM-dd"
                                className=" awardedon"
                                id="awardedon"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Email
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  instemail"
                                placeholder="..."
                                name="instemail"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.instemail[index]}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Phone Number
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  instphone"
                                placeholder="..."
                                name="instphone"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.instphone[index]}
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Address (Optional)
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  insaddr"
                                placeholder="Enter  Address (Optional).."
                                name="insaddr"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.insaddr[index]}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                City/Town (Optional)
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  insaddr"
                                placeholder="Enter ADDRESS (OPTIONAL).."
                                name="inscity"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.inscity[index]}
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Province (Optional)
                              </Label>

                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="instate"
                                onChange={(evt) =>
                                  this.handleInstateChange(evt, index)
                                }
                                className="instate"
                                value={this.state.instate[index]}
                              >
                                <option value="">Select</option>
                                {this.state.inestate[index]
                                  ? this.state.inestate[index].map((states) => (
                                    <option key={states.id} value={states.id}>
                                      {states.name}
                                    </option>
                                  ))
                                  : null}
                              </Input>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Postal/Zip Code
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  inszip"
                                placeholder="Enter Postal/Zip Code... "
                                //id="szip"
                                name="inszip"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.inszip[index]}
                                id="inszip"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}

            {this.state.schoolsattended.map((element, index) => (
              <React.Fragment key={index.toString()}>
                {this.state.recentcheck == true ? (
                  this.maxAndMinDateHandler(this.state.awardedon, "min") !=
                    index ? (
                    <>
                      {this.state.edit ? null : <div className="preview"></div>}
                      <div className="personal-box-inner box">
                        <div className="pr-header">
                          <h3 id="demo">Schools Attended {index + 1}</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Level of Education
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="edulevel"
                                name="edulevel"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                className=" edulevel"
                                value={this.state.edulevel[index]}
                              >
                                <option value="">
                                  Select Level of Education
                                </option>
                                {this.state.elevel.map((elevel) => (
                                  <option key={elevel.id} value={elevel.id}>
                                    {elevel.educationlevel_name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            <FormError
                              error={
                                errors[index] ? errors[index].edulevel : null
                              }
                            />
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Level of Education
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="insticountry"
                                onChange={(evt) =>
                                  this.handleInsticountryChange(evt, index)
                                }
                                className=" insticountry"
                                value={this.state.insticountry[index]}
                              >
                                <option value="">Select Country</option>
                                {this.state.country.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.country_name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].insticountry} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Name
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  institutename"
                                placeholder="Enter INSTITUTE NAME"
                                name="institutename"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.institutename[index]}
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].institutename} />
                            ) : null}
                          </div>
                          {/*<div className="col-md-6">
                          <FormGroup>
                            <Label className="uni-label">
                              PRIMARY LANGUAGE OF INSTRUCTION
                            </Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  langinstruction"
                              placeholder="Enter Language of Instruction"
                              name="langinstruction"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={this.state.langinstruction[index]}
                            />
                          </FormGroup>
                          {errors[index]
                            ? <FormError error={errors[index].langinstruction}/>
                            : null}
                        </div> */}
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Attended Institution From (Optional)
                              </Label>

                              <DatePicker
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                selected={this.state.attendedfrom[index]}
                                onChange={(date) =>
                                  this.handleDateChange(date, index)
                                }
                                dateFormat="yyyy-MM-dd"
                                id="attendedfrom"
                                className=" attendedfrom"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].attendedfrom} />
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Attended Institution to (Optional)
                              </Label>

                              <DatePicker
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                selected={this.state.attendedto[index]}
                                onChange={(date) =>
                                  this.handleDateChange(date, index)
                                }
                                dateFormat="yyyy-MM-dd"
                                className=" attendedto"
                                id="attendedto"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].attendedto} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Degree Awarded
                              </Label>

                              <Input
                                type="text"
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                className="form-control  awardeddegree"
                                placeholder="Enter Degree Awarded"
                                name="awardeddegree"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.awardeddegree[index]}
                                id="awardeddegree"
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].awardeddegree} />
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Degree Awarded on
                              </Label>

                              <DatePicker
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                selected={this.state.awardedon[index]}
                                onChange={(date) => {
                                  this.handleDateChange(
                                    date,
                                    index,
                                    "awardedon"
                                  );
                                }}
                                dateFormat="yyyy-MM-dd"
                                className=" awardedon"
                                id="awardedon"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="YYYY-MMM-DD"
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].awardedon} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Email
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  instemail"
                                placeholder="..."
                                name="instemail"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.instemail[index]}
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].instemail} />
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Institute Phone Number
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  instphone"
                                placeholder="..."
                                name="instphone"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.instphone[index]}
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].instphone} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Address (Optional)
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  insaddr"
                                placeholder="Enter ADDRESS (OPTIONAL).."
                                name="insaddr"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.insaddr[index]}
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].insaddr} />
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Province (Optional)
                              </Label>

                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="instate"
                                onChange={(evt) =>
                                  this.handleInstateChange(evt, index)
                                }
                                className=" instate"
                                value={this.state.instate[index]}
                              >
                                <option value="">Select</option>
                                {this.state.inestate[index]
                                  ? this.state.inestate[index].map((states) => (
                                    <option key={states.id} value={states.id}>
                                      {states.name}
                                    </option>
                                  ))
                                  : null}
                              </Input>
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].instate} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                City/Town (Optional)
                              </Label>

                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="select"
                                id="scity"
                                name="inscity"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                className=" inscity"
                                value={this.state.inscity[index]}
                              >
                                <option value="">Select</option>
                                {this.state.inecity[index]
                                  ? this.state.inecity[index].map((cities) => (
                                    <option value={cities.id} key={cities.id}>
                                      {cities.name}
                                    </option>
                                  ))
                                  : null}
                              </Input>
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].inscity} />
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="uni-label">
                                Postal/Zip Code
                              </Label>
                              <Input
                                disabled={
                                  localStorage.getItem(
                                    "fromApplicationType"
                                  ) === "Sent"
                                    ? true
                                    : false
                                }
                                type="text"
                                className="form-control  inszip"
                                placeholder="Enter Postal/Zip Code... "
                                //id="szip"
                                name="inszip"
                                onChange={(evt) =>
                                  this.handleCloneChange(evt, index)
                                }
                                value={this.state.inszip[index]}
                                id="inszip"
                              />
                            </FormGroup>
                            {errors[index] ? (
                              <FormError error={errors[index].inszip} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null
                ) : (
                  <>
                    {this.state.edit ? null : <div className="preview"></div>}
                    <div className="personal-box-inner attend box">
                      <div className="pr-header row mt-5 pl-0 pr-0">
                        <h3 className="col-9 mt-4" id="demo">
                          Schools Attended {index + 1}
                        </h3>
                        <div className="col-3">
                          {!this.props.showComment ? (
                            <>
                              {index == 0 ? (
                                <Button
                                  color="link"
                                  className="blue-addmore float-right mt-3"
                                  type="submit"
                                  onClick={() => {
                                    let { schoolsattended } = this.state;
                                    schoolsattended.push({
                                      id: 0,
                                      edulevel: "",
                                      insticountry: "",
                                      institutename: "",
                                      attendedfrom: "",
                                      attendedto: "",
                                      awardeddegree: "",
                                      awardedon: "",
                                      instemail: "",
                                      instphone: "",
                                      insaddr: "",
                                      instate: "",
                                      inscity: "",
                                      inszip: "",
                                      institutename_other: "",
                                      school_grading_scheme: "",
                                      schoolaveragescheme: "",
                                    });
                                    this.setState({ schoolsattended });
                                  }}
                                >
                                  + Add More
                                </Button>
                              ) : (
                                ""
                              )}
                              {index > 0 ? (
                                <Button
                                  color="primary"
                                  className="ml-0 float-right beforesave removebtn blue-addmore btn btn-link"
                                  type="submit"
                                  onClick={() => this.onDelEducation(index)}
                                >
                                  - Remove
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`education_level_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Level of Education
                            </Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="select"
                              id="edulevel"
                              name="edulevel"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              className=" edulevel"
                              value={element.edulevel}
                            >
                              <option value="">
                                Select Level of Education
                              </option>
                              {this.state.elevel.map((elevel) => (
                                <option key={elevel.id} value={elevel.id}>
                                  {elevel.educationlevel_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].edulevel} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`education_country_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Country of Institution
                            </Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="select"
                              id="insticountry"
                              name="insticountry"
                              onChange={(evt) =>
                                this.handleInsticountryChange(evt, index)
                              }
                              className=" insticountry"
                              value={element.insticountry}
                            >
                              <option value="">Select Country</option>
                              {this.state.country.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.country_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].insticountry} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`institute_name_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Institute Name</Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  institutename"
                              placeholder="Enter Institute Name"
                              name="institutename"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.institutename}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].institutename} />
                          ) : null}
                        </div>

                        {/* <div className="col-md-6">
                        {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`institute_name_${index}`}
                            />
                          ) : null}
                          <Label className="uni-label">Other Institute</Label>
                          <Input
                            disabled={
                              localStorage.getItem("fromApplicationType") ===
                                "Sent"
                                ? true
                                : false
                            }
                            onChange={(evt) =>
                              this.handleCloneChange(evt, index)
                            }
                            type="text"
                            className="form-control  institutename_other"
                            placeholder="Enter Other Institute"
                            name="institutename_other"
                            value={element.institutename_other}
                          />
                          {errors[index] && errors[index].institutename_other ? (
                            <FormError error={errors[index].institutename_other} />
                          ) : null}
                        </div> */}

                        {/* <div className="col-md-6">
                        <FormGroup>
                          <Label className="uni-label">
                            PRIMARY LANGUAGE OF INSTRUCTION
                          </Label>
                          <Input
                            disabled={
                              localStorage.getItem("fromApplicationType") ===
                              "Sent"
                                ? true
                                : false
                            }
                            type="text"
                            className="form-control  langinstruction"
                            placeholder="Enter Language of Instruction"
                            name="langinstruction"
                            onChange={(evt) =>
                              this.handleCloneChange(evt, index)
                            }
                            value={this.state.langinstruction[index]}
                          />
                        </FormGroup>
                        {errors[index] ? <FormError error={errors[index].langinstruction}/> : null}
                      </div> */}

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`grade_scheme_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Grading Scheme</Label>
                            <Input
                              type="text"
                              className="form-control  school_grading_scheme"
                              placeholder="Enter Grading Scheme"
                              name="school_grading_scheme"
                              value={element.school_grading_scheme}
                              onChange={(e) =>
                                this.handleGradingScheme(e, index)
                              }
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError
                              error={errors[index].school_gradingscheme}
                            />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`grade_average_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Grade Average</Label>
                            <Input
                              type="text"
                              className="form-control  school_average_scheme"
                              placeholder="Enter Grade Average"
                              name="schoolaveragescheme"
                              value={element.schoolaveragescheme}
                              id="school_average_scheme"
                              onChange={(e) =>
                                this.handleGradingScheme(e, index)
                              }
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError
                              error={errors[index].school_gradeaverage}
                            />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`attend_from_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Attended Institution to (optional)
                            </Label>

                            <DatePicker
                              selected={
                                element.attendedfrom ? element.attendedfrom : ""
                              }
                              onChange={(date) =>
                                this.updateDates(index, "attendedfrom", date)
                              }
                              dateFormat="yyyy-MMM"
                              id="attendedfrom"
                              className="attendedfrom"
                              showMonthYearPicker
                              dropdownMode="select"
                              placeholderText="YYYY-MMM"
                              maxDate={new Date()}
                              autoComplete="off"
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].attendedfrom} />
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`attend_to_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Attended Institution to (Optional)
                            </Label>

                            <DatePicker
                              selected={
                                element.attendedto ? element.attendedto : ""
                              }
                              onChange={(date) =>
                                this.updateDates(index, "attendedto", date)
                              }
                              dateFormat="yyyy-MMM"
                              id="attendedfrom"
                              className="attendedfrom"
                              showMonthYearPicker
                              dropdownMode="select"
                              placeholderText="YYYY-MMM"
                              maxDate={new Date()}
                              minDate={
                                element.attendedfrom
                                  ? new Date(element.attendedfrom)
                                  : null
                              }
                              autoComplete="off"
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].attendedto} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`degree_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Degree Awarded</Label>

                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  awardeddegree"
                              placeholder="Enter Degree Awarded"
                              name="awardeddegree"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.awardeddegree}
                              id="awardeddegree"
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].awardeddegree} />
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`degree_date_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Degree Awarded on
                            </Label>

                            <DatePicker
                              selected={
                                element.awardedon ? element.awardedon : ""
                              }
                              onChange={(date) =>
                                this.updateDates(index, "awardedon", date)
                              }
                              dateFormat="yyyy-MMM"
                              id="awardedon"
                              className="awardedon"
                              showMonthYearPicker
                              dropdownMode="select"
                              placeholderText="YYYY-MMM"
                              maxDate={new Date()}
                              autoComplete="off"
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].awardedon} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_email_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Institute Email</Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  instemail"
                              placeholder="..."
                              name="instemail"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.instemail}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].instemail} />
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_phone_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Institute Phone Number
                            </Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  instphone"
                              placeholder="..."
                              name="instphone"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.instphone}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].instphone} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_address_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Address (Optional)
                            </Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  insaddr"
                              placeholder="Enter Address (Optional).."
                              name="insaddr"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.insaddr}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].insaddr} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_city_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              City/Town (Optional)
                            </Label>
                            <Input
                              type="text"
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              className="form-control  inscity"
                              placeholder="Enter CITY/TOWN.."
                              name="inscity"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.inscity}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].inscity} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_province_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">
                              Province (Optional)
                            </Label>
                            <Input
                              type="text"
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              className="form-control  instate"
                              placeholder="Enter PROVICE.."
                              name="instate"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.instate}
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].instate} />
                          ) : null}
                        </div>

                        <div className="col-md-6">
                          {this.props.showComment ? (
                            <CommentPopover
                              {...this.props}
                              id={`ins_post_${index}`}
                            />
                          ) : null}
                          <FormGroup>
                            <Label className="uni-label">Postal/Zip Code</Label>
                            <Input
                              disabled={
                                localStorage.getItem("fromApplicationType") ===
                                  "Sent"
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control  inszip"
                              placeholder="Enter Postal/Zip Code... "
                              //id="szip"
                              name="inszip"
                              onChange={(evt) =>
                                this.handleCloneChange(evt, index)
                              }
                              value={element.inszip}
                              id="inszip"
                            />
                          </FormGroup>
                          {errors[index] ? (
                            <FormError error={errors[index].inszip} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
            {
              this.state.appQuestions
                && this.state.appQuestions.length ? (
                <ApplicationQa
                  appAnswers={this.state.appAnswers}
                  appQuestions={this.state.appQuestions}
                  updatedAnswers={(appAnswers) => {
                    this.setState({ appAnswers })
                  }}
                />
              ) : null
            }
          </div>

          {this.props.showComment ? null : (
            <div className="bottomSaveButton">
              <button
                type="button"
                class="beforesave btn btn-secondary"
                onClick={this.onSubmit.bind(this, "exit")}
              >
                Save & Exit
              </button>
              <button
                type="button"
                class="beforesave btn btn-primary"
                onClick={this.onSubmit.bind(this, "")}
              >
                Next
              </button>
            </div>
          )}
        </div>

        <div className="col-12 col-md-3">
          {/*<div className=" list-card uni-no-padd mt-3 add-more-box">
            <button
              className="list-group-item w-100"
              // className="nav-l"
              onClick={() => edutab()}
            >
              Education
            </button>

            <div className="uni-no-padd card-body">
              <ul className="university-list list-group">
                <div className="stepone">
                  <div className="steptwo">
                    {this.state.questiondata1.map((question, index) => (
                      <li
                        id="about"
                        key={index}
                        className="list-group-item"
                        onClick={() => this.setQuestionDataOne(index)}
                      >
                        {question.question[0]}
                      </li>
                    ))}
                  </div>
                </div>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default template;
