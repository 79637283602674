import axios from "axios";
import { baseUrl } from ".";
import cors from "cors"

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Acess-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  },
  withCredentials: false
});

// instance.interceptors.request.use((config) => {
//   let universityToken = localStorage.getItem('universityToken');
//   if (universityToken) {
//     // config.headers['Token'] = `Bearer ${universityToken}`;
//   }
//   console.log('Authentication', universityToken)
//   return config;
// })

export default instance;