import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './protected.route';
import './App.scss';
import PageLoading from "./common/PageLoading";
const loading = () => <PageLoading loading={true} />;
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const Forgot = React.lazy(() => import('./views/Pages/Forgot/Forgot'));
const Reset = React.lazy(() => import('./views/Pages/Reset/Reset'));
const Studentprofile = React.lazy(() => import('./views/Studentprofile/Studentprofile'));
const Librarygeneral = React.lazy(() => import("./views/Librarygeneral/Librarygeneral"));
const Librarygre = React.lazy(() => import("./views/Librarygre/Librarygre"));
const Librarygmat = React.lazy(() => import("./views/Librarygmat/Librarygmat"));
const Libraryielts = React.lazy(() => import("./views/Libraryielts/Libraryielts"));
const Libraryvideo = React.lazy(() => import("./views/Libraryvideo/Libraryvideo"));
const Librarygrevid = React.lazy(() => import("./views/Librarygrevid/Librarygrevid"));
const Librarygmatvid = React.lazy(() => import("./views/Librarygmatvid/Librarygmatvid"));
const Libraryieltsvid = React.lazy(() => import("./views/Libraryieltsvid/Libraryieltsvid"));

const Institutelist = React.lazy(() => import('./views/Institutelist/Institutelist'));
const Universityabout = React.lazy(() => import('./views/Universityabout/Modern'));
const UniversityProgramDetail = React.lazy(() => import('./views/Universityabout/elements/ProgramDetails'));
const UniversityProgram = React.lazy(() => import('./views/Universityabout/ProgramTabPage'));
const Preferedpartnerlist = React.lazy(() => import("./views/Preferedpartnerlist/Preferedpartnerlist"));
const Draftapplication = React.lazy(() => import('./views/Draftapplication/Draftapplication'));
const Sendapplication = React.lazy(() => import('./views/Sendapplication/Sendapplication'));
const Acceptedapplication = React.lazy(() => import('./views/Acceptedapplication/Acceptedapplication'));
const Sendapplicationinprocess = React.lazy(() => import("./views/Sendapplicationinprocess/Sendapplicationinprocess"));
const OfferLetter = React.lazy(() => import("./views/OfferLetter/OfferLetter"));

const ProfileDashBoard = React.lazy(() => import("./views/ProfileDashBoard/ProfileDashBoard"));
const Changepassword = React.lazy(() => import('./views/Changepassword/Changepassword'));
const Paymentdetails = React.lazy(() => import("./views/Paymentdetails/Paymentdetails"));
const Profilenotification = React.lazy(() => import('./views/Profilenotification/Profilenotification'));
const Savedsearch = React.lazy(() => import('./views/Savedsearch/Savedsearch'));
const Profilewishlist = React.lazy(() => import('./views/Profilewishlist/Profilewishlist'));
const Profilewishlistinstitute = React.lazy(() => import('./views/Profilewishlistinstitute/Profilewishlistinstitute'));
const InboxMail = React.lazy(() => import("./views/mail/InboxMail/InboxMail"));
const SentMail = React.lazy(() => import("./views/mail/SentMail/SentMail"));
const OutboxMail = React.lazy(() => import("./views/mail/OutboxMail/OutboxMail"));
const MailDraft = React.lazy(() => import("./views/mail/MailDraft/MailDraft"));
const StarredMail = React.lazy(() => import("./views/mail/StarredMail/StarredMail"));
const ComposeMail = React.lazy(() => import("./views/mail/ComposMail/ComposMail"));
const Applications = React.lazy(() => import('./views/Applications/Applications'));

const Studentlibrary = React.lazy(() => import("./views/studentlibrary/studentlibrary"));
const Academicbeginner = React.lazy(() => import("./views/Academicbeginner/Academicbeginner"));
const Viewtutorial = React.lazy(() => import("./views/viewtutorial/viewtutorial"));

const ApplicationAddComment = React.lazy(() => import("./views/ApplicationAddComment/index"));


const Exam = React.lazy(() => import("./views/Exam/Exam"));
const Answer = React.lazy(() => import("./views/Exam/Answer"));
const Question = React.lazy(() => import("./views/Exam/Question"));
const Examresult = React.lazy(() => import("./views/Exam/Examresult"));
const Examresultmultiple = React.lazy(() => import("./views/Exam/Examresultmultiple"));
const Examwrittenanswer = React.lazy(() => import("./views/Exam/Examwrittenanswer"));

const ViewMessage = React.lazy(() => import("./views/Inbox/ViewMessage/viewMessageInbox"));
const CreateMeeting = React.lazy(() => import("./views/Meetings/CreateMeeting"));
const ViewMeeting = React.lazy(() => import("./views/Meetings/ViewMeeting"));
const Payment = React.lazy(() => import("./views/Payment"));
const MakePayment = React.lazy(() => import("./views/Payment/MakePayment"));
const AddPayment = React.lazy(() => import("./views/Paymentdetails/AddPayment"));
const PaymentInfo = React.lazy(() => import("./views/Paymentdetails/PaymentInfo"));
const LeaveReview = React.lazy(() => import("./views/LeaveReview/LeaveReview"));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <ProtectedRoute path="/studentprofile" name="Studentprofile" Component={Studentprofile} />
            <ProtectedRoute path="/Librarygeneral" name="Librarygeneral" Component={Librarygeneral} />
            <ProtectedRoute path="/Librarygre" name="Librarygre" Component={Librarygre} />
            <ProtectedRoute path="/Librarygmat" name="Librarygmat" Component={Librarygmat} />
            <ProtectedRoute path="/Libraryielts" name="Libraryielts" Component={Libraryielts} />
            <ProtectedRoute path="/Libraryvideo" name="Libraryvideo" Component={Libraryvideo} />
            <ProtectedRoute path="/Librarygrevid" name="Librarygrevid" Component={Librarygrevid} />
            <ProtectedRoute path="/Librarygmatvid" name="Librarygmatvid" Component={Librarygmatvid} />
            <ProtectedRoute path="/Libraryieltsvid" name="Libraryieltsvid" Component={Libraryieltsvid} />

            <ProtectedRoute path="/institutelist" name="Institutelist" Component={Institutelist} />
            <ProtectedRoute path="/universityabout" name="Universityabout" Component={Universityabout} />
            <ProtectedRoute path="universityprogramdetail" name="UniversityProgramDetail" Component={UniversityProgramDetail} />
            <ProtectedRoute path="universityprogram" name="UniversityProgram" Component={UniversityProgram} />
            <ProtectedRoute path="/preferedpartnerlist" name="Preferedpartnerlist"
              Component={Preferedpartnerlist} />
            <ProtectedRoute path="/draftapplication" name="Draftapplication" Component={Draftapplication} />
            <ProtectedRoute path="/sendapplication" name="Sendapplication" Component={Sendapplication} />
            <ProtectedRoute path="/Acceptedapplication" name="Acceptedapplication"
              Component={Acceptedapplication} />
            <ProtectedRoute path="/sendapplicationinprocess" name="Sendapplicationinprocess"
              Component={Sendapplicationinprocess} />
            <ProtectedRoute path="/offerLetter" name="OfferLetter"
              Component={OfferLetter} />
            <ProtectedRoute path="/profiledashboard" name="profiledashboard" Component={ProfileDashBoard} />
            <ProtectedRoute path="/changepassword" name="Changepassword" Component={Changepassword} />
            <ProtectedRoute path="/paymentdetails" name="Paymentdetails" Component={Paymentdetails} />
            <ProtectedRoute path="/profilenotification" name="Profilenotification"
              Component={Profilenotification} />
            <ProtectedRoute path="/savedsearch" name="Savedsearch" Component={Savedsearch} />
            <ProtectedRoute path="/profilewishlist" name="Profilewishlist" Component={Profilewishlist} />
            <ProtectedRoute path="/profilewishlistinstitute" name="Profilewishlistinstitute"
              Component={Profilewishlistinstitute} />
            <ProtectedRoute path="/inboxMail" name="InboxMail" Component={InboxMail} />
            <ProtectedRoute path="/sentMail" name="SentMail" Component={SentMail} />
            <ProtectedRoute path="/outboxmail" name="OutboxMail" Component={OutboxMail} />
            <ProtectedRoute path="/maildraft" name="MailDraft" Component={MailDraft} />
            <ProtectedRoute path="/starredmail" name="StarredMail" Component={StarredMail} />
            <ProtectedRoute path="/composemail" name="ComposeMail" Component={ComposeMail} />
            <ProtectedRoute path="/applications" name="Applications" Component={Applications} />

            {/* ======library links========== */}

            <ProtectedRoute path="/studentlibrary" name="studentlibrary" Component={Studentlibrary} />

            <ProtectedRoute exact path="/Academicbeginner/:id" name="Academicbeginner" Component={Academicbeginner} />
            <ProtectedRoute exact path="/viewtutorial/:courseId/:tutorialId" name="viewtutorial" Component={Viewtutorial} />

            {/* ======Practise exam========== */}
            <ProtectedRoute exact path="/Exam" name="Exam" Component={Exam} />
            <ProtectedRoute exact path="/practicalExamStart" name="Answer" Component={Answer} />
            <ProtectedRoute exact path="/Question" name="Question" Component={Question} />
            <ProtectedRoute exact path="/Examresult" name="Examresult" Component={Examresult} />
            <ProtectedRoute exact path="/ViewResults" name="Examresultmultiple" Component={Examresultmultiple} />
            <ProtectedRoute exact path="/Examwrittenanswer" name="Examwrittenanswer" Component={Examwrittenanswer} />

            <ProtectedRoute path="/application-comments/:id" name="ApplicationAddComment" Component={ApplicationAddComment} />
            <ProtectedRoute
              path="/viewMessageInbox/:id?/:type?"
              name="ViewMessage"
              Component={ViewMessage}
            />
            <ProtectedRoute
              path="/payment"
              name="Staffs"
              Component={Payment}
            />
            <ProtectedRoute
              path="/make-payment/:id"
              name="Make Payment"
              Component={MakePayment}
            />
            <ProtectedRoute
              path="/add-payment"
              name="Add Payment"
              Component={AddPayment}
            />
            <ProtectedRoute
              path="/payment-info"
              name="Payment Info"
              Component={PaymentInfo}
            />
            <ProtectedRoute
              path="/create-meeting/:id?"
              name="Drafts"
              Component={CreateMeeting}
            />
            <ProtectedRoute
              path="/view-meeting/:id?"
              name="Drafts"
              Component={ViewMeeting}
            />
            <ProtectedRoute
              path="/LeaveReview"
              name="Drafts"
              Component={LeaveReview}
            />

            {/* ======end library exam========== */}


            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/autologin/:id" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/forgot" name="Forgot Page" render={props => <Forgot {...props} />} />
            <Route exact path="/reset" name="Reset Page" render={props => <Reset {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
