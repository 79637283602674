import {
    SAVE_DEPARTMENT,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS,
	GET_UNIVERSITY_OVERVIEW_SUCCESS
  } from "../constants";

  const initialState = {
    overview: [],
    departmentList: [],
  };

  export default function departmentReducer(state = initialState, action) {
    switch (action.type) {
      case SAVE_DEPARTMENT:
        return { ...state };
      case GET_DEPARTMENTS:
        return { ...state }
    case GET_DEPARTMENTS_SUCCESS:
        return { ...state, departmentList: action.payload };
	  case GET_UNIVERSITY_OVERVIEW_SUCCESS:
		return { ...state, overview: action.payload };
      default:
        return state;
    }
  }
