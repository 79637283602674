import { put, takeLatest, all, call } from 'redux-saga/effects';
import { DELETE_CHAT_SESSIONS_API, GET_CHAT_MESSAGES_API, GET_CHAT_SESSIONS_LIST_API, STAR_CHAT_MESSAGES_API } from "../../Api";
import { deleteChatError, deleteChatSuccess, getChatMessagesListError, getChatMessagesListSuccess, getChatSessionListError, getChatSessionListSuccess, handleErrorResponse, handleSuccessResponse, starChatError, starChatSuccess } from "../actions";
import { ADD_STAR_CHAT, DELETE_CHAT, GET_CHAT_MESSAGES_LIST, GET_CHAT_SESSIONS_LIST } from "../constants";
// import axios from "../../Api/axios"
import axios from "axios";

function getChatSessionListApi(params) {
  return axios.request({
    method: "post",
    url: GET_CHAT_SESSIONS_LIST_API,
    data: params
  });
}

function* getChatSessionList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getChatSessionListApi, payload);
    handleSuccessResponse(response.data)
    yield put(getChatSessionListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getChatSessionListError(e))
    if (reject) reject(e)
  }
}

export function* getChatSessionListWatcher() {
  yield takeLatest(GET_CHAT_SESSIONS_LIST, getChatSessionList)
}

function getChatMessagesListApi(params) {
  return axios.request({
    method: "get",
    url: GET_CHAT_MESSAGES_API+"/"+params.id
  });
}

function* getChatMessagesList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getChatMessagesListApi, payload);
    handleSuccessResponse(response.data)
    yield put(getChatMessagesListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getChatMessagesListError(e))
    if (reject) reject(e)
  }
}

export function* getChatMessagesListWatcher() {
  yield takeLatest(GET_CHAT_MESSAGES_LIST, getChatMessagesList)
}

function deleteChatApi(params) {
  return axios.request({
    method: "get",
    url: DELETE_CHAT_SESSIONS_API+"/"+params.id,
  });
}

function* deleteChat(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteChatApi, payload);
    handleSuccessResponse(response.data)
    yield put(deleteChatSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteChatError(e))
    if (reject) reject(e)
  }
}

export function* deleteChatWatcher() {
  yield takeLatest(DELETE_CHAT, deleteChat)
}

function starChatApi(params) {
  return axios.request({
    method: "post",
    url: STAR_CHAT_MESSAGES_API,
    data: params
  });
}

function* starChat(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(starChatApi, payload);
    handleSuccessResponse(response.data)
    yield put(starChatSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(starChatError(e))
    if (reject) reject(e)
  }
}

export function* starChatWatcher() {
  yield takeLatest(ADD_STAR_CHAT, starChat)
}