import "./AppBackgroundinfo.css";
import Addmore from "../Addmore/Addmore";
import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import FormError from "../../common/FormError";
import Select from "react-select";
import CompleteProfileMessage from "../AppUploadDocument/CompleteProfileMessage";
import CommentPopover from "../ApplicationAddComment/component/CommentPopover";
import ApplicationQa from "../commonComponents/ApplicationQa";

function template() {
  const backgroundtab = () => {
    this.setState({ BackgroundAddMore: false, selectedQuestion3: null });
  };
  return (
    <div className="tab-wrap appbackgroundinfo newBg">
      {/* <CompleteProfileMessage
          {...this.props}
          show={this.state.showCompleteProfileMessage}
          toggleShowHide={() => {
            this.setState({
              showCompleteProfileMessage: false
            })
          }}
        /> */}
      <Form ref={this.props.formRef}>
        <div className="personal-box-inner box">
          <div className="row activeTitle">
            <div className="col-8">
              <h3>Background Information</h3>
            </div>

            <div className="col-4 text-right right-btn-align">
              {/* {!this.props.showComment ? (
                this.state.edit ? (
                  <Button
                    color="primary"
                    className="app-personal-savebtn"
                    type="submit"
                    onClick={this.onSubmit.bind(this)}
                  >
                    SAVE
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="app-personal-savebtn"
                    type="submit"
                    onClick={() => {
                      this.setState({
                        edit: true,
                      });
                    }}
                  >
                    EDIT
                  </Button>
                )
              ) : null} */}
            </div>
            <div className="col-12">
              <div
                style={{
                  display:
                    this.state.BackgroundAddMore == false ? "block" : "none",
                }}
              >
                {this.state.edit || this.props.showComment ? null : (
                  <div className="preview"></div>
                )}
                <div className="destination-box">
                  <div className="row uni-no-mar flex-column">
                    <Label className="uni-label">
                      Have you been refused a visa from Canada, the USA, the
                      United Kingdom, New Zealand or Australia?
                    </Label>

                    <div
                      onChange={this.handleTargetChange}
                      className="col-md-6"
                    >
                      <div className="row">
                        {this.props.showComment ? (
                          <CommentPopover {...this.props} id={"refusedvisa"} />
                        ) : null}
                        <div className="col-6 col-md-6 form-check my-2">
                          <Label className="check-name">
                            <input
                              disabled={this.state.disabled}
                              type="radio"
                              className="form-check-input "
                              id="exampleCheck1"
                              value="yes"
                              name="refusedvisa"
                              checked={this.state.refusedvisa == "yes"}
                              onChange={() => {
                                this.setState({
                                  refusedvisa: "yes",
                                });
                              }}
                            />
                            <span className="checkmark" />
                          </Label>
                          <label className="form-check-label pl-3">Yes</label>
                        </div>

                        <div className="col-6 col-md-6 form-check my-2">
                          <Label className="check-name">
                            <input
                              disabled={this.state.disabled}
                              type="radio"
                              className="form-check-input "
                              id="exampleCheck2"
                              value="no"
                              name="refusedvisa"
                              checked={this.state.refusedvisa == "no"}
                              onChange={() => {
                                this.setState({
                                  refusedvisa: "no",
                                });
                              }}
                            />
                            <span className="checkmark" />
                          </Label>
                          <label className="form-check-label pl-3">No</label>
                        </div>
                      </div>
                    </div>
                    <FormError error={this.state.errors.refusedvisa} />
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {this.props.showComment ? (
                        <CommentPopover {...this.props} id={"visapermit"} />
                      ) : null}
                      <FormGroup>
                        <Label className="uni-label">
                          Do you have a valid Study Permit / Visa?
                        </Label>
                        <Select
                          options={this.state.visaOptions}
                          closeMenuOnSelect={true}
                          value={this.state.visapermit}
                          onChange={(e) => {
                            let current = e;
                            let dontHaveSelected = null;
                            if (this.state.visapermit) {
                              dontHaveSelected = this.state.visapermit.find(
                                (val) => val.value == 1
                              );
                            }
                            let dontHaveCurrent = null;
                            if (e && e.length) {
                              dontHaveCurrent = e.find((val) => val.value == 1);
                            }

                            if (dontHaveCurrent && !dontHaveSelected) {
                              let index = current.indexOf(dontHaveCurrent);
                              if (index) {
                                current = [dontHaveCurrent];
                                // current.splice(index, 1)
                              }
                            } else if (dontHaveCurrent && dontHaveSelected) {
                              if (current) {
                                let index = current.indexOf(dontHaveCurrent);
                                if (index != null) {
                                  // current = [dontHaveCurrent]
                                  current.splice(index, 1);
                                }
                              }
                            }
                            this.setState({
                              visapermit: current,
                            });
                          }}
                          isMulti={true}
                          className="SelectDropdown"
                        />
                        {/* <Input
                      disabled={this.state.disabled}
                      type="select"
                      name="visapermit"
                      id="visapermit"
                      onChange={this.handleTargetChange}
                      className=""
                      value={this.state.visapermit}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Input> */}
                      </FormGroup>
                      <FormError error={this.state.errors.visapermit} />
                    </div>
                    <div className="col-md-12">
                      {this.props.showComment ? (
                        <CommentPopover {...this.props} id={"txtdetails"} />
                      ) : null}
                      <FormGroup>
                        <Label className="uni-label">
                          If you answered "Yes" to any of the following
                          questions above, please provide more details below:
                        </Label>
                        <textarea
                          disabled={this.state.disabled}
                          id="txtdetails"
                          name="txtdetails"
                          value={this.state.txtdetails}
                          className="form-control "
                          placeholder="Provide Details"
                          onChange={this.handleTargetChange}
                        />
                      </FormGroup>
                      <FormError error={this.state.errors.txtdetails} />
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 d-flex justify-content-center">
              {this.state.disabled === false ? (
                <Button
                  color="primary"
                  className="score-save "
                  type="submit"
                  onClick={this.onSubmit.bind(this)}
                >
                  SAVE
                </Button>
              ) : (
                <div className="col-12 d-flex justify-content-center">
                  {" "}
                  <Button
                    color="primary"
                    className="score-back mx-4 aftersave"
                    onClick={this.prevTab}
                  >
                    BACK
                  </Button>
                  <Button
                    color="primary"
                    className="score-save aftersave"
                    onClick={this.nextTab}
                  >
                    NEXT
                  </Button>
                </div>
              )}
            </div> */}
              </div>
              <div
                style={{
                  display:
                    this.state.BackgroundAddMore == true ? "block" : "none",
                }}
              >
                <Addmore
                  clearBackgroundAddmore={this.state.clearBackgroundAddmore}
                  getQuestiondata={this.getQuestionData3}
                  selectedQuestion={this.state.selectedQuestion3}
                  type={"background"}
                  customClass={"backAddmore"}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {this.state.uniQuestTextArr.length ? (
                  <div className="personal-box-inner mt-4 mb-5">
                    {(Array.isArray(this.state.uniQuestMultiArr) &&
                      this.state.uniQuestMultiArr.length) ||
                    (Array.isArray(this.state.uniQuestTextArr) &&
                      this.state.uniQuestTextArr.length) ||
                    (Array.isArray(this.state.uniQuestSignleArr) &&
                      this.state.uniQuestSignleArr.length) ? (
                      <div className="pr-header">
                        <h3>Other Information</h3>
                      </div>
                    ) : null}

                    {Array.isArray(this.state.uniQuestMultiArr) &&
                      this.state.uniQuestMultiArr.map(
                        (uniQuestMulti, index) => (
                          <div
                            className="col-md-12 multi-slct-answr mt-3"
                            key={index}
                          >
                            <div className="row font-weight-bold">
                              {uniQuestMulti.question}
                            </div>

                            {JSON.parse(uniQuestMulti.question_options).map(
                              (item, ind) => (
                                <div className="row mt-3 ml-3" key={ind}>
                                  <Input
                                    defaultChecked={
                                      uniQuestMulti.uni_app_que_id ==
                                        uniQuestMulti.id &&
                                      item.text == uniQuestMulti.answers &&
                                      uniQuestMulti.tab_name == "background" &&
                                      localStorage.getItem("studentid") ==
                                        uniQuestMulti.student_id
                                    }
                                    type="checkbox"
                                    className="multiCheck"
                                    value={item.text + "|" + uniQuestMulti.id}
                                  />
                                  <div className="ansr-chck-txt">
                                    {item.text}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )
                      )}

                    {Array.isArray(this.state.uniQuestTextArr) &&
                      this.state.uniQuestTextArr.map((uniQuestText, index) => (
                        <div className="col-md-12 text-answr mt-5" key={index}>
                          <div className="row font-weight-bold">
                            {uniQuestText.question}
                          </div>
                          <div className="row mt-3">
                            <textarea
                              name="ansrTxt[]"
                              className="ansrTxt"
                              data-id={uniQuestText.id}
                              rows="4"
                              cols="100"
                            >
                              {(() => {
                                if (
                                  uniQuestText.uni_app_que_id ==
                                    uniQuestText.id &&
                                  uniQuestText.tab_name == "background" &&
                                  localStorage.getItem("studentid") ==
                                    uniQuestText.student_id
                                ) {
                                  return uniQuestText.answers;
                                }
                                return null;
                              })()}
                            </textarea>
                          </div>
                        </div>
                      ))}

                    {Array.isArray(this.state.uniQuestSignleArr) &&
                      this.state.uniQuestSignleArr.map(
                        (uniQuestSignle, index) => (
                          <div className="col-md-12 single-slct-answr mt-5">
                            <div className="row font-weight-bold">
                              {uniQuestSignle.question}
                            </div>
                            {JSON.parse(uniQuestSignle.question_options).map(
                              (item) => (
                                <div className="row mt-3 ml-3">
                                  <Input
                                    defaultChecked={
                                      uniQuestSignle.uni_app_que_id ==
                                        uniQuestSignle.id &&
                                      item.text == uniQuestSignle.answers &&
                                      uniQuestSignle.tab_name == "background" &&
                                      localStorage.getItem("studentid") ==
                                        uniQuestSignle.student_id
                                    }
                                    type="radio"
                                    name={"generalradio" + index}
                                    className="generalradio"
                                    value={item.text + "|" + uniQuestSignle.id}
                                  />
                                  <div className="ansr-chck-txt">
                                    {item.text}
                                  </div>
                                </div>
                              )
                            )}
                            <Input type="hidden" name="signlechck" />
                          </div>
                        )
                      )}
                  </div>
                ) : null} */}

        {this.state.questiondata3.length ? (
          <div className="personal-box-inner box">
            <div className="col-12">
              <div className=" list-card uni-no-padd mt-3 add-more-box">
                {/*<button
              className="list-group-item w-100"
              // className="nav-l"
              onClick={() => backgroundtab()}
            >
              Background Information
            </button> */}

                <div className="uni-no-padd card-body">
                  <ul className="university-list list-group">
                    <div className="stepone">
                      <div className="steptwo">
                        {this.state.questiondata3.map((question, index) => (
                          <li
                            id="about"
                            key={index.toString()}
                            className="list-group-item"
                            onClick={() => this.setQuestionData3(index)}
                          >
                            {question.question[0]}
                          </li>
                        ))}
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Form>

      {
        this.state.appQuestions && this.state.appQuestions.length ? (
          <ApplicationQa
            appQuestions={this.state.appQuestions}
            appAnswers={this.state.appAnswers}
            updatedAnswers={(appAnswers) => {
              this.setState({ appAnswers })
            }}
          />
        ) : null
      }

      {this.props.showComment ? null : (
        <div className="bottomSaveButton">
          <button
            type="button"
            class="beforesave btn btn-secondary"
            onClick={this.onSubmit.bind(this, "exit")}
          >
            Save & Exit
          </button>
          <button
            type="button"
            class="beforesave btn btn-primary"
            onClick={this.onSubmit.bind(this, "")}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default template;
