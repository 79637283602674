import { DELETE_SATFF_API } from "../../Api";
import {
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  GET_GROUP_LIST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_LIST_ERROR,
  SEND_STAFF_INVITE,
  SEND_STAFF_INVITE_SUCCESS,
  SEND_STAFF_INVITE_ERROR,
  GET_GROUP_ROLES_LIST,
  GET_GROUP_ROLES_LIST_SUCCESS,
  GET_GROUP_ROLES_LIST_ERROR,
  ADD_ROLES_TO_GROUP,
  ADD_ROLES_TO_GROUP_SUCCESS,
  ADD_ROLES_TO_GROUP_ERROR,
  GET_ROLE_PERMISSION,
  GET_ROLE_PERMISSION_SUCCESS,
  GET_ROLE_PERMISSION_ERROR,
  SET_ROLE_PERMISSION_SUCCESS,
  SET_ROLE_PERMISSION_ERROR,
  SET_ROLE_PERMISSION,
  GET_STAFF_LIST_ERROR,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  DELETE_ROLE_ERROR,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE,
  GET_GROUP_INVITE_LIST_SUCCESS,
  GET_GROUP_INVITE_LIST_ERROR,
  GET_GROUP_INVITE_LIST,
  STAFF_INVITE_LIST,
  STAFF_INVITE_LIST_SUCCESS,
  STAFF_INVITE_LIST_ERROR,
  DELETE_SAFF_ERROR,
  DELETE_SAFF_SUCCESS,
  DELETE_STAFF,
  DELETE_STAFF_INVITE,
  DELETE_STAFF_INVITE_SUCCESS,
  DELETE_STAFF_INVITE_ERROR,
  GET_PROFILE_SUCCESS
} from "../constants";

export function createGroupAction(session, resolve, reject) {
  return {
    type: CREATE_GROUP,
    payload: session,
    resolve,
    reject
  };
}

export function createGroupSuccess(data) {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: data
  };
}

export function createGroupError(error) {
  return {
    type: CREATE_GROUP_ERROR,
    payload: error
  };
}

export function getGroupListAction(session, resolve, reject) {
  return {
    type: GET_GROUP_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getGroupListSuccess(data) {
  return {
    type: GET_GROUP_LIST_SUCCESS,
    payload: data
  };
}

export function getGroupListError(error) {
  return {
    type: GET_GROUP_LIST_ERROR,
    payload: error
  };
}

export function getRoleListAction(session, resolve, reject) {
  return {
    type: GET_ROLE_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getRoleListSuccess(data) {
  return {
    type: GET_ROLE_LIST_SUCCESS,
    payload: data
  };
}

export function getRoleListError(error) {
  return {
    type: GET_ROLE_LIST_ERROR,
    payload: error
  };
}

export function sendStaffInviteAction(session, resolve, reject) {
  return {
    type: SEND_STAFF_INVITE,
    payload: session,
    resolve,
    reject
  };
}

export function sendStaffInviteSuccess(data) {
  return {
    type: SEND_STAFF_INVITE_SUCCESS,
    payload: data
  };
}

export function sendStaffInviteError(error) {
  return {
    type: SEND_STAFF_INVITE_ERROR,
    payload: error
  };
}

export function getGroupRolesListAction(session, resolve, reject) {
  return {
    type: GET_GROUP_ROLES_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getGroupRolesListSuccess(data) {
  return {
    type: GET_GROUP_ROLES_LIST_SUCCESS,
    payload: data
  };
}

export function getGroupRolesListError(error) {
  return {
    type: GET_GROUP_ROLES_LIST_ERROR,
    payload: error
  };
}

export function addRolesToGroupAction(session, resolve, reject) {
  return {
    type: ADD_ROLES_TO_GROUP,
    payload: session,
    resolve,
    reject
  };
}

export function addRolesToGroupSuccess(data) {
  return {
    type: ADD_ROLES_TO_GROUP_SUCCESS,
    payload: data
  };
}

export function addRolesToGroupError(error) {
  return {
    type: ADD_ROLES_TO_GROUP_ERROR,
    payload: error
  };
}

export function getRolePermissionAction(session, resolve, reject) {
  return {
    type: GET_ROLE_PERMISSION,
    payload: session,
    resolve,
    reject
  };
}

export function getRolePermissionSuccess(data) {
  return {
    type: GET_ROLE_PERMISSION_SUCCESS,
    payload: data
  };
}

export function getRolePermissionError(error) {
  return {
    type: GET_ROLE_PERMISSION_ERROR,
    payload: error
  };
}

export function setRolePermissionAction(session, resolve, reject) {
  return {
    type: SET_ROLE_PERMISSION,
    payload: session,
    resolve,
    reject
  };
}

export function setRolePermissionSuccess(data) {
  return {
    type: SET_ROLE_PERMISSION_SUCCESS,
    payload: data
  };
}

export function setRolePermissionError(error) {
  return {
    type: SET_ROLE_PERMISSION_ERROR,
    payload: error
  };
}

export function getStaffListAction(session, resolve, reject) {
  return {
    type: GET_STAFF_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getStaffListSuccess(data) {
  return {
    type: GET_STAFF_LIST_SUCCESS,
    payload: data
  };
}

export function getStaffListError(error) {
  return {
    type: GET_STAFF_LIST_ERROR,
    payload: error
  };
}

export function deleteGroupAction(session, resolve, reject) {
  return {
    type: DELETE_GROUP,
    payload: session,
    resolve,
    reject
  };
}

export function deleteGroupSuccess(data) {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: data
  };
}

export function deleteGroupError(error) {
  return {
    type: DELETE_GROUP_ERROR,
    payload: error
  };
}

export function getProfileAction(session, resolve, reject) {
  return {
    type: GET_PROFILE,
    payload: session,
    resolve,
    reject
  };
}

export function getProfileSuccess(data) {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: data
  };
}

export function getProfileError(error) {
  return {
    type: GET_PROFILE_ERROR,
    payload: error
  };
}


export function deleteRoleAction(session, resolve, reject) {
  return {
    type: DELETE_ROLE,
    payload: session,
    resolve,
    reject
  };
}

export function deleteRoleSuccess(data) {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: data
  };
}

export function deleteRoleError(error) {
  return {
    type: DELETE_ROLE_ERROR,
    payload: error
  };
}

export function getGroupInviteListAction(session, resolve, reject) {
  return {
    type: GET_GROUP_INVITE_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getGroupInviteListSuccess(data) {
  return {
    type: GET_GROUP_INVITE_LIST_SUCCESS,
    payload: data
  };
}

export function getGroupInviteListError(error) {
  return {
    type: GET_GROUP_INVITE_LIST_ERROR,
    payload: error
  };
}

export function getStaffInviteListAction(session, resolve, reject) {
  return {
    type: STAFF_INVITE_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getStaffInviteListSuccess(data) {
  return {
    type: STAFF_INVITE_LIST_SUCCESS,
    payload: data
  };
}

export function getStaffInviteListError(error) {
  return {
    type: STAFF_INVITE_LIST_ERROR,
    payload: error
  };
}

export function deleteStaffAction(session, resolve, reject) {
  return {
    type: DELETE_STAFF,
    payload: session,
    resolve,
    reject
  };
}

export function deleteStaffSuccess(data) {
  return {
    type: DELETE_SAFF_SUCCESS,
    payload: data
  };
}

export function deleteStaffError(error) {
  return {
    type: DELETE_SAFF_ERROR,
    payload: error
  };
}

export function deleteStaffInviteAction(session, resolve, reject) {
  return {
    type: DELETE_STAFF_INVITE,
    payload: session,
    resolve,
    reject
  };
}

export function deleteStaffInviteSuccess(data) {
  return {
    type: DELETE_STAFF_INVITE_SUCCESS,
    payload: data
  };
}

export function deleteStaffInviteError(error) {
  return {
    type: DELETE_STAFF_INVITE_ERROR,
    payload: error
  };
}