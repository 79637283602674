import {
  GET_COUNTRY_LIST_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_UNIVERSITY_ABOUT,
  GET_UNIVERSITY_ABOUT_SUCCESS,
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS
} from "../constants";

const initialState = {
  sideProfile: null,
  profile: null,
  universityLogo: ""
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SIDE_PROFILE:
      return { ...state };
    case SAVE_SIDE_PROFILE_SUCCESS:
      return { ...state }
    case GET_UNIVERSITY_ABOUT:
      return { ...state }
    case GET_UNIVERSITY_ABOUT_SUCCESS:
      console.log('action.payload', action.payload.data)
      return { ...state, sideProfile: action.payload.data };
    case GET_PROFILE:
      return { ...state };
    case GET_PROFILE_SUCCESS:
      let universityLogo = "";
      let profile = null
      
      if (
        action.payload &&
        action.payload.length
      ) {
        profile = action.payload[0]
        universityLogo = action.payload[0].filename
      }

      return {  
        ...state, 
        profile, 
        universityLogo
      };
    case UPDATE_PROFILE:
      return { ...state };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state };
    default:
      return state;
  }
}