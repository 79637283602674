import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export function checkValidPdfFile(file) {
  let matched = file.type.match('application/pdf');

  return matched;
}

export function checkValidFileSize(file) {
  let fileSize = file.size / 1048576;

  return fileSize > 14;
}

export function showConfirmation(data) {
  confirmAlert({
    title: data.title,
    message: data.message,
    buttons: data.buttons
  })
}

export function groupArray(list, getKey) {
  let map = new Map();
  list.forEach((val) => {
    let key = getKey(val);
    let checkcollect = map.get(key);
    if (checkcollect) {
      checkcollect.push(val);
    } else {
      map.set(key, [val]);
    }
  });

  return map;
}

export function extractAddressComponents(addressComponents) {
  let city = null;
  let state = null;
  let country = null;
  let postal_code = null;

  addressComponents.forEach((component) => {
    if (component.types.includes("locality")) {
      city = component.long_name;
    } else if (component.types.includes("administrative_area_level_1")) {
      state = component.long_name;
    } else if (component.types.includes("country")) {
      country = component.long_name;
    } else if (component.types.includes("postal_code")) {
      postal_code = component.long_name;
    }
  });

  return { city, state, country, postal_code };
}

export function groupArrayCustomResponse(list, getKey) {
  let map = new Map();
  let finalMap = new Map()

  list.forEach((val) => {
    if (val.tab) {
      let key = getKey(val);
      let checkcollect = map.get(key);
      if (checkcollect) {
        let obj = {
          field_id: key,
          comments: checkcollect.length,
          last_comment: val.comment,
          created_date: val.created_at,
          tab: val.tab,
          sender: val.sender,
          user_id: val.user_id,
          type: val.user_type
        }
        checkcollect.push(val);
        finalMap.set(key, obj)
      } else {
        map.set(key, [val]);
        let obj = {
          field_id: key,
          comments: 1,
          last_comment: val.comment,
          created_date: val.created_at,
          tab: val.tab,
          sender: val.sender,
          user_id: val.user_id,
          type: val.user_type
        }
        finalMap.set(key, obj)
      }
    }
  });

  return finalMap;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const timestampToDate = (timestamp) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const d = new Date(timestamp * 1000);
  let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

  return `${date} ${months[d.getMonth()]} ${d.getFullYear()}`;
}
export const timestampToHm = (timestamp) => {
  const date = new Date(timestamp * 1000).toLocaleTimeString("it-IT");
  return `${date}`;
}