import React, { Component } from "react";
import solveicon from "../assets/img/checkmark.svg";
import optiondot from "../assets/img/more.svg";
import chathead from "../assets/img/chat-bluehead.png";
import chaticon1 from "../assets/img/chat-bluehead.png";
import circleflg from "../assets/img/university/australia_circle_flag.svg";
import downloadicon from "../assets/img/university/download.png";
import chatinitial from "../assets/img/initialprofile.png";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import comments from "../assets/img/comments.svg";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup, Input, Label
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import config from "../config.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MESSAGES } from "../constant/messages";
import submitForm from '../utils/submitForm';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from '../common/FormError';
import ApplicationOverview from "./ApplicationAddComment"

const baseurl = `${config.baseurl}`;
const admin_url = `${config.admin_url}`;
const genders = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Non-binary',
    value: 'non-Binary'
  },
  {
    label: 'I dont want to disclose',
    value: 'disclose'
  }
];
const titles = ["Mr", "Mrs", "Miss", "Ms", "I dont want to disclose"];
const marritalStatuses = ["single", "married", "widowed", "separated", "divorced"];

class ViewFeedBack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      filterallcommants: "all",
      totalcountmsg: 0,
      feedbackmodel: false,
      commandmodel: false,
      viewcommandmodel: false,
      chaticonaction: false,
      getmessagebyid: [],
      id: "",
      app_id: "",
      course_id: "",
      course_name: "",
      message: "",
      student_created: "",
      edu_level: "",
      student_id: "",
      university_name: "",
      commantfieldname: "",
      login_email: "",
      main_country: "",
      stu_personal: [],
      stu_doc: [],
      org_stu_doc: [],
      stu_edu: [],
      stu_tst: [],
      classes: [],
      main_status: "",
      eduhis: [],
      getnotes: [],
      stu_app_id: [],
      stu_stuid: [],
      stu_stuname: [],
      commantmsg: "",
      getallmessagecommant: [],
      starred_status: [],
      verified: "",
      course_startdate: "",
      first_namefieldname: "",
      addr_emailfieldname: "",
      dateof_birthfieldname: "",
      phonefieldname: "",
      first_languagefieldname: "",
      genderfieldname: "",
      marital_statusfieldname: "",
      passport_nofieldname: "",
      edu_countryfieldname: "",
      edu_edulevelfieldname: "",
      edulevelfieldname: "",
      institutefieldname: "",
      countryfieldname: "",
      awardfieldname: "",
      awardonfieldname: "",
      emailfieldname: "",
      cityfieldname: "",
      exam_typefieldname: "",
      exam_datefieldname: "",
      reading_scorefieldname: "",
      listening_scorefieldname: "",
      writing_scorefieldname: "",
      speaking_scorefieldname: "",
      gre_exam_datefieldname: "",
      gre_verbal_scorefieldname: "",
      gre_verbal_rankfieldname: "",
      gre_quanitative_scorefieldname: "",
      gre_quanitative_rankfieldname: "",
      gre_writing_scorefieldname: "",
      gre_writing_rankfieldname: "",
      gmat_exam_datefieldname: "",
      gmat_verbal_scorefieldname: "",
      gmat_verbal_rankfieldname: "",
      gmat_quanitative_scorefieldname: "",
      gmat_quanitative_rankfieldname: "",
      gmat_writing_scorefieldname: "",
      gmat_writing_rankfieldname: "",
      gmat_total_scorefieldname: "",
      gmat_total_rankfieldname: "",
      stu_docfieldname: "",
      tickshow: false,
      edittext: false,
      formData: new FormData(),
      tabDetail: '',
      errors: {},
      fname: '',
      lname: '',
      dateofbirth: '',
      flang: '',
      citiship: '',
      passno: '',
      sgender: '',
      smarital: '',
      saddr: '',
      scity: '',
      scountry: '',
      sstate: '',
      szip: '',
      semail: '',
      sphcode: '',
      sphno: '',
      smbcode: '',
      smbno: '',
      sdob: '',
      study_course: '',
      study_destination: '',
      study_level_id: '',
      study_plan: '',
      comments: [],
      filteredComments: []
    };
  }
  printDocument = () => {
    const input = document.getElementById("pdfdiv");
    html2canvas(input).then((canvas) => {
      const imgWidth = 200;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      let position = 0;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        if (heightLeft >= 0) {
          pdf.addPage();
        }
      }
      pdf.save("download.pdf");
    });
  };

  componentDidMount() {
    let student_id = localStorage.getItem('studentid')
    this.setState({
      student_id
    })
  }

  toggleFeedback = () => {
    this.setState({ feedbackmodel: !this.state.feedbackmodel });
  };

  openFeedback = () => {
    console.log('log inopenfeed');
    const { item } = this.props;
    if (item) {
      localStorage.setItem("app_id", item.application_id);
      localStorage.setItem("agentid", item.agent_id);
      this.props.showLoader();
      axios.get(
        baseurl + '/update_highlight/' + item.application_id)
        .then(function (response) {
          this.props.hideLoader();
          console.log("highlight check", response)
        }).catch((error) => {
          this.props.hideLoader();
          console.log('error: ', error);
          toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        })
    }
    // this.updateStatus();
    // this.getPopupData();
    this.getAllMessage();
    this.getGeneraldata();
    this.setState({ feedbackmodel: !this.state.feedbackmodel });
  };
  // updateStatus = () => {
  //   this.props.showLoader();
  //   axios
  //     .get(
  //       baseurl +
  //       "/updatefeedbackcount/" +
  //       localStorage.getItem("app_id") +
  //       "/" +
  //       "Student"
  //     )
  //     .then((response) => {
  //       this.props.hideLoader();
  //     }).catch(() => {
  //       this.props.hideLoader();
  //     });
  // };

  // getPopupData = () => {
  //   const appId = localStorage.getItem("app_id");
  //   this.props.showLoader();
  //   const applicantAgentDetailsReq = axios.get(
  //     baseurl + "/viewapplicationagent_details/" + appId
  //   );
  //   const messageApplicantReq = axios.get(
  //     baseurl + "/getmessageapplicant/" + appId
  //   );
  //   Promise.all([applicantAgentDetailsReq, messageApplicantReq])
  //     .then(async ([applicantAgentDetailsRes, messageApplicantRes]) => {
  //       let applicantAgentDetailsResData = {};
  //       let messageApplicantResData = {
  //         getnotes: messageApplicantRes.data,
  //       };
  //       let appAllCommentsResData = {};
  //       let applicationFieldNameStudentResData = {};
  //       if (applicantAgentDetailsRes.data) {
  //         applicantAgentDetailsResData = {
  //           app_id: applicantAgentDetailsRes.data.appl_id,
  //           course_id: applicantAgentDetailsRes.data.course_id,
  //           course_name: applicantAgentDetailsRes.data.course_name,
  //           student_created: applicantAgentDetailsRes.data.student_created,
  //           edu_level: applicantAgentDetailsRes.data.edu_level,
  //           student_id: applicantAgentDetailsRes.data.stu_personal.student_id,
  //           stu_personal: applicantAgentDetailsRes.data.stu_personal,
  //           university_name: applicantAgentDetailsRes.data.university_name,
  //           login_email: applicantAgentDetailsRes.data.login_email,
  //           stu_doc: applicantAgentDetailsRes.data.stu_doc,
  //           org_stu_doc: applicantAgentDetailsRes.data.org_stu_doc,
  //           stu_edu: applicantAgentDetailsRes.data.stu_edu,
  //           eduhis: applicantAgentDetailsRes.data.eduhis,
  //           stu_tst: applicantAgentDetailsRes.data.stu_tst,
  //           classes: applicantAgentDetailsRes.data.classes,
  //           main_country: applicantAgentDetailsRes.data.main_country,
  //           stu_stuid: applicantAgentDetailsRes.data.id,
  //           stu_stuname: applicantAgentDetailsRes.data.student_name,
  //           starred_status: applicantAgentDetailsRes.data.starred_status,
  //           stu_app_id: applicantAgentDetailsRes.data.id,
  //           main_status: applicantAgentDetailsRes.data.main_status,
  //           verified: applicantAgentDetailsRes.data.verified,
  //           course_startdate: applicantAgentDetailsRes.data.course_startdate,
  //         };
  //         if (applicantAgentDetailsRes.data.stu_personal) {
  //           const appAllCommentsRes = await axios.get(
  //             baseurl +
  //             "/getappallcomments/" +
  //             appId +
  //             "/" +
  //             localStorage.getItem("agentid") +
  //             "/" +
  //             applicantAgentDetailsRes.data.stu_personal.student_id
  //           );
  //           const applicationFieldNameStudentRes = await axios.get(
  //             baseurl +
  //             "/getapplicationfieldnamestudent/" +
  //             appId +
  //             "/" +
  //             applicantAgentDetailsRes.data.stu_personal.student_id
  //           );
  //           if (
  //             appAllCommentsRes.data &&
  //             appAllCommentsRes.status === 200 &&
  //             appAllCommentsRes.data.length
  //           ) {
  //             let groups = {};
  //             for (var i = 0; i < appAllCommentsRes.data.length; i++) {
  //               const groupName = moment(
  //                 appAllCommentsRes.data[i].created_date
  //               ).format("yyyy, MMM DD");
  //               if (!groups[groupName]) {
  //                 groups[groupName] = [];
  //               }
  //               groups[groupName].push(appAllCommentsRes.data[i]);
  //             }
  //             let myArray = [];
  //             for (var groupName in groups) {
  //               myArray.push({
  //                 group: groupName,
  //                 groupdata: groups[groupName],
  //               });
  //             }
  //             appAllCommentsResData = {
  //               getallmessagecommant: myArray,
  //             };
  //           }
  //           if (
  //             applicationFieldNameStudentRes.data &&
  //             applicationFieldNameStudentRes.status === 200 &&
  //             applicationFieldNameStudentRes.data.length
  //           ) {
  //             applicationFieldNameStudentRes.data.map((element) => {
  //               switch (element.fieldname) {
  //                 case "first_name":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     first_namefieldname: element.fieldname,
  //                     first_namefieldnamecount: element.totalcount,
  //                   };
  //                 case "addr_email":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     addr_emailfieldname: element.fieldname,
  //                     addr_emailfieldnamecount: element.totalcount,
  //                   };
  //                 case "dateof_birth":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     dateof_birthfieldname: element.fieldname,
  //                     dateof_birthfieldnamecount: element.totalcount,
  //                   };
  //                 case "phone":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     phonefieldname: element.fieldname,
  //                     phonefieldnamecount: element.totalcount,
  //                   };
  //                 case "first_language":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     first_languagefieldname: element.fieldname,
  //                     first_languagefieldnamecount: element.totalcount,
  //                   };
  //                 case "gender":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     genderfieldname: element.fieldname,
  //                     genderfieldnamecount: element.totalcount,
  //                   };
  //                 case "marital_status":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     marital_statusfieldname: element.fieldname,
  //                     marital_statusfieldnamecount: element.totalcount,
  //                   };
  //                 case "passport_no":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     passport_nofieldname: element.fieldname,
  //                     passport_nofieldnamecount: element.totalcount,
  //                   };
  //                 case "edu_country":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     edu_countryfieldname: element.fieldname,
  //                     edu_countryfieldnamecount: element.totalcount,
  //                   };
  //                 case "edu_edulevel":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     edu_edulevelfieldname: element.fieldname,
  //                     edu_edulevelfieldnamecount: element.totalcount,
  //                   };
  //                 case "edulevel":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     edulevelfieldname: element.fieldname,
  //                     edulevelfieldnamecount: element.totalcount,
  //                   };
  //                 case "institute":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     institutefieldname: element.fieldname,
  //                     institutefieldnamecount: element.totalcount,
  //                   };
  //                 case "country":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     countryfieldname: element.fieldname,
  //                     countryfieldnamecount: element.totalcount,
  //                   };
  //                 case "award":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     awardfieldname: element.fieldname,
  //                     awardfieldnamecount: element.totalcount,
  //                   };
  //                 case "awardon":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     awardonfieldname: element.fieldname,
  //                     awardonfieldnamecount: element.totalcount,
  //                   };
  //                 case "email":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     emailfieldname: element.fieldname,
  //                     emailfieldnamecount: element.totalcount,
  //                   };
  //                 case "city":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     cityfieldname: element.fieldname,
  //                     cityfieldnamecount: element.totalcount,
  //                   };
  //                 case "exam_type":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     exam_typefieldname: element.fieldname,
  //                     exam_typefieldnamecount: element.totalcount,
  //                   };
  //                 case "exam_date":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     exam_datefieldname: element.fieldname,
  //                     exam_datefieldnamecount: element.totalcount,
  //                   };
  //                 case "reading_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     reading_scorefieldname: element.fieldname,
  //                     reading_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "listening_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     listening_scorefieldname: element.fieldname,
  //                     listening_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "writing_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     writing_scorefieldname: element.fieldname,
  //                     writing_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "speaking_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     speaking_scorefieldname: element.fieldname,
  //                     speaking_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_exam_date":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_exam_datefieldname: element.fieldname,
  //                     gre_exam_datefieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_verbal_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_verbal_scorefieldname: element.fieldname,
  //                     gre_verbal_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_verbal_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_verbal_rankfieldname: element.fieldname,
  //                     gre_verbal_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_quanitative_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_quanitative_scorefieldname: element.fieldname,
  //                     gre_quanitative_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_quanitative_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_quanitative_rankfieldname: element.fieldname,
  //                     gre_quanitative_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_writing_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_writing_scorefieldname: element.fieldname,
  //                     gre_writing_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gre_writing_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gre_writing_rankfieldname: element.fieldname,
  //                     gre_writing_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_exam_date":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_exam_datefieldname: element.fieldname,
  //                     gmat_exam_datefieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_verbal_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_verbal_scorefieldname: element.fieldname,
  //                     gmat_verbal_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_verbal_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_verbal_rankfieldname: element.fieldname,
  //                     gmat_verbal_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_quanitative_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_quanitative_scorefieldname: element.fieldname,
  //                     gmat_quanitative_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_quanitative_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_quanitative_rankfieldname: element.fieldname,
  //                     gmat_quanitative_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_writing_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_writing_scorefieldname: element.fieldname,
  //                     gmat_writing_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_writing_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_writing_rankfieldname: element.fieldname,
  //                     gmat_writing_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_total_score":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_total_scorefieldname: element.fieldname,
  //                     gmat_total_scorefieldnamecount: element.totalcount,
  //                   };
  //                 case "gmat_total_rank":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     gmat_total_rankfieldname: element.fieldname,
  //                     gmat_total_rankfieldnamecount: element.totalcount,
  //                   };
  //                 case "stu_doc":
  //                   applicationFieldNameStudentResData = {
  //                     ...applicationFieldNameStudentResData,
  //                     stu_docfieldname: element.fieldname,
  //                     stu_docfieldnamecount: element.totalcount,
  //                   };
  //                 default:
  //                   return null;
  //               }
  //             });
  //           }
  //         }
  //       }
  //       this.setState({
  //         ...applicantAgentDetailsResData,
  //         ...messageApplicantResData,
  //         // ...appAllCommentsResData,
  //         ...applicationFieldNameStudentResData,
  //       }, () => this.props.hideLoader());
  //     })
  //     .catch((error) => {
  //       this.props.hideLoader();
  //       console.log('errror', error);
  //       toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
  //     });
  // };

  getAllMessage = () => {
    axios
      .get(
        baseurl +
        "/get_application_comments/" +
        this.props.applicationId
      )
      .then((response) => {
        if (response.data) {
          let groups = {};
          for (var i = 0; i < response.data.length; i++) {
            const groupName = moment(
              response.data[i].created_at
            ).format("yyyy, MMM DD");
            if (!groups[groupName]) {
              groups[groupName] = [];
            }
            groups[groupName].push(response.data[i]);
          }
          let myArray = [];
          for (var groupName in groups) {
            myArray.push({
              group: groupName,
              groupdata: groups[groupName],
            });
          }
          this.setState({
            getallmessagecommant: myArray,
            comments: response.data
          })
        } else {
          this.props.hideLoader()
          toast.success(MESSAGES.PLEASE_TRY_AGAIN);
        }
      });

  }

  getGeneraldata = () => {
    this.props.showLoader();
    const countryReq = axios.get(baseurl + "/get_country");
    const levelDetailsReq = axios.get(baseurl + "/get_leveldetails");
    const generalInfoAppReq = axios.get(
      baseurl + "/get_generalinfo_app/" + localStorage.getItem("studentid")
    );

    const uniQuestTextReq = axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/general/1');
    const uniQuestSignleReq = axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/general/2');
    const uniQuestMultiReq = axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/general/3');

    Promise.all([countryReq, levelDetailsReq, generalInfoAppReq, uniQuestTextReq, uniQuestSignleReq, uniQuestMultiReq])
      .then(async ([countryRes, levelDetailsRes, generalInfoAppRes, uniQuestTextRes, uniQuestSignleRes, uniQuestMultiRes]) => {
        const levelDetailsResData = levelDetailsRes.data
          ? {
            level: levelDetailsRes.data.level,
            grade: levelDetailsRes.data.grade,
            uniQuestTextArr: uniQuestTextRes.data.data,
            uniQuestSignleArr: uniQuestSignleRes.data.data,
            uniQuestMultiArr: uniQuestMultiRes.data.data
          }
          : {};
        let generalInfoAppResData = {};
        if (generalInfoAppRes.data.data && generalInfoAppRes.data.data.length > 0) {
          if (generalInfoAppRes.data.data[0].addr_country) {
            var stateRes = await axios.get(
              baseurl + "/get_state/" + generalInfoAppRes.data.data[0].addr_country
            );
          }
          if (generalInfoAppRes.data.data[0].state) {
            var cityRes = await axios.get(
              baseurl + "/get_city/" + generalInfoAppRes.data.data[0].state
            );
          }
          if (generalInfoAppRes.data.data[0].nationality) {
            var nationalRes = await axios.get(
              baseurl + "/get_country/" + generalInfoAppRes.data.data[0].nationality
            );
          }
          if (generalInfoAppRes.data.data[0].study_destination) {
            var destination = await axios.get(
              baseurl + "/get_country/" + generalInfoAppRes.data.data[0].study_destination
            );
          }
          var stateName = stateRes.data.filter((item) => {
            return (
              item.id == generalInfoAppRes.data.data[0].state
            );
          });
          generalInfoAppResData = {
            cities: cityRes.data ? cityRes.data : this.state.cities,
            states: stateRes.data ? stateRes.data : this.state.states,
            title: generalInfoAppRes.data.data[0].title,
            fname: generalInfoAppRes.data.data[0].first_name,
            mname: generalInfoAppRes.data.data[0].middlename,
            lname: generalInfoAppRes.data.data[0].last_name,
            // dateofbirth: sdob,
            dateofbirth: generalInfoAppRes.data.data[0].dateof_birth,
            flang: generalInfoAppRes.data.data[0].first_language,
            citiship: nationalRes && nationalRes.data ? nationalRes.data : this.state.citiship,
            passno: generalInfoAppRes.data.data[0].passport_no,
            sgender: generalInfoAppRes.data.data[0].gender,
            smarital: generalInfoAppRes.data.data[0].marital_status,

            saddr: generalInfoAppRes.data.data[0].address,
            saddr2: generalInfoAppRes.data.data[0].saddr2,
            scity: generalInfoAppRes.data.data[0].city,
            scountry: nationalRes && nationalRes.data ? nationalRes.data : this.state.scountry,
            sstate: stateName ? stateName[0].name : this.state.sstate,
            szip: generalInfoAppRes.data.data[0].zipcode,
            semail: generalInfoAppRes.data.data[0].addr_email,
            sphcode: generalInfoAppRes.data.data[0].ph_code,
            sphno: generalInfoAppRes.data.data[0].phone,
            smbcode: generalInfoAppRes.data.data[0].cell_code,
            smbno: generalInfoAppRes.data.data[0].cellphone,
            next_kin_name: generalInfoAppRes.data.data[0].next_kin_name,
            next_kin_contact: generalInfoAppRes.data.data[0].next_kin_contact,
            study_course: generalInfoAppRes.data.data[0].study_course,
            study_destination: destination && destination.data ? destination.data : this.state.study_destination,
            study_plan: generalInfoAppRes.data.data[0].study_plan,
            study_level_id: generalInfoAppRes.data.data[0].study_level_id,
          };
        }
        //alert(JSON.stringify(levelDetailsRes.data.level));
        this.setState({
          country: countryRes.data,
          levelArr: levelDetailsRes.data.level,
          ...levelDetailsResData,
          ...generalInfoAppResData,
        }, () => this.props.hideLoader());
      })
      .catch((error) => {
        console.log('error: ', error);
        this.props.hideLoader()
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        this.props.hideLoader();
      });
  };

  commandModel = () => {
    this.setState({ commandmodel: !this.state.commandmodel });
  };

  viewCommandModel = () => {
    this.setState({
      viewcommandmodel: !this.state.viewcommandmodel,
      tickshow: false,
      edid: 0,
      edittext: false,
      commantmsg: "",
    });
  };

  chatIconAction = () => {
    this.setState({ chaticonaction: !this.state.chaticonaction });
  };

  commandFieldModel = (data, tabName) => {
    this.setState({ commantfieldname: "", tabDetail: "" });
    if (data) {
      this.setState({
        commantfieldname: data,
        tabDetail: tabName
      });
      this.commandModel();
    }
  };

  updateDoneStatus = (datas) => {
    console.log('log datas', datas);
    if (datas) {
      this.props.showLoader()
      axios
        .get(
          baseurl +
          "/updatedonestatus/" +
          this.props.applicationId +
          "/" +
          localStorage.getItem("agentid") +
          "/" +
          datas +
          "/Student"
        )
        .then((response) => {
          this.props.hideLoader()
          if (response.data && response.data.status_code == 200) {
            // this.componentDidMount();
            toast.success(MESSAGES.FEEDBACK_SENT_SUCCESSFUL);

          } else {
            this.props.hideLoader()
            toast.success(MESSAGES.PLEASE_TRY_AGAIN);
          }
        });
    }
  };

  deleteCommantStatus = (dataid) => {
    if (dataid) {
      this.props.showLoader()
      axios
        // .get(baseurl + "/deletecomments/" + dataid + "/" + 0)
        .get(baseurl + "/remove_application_comment/" + dataid + "/" + localStorage.getItem("studentid"))
        .then((response) => {
          this.props.hideLoader();
          this.viewCommandModel();
          // this.componentDidMount();
          toast.success(MESSAGES.COMMENT_DELETED_SUCCESSFULLY);
        });
    }
  };

  filterCommants = (e) => {
    this.setState({ filterallcommants: e });
    // this.props.showLoader();
    if (e !== "all") {
      // const dateTo = moment(new Date()).format("yyyy, MMM DD");
      // const dateFrom = moment().subtract(e, "d").format("yyyy, MMM DD");

      // let filteredComments = this.state.comments.filter(item => {
      //   return (
      //     moment(item.created_date).format("yyyy, MMM DD") >= dateFrom &&
      //     moment(item.created_date).format("yyyy, MMM DD") <= dateTo
      //   );
      // })

      let filteredComments = []

      if (e == "new") {
        filteredComments = this.state.comments.sort((first, second) => {
          return new Date(second.updated_at) - new Date(first.updated_at)
        })
      } else {
        filteredComments = this.state.comments.sort((first, second) => {
          return new Date(first.updated_at) - new Date(second.updated_at)
        })
      }

      this.setState({
        filteredComments
      })
    } else {
      this.setState({
        filteredComments: []
      })
    }
  };

  sentMessage = (data) => {
    this.state.formData.append("application_id", this.props.applicationId);
    this.state.formData.append("type", "Student");
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("field_id", this.state.commantfieldname);
    this.state.formData.append("comment", this.state.commantmsg);
    this.state.formData.append("is_parent", 1);
    this.state.formData.append("tab", this.state.tabDetail);
    this.props.showLoader();
    submitForm(
      "multipart/form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        this.commandModel();
      },
      // "applicationcomment"
      "save_application_comment"
    )
  };

  onTextEdit = (data) => (e) => {
    this.state.formData.append("id", data);
    this.state.formData.append("application_id", localStorage.getItem("app_id"));
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("comment", this.state.commantmsg);
    this.props.showLoader();
    submitForm(
      "multipart/form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        this.viewCommandModel();
      },
      // "editapplicationcomment"
      "edit_application_comment"
    );
  };

  rsentMessage = (data) => {
    this.state.formData.append(
      "application_id",
      this.props.applicationId
    );
    // this.state.formData.append("application_id", localStorage.getItem("app_id"));
    this.state.formData.append("type", "Student");
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("to_id", localStorage.getItem("agentid"));
    this.state.formData.append("field_id", this.state.commantfieldname);
    this.state.formData.append("comment", this.state.commantmsg);
    this.state.formData.append("is_parent", 1);
    this.state.formData.append("tab", this.state.tabDetail);
    this.props.showLoader();
    submitForm(
      "multipart/form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        if (msg.status_code == 200) {
          toast.success(msg.message);
          this.viewCommandModel();
          // this.componentDidMount();
        } else {
          toast.success(msg.message);
        }
      },
      // "applicationcomment"
      "save_application_comment"
    )
  };

  getSentMessageById = (data) => {
    this.setState({ commantfieldname: "" });
    this.props.showLoader();
    if (data) {
      this.setState({ commantfieldname: data });
      this.viewCommandModel();
      axios
        // .get(
        //   baseurl +
        //   "/getapplicationcommentstudent/" +
        //   localStorage.getItem("app_id") +
        //   "/" +
        //   localStorage.getItem("studentid") +
        //   "/" +
        //   data
        // )
        .get(
          baseurl +
          "/get_applicationfield_comments/" +
          localStorage.getItem("app_id") +
          "/" +
          data
        )
        .then((response) => {
          if (response.data) {
            this.setState({
              getmessagebyid: response.data,
              commantfieldname: response.data[0].field_id,
              tabDetail: response.data[0].tab,
            }, () => this.props.hideLoader());
          } else this.props.hideLoader();
        }).catch((error) => {
          this.props.hideLoader();
          console.log('error: ', error);
          toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        });
    }
  };

  chatCommant = (data) => {
    if (data) {
      this.props.showLoader();
      this.setState({ commantfieldname: data.field_id });
      this.viewCommandModel();
      axios
        // .get(
        //   baseurl +
        //   "/getapplicationcommentstudent/" +
        //   localStorage.getItem("app_id") +
        //   "/" +
        //   localStorage.getItem("studentid") +
        //   "/" +
        //   data.field_id
        // )
        .get(
          baseurl +
          "/get_applicationfield_comments/" +
          localStorage.getItem("app_id") +
          "/" +
          data.field_id
        )
        .then((response) => {
          if (response.data) {
            this.setState({
              getmessagebyid: response.data,
            }, () => this.props.hideLoader());
          } else this.props.hideLoader();
        }).catch((error) => {
          console.log('error: ', error);
          toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
          this.props.hideLoader();

        });
      axios
        .get(
          baseurl +
          "/mark_read_comment/" +
          data.id +
          "/" +
          localStorage.getItem('studentid')
        )
        .then((response) => {
          if (response) {
            this.props.countfunc();
          } else this.props.hideLoader();
        }).catch((error) => {
          console.log('error: ', error);
          toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
          this.props.hideLoader();

        });
    }
  };

  showTick = (data) => (e) => {
    this.setState({
      tickshow: true,
      edid: data.id,
      edittext: true,
      commantmsg: data.comment,
    });
  };

  renderFeedBackModal = () => {
    return (
      <Modal
        isOpen={this.state.feedbackmodel}
        toggle={this.toggleFeedback}
        className={"modal-xl viewpop " + this.props.className}
      >
        <ModalHeader toggle={this.toggleFeedback}></ModalHeader>
        <ModalBody className="p-0">
          <div>
            <div className="modal_header  w-100 ">
              <div className="col-md-9 col-12 uni-add-box mx-auto py-4">
                <div className="float-right d-flex">
                  {/* <img
                    src={downloadicon}
                    alt=""
                    className="view-applcn-icon min-icon pop-headicon"
                    width="20"
                    onClick={this.printDocument}
                  /> */}

                  {/* <img
                    src={chaticon1}
                    alt=""
                    className="view-applcn-icon min-chat-icon pop-headicon"
                    onClick={this.chatIconAction}
                    width="20"
                  /> */}
                  {/* {localStorage.getItem("fromApplicationType") === "Draft" ? (
                    <button
                      className="add-staff-bn btn btn-primary"
                      onClick={() => {
                        this.toggleFeedback()
                      }}
                    >
                      Done
                    </button>
                  ) : null} */}
                </div>
              </div>
              <div className="col-md-3 col-12 uni-add-box mx-auto view-modal-border-left view-modal-border-down view-app-detail row px-0 py-4">
                <div className=" w-100 cmnt-head col-12 row">
                  <div className="col-5 pr-0">Comments</div>
                  <div className="col-5 pr-0">
                    <div className="select-wrapper-1">
                      <select
                        className="w-100 cmt-drop"
                        value={this.state.filterallcommants}
                        onChange={(e) => this.filterCommants(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="new">Newest</option>
                        <option value="old">Oldest</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-2 px-0">
                    <img src={chaticon1} alt="" className="" width="20" />
                    {this.props.count}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto w-100" id="pdfdiv">
              <div className="col-12">
                {
                  console.log('log filterallcommants', this.state.filteredComments, this.state.comments, this.state.filterallcommants)
                }
                <ApplicationOverview
                  {...this.props}
                  comments={this.state.filterallcommants != "all" ? this.state.filteredComments : this.state.comments}
                  getAllMessage={this.getAllMessage}
                  student_id={this.state.student_id}
                  key="from_comments"
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    return (
      <>
        {/* {this.state.feedbackmodel ? this.renderFeedBackModal() : null} */}
        <div 
          className="hand-cursor commentsIcon" 
          onClick={() => {
            this.props.history.push(`/application-comments/${this.props.applicationId}`)
          }}
        >
          <img src={comments} />
          <span className="unread-count">{this.props.count}</span>
        </div>
      </>
    );
  }
}

export default ViewFeedBack;
