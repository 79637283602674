import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  ListGroup,
  ListGroupItem,
  UncontrolledCollapse,
} from "reactstrap";
import $ from "jquery";

import Scrollbars from "react-custom-scrollbars";

import "../../../common/sidebarFixed.scss";

import { ReactComponent as DraftIcon } from "../../../assets/img/icons/inbox/draft_gray_icon.svg";
import { ReactComponent as ChatIcon } from "../../../assets/img/icons/inbox/chat_gray_icon.svg";
import { ReactComponent as PendingIcon } from "../../../assets/img/icons/inbox/pending_meetings_gray_icon.svg";
import { ReactComponent as UpcomingIcon } from "../../../assets/img/icons/inbox/upcoming_meetings_gray_icon.svg";

import { ReactComponent as SideMenuOpenIcon } from "../../../assets/img/icons/default-user_grey.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/icons/modal_close_icon.svg";

import { ReactComponent as ComposeIcon } from "../../../assets/img/icons/inbox/compose_white_icon.svg";
import { ReactComponent as InboxIcon } from "../../../assets/img/icons/inbox/inbox_gray_icon.svg";
import { ReactComponent as SentIcon } from "../../../assets/img/icons/inbox/sent_gray_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/inbox/delete_gray_icon.svg";
import { ReactComponent as StarredIcon } from "../../../assets/img/icons/inbox/starred_gray_icon.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/img/icons/chevron-right.svg";
import { ReactComponent as MeetingIcon } from "../../../assets/img/icons/inbox/meetings_gray_icon.svg";
class LeftInboxMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingsOpen: false,
      hiringRequestOpen: false,
      chatsOpen: false,
      activeSidebar: false,
      sidebarFixedOpen: false,
      submenuOpen: "",
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    console.log("props.location", pathname);
    let meetingsOpen = false;
    let hiringRequestOpen = false;
    let chatsOpen = false;

    if (
      pathname == "/meetings-pending-invites" ||
      pathname == "/upcoming-meetings" ||
      pathname == "/meetings-drafts"
    ) {
      meetingsOpen = true;
    }
    if (
      pathname == "/hiring-request-me" ||
      pathname == "/hiring-request-client" ||
      pathname == "/hiring-request-declined"
    ) {
      hiringRequestOpen = true;
    }

    if (
      pathname == "/chats" ||
      pathname == "/starred_chats" ||
      pathname == "/delete_chats"
    ) {
      chatsOpen = true;
    }

    this.setState({
      meetingsOpen,
      hiringRequestOpen,
      chatsOpen,
    });
  }

  sidebarOpen = () => {
    this.setState({
      activeSidebar: true,
    });
  };

  sidebarClose = () => {
    this.setState({
      activeSidebar: false,
    });
  };

  sidebarMenuOpenClose = () => {
    this.setState({ sidebarFixedOpen: !this.state.sidebarFixedOpen });
  };

  handleCollapseMenu = (val) => {
    this.state.submenuOpen === val
      ? this.setState({ submenuOpen: "" })
      : this.setState({ submenuOpen: val });
  };

  render() {
    let getUrl = window.location.href.split("/");
    let urlLength = window.location.href.split("/").length;
    let currentUrl = getUrl[urlLength - 1];
    console.log("this.props.location.pathname", this.props.location.pathname);
    return (
      <>
        <span className="sidebarMenuBtn" onClick={this.sidebarMenuOpenClose}>
          <SideMenuOpenIcon />
        </span>
        <div
          className={
            this.state.sidebarFixedOpen === true
              ? "open sidebarFixed inbox"
              : "sidebarFixed inbox"
          }
        >
          
          <Scrollbars style={{ height: "100%" }}>
            <div className="innerSidebarWrap">
              <div className="closeMenu" onClick={this.sidebarMenuOpenClose}>
                <CloseIcon />
              </div>
              <h3 className='title'>{this.props.title}</h3>
              <Card className="uni-left-card">
                <CardBody>
                  <ListGroup className="left-list">
                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/inbox-compose"
                          ? "compose active"
                          : "compose"
                      }
                    >
                      <Link to="/inbox-compose">
                        <span className="iconBox">
                          <ComposeIcon className="icon" />
                        </span>
                        <span>Compose</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/inbox" ||
                        this.props.location.pathname === "/Inbox"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/inbox">
                        <span className="iconBox">
                          <InboxIcon className="icon stroke" />
                        </span>
                        <span>Inbox</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/sentInbox" ||
                        this.props.location.pathname === "/Sent"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/sentInbox">
                        <span className="iconBox">
                          <SentIcon className="icon stroke" />
                        </span>
                        <span>Sent Mail</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/draftsInbox" ||
                        this.props.location.pathname === "/Draft"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/draftsInbox">
                        <span className="iconBox">
                          <DraftIcon className="icon stroke" />
                        </span>
                        <span>Drafts</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/deleteInbox" ||
                        this.props.location.pathname === "/Deleted"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/deleteInbox">
                        <span className="iconBox">
                          <DeleteIcon className="icon stroke" />
                        </span>
                        <span>Deleted</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/starredInbox" ||
                        this.props.location.pathname === "/Starred"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/starredInbox">
                        <span className="iconBox">
                          <StarredIcon className="icon stroke" />
                        </span>
                        <span>Starred</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        currentUrl.includes("hiring-request-me") ||
                        currentUrl.includes("hiring-request-client") ||
                        currentUrl.includes("hiring-request-declined") ||
                        this.state.submenuOpen === "hiringRequest"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.handleCollapseMenu("hiringRequest");
                      }}
                    >
                      <div>Hiring Request</div>
                      <DropDownIcon className="optionIcon" />

                      {(currentUrl.includes("hiring-request-me") ||
                        currentUrl.includes("hiring-request-client") ||
                        currentUrl.includes("hiring-request-declined") ||
                        this.state.submenuOpen === "hiringRequest") && (
                        <ListGroup className="left-list submenu">
                          <ListGroupItem
                            className={
                              currentUrl.includes("hiring-request-me")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/hiring-request-me">Pending from me</Link>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              currentUrl.includes("hiring-request-client")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/hiring-request-client">
                              Pending from  <br /> Preferred Partners
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              currentUrl.includes("hiring-request-declined")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/hiring-request-declined">Declined</Link>
                          </ListGroupItem>
                        </ListGroup>
                      )}
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/chats" ||
                        this.props.location.pathname === "/Chats"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/chats">
                        <span className="iconBox">
                          <ChatIcon className="icon" />
                        </span>
                        <span>Chats</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        currentUrl.includes("meetings-pending-invites") ||
                        currentUrl.includes("upcoming-meetings") ||
                        currentUrl.includes("meetings-drafts") ||
                        this.state.submenuOpen === "meetings"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.handleCollapseMenu("meetings");
                      }}
                    >
                      <div>
                        <span className="iconBox">
                          <MeetingIcon className="icon stroke" />
                        </span>{" "}
                        <span>Meetings</span>
                      </div>
                      <DropDownIcon className="optionIcon" />

                      {(currentUrl.includes("meetings-pending-invites") ||
                        currentUrl.includes("upcoming-meetings") ||
                        currentUrl.includes("meetings-drafts") ||
                        this.state.submenuOpen === "meetings") && (
                        <ListGroup className="left-list submenu">
                          <ListGroupItem
                            className={
                              currentUrl.includes("meetings-pending-invites")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/meetings-pending-invites">
                              <span className="iconBox">
                                <PendingIcon className="icon" />
                              </span>
                              <span>Pending Invites</span>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              currentUrl.includes("upcoming-meetings")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/upcoming-meetings">
                              <span className="iconBox">
                                <UpcomingIcon className="icon" />
                              </span>
                              <span>Upcoming</span>
                            </Link>
                          </ListGroupItem>
                          <ListGroupItem
                            className={
                              currentUrl.includes("meetings-drafts")
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="/meetings-drafts">
                              <span className="iconBox">
                                <DraftIcon className="icon stroke" />
                              </span>
                              <span>Drafts</span>
                            </Link>
                          </ListGroupItem>
                        </ListGroup>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </div>
          </Scrollbars>
        </div>
      </>
    );
  }
}
export default withRouter(LeftInboxMenu);
