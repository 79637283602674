import React, { Component, Suspense } from "react";
import { Container, Row, Col, Badge, Button, UncontrolledPopover, PopoverBody, Modal, ModalBody, Input, ModalFooter } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import PageLoading from "../../../common/PageLoading";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../LeftInboxMenu";
import moment from "moment";
// import $ from "jquery";
import EmailContent from "./EmailContent.json";
import { HashLink as Link } from "react-router-hash-link";


import "./viewMessage.scss";
import ReplyMessage from "../ReplyMessage"
import BackArrowIcon from "../../../assets/img/icons/inbox/back_arrow_icon.svg";
import PrintIcon from "../../../assets/img/icons/inbox/print_icon.svg";
import UserPic from "../../../assets/img/icons/pref-img-2.svg";
import AvatarPic from "../../../assets/img/icons/inbox/avatar_icon.svg";
import ThreeDotIcon from "../../../assets/img/icons/inbox/three_ver_dots.svg";
import StarredIcon from "../../../assets/img/icons/inbox/starred_gray_icon.svg";
import StarredActiveIcon from "../../../assets/img/icons/inbox/star-filled.svg";
import AttachmentIcon from "../../../assets/img/icons/inbox/attachemnt_icon.svg";
import ReplyIcon from "../../../assets/img/icons/inbox/reply_icon.svg";
import DownloadAttachemnet from "../../../assets/img/icons/download_attachemnet.svg";
import { ReactComponent as HasAttachmentIcon } from "../../../assets/img/icons/has_attachment_icon.svg";
import { ReactComponent as ReplySVGIcon } from "../../../assets/img/icons/inbox/reply_icon.svg";
import { ReactComponent as ReplyAllIcon } from "../../../assets/img/icons/inbox/reply_all_icon.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/img/icons/inbox/forward_icon.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getInboxMailDetailsAction,
  updateInboxReadStatusAction,
  deleteEmailAction,
  composeMailAction,
  updateFavouriteAction,
  getProfileAction,
  getAgentCheckHireAction,
  getHireAgentAction,
  getShareProfileAction,
  updateAgentHireRequestAction
} from "../../../store/actions";
import { baseUrl, DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS } from "../../../Api";
import { confirmAlert } from "react-confirm-alert";
import { MESSAGES } from "../../../constant/messages";
import { checkValidFileSize } from "../../../common/commonHelper";
import config from "../../../config.json";
import Closeicon from "../../../assets/img/close-btn.svg";
import Confirm from "../../../assets/img/confirm.svg";
import Warning from "../../../assets/img/warning.svg";
import UserX from "../../../assets/img/user_x.svg";
import { ReactComponent as ConfirmIcon } from "../../../assets/img/confirm.svg";
import axios from "axios";
import EmailTemplate from "./elements/EmailTemplate";

const baseurl = `${config.baseurl}/`;

class ViewMessage extends Component {
  constructor(props) {
    super(props);

    this.replyEditorRef = React.createRef();

    this.state = {
      editorToolbar: false,
      todayDate: moment().format("DD MMM YY"),
      //dropdownOpen: '',
      files: [],
      thumbs: [],
      replyType: "Reply",
      from_type: localStorage.getItem("from_type"),
      mail_type_id: localStorage.getItem("mail_type_id"),
      mail_type: 'Inbox',
      mail_id: localStorage.getItem("mail_id"),
      mail_from: localStorage.getItem("mail_from"),
      mail_from_email: localStorage.getItem("mail_from_email"),
      mail_subject: '',
      mailToTypeBcc: false,
      mailToTypeCc: false,

      showDetail: EmailContent.treads.length - 1,
      showReply: false,

      type: "University",
      type_id: localStorage.getItem("studentid"),
      inboxMsg: [],
      mailCount:
        localStorage.getItem("mailCount") !== null
          ? localStorage.getItem("mailCount")
          : 0,
      editorVal: 0,
      //editorState: EditorState.createEmpty(),
      editable: true,
      loading: false,
      emails: [],
      mailId: 0,
      universityId: 0,
      parentId: 0,
      ccEmails: [],
      toEmails: [],
      bccEmails: [],
      parentFlag: 0,
      existingFiles: [],
      validHire: false,
      hireAgain: false,
      shareProfile: false,
      shareProfileType: [],
      hireAgentId: 0,
      shareAgentId: 0,
      hireMailId: 0,
      disputeReasonTitle: [],
      terminateReasonTitle: [],
      issueType: '',
      terminateType: '',
      raiseDescription: '',
      terminateDescription: '',
      disputeAgentId: 0,
      dispute: false,
      successAdmin: false,
      terminate: false,
      rating: 0,
      agent: null
    };
  }

  onDrop = (filesDropped) => {
    // this.setState({ files });
    let filesList = filesDropped;
    let noOfFiles = filesDropped.length;
    let {
      files
    } = this.state;

    for (let file = 0; file < noOfFiles; file++) {
      console.log('files[file]', filesList[file])
      let size = checkValidFileSize(filesList[file]);
      if (!size) {

        files.push(filesList[file]);
      } else {
        toast.error(MESSAGES.VALID_IMAGE_SIZE);
        return;
      }
    }

    this.setState({
      files
    })
  };

  componentDidMount() {
    let universityId = localStorage.getItem("studentid");
    let mailId = this.props.match
      ? this.props.match.params
        ? this.props.match.params.id
          ? this.props.match.params.id
          : ""
        : ""
      : "";

    let mail_type = this.props.match
      ? this.props.match.params
        ? this.props.match.params.type
          ? this.props.match.params.type
          : ""
        : ""
      : "";
    this.setState({
      universityId,
      mailId,
      mail_type
    }, () => {
      this.getData();
      // this.updateReadStatus(mailId, 0);
      this.props.getProfileAction(
        {
          univ_id: this.state.universityId
        },
        () => { },
        () => { }
      );
    })
  }

  getData = () => {
    let showThread = false
    let {
      mail_type
    } = this.state
    mail_type = mail_type.toLowerCase()
    if (
      mail_type == "inbox"
      || mail_type == "sent"
    ) {
      showThread = true
    }
    let mark_read = 1
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getInboxMailDetailsAction(
        {
          student_id: this.state.universityId,
          mail_id: this.state.mailId,
          handle: mail_type,
          show_thread: showThread,
          mark_read
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            let emails = [];
            let agent = null;

            if (response.data && response.data.mail) {
              response.data.mail.map((val, index) => {
                let currentEmail = val;
                let parentId = 0;
                if (index == response.data.mail.length - 1) {
                  currentEmail.content = false;
                } else {
                  currentEmail.content = true;
                }
                emails.push(currentEmail);

                if (emails.length) {
                  parentId = emails[0].id;
                  this.setState({
                    parentId
                  })
                }
              })
            }
            if (response.data && response.data.agent) {
              agent = response.data.agent
            }
            this.setState({
              emails,
              agent
            }, () => {
              if (this.state.emails.length) {
                this.setState({
                  mail_subject: this.state.emails[this.state.emails.length - 1].subject
                })
              }
            })
            this.checkHire(emails[0].sender_id);
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  };

  checkHire = (agentId) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getAgentCheckHireAction(
        {
          agent_id: 17,
          student_id: localStorage.getItem("studentid"),

        },
        (response) => {
          this.hideLoading();
          if (response) {
            this.setState({
              validHire: response
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  loading = () => <PageLoading loading={this.state.loading} />;

  print() {
    window.print();
  }

  showMailFullDetail(e) {
    let {
      emails
    } = this.state;

    emails[e].content = !emails[e].content;

    this.setState({
      emails
    });
  }

  handleInit = (evt, editor) => {
    this.setState({ length: editor.getContent({ format: "text" }).length });
  };

  attachemtList = [
    {
      id: 1,
      name: "Assignment_name.pdf",
      size: "45842",
    },
    {
      id: 2,
      name: "Assignment_name_2.pdf",
      size: "65481",
    },
    {
      id: 3,
      name: "Assignment_name_3.pdf",
      size: "54132",
    },
  ];

  updateReadStatus = (id, status) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateInboxReadStatusAction(
        {
          student_id: this.state.universityId,
          mail_id: id,
          status: status
        },
        (response) => {
          this.hideLoading();
          if (status) {
            toast.success(response.message)
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  deleteEmail = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.showLoading();
            new Promise((resolve, reject) => {
              this.props.deleteEmailAction(
                {
                  student_id: this.state.universityId,
                  mail_id: id
                },
                (response) => {
                  this.hideLoading();
                  toast.success(response.message)
                  resolve();
                },
                (err) => {
                  this.hideLoading();
                  reject();
                }
              )
            })
          },
        },
        {
          label: "No",
          onClick: () => {

          },
        },
      ],
    });
  }

  sendEmail = () => {
    let {
      toEmails,
      ccEmails,
      bccEmails,
      subject,
      files,
      existingFiles
    } = this.state;

    let content = this.replyEditorRef.current.getContent();

    if (!toEmails.length) {
      toast.error(MESSAGES.TO_EMAILS_REQUIRED);
      return;
    }
    if (!content) {
      toast.error(MESSAGES.EMAIL_CONTENT_REQUIRED);
      return;
    }

    this.showLoading();

    let formData = new FormData();
    formData.append('student_id', this.state.universityId);
    toEmails.map((val, index) => {
      formData.append('to[]', val);
    });
    bccEmails.map((val, index) => {
      formData.append('bcc[]', val);
    });
    ccEmails.map((val, index) => {
      formData.append('cc[]', val);
    });
    formData.append('body', content);
    formData.append('subject', subject);

    if (files.length) {
      files.map((val, index) => {
        formData.append('attachment[]', val);
      })
    }
    if (this.state.parentId) {
      formData.append('parent_id', this.state.parentId);
    }
    formData.append('parent_flag', this.state.parentFlag);
    existingFiles.map((val, index) => {
      formData.append('exists_attachments[]', val.id);
    })

    new Promise((resolve, reject) => {
      this.props.composeMailAction(
        formData,
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            if (response.data.mail_id) {
              this.setState({
                mailId: response.data.mail_id
              })
            }
            // if (!draft) {
            // this.props.history.push("/inbox");
            // }
            this.getData();
            this.setState({ showReply: false });
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  setReplyState = (all) => {
    if (this.state.emails.length) {
      let item = this.state.emails[this.state.emails.length - 1];
      let toEmails = [],
        ccEmails = [],
        bccEmails = [],
        replyType = "Reply",
        existingFiles = [],
        subject = item.subject.includes('Re') ? (
          item.subject
        ) : ("Re: " + item.subject);

      if (item.sender_id == this.state.universityId) {
        item.email_to.map((val, index) => {
          toEmails.push(val.receiver.email)
        });
      } else {
        toEmails.push(item.email_from_details.email)
      }
      console.log('toEmails', toEmails)
      if (all) {
        item.email_cc.map((val, index) => {
          ccEmails.push(val.receiver.email)
        });
        item.email_bcc.map((val, index) => {
          bccEmails.push(val.receiver.email)
        });
        replyType = "ReplyAll";
      }

      // if (item.attachments) {
      //   existingFiles = item.attachments.map(val => val);
      // }

      this.replyEditorRef.current.setContent("");

      this.setState({
        toEmails,
        ccEmails,
        bccEmails,
        subject,
        replyType,
        existingFiles
      });
    }
  }

  setForwardState = (item) => {
    if (this.state.emails.length) {
      let item = this.state.emails[this.state.emails.length - 1]

      let toEmails = [],
        ccEmails = [],
        bccEmails = [],
        replyType = "Forward",
        subject = "",
        existingFiles = [];

      if (item.subject.includes('Fwd')) {
        subject = item.subject;
      } else {
        subject = "Fwd: " + item.subject;
      }

      if (item.attachments) {
        existingFiles = item.attachments.map(val => val);
      }

      this.replyEditorRef.current.setContent(item.body);

      this.setState({
        toEmails,
        ccEmails,
        bccEmails,
        subject,
        replyType,
        existingFiles
      });
    }
  }

  updateFavourite = (id) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateFavouriteAction(
        {
          student_id: this.state.universityId,
          mail_id: id
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            this.getData()
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  closeHireAgain = () => {
    this.setState({
      hireAgain: false
    })
  }

  openHireAgain = (data) => {
    if (this.state.agent) {
      this.setState({
        hireAgentId: this.state.agent.agent_id,
        hireAgain: true,
        hireMailId: data.id
      })
    }
  }

  closeShareProfile = () => {
    this.setState({
      shareProfile: false
    })
  }

  openShareProfile = (data) => {
    this.setState({
      // shareAgentId: data.sender_id,
      shareProfile: true
    })
  }

  getApplicationSelected = (data) => {
    var selectedIds = this.state.shareProfileType;
    const index = selectedIds.indexOf(data);
    if (index > -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(data);
    }
    this.setState({
      shareProfileType: selectedIds
    })
  }

  hireAgentAgain = () => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getHireAgentAction(
        {
          student_id: localStorage.getItem("studentid"),
          agent_id: this.state.hireAgentId,
          id: this.state.hireMailId
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            this.setState({
              hireAgentId: 0,
              hireAgain: false,
              hireMailId: 0
            })
            toast.success(response.message);
            this.getData()
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  confirmShareProfile = (status = 1) => {
    if (this.state.shareProfileType.length > 0 || status == 2) {
      this.showLoading();
      new Promise((resolve, reject) => {
        this.props.getShareProfileAction(
          {
            student_id: this.state.universityId,
            agent_id: this.state.agent.agent_id,
            share_profile: status,
            share_section: this.state.shareProfileType
          },
          (response) => {
            this.hideLoading();
            if (response.success) {
              this.setState({
                shareAgentId: 0,
                shareProfile: false,
              })
              toast.success(response.message);
              this.getData()
            }
            resolve();
          },
          (err) => {
            this.hideLoading();
            reject();
          }
        )
      })
    } else {
      toast.error("Please select the information that you want to share!")
    }
  }

  closeDispute = () => {
    this.setState({
      dispute: false
    })
  }

  openRaiseDispute = (data) => {
    this.disputeList();
    this.setState({
      disputeAgentId: data.sender_id,
      dispute: true
    })
  }

  openTerminate = (data) => {
    this.terminateList();
    this.setState({
      terminateAgentId: data.request_reference_id,
      terminate: true
    })
  }

  closeTerminate = () => {
    this.setState({
      terminate: false
    })
  }

  closeSuccessAdmin = () => {
    this.setState({
      successAdmin: false
    })
  }

  handleIssue = (e) => {
    this.setState({
      issueType: e.target.value
    })
  }

  handleTerminate = (e) => {
    this.setState({
      terminateType: e.target.value
    })
  }


  disputeList = () => {
    // this.props.showLoader();
    axios
      .get(baseurl + "get_dispute_reason_list")
      .then((response) => {
        // this.props.hideLoader();
        this.setState({
          disputeReasonTitle: response.data.data
        })
      }).catch((error) => {
        console.log('error: ', error);
        // this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
      });
  }

  terminateList = () => {
    // this.props.showLoader();
    axios
      .get(baseurl + "agent/terminate-reason")
      .then((response) => {
        // this.props.hideLoader();
        this.setState({
          terminateReasonTitle: response.data.data
        })
      }).catch((error) => {
        console.log('error: ', error);
        // this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
      });
  }


  changeDescription = (e) => {
    this.setState({
      raiseDescription: e.target.value
    })
  }

  changeReason = (e) => {
    this.setState({
      terminateDescription: e.target.value
    })
  }


  raiseDispute = () => {
    // this.props.showLoader();
    var data = {
      agent_id: this.state.disputeAgentId,
      raise_dispute_title_id: this.state.issueType,
      raise_dispute_description: this.state.raiseDescription,
      student_id: localStorage.getItem("studentid"),
    }
    axios
      .post(baseurl + "raise_dispute", data)
      .then((response) => {
        // this.props.hideLoader();
        this.setState({
          dispute: false,
          successAdmin: true,
          raiseDescription: '',
          disputeAgentId: 0
        })
        toast.success(MESSAGES.UPDATE_SUCCESSFULL);
        this.getData();
      }).catch((error) => {
        console.log('error: ', error);
        // this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
      });
  }

  terminateRating = (data) => {
    this.setState({
      rating: data
    })
  }

  terminateContract = () => {
    // this.props.showLoader();
    var data = {
      id: this.state.terminateAgentId,
      terminate_reason_title_id: this.state.terminatetype,
      terminate_reason_description: this.state.terminateDescription,
      terminate_reason_rating: this.state.rating
    }
    axios
      .post(baseurl + "agent_terminate", data)
      .then((response) => {
        // this.props.hideLoader();
        this.setState({
          terminate: false,
          terminateDescription: '',
          terminateAgentId: 0,
          terminatetype: '',
          rating: 0
        })
        toast.success(MESSAGES.UPDATE_SUCCESSFULL);
        this.getData();
      }).catch((error) => {
        console.log('error: ', error);
        // this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
      });
  }

  updateHireRequest = (data) => {
    this.showLoading()
    return new Promise((resolve, reject) => {
      this.props.updateAgentHireRequestAction(data, (response) => {
        this.hideLoading()
        if (response.success) {
          toast.success(response.message)
          this.getData()
        }
        resolve(response)
      }, (err) => {
        this.hideLoading()
        reject(err)
      })
    })
  }

  render() {
    return (
      <>

        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={this.state.loading} />
          ) : null
        }
        <div className="app">
          <AppHeader fixed className="header">
            <Suspense fallback={this.loading()}>
              <DefaultHeader onLogout={(e) => this.signOut(e)} />
            </Suspense>
          </AppHeader>

          <div className="profileBox">
            <LeftInboxMenu title={"Starred"} />
            <Container className='profileContainer foe-inbox-container'>
              <Row>
                <Col xs="12" className="uni-right-card rightInboxSec">
                  <div className="foe-inbox-container viewMessageInboxPage">
                    <div className="messageSection pb-10">
                      <header className="header mb-0">
                        <h3 className="title">
                          <img
                            src={BackArrowIcon}
                            alt="go back"
                            className="mr-10 pointer"
                            onClick={() => {
                              if (this.state.mail_type == "Inbox") {
                                this.props.history.push("/inbox")
                              }
                              if (this.state.mail_type == "Sent") {
                                this.props.history.push("/sentInbox")
                              }
                              if (this.state.mail_type == "Deleted") {
                                this.props.history.push("/deleteInbox")
                              }
                              if (this.state.mail_type == "Starred") {
                                this.props.history.push("/starredInbox")
                              }
                            }}
                          />
                          {this.state.mail_subject}{" "}
                          <Badge color="primary">
                            {this.state.mail_type}
                          </Badge>
                        </h3>
                        <Button
                          color="link"
                          onClick={this.print}
                          className="print"
                        >
                          <img
                            src={PrintIcon}
                            alt="print"
                          />
                        </Button>
                      </header>

                      <div className="mailBox">
                        {this.state.emails &&
                          this.state.emails.map(
                            (item, index) => {
                              return (
                                <>
                                  {/* Mail Header */}
                                  <div
                                    key={index}
                                    className={`mailHeader ${item.content
                                      ? "show"
                                      : "hide"
                                      }`}
                                  >
                                    <div className="topPart">
                                      {
                                        item.sender_id ==
                                          this.state.universityId && item.sender_type == 1 ? (
                                          <div
                                            className="leftSide"
                                            onClick={() => {
                                              this.showMailFullDetail(
                                                index
                                              );
                                            }}
                                          >
                                            <span className="avatar">
                                              <img
                                                src={
                                                  this.props.universityLogo
                                                    ? baseUrl + this.props.universityLogo
                                                    : AvatarPic
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <strong className="mr-2">
                                              Me
                                            </strong>
                                          </div>
                                        ) : (
                                          <div
                                            className="leftSide"
                                            onClick={() => {
                                              this.showMailFullDetail(
                                                index
                                              );
                                            }}
                                          >
                                            <span className="avatar">
                                              <img
                                                src={
                                                  item.profile_pic
                                                    ? baseurl + item.profile_pic
                                                    : AvatarPic
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <strong className="mr-2">
                                              {
                                                item.email_from_details ? (
                                                  item.email_from_details.first_name +
                                                  " " +
                                                  item.email_from_details.last_name
                                                ) : ""
                                              }
                                            </strong>
                                            &lt;
                                            {
                                              item.email_from_details ? (
                                                item.email_from_details.email
                                              ) : ""
                                            }
                                            &gt;
                                          </div>
                                        )
                                      }

                                      <div className="rightSide">
                                        {
                                          item.attachment_flag === 1 ? (
                                            <img
                                              src={
                                                AttachmentIcon
                                              }
                                              alt="Attachment"
                                              className="AttachmentIcon"
                                            />
                                          ) : null
                                        }
                                        <div className="dateTime">
                                          {
                                            moment(moment.utc(item.time_sent)).local().format('DD MMM YY HH:MM A')
                                          }

                                          {" (" +
                                            moment(moment.utc(item.time_sent))
                                              .local()
                                              .startOf(
                                                "seconds"
                                              )
                                              .fromNow() +
                                            ")"}
                                        </div>

                                        {
                                          this.state.mail_type != "Deleted" ? (
                                            <img
                                              src={
                                                (
                                                  item.star &&
                                                  item.star.is_star
                                                ) ? StarredActiveIcon
                                                  : StarredIcon
                                              }
                                              alt="Starred"
                                              className="StarredIcon"
                                              onClick={() => this.updateFavourite(item.id)}
                                            />
                                          ) : null
                                        }

                                        <Button
                                          className="ThreeDotIcon"
                                          color="link"
                                          id={
                                            "Popover-" +
                                            index
                                          }
                                        >
                                          <img
                                            src={
                                              ThreeDotIcon
                                            }
                                            alt=""
                                          />
                                        </Button>
                                        <UncontrolledPopover
                                          trigger="hover"
                                          placement="bottom"
                                          target={
                                            "Popover-" +
                                            index
                                          }
                                          className="emailExtraOptions"
                                        >
                                          <PopoverBody>
                                            <ul>
                                              <li
                                                onClick={
                                                  () => this.updateReadStatus(item.id, 1)
                                                }
                                              >
                                                Mark as unread
                                              </li>
                                              {
                                                item.attachment_flag ? (
                                                  <li
                                                    onClick={
                                                      () => {
                                                        window.open(
                                                          baseUrl +
                                                          DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS +
                                                          `?student_id=${this.state.universityId}&mail_id=${item.id}`,
                                                          "_blank"
                                                        )
                                                      }
                                                    }
                                                  >
                                                    Download all attachment
                                                  </li>
                                                ) : null
                                              }
                                              {
                                                this.state.mail_type != "Deleted" ? (
                                                  <li
                                                    onClick={() => {
                                                      this.deleteEmail(item.id)
                                                    }}
                                                  >
                                                    Delete
                                                  </li>
                                                ) : null
                                              }

                                              {/* <li>Create Meeting</li> */}
                                            </ul>
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </div>
                                    </div>
                                    {
                                      item.request_mail_type ? (
                                        <div
                                          className="content"
                                          id="mailContent"
                                        >
                                          <EmailTemplate
                                            item={item}
                                            updateHireRequest={(data) => {
                                              return this.updateHireRequest(data)
                                            }}
                                            agent={this.state.agent}
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className="content"
                                          id="mailContent"
                                          dangerouslySetInnerHTML={{
                                            __html: item.body,
                                          }}
                                        >
                                          {/* Mail Data Comes here */}
                                        </div>
                                      )
                                    }

                                  </div>
                                  {/* Mail Body */}
                                  <div
                                    className={`mailBody ${!item.content ? "show" : "hide"}`}
                                  >
                                    <div key={index} className={`mailHeader`}
                                    >
                                      <div className="topPart">
                                        <div className="leftSide"

                                        >
                                          <span className="avatar">
                                            <img
                                              src={
                                                item.sender_id ==
                                                  this.state.universityId ? (
                                                  this.props.universityLogo
                                                    ? baseUrl + this.props.universityLogo
                                                    : AvatarPic
                                                ) : (item.profile_pic ? baseurl + item.profile_pic : AvatarPic)
                                              }
                                              alt=""
                                            />
                                          </span>
                                          <div>
                                            {
                                              item.sender_id ==
                                                this.state.universityId ? (
                                                <>
                                                  <strong className="mr-2"
                                                    onClick={() => {
                                                      this.showMailFullDetail(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    Me
                                                  </strong>

                                                  <div>
                                                    <Button
                                                      className="MailSummary"
                                                      color="link"
                                                      id={"MailSummary-" + index}
                                                    >
                                                      {"To: "}
                                                      {
                                                        item.email_to &&
                                                          item.email_to.length ?
                                                          (
                                                            item.email_to.map((val, index) => {
                                                              let nameString = "";
                                                              if (val.receiver) {
                                                                if (
                                                                  val.receiver.personal_details
                                                                ) {
                                                                  nameString = val.receiver.personal_details.first_name
                                                                    + " " +
                                                                    val.receiver.personal_details.last_name
                                                                } else {
                                                                  nameString = val.receiver.first_name
                                                                    + " " +
                                                                    val.receiver.last_name
                                                                }
  
                                                                if (index > 0) {
                                                                  nameString = ", " + nameString;
                                                                }
                                                              }

                                                              return nameString;
                                                            })
                                                          ) : ""
                                                      }
                                                    </Button>
                                                    <UncontrolledPopover
                                                      trigger="legacy"
                                                      placement="bottom-start"
                                                      target={"MailSummary-" + index}
                                                      className="MailSummaryData"
                                                    >
                                                      <PopoverBody>
                                                        <ul>

                                                          <li>
                                                            <span>
                                                              from:
                                                            </span>
                                                            <b>
                                                              {
                                                                item.email_from_details ?
                                                                  item.email_from_details.studentdetails ? (
                                                                    item.email_from_details.studentdetails.first_name
                                                                    + " " +
                                                                    item.email_from_details.studentdetails.last_name
                                                                  ) : (
                                                                    item.email_from_details.first_name
                                                                    + " " +
                                                                    item.email_from_details.last_name
                                                                  ) : ""
                                                              }
                                                            </b>
                                                            &lt;{
                                                              item.email_from_details ? (
                                                                item.email_from_details.email
                                                              ) : ""
                                                            }&gt;
                                                          </li>

                                                          <li>
                                                            <span>
                                                              to:
                                                            </span>
                                                            <b>
                                                              {
                                                                item.email_to &&
                                                                  item.email_to.length ?
                                                                  (
                                                                    item.email_to.slice(0, 1).map((val, mailIndex) => {
                                                                      let nameString = "";
                                                                      if (val.receiver) {
                                                                        if (
                                                                          val.receiver.personal_details
                                                                        ) {
                                                                          nameString = val.receiver.personal_details.first_name
                                                                            + " " +
                                                                            val.receiver.personal_details.last_name
                                                                        } else {
                                                                          nameString = val.receiver.first_name
                                                                            + " " +
                                                                            val.receiver.last_name
                                                                        }
  
                                                                        if (mailIndex > 0) {
                                                                          nameString = ", " + nameString;
                                                                        }
                                                                      }

                                                                      return nameString;
                                                                    })
                                                                  ) : ""
                                                              }
                                                            </b>
                                                            &lt;
                                                            {
                                                              item.email_to &&
                                                                item.email_to.length ?
                                                                (
                                                                  item.email_to.slice(0, 1).map((val, mailIndex) => {
                                                                    let nameString = val.receiver?.email

                                                                    if (mailIndex > 0) {
                                                                      nameString = ", " + nameString;
                                                                    }

                                                                    return nameString;
                                                                  })
                                                                ) : ""
                                                            }
                                                            &gt;
                                                          </li>

                                                          {
                                                            item.email_cc &&
                                                              item.email_cc.length ? (
                                                              <li>
                                                                <span>
                                                                  cc:
                                                                </span>
                                                                <b>
                                                                  {
                                                                    item.email_cc.map((val, mailIndex) => {
                                                                      let nameString = "";
                                                                      if (
                                                                        val.receiver.personal_details
                                                                      ) {
                                                                        nameString = val.receiver.personal_details.first_name
                                                                          + " " +
                                                                          val.receiver.personal_details.last_name
                                                                      } else {
                                                                        nameString = val.receiver.first_name
                                                                          + " " +
                                                                          val.receiver.last_name
                                                                      }

                                                                      nameString +=
                                                                        `<${val.receiver.email
                                                                        }>`;
                                                                      ;

                                                                      if (mailIndex > 0) {
                                                                        nameString = ", " + nameString;
                                                                      }

                                                                      return nameString;
                                                                    })
                                                                  }
                                                                </b>
                                                              </li>
                                                            ) : null
                                                          }

                                                          <li>
                                                            <span>
                                                              date:
                                                            </span>
                                                            {
                                                              moment(moment.utc(item.time_sent)).local().format('DD MMM YY HH:MM A')
                                                            }
                                                          </li>

                                                          <li>
                                                            <span>
                                                              subject:
                                                            </span>
                                                            {this.state.mail_subject}
                                                          </li>
                                                        </ul>
                                                      </PopoverBody>
                                                    </UncontrolledPopover>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <strong className="mr-4"
                                                    onClick={() => {
                                                      this.showMailFullDetail(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    {
                                                      item.email_from_details ? (
                                                        item.email_from_details.first_name +
                                                        " " +
                                                        item.email_from_details.last_name
                                                      ) : ""
                                                    }
                                                  </strong>
                                                  &lt;
                                                  {
                                                    item.email_from_details ? (
                                                      item.email_from_details.email
                                                    ) : ""
                                                  }
                                                  &gt;

                                                  <div>
                                                    <Button
                                                      className="MailSummary"
                                                      color="link"
                                                      id={"MailSummary-" + index}
                                                    >
                                                      to me
                                                    </Button>
                                                    <UncontrolledPopover
                                                      trigger="legacy"
                                                      placement="bottom-start"
                                                      target={"MailSummary-" + index}
                                                      className="MailSummaryData"
                                                    >
                                                      <PopoverBody>
                                                        <ul>

                                                          <li>
                                                            <span>
                                                              from:
                                                            </span>
                                                            <b>
                                                              {
                                                                item.email_from_details ? (
                                                                  item.email_from_details.first_name +
                                                                  " " +
                                                                  item.email_from_details.last_name
                                                                ) : ""
                                                              }
                                                            </b>
                                                            &lt;
                                                            {
                                                              item.email_from_details ? (
                                                                item.email_from_details.email
                                                              ) : ""
                                                            }
                                                            &gt;
                                                          </li>

                                                          <li>
                                                            <span>
                                                              to:
                                                            </span>
                                                            <b>
                                                              {
                                                                item.email_to &&
                                                                  item.email_to.length ?
                                                                  (
                                                                    item.email_to.map((val, index) => {
                                                                      let nameString = "";
                                                                      if (
                                                                        val.receiver.personal_details
                                                                      ) {
                                                                        nameString = val.receiver.personal_details.first_name
                                                                          + " " +
                                                                          val.receiver.personal_details.last_name
                                                                      } else {
                                                                        nameString = val.receiver.first_name
                                                                          + " " +
                                                                          val.receiver.last_name
                                                                      }

                                                                      nameString += '<' + val.receiver.email + '>'

                                                                      if (index > 0) {
                                                                        nameString = ", " + nameString;
                                                                      }

                                                                      return nameString;
                                                                    })
                                                                  ) : ""
                                                              }
                                                            </b>
                                                          </li>
                                                          {
                                                            item.email_cc &&
                                                              item.email_cc.length ? (

                                                              <li>
                                                                <span>
                                                                  cc:
                                                                </span>
                                                                <b>
                                                                  {
                                                                    item.email_cc &&
                                                                      item.email_cc.length ?
                                                                      (
                                                                        item.email_cc.map((val, index) => {
                                                                          let nameString = "";
                                                                          if (
                                                                            val.receiver.personal_details
                                                                          ) {
                                                                            nameString = val.receiver.personal_details.first_name
                                                                              + " " +
                                                                              val.receiver.personal_details.last_name
                                                                          } else {
                                                                            nameString = val.receiver.first_name
                                                                              + " " +
                                                                              val.receiver.last_name
                                                                          }

                                                                          nameString += '<' + val.receiver.email + '>';

                                                                          if (index > 0) {
                                                                            nameString = ", " + nameString;
                                                                          }

                                                                          return nameString;
                                                                        })
                                                                      ) : ""
                                                                  }
                                                                </b>
                                                              </li>
                                                            ) : null
                                                          }

                                                          <li>
                                                            <span>
                                                              date:
                                                            </span>
                                                            {moment(moment.utc(item.time_sent)).local().local('DD MMM YY HH:MM A')}
                                                          </li>

                                                          <li>
                                                            <span>
                                                              subject:
                                                            </span>
                                                            {this.state.mail_subject}
                                                          </li>

                                                        </ul>
                                                      </PopoverBody>
                                                    </UncontrolledPopover>
                                                  </div>
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                        <div className="rightSide">
                                          {
                                            this.state.agent ? (
                                              this.state.agent.share_flag ? (
                                                <>
                                                  <Button
                                                    color="secondary" outline
                                                    id={"shared1"}
                                                    className="shareProfileBtn"
                                                  >
                                                    <ConfirmIcon className="pr-2" />
                                                    Profile Shared
                                                  </Button>
                                                  <UncontrolledPopover
                                                    trigger="hover"
                                                    placement="bottom"
                                                    target={"shared1"}
                                                    className="emailExtraOptions"
                                                  >
                                                    <PopoverBody>
                                                      <ul>
                                                        <li
                                                          onClick={
                                                            () => {
                                                              this.confirmShareProfile(2)
                                                            }
                                                          }
                                                        >
                                                          Cancel Sharing
                                                        </li>
                                                      </ul>
                                                    </PopoverBody>
                                                  </UncontrolledPopover>
                                                </>
                                              ) : (
                                                <>
                                                  <Button color="secondary" outline
                                                    onClick={() => {
                                                      this.openShareProfile()
                                                    }}
                                                    className="shareProfileBtn"
                                                  >
                                                    Share Profile
                                                  </Button>
                                                </>
                                              )
                                            ) : null
                                          }
                                          {
                                            this.state.agent
                                              && this.state.agent.agent_flag ? (
                                              this.state.agent.hire_flag
                                                && this.state.agent.hire ? (
                                                this.state.agent.hire.hire_status == 3 ? (
                                                  <div className="currentHireSec">
                                                    <div className="d-flex">
                                                      <h5 className="mb-0">Current Hire: </h5>
                                                      <p className="mb-0">{" "}{this.state.agent.hire.agent_compnay}</p>
                                                    </div>
                                                    <div className="text-right">
                                                      {
                                                        !this.state.agent.hire.terminate ? (
                                                          <a onClick={() => this.openRaiseDispute(item)}>
                                                            <span style={{ color: "#F39734" }}>
                                                              <img src={Warning} alt="close-icon" className="uni-icon" height="17" width="17" />
                                                              Raise a dispute
                                                            </span>
                                                          </a>
                                                        ) : (
                                                          <a onClick={() => this.openTerminate(item)}>
                                                            <span style={{ color: "#D80027" }}>
                                                              <img src={UserX} alt="close-icon" className="uni-icon" height="17" width="17" />
                                                              Terminate Contract
                                                            </span>
                                                          </a>
                                                        )
                                                      }
                                                    </div>
                                                  </div>
                                                ) : (<></>)
                                              ) : (
                                                <Button color="secondary" outline
                                                  className="ml-2 hireNowBtn"
                                                  onClick={() => {
                                                    this.openHireAgain(item)
                                                  }}
                                                >
                                                  Hire Now
                                                </Button>
                                              )
                                            ) : (
                                              <></>
                                            )
                                          }

                                          <div className="rightSideicon">
                                            {item.attachment_flag ? (
                                              <img
                                                src={
                                                  AttachmentIcon
                                                }
                                                alt="Attachment"
                                                className="AttachmentIcon"
                                              />
                                            ) : null
                                            }

                                            <img
                                              src={ReplyIcon}
                                              alt="Reply"
                                              className="ReplyIcon mr-10 ml-5"
                                              onClick={() => {
                                                this.setState({
                                                  showReply: true,
                                                  parentFlag: 0
                                                });
                                                this.setReplyState(item)
                                              }}
                                            />
                                            {
                                              this.state.mail_type != "Deleted" ? (
                                                <img
                                                  src={
                                                    (
                                                      item.star &&
                                                      item.star.is_star
                                                    ) ? StarredActiveIcon
                                                      : StarredIcon
                                                  }
                                                  alt="Starred"
                                                  className="StarredIcon"
                                                  onClick={() => this.updateFavourite(item.id)}
                                                />
                                              ) : null
                                            }

                                            <Button
                                              className="ThreeDotIcon"
                                              color="link"
                                              id={"ThreeDot-" + index}
                                            >
                                              <img
                                                src={ThreeDotIcon}
                                                alt=""
                                              />
                                            </Button>
                                            <UncontrolledPopover
                                              trigger="hover"
                                              placement="bottom"
                                              target={"ThreeDot-" + index}
                                              className="emailExtraOptions"
                                            >
                                              <PopoverBody>
                                                <ul>
                                                  <li
                                                    onClick={
                                                      () => this.updateReadStatus(item.id, 1)
                                                    }
                                                  >
                                                    Mark as unread
                                                  </li>
                                                  {
                                                    item.attachment_flag ? (
                                                      <li
                                                        onClick={
                                                          () => {
                                                            window.open(
                                                              baseUrl +
                                                              DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS +
                                                              `?student_id=${this.state.universityId}&mail_id=${item.id}`,
                                                              "_blank"
                                                            )
                                                          }
                                                        }
                                                      >
                                                        Download all attachment
                                                      </li>
                                                    ) : null
                                                  }
                                                  {
                                                    this.state.mail_type != "Deleted" ? (
                                                      <li
                                                        onClick={() => {
                                                          this.deleteEmail(item.id)
                                                        }}
                                                      >
                                                        Delete
                                                      </li>
                                                    ) : null
                                                  }
                                                  {/* <li>Create Meeting</li> */}
                                                </ul>
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="content">
                                        <div className="dateTime">
                                          {
                                            moment(moment.utc(item.time_sent)).local().format('DD MMM YY HH:MM A')
                                          }

                                          {" (" +
                                            moment(moment.utc(item.time_sent)).local().fromNow() +
                                            ")"}
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      item.request_mail_type ? (
                                        <div
                                          className="content"
                                          id="mailContent"
                                        >
                                          <EmailTemplate
                                            item={item}
                                            updateHireRequest={(data) => {
                                              return this.updateHireRequest(data)
                                            }}
                                            agent={this.state.agent}
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className="content"
                                          id="mailContent"
                                          dangerouslySetInnerHTML={{
                                            __html: item.body,
                                          }}
                                        >
                                          {/* Mail Data Comes here */}
                                        </div>
                                      )
                                    }
                                    {
                                      item.attachment_flag ? (
                                        <div className="attachement">
                                          {
                                            item.attachments.length ? (
                                              <>
                                                <div className="detail">
                                                  <HasAttachmentIcon
                                                    className="HasAttachmentIcon"
                                                  />
                                                  {item.attachments.length}{" "}
                                                  Attachment
                                                </div>
                                              </>
                                            ) : null
                                          }
                                          {
                                            item.attachments.length ? (
                                              <div className="fileList">
                                                {item.attachments.map(
                                                  (file, index) => (
                                                    <div
                                                      className="box"
                                                      key={index}
                                                    >
                                                      <div className="fileName">
                                                        <u>
                                                          {file.original_filename}
                                                        </u>
                                                        <div className="fileSize">

                                                        </div>
                                                      </div>

                                                      <img
                                                        src={
                                                          DownloadAttachemnet
                                                        }
                                                        alt="Download"
                                                        className="DownloadAttachemnet"
                                                        onClick={() => {
                                                          window.open(baseurl + file.attachment_path, "_blank")
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : null
                                          }
                                        </div>
                                      ) : null
                                    }
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>

                      {/* Reply */}
                      {ReplyMessage.call(this)}

                      <footer>
                        <Button color="secondary" outline
                          onClick={() => {
                            this.setState({
                              showReply: true,
                              parentFlag: 0
                            });
                            this.setReplyState(false);
                          }}
                        >
                          <ReplySVGIcon width="14px" height="10px" /> Reply
                        </Button>
                        <Button color="secondary" outline
                          onClick={() => {
                            this.setState({
                              showReply: true,
                              parentFlag: 0
                            });
                            this.setReplyState(true);
                          }}
                        >
                          <ReplyAllIcon width="14px" height="10px" /> Reply All
                        </Button>
                        <Button color="secondary" outline
                          onClick={() => {
                            this.setState({
                              showReply: true,
                              parentFlag: 1
                            })
                            this.setForwardState()
                          }}
                        >
                          <ForwardIcon width="14px" height="10px" /> Forward
                        </Button>
                      </footer>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>



        </div>
        <Modal
          isOpen={this.state.terminate}
          toggle={this.toggleSuccess}
          className="primaryModal terminate-form"
          centered
        >
          <ModalBody className="modal-saveSearch termi-contr">
            <div className="modal_header">
              <a onClick={() => this.closeTerminate()}>
                <span>
                  <img src={Closeicon} alt="close-icon" className="uni-icon" height="17" width="17" />
                </span>
              </a>
            </div>
            <div className="text-center">
              <div className="mb-4 saveSearchFont-header">
                <h4>Reason for Terminating Contract</h4>
              </div>
              <div className="sucess-text-saveSearch">
                <div className="select-wrapper4 hand-cursor">
                  <label className="card-label">Select a reason for terminating the contract</label>
                  <Input
                    type="select"
                    id="sortType"
                    onChange={(e) => this.handleTerminate(e)}
                    className="select-sort2 border-0 hand-cursor"
                    value={this.state.terminatetype}
                  >
                    {this.state.terminateReasonTitle && this.state.terminateReasonTitle.length > 0 &&
                      this.state.terminateReasonTitle.map((item, index) => (
                        <option value={item.id}>{item.title}</option>
                      ))
                    }

                  </Input>
                </div>
                <div className="mt-3">
                  <label className="card-label">Type your reason (Optional)</label>
                  <textarea
                    id="review"
                    name="review"
                    onChange={(e) => this.changeReason(e)}
                    className="form-control w-100 checksame"
                    placeholder="Write a review..."
                    value={this.state.terminateDescription}
                  />
                </div>
                <div className="satisf_radio mt-4">
                  <label className="card-label">Your overall satisfaction of this Preferred partner</label>
                  <div className="d-flex">
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='1'
                        onChange={() => this.terminateRating(1)}
                      />
                      <span>1</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='2'
                        onChange={() => this.terminateRating(2)}
                      />
                      <span>2</span>
                    </div>

                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='3'
                        onChange={() => this.terminateRating(3)}
                      />
                      <span>3</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='4'
                        onChange={() => this.terminateRating(4)}
                      />
                      <span>4</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='5'
                        onChange={() => this.terminateRating(5)}
                      />
                      <span>5</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='6'
                        onChange={() => this.terminateRating(6)}
                      />
                      <span>6</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='17'
                        onChange={() => this.terminateRating(7)}
                      />
                      <span>7</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='8'
                        onChange={() => this.terminateRating(8)}
                      />
                      <span>8</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='9'
                        onChange={() => this.terminateRating(9)}
                      />
                      <span>9</span>
                    </div>
                    <div className="mt-3 radio-margin">
                      <Input
                        type="radio"
                        name="viewerid"
                        value='10'
                        onChange={() => this.terminateRating(10)}
                      />
                      <span>10</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex filter-bottom flex-column">
                <div className="row">
                  <div className="col-md-12 flex2">
                    <Button
                      color="primary"
                      className="score-save beforesave "
                      type="submit"
                      onClick={() => this.terminateContract()}
                      style={{ marginLeft: "15px" }}
                    >
                      SEND
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.successAdmin}
          toggle={this.toggleSuccess}
          className="primaryModal"
          centered
        >
          <ModalBody className="modal-saveSearch">
            <div className="modal_header">
              <a onClick={() => this.closeSuccessAdmin()}>
                <span>
                  <img src={Closeicon} alt="close-icon" className="uni-icon" height="17" width="17" />
                </span>
              </a>
            </div>
            <div className="text-center">
              <div className="mb-3 saveSearchFont-header">
                <img src={Confirm} alt="close-icon" className="uni-icon" />
              </div>
              <div className="mb-3 saveSearchFont-header">
                <h2 className="successMsg">Your request has been sent to admin.</h2>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.dispute}
          toggle={this.toggleSuccess}
          className="primaryModal"
          centered
        >
          <ModalBody className="modal-saveSearch">
            <div className="modal_header">
              <a onClick={() => this.closeDispute()}>
                <span>
                  <img src={Closeicon} alt="close-icon" className="uni-icon" height="17" width="17" />
                </span>
              </a>
            </div>
            <div className="text-center">
              <div className="mb-3 saveSearchFont-header">
                <h4>Raise Dispute to Admin</h4><br />
              </div>
              <div className="sucess-text-saveSearch">
                <div className="select-wrapper4 hand-cursor">
                  <label className="card-label">Select the issue</label>
                  <Input
                    type="select"
                    id="sortType"
                    onChange={(e) => this.handleIssue(e)}
                    className="select-sort2 border-0 hand-cursor"
                    value={this.state.issueType}
                  >
                    {this.state.disputeReasonTitle && this.state.disputeReasonTitle.length > 0 &&
                      this.state.disputeReasonTitle.map((item, index) => (
                        <option value={item.id}>{item.title}</option>
                      ))
                    }

                  </Input>
                </div>
                <div className="mt-4">
                  <label className="card-label">Type your issue in detail.</label>
                  <textarea
                    id="review"
                    name="review"
                    onChange={(e) => this.changeDescription(e)}
                    className="form-control w-100 checksame"
                    placeholder="Write a review..."
                    value={this.state.raiseDescription}
                  />
                </div>
              </div>
              <div className="col-12 d-flex filter-bottom flex-column">
                <div className="row">
                  <div className="col-md-12 flex2">
                    <Button
                      color="primary"
                      className="score-save beforesave "
                      type="submit"
                      onClick={() => this.raiseDispute()}
                    // style={{ marginLeft: "15px" }}
                    >
                      SEND
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.shareProfile}
          toggle={this.toggleSuccess}
          className="primaryModal"
          centered
        // className={
        //   "" +
        //   "register-popup sucess-pop profile-success-pop shareProfileModal primaryModal" +
        //   " " +
        //   this.props.className
        // }
        >
          <ModalBody className="">
            <div className="modal_header">
              <a onClick={() => this.closeShareProfile()}>
                <span>
                  <img src={Closeicon} alt="close-icon" className="uni-icon" height="17" width="17" />
                </span>
              </a>
            </div>
            <div className="">
              <div className="modalBody">
                <div className="saveSearchFont-header">
                  <h4 className='title'>Do you want to share your profile?</h4>
                </div>
                <div className="sucess-text-saveSearch">
                  <h5 className='subTitle'>If “yes” Please select the information that you want to share?</h5>
                  <ul className="customCheckbox">
                    <li>
                      <Input
                        type="checkbox"
                        name="viewerid"
                        value='general'
                        onChange={() => this.getApplicationSelected('general')}
                      />
                      <label className="card-label">General Information</label>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="viewerid"
                        value='education'
                        onChange={() => this.getApplicationSelected('education')}
                      />
                      <label className="card-label">Education History</label>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="viewerid"
                        value='test'
                        onChange={() => this.getApplicationSelected('test')}
                      />
                      <label className="card-label">Test Scores</label>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="viewerid"
                        value='documents'
                        onChange={() => this.getApplicationSelected('documents')}
                      />
                      <label className="card-label">Documents</label>
                    </li>
                  </ul>
                  {/* <table className="table table-responsive-sm">
                  <tbody> */}
                  {/* <tr>
                      <td>
                        <Input
                          type="checkbox"
                          className="mt-2"
                          name="viewerid"
                          value='general'
                          onChange={() => this.getApplicationSelected('general')}
                        />
                      </td>
                      <td><label className="card-label">General Information</label></td>
                    </tr> */}
                  {/* <tr>
                      <td><Input
                        type="checkbox"
                        name="viewerid"
                        value='education'
                        onChange={() => this.getApplicationSelected('education')}
                      /></td>
                      <td><label className="card-label">Education History</label></td>
                    </tr> */}
                  {/* <tr>
                      <td><Input
                        type="checkbox"
                        name="viewerid"
                        value='test'
                        onChange={() => this.getApplicationSelected('test')}
                      /></td>
                      <td><label className="card-label">Test Scores</label></td>
                    </tr> */}
                  {/* <tr>
                      <td><Input
                        type="checkbox"
                        name="viewerid"
                        value='documents'
                        onChange={() => this.getApplicationSelected('documents')}
                      /></td>
                      <td><label className="card-label">Documents</label></td>
                    </tr> */}
                  {/* </tbody>
                </table> */}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='shareProfile'>
            <Button
              color="secondary"
              outline
              className="score-save beforesave reset"
              type="submit"
              onClick={() => this.closeShareProfile()}
              style={{ marginLeft: "20px" }}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              className="score-save beforesave "
              type="submit"
              onClick={() => this.confirmShareProfile()}
              style={{ marginLeft: "15px" }}
            >
              YES, SHARE
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.hireAgain}
          toggle={this.toggleSuccess}
          className="primaryModal"
          centered
        >
          <ModalBody className="modal-saveSearch">
            <div className="modal_header mb-4 ">
              <a onClick={() => this.closeHireAgain()}>
                <span>
                  <img src={Closeicon} alt="close-icon" className="uni-icon" height="17" width="17" />
                </span>
              </a>
            </div>
            <div className="text-center">
              <div className="saveSearchFont-header pt-30 pb-50">
                <h4>Do you want to hire this Preferred partner?</h4>
              </div>
              {/* <div className="sucess-text-saveSearch">

              </div> */}
              {/* <div className="col-12 d-flex filter-bottom flex-column">
                <div className="row">
                  <div className="col-md-12 flex2">

                  </div>
                </div>
              </div> */}
            </div>
          </ModalBody>
          <ModalFooter className='shareProfile'>
            <Button
              color="secondary"
              outline
              className="score-save beforesave reset"
              type="submit"
              onClick={() => this.closeHireAgain()}
              style={{ marginLeft: "20px" }}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              className="score-save beforesave "
              type="submit"
              onClick={() => this.hireAgentAgain()}
              style={{ marginLeft: "15px" }}
            >
              YES, HIRE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profileReducer.profile,
    universityLogo: state.profileReducer.universityLogo
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInboxMailDetailsAction,
      updateInboxReadStatusAction,
      deleteEmailAction,
      composeMailAction,
      updateFavouriteAction,
      getProfileAction,
      getAgentCheckHireAction,
      getHireAgentAction,
      getShareProfileAction,
      updateAgentHireRequestAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewMessage);
