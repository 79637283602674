import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container, Row, Col, Button, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import PageLoading from "../../common/PageLoading";
import "./ViewMeeting.scss";
import { ReactComponent as BackArrowWhite } from "../../assets/img/icons/left-arrow.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getTimeZonesListAction,
  deleteMeetingAction,
  meetingDetailsAction,
  updateMeetingStatusAction
} from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ReactComponent as LinkIcon } from "../../assets/img/icons/link_icon.svg";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as DownArrowIcon } from "../../assets/img/icons/keyboard_arrow_down.svg";
import { ReactComponent as BlueTick } from "../../assets/img/icons/tick_icon_blue.svg";

class ViewMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssign: [],
      toTime: null,
      toTimePost: "AM",
      fromTime: null,
      fromTimePost: "AM",
      meetingTitle: "",
      meetingDetails: "",
      meetingDate: null,
      timeZone: "",
      timeZoneList: [],
      meetingLink: "",
      loading: false,
      universityId: 0,
      errors: {},
      meetingId: 0,
      details: null
    }
  }

  componentDidMount() {
    let universityId = localStorage.getItem("studentid");
    let id =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    this.setState({
      universityId,
      meetingId: id
    }, () => {
      this.getTimeZonesList();
      this.getDetails()
    })
  }

  getDetails = () => {
    this.showLoading()
    new Promise((resolve, reject) => {
      this.props.meetingDetailsAction(
        {
          student_id: this.state.universityId,
          meeting_id: this.state.meetingId
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            let startTime = response.data.meeting_start_time_val;
            let start = startTime.split(" ");
            let fromTimePost = start[1];
            let fromTime = start[0];

            let endTime = response.data.meeting_end_time;
            let end = endTime.split(" ");
            let toTimePost = end[1];
            let toTime = end[0];

            let selectedAssign = [];
            response.data.participants.map((participant) => {
              let participantDetails = {};
              if (participant.participant_type === 1) {
                participantDetails.name = (participant.participant) ? participant.participant.first_name + " " + participant.participant.last_name : "";
                participantDetails.color = participant.participant.color_code;
              } else if (participant.participant_type === 2) {
                participantDetails.name = participant.participant.personal_details.first_name + " " + participant.participant.personal_details.last_name;
                participantDetails.color = participant.participant.personal_details.color_code;
              } else if (participant.participant_type === 3) {
                participantDetails.name = participant.participant.email;
                participantDetails.color = "#4DA1FF";
              }
              selectedAssign.push(participantDetails);
            });

            let meetingUrl = "";
            meetingUrl = (parseInt(response.data.host_id) === parseInt(this.state.agentId)) ? response.data.meeting_link : response.data.participant_link;

            this.setState({
              details: response.data,
              meetingLink: meetingUrl,
              meetingTitle: response.data.title,
              meetingDetails: response.data.description,
              meetingDate: new Date(response.data.meeting_date),
              fromTime,
              fromTimePost,
              toTime,
              toTimePost,
              timeZone: response.data.time_zone,
              selectedAssign
            })
          }
          resolve();
        },
        (err) => {
          console.log('err', err)
          this.hideLoading();
          reject();
        }
      )
    })
  }
  getTimeZonesList = () => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getTimeZonesListAction(
        {
          university_id: this.state.universityId
        },
        (response) => {
          this.hideLoading();
          this.setState({
            timeZoneList: response.data
          })
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  updateStatus = (status) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateMeetingStatusAction(
        {
          university_id: this.state.universityId,
          meeting_id: this.state.details.id,
          status: status
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            this.getDetails(this.state.details.id)
          }
          resolve();
        },
        (err) => {
          this.props.hideLoading();
          reject();
        }
      )
    })
  }
  getCurrentStatusString = (details) => {
    // this.state.acceptOption
    let returnString = "Yes, I’ll attend";
    if (details.participants) {
      let participant = null;
      details.participants.map((val) => {
        if (val.participant_id == this.state.universityId) {
          participant = val
        }
      });
      if (participant) {
        if (participant.status == 3) {
          returnString = "Maybe";
        }
        if (participant.status == 4) {
          returnString = "No";
        }
      }
    }

    return returnString;
  }
  loading = () => <PageLoading loading={true} />;
  render() {
    return (
      <>
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={this.state.loading} />
          ) : null
        }

        <div className="blueStrip">
          <Container>
            <Row>
              <Col xs="12" md="6" className="leftSection">
                <BackArrowWhite className="backArrow cur-pointer"
                  onClick={() => { this.props.history.push("/profiledashboard") }}
                /> Meeting Details
              </Col>
              <Col xs="12" md="6" className="rightSection">
                {(this.state.details && parseInt(this.state.universityId) === parseInt(this.state.details.host_id)) &&
                  <>
                    <Button
                      color="white"
                      outline
                      onClick={() => {
                        confirmAlert({
                          title: "Confirm",
                          message: "Are you sure you want to delete this event?",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => {
                                this.showLoading();
                                new Promise((resolve, reject) => {
                                  this.props.deleteMeetingAction(
                                    {
                                      university_id: this.state.universityId,
                                      meeting_id: this.state.details.id
                                    },
                                    (response) => {
                                      this.hideLoading();
                                      if (response.success) {
                                        toast.success(response.message);
                                        this.props.history.push("/universitydashboard");
                                      }
                                      resolve();
                                    },
                                    (err) => {
                                      this.hideLoading();
                                      reject();
                                    }
                                  )
                                })
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {

                              },
                            },
                          ],
                        });
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      color="white"
                      onClick={() => {
                        this.props.history.push("/create-meeting/" + this.state.meetingId)
                      }}
                    >
                      Edit
                    </Button>
                  </>
                }
                <div className="description">
                  {this.state.details &&
                    this.state.details.host_id != this.state.universityId ? (
                    <section>
                      <UncontrolledDropdown className="acceptInvite">
                        <DropdownToggle color="white">
                          {
                            this.getCurrentStatusString(this.state.details)
                          } <DownArrowIcon />
                        </DropdownToggle>
                        <DropdownMenu className="options">
                          <DropdownItem
                            onClick={() => {
                              this.updateStatus('yes')
                            }}
                          ><span>{this.getCurrentStatusString(this.state.details) === 'Yes, I’ll attend' && <BlueTick />}</span> Yes, I’ll attend</DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.updateStatus('maybe')
                            }}
                          ><span>{this.getCurrentStatusString(this.state.details) === 'Maybe' && <BlueTick />}</span> Maybe</DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.updateStatus('no')
                            }}
                          ><span>{this.getCurrentStatusString(this.state.details) === 'No' && <BlueTick />}</span> No</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </section>
                  ) : null
                  }
                </div>
                {this.state.details && this.state.details.host_id != this.state.universityId &&
                  <Link to={`/inbox-compose/${this.state.details.id}/meeting`} className="btn btn-outline-white">
                    Reply to all
                  </Link>
                }
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ViewMeeting">
          <Container >
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} className="mt-40">
                <section>
                  <head>
                    {(this.state.details && this.state.universityId === this.state.details.host_id)
                      ? "You have created the following event."
                      : "You have been invited to the following event."
                    }
                  </head>
                  <div className="content">
                    <div className="basicForm row">
                      <Col sm={12}>
                        <div className="viewMeetingInput">
                          <span className="mr-20">Suject</span>
                          <span className="strong">{this.state.meetingTitle}</span>
                        </div>
                      </Col>
                      <Col sm={6} className="">
                        <div className="viewMeetingInput mr-5">
                          <span className="mr-20">Date</span>
                          <span className="strong">{moment(this.state.meetingDate).format("dddd, DD MMMM YYYY")}</span>
                        </div>
                      </Col>
                      <Col sm={6} className="">
                        <div className="viewMeetingInput ml-5">
                          <span className="mr-20">Time</span>
                          <span className="strong">{this.state.fromTime} {this.state.fromTimePost} to {this.state.toTime} {this.state.toTimePost}</span>
                        </div>
                      </Col>
                      <Col sm={12} className="text-right p-0">
                        {this.state.details && <span className="my-5">{this.state.details.timezone.label}</span>}
                      </Col>
                      <Col sm={12} className="">
                        <Label for="details" sm={2}>Details</Label>
                        <div className="viewMeetingTextArea">
                          <span className="">{this.state.meetingDetails}</span>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <Label for="details" sm={2}>Details</Label>
                        <ul className="participantsList">
                          {
                            this.state.selectedAssign.length ?
                              this.state.selectedAssign.map((item, index) => {
                                const personName = item.name;
                                return (
                                  <li>
                                    <span
                                      style={{
                                        backgroundColor: item.color ? item.color : null
                                      }}// take color from Api
                                    >
                                      {personName.charAt(0)}
                                    </span>
                                    <em>{item.name}</em>
                                  </li>
                                  //item.name.charAt(0) +
                                )
                              }) : null
                          }
                        </ul>
                      </Col>
                      {this.state.meetingLink &&
                        <Col sm={12} className="description">
                          <div className="section">
                            <hr />
                            <h3 className="title">
                              <LinkIcon /> Start meeting here
                            </h3>
                            <Row className="copyLink">
                              {this.state.details &&
                                <Col xs="12" md="9">
                                  <Input
                                    value={this.state.meetingLink}
                                  />
                                </Col>
                              }
                              <Col xs="12" md="3" className="p-0">
                                <Button
                                  color="link"
                                  onClick={() => {
                                    navigator.clipboard.writeText(this.state.meetingLink);
                                    toast.success('Link copied successfully!')
                                  }}
                                >
                                  Copy link
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      }
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {

  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTimeZonesListAction,
      deleteMeetingAction,
      meetingDetailsAction,
      updateMeetingStatusAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewMeeting);
