import {
	LOGIN,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	ROLE_PERMISSION,
	ROLE_PERMISSION_ERROR,
	ROLE_PERMISSION_SUCCESS
} from "../constants";

export function loginAction(session, resolve, reject){
	return {
	  type: LOGIN,
	  payload: session,
	  resolve,
	  reject
	};
}

export function loginSuccess(data) {
	return {
	  type: LOGIN_SUCCESS,
	  payload: data
	};
}

export function loginError(error) {
	return {
	  type: LOGIN_ERROR,
	  payload: error
	};
}

export function getRolePermissionsAction(session, resolve, reject){

	return {
	  type: ROLE_PERMISSION,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getRolePermissionsSuccess(data) {
	return {
	  type: ROLE_PERMISSION_SUCCESS,
	  payload: data
	};
}

export function getRolePermissionsError(error) {
	return {
	  type: ROLE_PERMISSION_ERROR,
	  payload: error
	};
}
