import React, { Component } from "react";
import Scrollbars from "react-custom-scrollbars";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import Row from "reactstrap/lib/Row";
import ThreeDotIcon from "../../../assets/img/icons/three_dots_vertical.svg";
import RightTicIcon from "../../../assets/img/comment_resolved.svg";
// import CommentCountIcon from "../../../../assets/img/icons/comment-count.svg";
import { ReactComponent as CommentCountIcon } from "../../../assets/img/comment-count.svg";
import "./CommentPopover.scss";
import CommentPopoverJSON from "./CommentPopoverJSON.json"
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

export default class CommentPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: 0,
      fromSideCommet: 0,
      comments: [],
      comment: "",
      filterComments: [],
      commentId: 0
    }
  }

  setPopoverOpen = (id) => {
    this.setState({
      popoverOpen: id
    })
  }

  setFromSideCommet = (id) => {
    this.setState({
      fromSideCommet: id
    })
  }

  componentDidMount() {
    // if (this.state.comments != this.props.comments) {
    this.setState({
      comments: this.props.comments
    })
    // }
  }

  componentDidUpdate() {
    if (this.state.fromSideCommet != this.props.openCommentPopover) {
      this.setFromSideCommet(this.props.openCommentPopover)
    }
    if (
      this.props.comments &&
      this.state.comments != this.props.comments
    ) {

      this.setState({
        comments: this.props.comments
      }, () => {
        let filterComments = this.state?.comments?.filter(val => val.tab == this.props.tab && val.field_id == this.props.id).map(val => {
          let currentVal = val;
          currentVal.options = false;
    
          return currentVal
        })

        this.setState({
          filterComments
        })
      })
    }
  }

  toggle = () => {
    this.setPopoverOpen(0)
    this.setFromSideCommet(0)
    this.props.toggle(0)
  }

  deleteComment = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to delete this comment?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteComment(id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  }

  render() {
    return (
      <>

        <span
          className="commentBtn"
          id={this.props.id}
          // onClick={() => {
          //   this.setPopoverOpen(this.props.id)
          //   this.setFromSideCommet(0)
          // }}
        >
          {/* {
            this.state.filterComments &&
            this.state.filterComments.length ? (
              <div className={`
                  commentCount ${false ? "read" : ""}
                `}>
                <CommentCountIcon className={`commentCountIcon`} />
                <span>
                  {
                    this.state.filterComments.length > 99 ?
                      "99+" :
                      this.state.filterComments.length
                  }
                </span>
              </div>
            ) : null
          } */}

        </span>
        {/* UncontrolledPopover */}
        <Popover
          className="applicationComment"
          trigger='legacy'
          placement="bottom-start"
          target={this.props.id}
          isOpen={
            (
              this.state.popoverOpen === this.props.id ||
              this.state.fromSideCommet === this.props.id
            ) ? true : false
          }
        // toggle={this.toggle}
        >
          <div className="commentSection active">
            {
              this.state.filterComments &&
              this.state.filterComments.length ? (
                this.state.filterComments.map((item, index) => {
                  
                  return (
                    <>
                      <div className="msgBox" key={index}>
                        <ul>
                          <li key={item.id}>
                            <Row className="m-0">
                              <Col xs="1" className="p-0">
                                <span
                                  className={`avatar`}
                                  style={{
                                    backgroundColor: item.sender ? (
                                      item.user_type == 2 ? (
                                        item.sender.personal_details ? (
                                          item.sender.personal_details.color_code
                                        ) : ""
                                      ) : item.sender.color_code
                                    ) : ""
                                  }}
                                >
                                  {
                                    item.sender ? (
                                      item.user_type == 2 ? (
                                        item.sender.personal_details ? (
                                          item.sender.personal_details.first_name.charAt(0).toUpperCase()
                                        ) : ""
                                      ) : item.sender?.first_name?.charAt(0).toUpperCase()
                                    ) : ""
                                  }

                                </span>
                              </Col>
                              <Col
                                xs="11"
                                className="p-0 details"
                              >
                                {
                                  item.user_id == this.props.student_id ? (
                                  <>
                                    <Button
                                      id={`action${item.id}`}
                                      className="actionBtn"
                                      onClick={() => {
                                        let filterComments = this.state.filterComments;
                                        filterComments[index].options = !item.options;
                                        this.setState({
                                          filterComments
                                        })
                                      }}
                                    >
                                      <img
                                        src={ThreeDotIcon}
                                        alt=""
                                      />
                                    </Button>
                                    {
                                      item.id ? (
                                        <Popover
                                          className="msgAction"
                                          placement="bottom-end"
                                          trigger="click"
                                          target={`action${item.id}`}
                                          isOpen={item.options}
                                          toggle={() => {
                                            let filterComments = this.state.filterComments;
                                            filterComments[index].options = !item.options;
                                            this.setState({
                                              filterComments
                                            })
                                          }}
                                        >
                                          <PopoverBody>
                                            <span
                                              onClick={() => {
                                                let filterComments = this.state.filterComments;
                                                // filterComments[index].edit = !item.edit;
                                                filterComments[index].options = false;

                                                this.setState({
                                                  filterComments,
                                                  commentId: item.id,
                                                  comment: item.comment
                                                })
                                              }}
                                            >
                                              Edit
                                            </span>
                                            <span
                                              onClick={() => {
                                                this.setState({
                                                  comment: "",
                                                  commentId: 0,
                                                  popoverOpen: 0,
                                                  fromSideCommet: 0
                                                })
                                                this.deleteComment(item.id)
                                              }}
                                            >
                                              Delete
                                            </span>
                                          </PopoverBody>
                                        </Popover>
                                      ) : null
                                    }

                                  </>
                                  ) : null
                                }
                                <strong>
                                  {
                                    item.sender ? (
                                      item.user_type == 2 ? (
                                        item.sender.personal_details.first_name + " " + item.sender.personal_details.last_name
                                      ) : item.sender.first_name + " " + item.sender.last_name
                                    ) : ""
                                  }
                                </strong>
                                <date>
                                  {moment(item.created_at).format('h:m A, D MMM YYYY')}
                                </date>
                                <div
                                  className="msg"
                                  id={`msg-${index}`}
                                >
                                  {/* {
                                  item.edit ? (
                                    <>
                                      <textarea
                                        onChange={(e) => {
                                          this.setState({
                                            editComment: e.target.value
                                          })
                                        }}
                                      >
                                        {item.message}
                                      </textarea>
                                      <Button
                                        color="secondary"
                                        onClick={() => {
                                          this.addComment(true)
                                        }}
                                      >
                                        Save
                                      </Button>
                                    </>
                                  ) : (

                                  )
                                } */}
                                  <div
                                    className="text"
                                    disabled={false}
                                  // dangerouslySetInnerHTML={{
                                  //   __html: item.message
                                  // }}
                                  >
                                    {item.comment}
                                  </div>

                                </div>


                              </Col>
                            </Row>
                          </li>
                        </ul>
                      </div>
                    </>
                  )
                })
              ) : null
            }

            {/* </Scrollbars> */}

            <div className="commentTextarea">
              <Input
                type="textarea" placeholder="Reply"
                value={this.state.comment}
                onChange={(e) => {
                  this.setState({
                    comment: e.target.value
                  })
                }}
              />
              <div className="commentFooter">
                <div className="buttons">
                  <Button
                    color="primary"
                    onClick={() => {
                      if (!this.state.comment) {
                        toast.error('Please enter comment!')
                        return
                      }
                      let request = {
                        tab: this.props.tab,
                        field: this.props.id,
                        comment: this.state.comment
                      };

                      if (this.state.commentId) {
                        request.comment_id = this.state.commentId;
                      }
                      this.props.addComment(request).then(data => {
                        if (data.success) {
                          this.setState({
                            comment: "",
                            commentId: 0
                          })
                        }
                      })
                    }}
                  >
                    Reply
                  </Button>

                  <Button
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        comment: "",
                        commentId: 0,
                        popoverOpen: 0,
                        fromSideCommet: ""
                      })
                      this.props.closeSidePopup()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                {/* <div className="resolvedIcon">
                  <Button
                    className="actionBtn"
                  >
                    <img
                      src={RightTicIcon}
                      alt=""
                    />
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </Popover>
      </>
    )
  }
}
