import React from "react";
import template from "./AppTestscore.jsx";
import config from '../../config.json';
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MESSAGES } from "../../constant/messages.js";
import submitForm from "../../utils/submitForm.js";

const baseurl = `${config.baseurl}`;

class AppTestscore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: localStorage.getItem("fromApplicationType") === "Sent" ? true : false,
      TestAddMore: false,
      questiondata2: [],
      selectedQuestion2: null,
      examtype: '',
      examdate: '',
      avg_score: '',
      readingscore: '',
      listeningscore: '',
      writingscore: '',
      speakingscore: '',
      englishCheck: '',
      greexamdate: '',
      greverbalscore: '',
      greverbalrank: '',
      grequanitativescore: '',
      grequanitativerank: '',
      grewritingscore: '',
      grewritingrank: '',
      greCheck: '',

      gmatexamdate: '',
      gmatverbalscore: '',
      gmatverbalrank: '',
      gmatquanitativescore: '',
      gmatquanitativerank: '',
      gmatwritingscore: '',
      gmatwritingrank: '',
      gmattotalscore: '',
      gmattotalrank: '',
      gmatCheck: '',

      errors: {},
      formData: new FormData(),
      //examdate: new Date(),
      examdateN: '',
      greexamdateN: '',
      gmatexamdateN: '',
      haveSatScore: false,
      satExamDate: "",
      satWritingScore: "",
      satWritingRank: "",
      satQuantScore: "",
      satQuantRank: "",
      satAverageScore: "",
      satAverageRank: "",
      haveLSATscore: false,
      lsatExamDate: "",
      lsatTotalScore: "",
      lsatTotalRank: "",

      haveMCATscore: false,
      mcatExamDate: "",
      mcatTotalScore: "",
      mcatTotalRank: "",
      mcat_bio_rank: "",
      mcat_bio_score: "",
      mcat_chemical_score: "",
      mcat_chemical_rank: "",
      mcat_psychology_score: "",
      mcat_psychology_rank: "",
      mcat_reading_score: "",
      mcat_reading_rank: "",

      ilets_exam_date: "",
      ilets_total_score: "",
      ilets_listening_score: "",
      ilets_writing_score: "",
      ilets_speaking_score: "",
      ilets_reading_score: "",

      duolingo_exam_date: "",
      duolingo_total_score: "",
      duolingo_comprehension_score: "",
      duolingo_conversation_score: "",
      duolingo_production_score: "",
      duolingo_literacy_score: "",

      toefl_exam_date: "",
      toefl_total_score: "",
      toefl_listening_score: "",
      toefl_writing_score: "",
      toefl_speaking_score: "",
      toefl_reading_score: "",

      uniQuestTextArr: [],
      uniQuestSignleArr: [],
      uniQuestMultiArr: [],
      showCompleteProfileMessage: true,
      edit: true,
      appAnswers: [],
      appQuestions: []
    };
  }

  componentDidMount() {
    // this.getTestScoresData();
    // this.getEnglishExamScore();
    if (this.props.applicationId) {
      this.getTestScoresData();
      // this.getEnglishExamScore();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type != this.props.type) {
      this.getTestScoresData();
      this.getEnglishExamScore();
    }

    if (this.props.applicationId && prevProps.applicationId != this.props.applicationId) {
      this.getTestScoresData();
      // this.getEnglishExamScore();
    }

    if (prevProps.showCompleteProfileMessage != this.props.showCompleteProfileMessage) {
      this.setState({
        showCompleteProfileMessage: this.props.showCompleteProfileMessage
      })
    }
  }

  getQuestionDataTwo = (question) => {
    this.setState({ questiondata2: question })
  }

  setQuestionDataTwo = (index) => {
    this.setState({ selectedQuestion2: index, TestAddMore: true })
  }

  handleGrecheckChange = (event) => {
    this.setState({ greCheck: $("#greCheck").is(":checked") });
  };

  handleGreexamdateChange = (date) => {
    this.setState({ greexamdate: date });
  };

  handleGreverbalscoreChange = (event) => {
    this.setState(
      {
        greverbalscore: event.target.value,
      },
      () => {
        //  this.calculategreaveragescore()
      }
    );
  };

  handleGreverbalrankChange = (event) => {
    this.setState(
      {
        greverbalrank: event.target.value,
      },
      () => {
        //  this.calculategreaveragerank()
      }
    );
  };

  handleGrequanitativescoreChange = (event) => {
    this.setState(
      {
        grequanitativescore: event.target.value,
      },
      () => {
        //  this.calculategreaveragescore()
      }
    );
  };

  handleGrequanitativerankChange = (event) => {
    this.setState(
      {
        grequanitativerank: event.target.value,
      },
      () => {
        this.calculategreaveragerank();
      }
    );
  };

  handleGrewritingscoreChange = (event) => {
    this.setState(
      {
        grewritingscore: event.target.value,
      },
      () => {
        //  this.calculategreaveragescore()
      }
    );
  };

  handleGrewritingrankChange = (event) => {
    this.setState(
      {
        grewritingrank: event.target.value,
      },
      () => {
        //  this.calculategreaveragerank()
      }
    );
  };

  calculategreaveragerank = () => {
    if (this.state.greverbalrank == null) {
      this.state.greverbalrank = 0;
    }
    if (this.state.grequanitativerank == null) {
      this.state.grequanitativerank = 0;
    }
    if (this.state.grewritingrank == null) {
      this.state.grewritingrank = 0;
    }
    if (this.state.grereadingrank == null) {
      this.state.grereadingrank = 0;
    }

    var finaleaverage =
      parseInt(this.state.greverbalrank) +
      parseInt(this.state.grequanitativerank) +
      parseInt(this.state.grewritingrank) +
      parseInt(this.state.grereadingrank);
    var toltalaverage = finaleaverage / 4;

    this.setState({
      gretotalrank: Math.max(0, parseFloat(toltalaverage))
        .toString()
        .slice(0, 6),
    });
  };

  handleGretotalscoreChange = (event) => {
    this.setState(
      {
        gretotalscore: event.target.value,
      }
    );
  };

  handleGretotalrankChange = (event) => {
    this.setState({ gretotalrank: event.target.value });
  };

  handleGmatCheckChange = (event) => {
    this.setState({ gmatCheck: $("#gmatCheck").is(":checked") });
  };

  handleGmatexamdateChange = (date) => {
    this.setState({ gmatexamdate: date });
  };

  handleGmatverbalscoreChange = (event) => {
    this.setState(
      {
        gmatverbalscore: event.target.value,
      },
      () => { }
    );
  };

  handleGmatverbalrankChange = (event) => {
    this.setState(
      {
        gmatverbalrank: event.target.value,
      },
      () => {
        //  this.calculategmataveragerank()
      }
    );
  };

  handleGmatquanitativescoreChange = (event) => {
    this.setState(
      {
        gmatquanitativescore: event.target.value,
      },
      () => {
        //  this.calculategmataveragescore()
      }
    );
  };

  handleGmatreasoningscoreChange = (event) => {
    this.setState(
      {
        gmatreasoningscore: event.target.value,
      },
      () => {
        //  this.calculategmataveragescore()
      }
    );
  };

  handleGmatquanitativerankChange = (event) => {
    this.setState(
      {
        gmatquanitativerank: event.target.value,
      },
      () => {
        //  this.calculategmataveragerank()
      }
    );
  };

  handleGmattotalscoreChange = (event) => {
    this.setState({ gmattotalscore: event.target.value });
  };

  handleGmatreasoningrankChange = (event) => {
    this.setState(
      {
        gmatreasoningrank: event.target.value,
      }
    );
  };

  handleSatScoreAvail = () => {
    this.setState({ haveSatScore: $("#haveSatScore").is(":checked") });
  };

  handleGmattotalrankChange = (event) => {
    this.setState({ gmattotalrank: event.target.value });
  };

  handleMCATscoreAvail = () => {
    this.setState({ haveMCATscore: $("#haveMCATscore").is(":checked") });
  };

  handleLSATscoreChange = (event) => {
    console.log(
      "handleLSATscoreChange",
      event.target.id,
      event.target.name,
      event.target.value
    );
    this.setState({ [event.target.id]: event.target.value });
  };

  handleExamdateChanges = (date, type) => {
    console.log(date, "date")
    this.setState({
      examdate: date
    })
  };

  handleExamDateChange = (date) => {
    const { examtype } = this.state;
    examtype === "toefl"
      ? this.setState({ toefl_exam_date: date })
      : examtype === "ielts"
        ? this.setState({ ilets_exam_date: date })
        : examtype === "duolingo"
          ? this.setState({ duolingo_exam_date: date })
          : examtype === "pte"
            ? this.setState({ pte_exam_date: date })
            : examtype === "oet"
              ? this.setState({ oet_exam_date: date })
              : examtype === "c1 Advanced"
                ? this.setState({ c_exam_date: date })
                : this.setState({ examdate: "" });
  };

  checkGrade = (value, exam_name) => {
    let grade = "";
    if (value) {
      if (exam_name === "oet") {
        if (value >= 0 && value <= 500) {
          return value < 100
            ? (grade = "E")
            : value < 200
              ? (grade = "D")
              : value < 300
                ? (grade = "C+")
                : value < 350
                  ? (grade = "C")
                  : value < 450
                    ? (grade = "B")
                    : "A";
        } else {
          return (grade = "Invalid input");
        }
      } else if (exam_name === "c1 Advanced") {
        if (value >= 0 && value <= 210) {
          return value >= 160 && value <= 179
            ? (grade = "B2")
            : value >= 180 && value <= 192
              ? (grade = "C")
              : value >= 193 && value <= 199
                ? (grade = "B")
                : value >= 200 && value <= 210
                  ? (grade = "A")
                  : "C2";
        } else {
          return (grade = "Invalid input");
        }
      }
    }
    return ""
  };

  calToeflAvgScore = () => {
    const {
      toefl_reading_score,
      toefl_listening_score,
      toefl_writing_score,
      toefl_speaking_score,
    } = this.state;
    let readScore = toefl_reading_score ? parseInt(toefl_reading_score) : 0;
    let listenScore = toefl_listening_score
      ? parseInt(toefl_listening_score)
      : 0;
    let writeScore = toefl_writing_score ? parseInt(toefl_writing_score) : 0;
    let speakScore = toefl_speaking_score ? parseInt(toefl_speaking_score) : 0;

    var finaleaverage = readScore + listenScore + writeScore + speakScore;
    this.setState({
      toefl_total_score: Math.max(0, parseFloat(finaleaverage))
        .toString()
        .slice(0, 6),
    });
  };

  calDuolingoAvgScore = () => {
    const {
      duolingo_literacy_score,
      duolingo_comprehension_score,
      duolingo_conversation_score,
      duolingo_production_score,
    } = this.state;
    let readScore = duolingo_literacy_score ? parseInt(duolingo_literacy_score) : 0;
    let listenScore = duolingo_comprehension_score
      ? parseInt(duolingo_comprehension_score)
      : 0;
    let writeScore = duolingo_conversation_score ? parseInt(duolingo_conversation_score) : 0;
    let speakScore = duolingo_production_score ? parseInt(duolingo_production_score) : 0;

    var finaleaverage = (readScore + listenScore + writeScore + speakScore) / 4;
    this.setState({
      duolingo_total_score: Math.round(finaleaverage * 2) / 2
    });
  };

  calIeltsAvgScore = () => {
    const {
      ilets_reading_score,
      ilets_listening_score,
      ilets_writing_score,
      ilets_speaking_score,
    } = this.state;
    let readScore = ilets_reading_score ? parseInt(ilets_reading_score) : 0;
    let listenScore = ilets_listening_score
      ? parseInt(ilets_listening_score)
      : 0;
    let writeScore = ilets_writing_score ? parseInt(ilets_writing_score) : 0;
    let speakScore = ilets_speaking_score ? parseInt(ilets_speaking_score) : 0;

    var finaleaverage = (readScore + listenScore + writeScore + speakScore) / 4;
    this.setState({
      ilets_total_score: Math.round(finaleaverage * 2) / 2
    });
  };

  handleReadingScoreChange = (e) => {
    const { examtype } = this.state;
    let value = e.target.value;
    let grade =
      examtype === "oet" || examtype === "c1 Advanced"
        ? this.checkGrade(e.target.value, examtype)
        : "";
    examtype === "toefl"
      ? this.setState({ toefl_reading_score: value }, () => {
        this.calToeflAvgScore();
      })
      : examtype === "ielts"
        ? this.setState({ ilets_reading_score: value }, () => {
          this.calIeltsAvgScore();
        })
        : examtype === "duolingo"
          ? this.setState({ duolingo_literacy_score: value }, () => {
            this.calDuolingoAvgScore();
          })
          : examtype === "pte"
            ? this.setState({ pte_reading_score: value })
            : examtype === "oet"
              ? this.setState({ oet_reading_score: value, oet_reading_grade: grade })
              : this.setState(
                { c_reading_score: value, c_reading_grade: grade },
                () => {
                  this.calC1AdvanceAvgScore();
                }
              );
  };

  handleListeningScoreChange = (e) => {
    const { examtype } = this.state;
    let grade =
      examtype === "oet" || examtype === "c1 Advanced"
        ? this.checkGrade(e.target.value, examtype)
        : "";

    examtype === "toefl"
      ? this.setState({ toefl_listening_score: e.target.value }, () => {
        this.calToeflAvgScore();
      })
      : examtype === "ielts"
        ? this.setState({ ilets_listening_score: e.target.value }, () => {
          this.calIeltsAvgScore();
        })
        : examtype === "duolingo"
          ? this.setState({ duolingo_comprehension_score: e.target.value }, () => {
            this.calDuolingoAvgScore();
          })
          : examtype === "pte"
            ? this.setState({ pte_listening_score: e.target.value })
            : examtype === "oet"
              ? this.setState({
                oet_listening_score: e.target.value,
                oet_listening_grade: grade,
              })
              : this.setState(
                { c_listening_score: e.target.value, c_listening_grade: grade },
                () => {
                  this.calC1AdvanceAvgScore();
                }
              );
  };

  handleWritingScoreChange = (e) => {
    const { examtype } = this.state;
    let grade =
      examtype === "oet" || examtype === "c1 Advanced"
        ? this.checkGrade(e.target.value, examtype)
        : "";
    examtype === "toefl"
      ? this.setState({ toefl_writing_score: e.target.value }, () => {
        this.calToeflAvgScore();
      })
      : examtype === "ielts"
        ? this.setState({ ilets_writing_score: e.target.value }, () => {
          this.calIeltsAvgScore();
        })
        : examtype === "duolingo"
          ? this.setState({ duolingo_conversation_score: e.target.value }, () => {
            this.calDuolingoAvgScore();
          })
          : examtype === "pte"
            ? this.setState({ pte_writing_score: e.target.value })
            : examtype === "oet"
              ? this.setState({
                oet_writing_score: e.target.value,
                oet_writing_grade: grade,
              })
              : this.setState(
                { c_writing_score: e.target.value, c_writing_grade: grade },
                () => {
                  this.calC1AdvanceAvgScore();
                }
              );
  };

  handleSpeakingScoreChange = (e) => {
    const { examtype } = this.state;
    let grade =
      examtype === "oet" || examtype === "c1 Advanced"
        ? this.checkGrade(e.target.value, examtype)
        : "";

    examtype === "toefl"
      ? this.setState({ toefl_speaking_score: e.target.value }, () => {
        this.calToeflAvgScore();
      })
      : examtype === "ielts"
        ? this.setState({ ilets_speaking_score: e.target.value }, () => {
          this.calIeltsAvgScore();
        })
        : examtype === "duolingo"
          ? this.setState({ duolingo_production_score: e.target.value }, () => {
            this.calDuolingoAvgScore();
          })
          : examtype === "pte"
            ? this.setState({ pte_speaking_score: e.target.value })
            : examtype === "oet"
              ? this.setState({
                oet_speaking_score: e.target.value,
                oet_speaking_grade: grade,
              })
              : this.setState(
                { c_speaking_score: e.target.value, c_speaking_grade: grade },
                () => {
                  this.calC1AdvanceAvgScore();
                }
              );
  };


  handleUseOfEngChange = (e) => {
    const { examtype } = this.state;
    let grade = this.checkGrade(e.target.value, examtype);
    this.setState(
      { c_use_english: e.target.value, c_use_english_grade: grade },
      () => {
        this.calC1AdvanceAvgScore();
      }
    );
  };

  calC1AdvanceAvgScore = () => {
    const {
      c_reading_score,
      c_listening_score,
      c_writing_score,
      c_speaking_score,
      c_use_english,
    } = this.state;
    let readScore = c_reading_score ? parseInt(c_reading_score) : 0;
    let listenScore = c_listening_score ? parseInt(c_listening_score) : 0;
    let writeScore = c_writing_score ? parseInt(c_writing_score) : 0;
    let speakScore = c_speaking_score ? parseInt(c_speaking_score) : 0;
    let useOfEnglish = c_use_english ? parseInt(c_use_english) : 0;

    var finaleaverage =
      (readScore + listenScore + writeScore + speakScore + useOfEnglish) / 5;
    this.setState({
      c_avg_score: Math.max(0, parseFloat(finaleaverage))
        .toString()
        .slice(0, 6),
    });
  };

  calculategreaveragescore = () => {
    if (this.state.greverbalscore == null) {
      this.state.greverbalscore = 0;
    }
    if (this.state.grequanitativescore == null) {
      this.state.grequanitativescore = 0;
    }
    if (this.state.grewritingscore == null) {
      this.state.grewritingscore = 0;
    }
    if (this.state.grereadingscore == null) {
      this.state.grereadingscore = 0;
    }
    var finaleaverage =
      parseInt(this.state.greverbalscore) +
      parseInt(this.state.grequanitativescore) +
      parseInt(this.state.grewritingscore) +
      parseInt(this.state.grereadingscore);
    var toltalaverage = finaleaverage / 4;
    this.setState({
      gretotalscore: Math.max(0, parseFloat(toltalaverage))
        .toString()
        .slice(0, 6),
    });
  };

  getEnglishExamScore = () => {
    this.props.showLoader();
    axios
      .get(
        baseurl + "/get_englishtestscores/" + localStorage.getItem("studentid")
      )
      .then((response) => {
        this.props.hideLoader();
        let engExamRes = response.data[0];
        if (response && response.data && response.data.length > "0") {
          let cExamDate =
            engExamRes.c_exam_date &&
              engExamRes.c_exam_date !== this.datePattern
              ? new Date(engExamRes.c_exam_date)
              : "";
          let iletsExamDate =
            engExamRes.ilets_exam_date &&
              engExamRes.ilets_exam_date !== this.datePattern
              ? new Date(engExamRes.ilets_exam_date)
              : "";
          let duolingoExamDate =
            engExamRes.duolingo_exam_date &&
              engExamRes.duolingo_exam_date !== this.datePattern
              ? new Date(engExamRes.duolingo_exam_date)
              : "";
          let oetExamDate =
            engExamRes.oet_exam_date &&
              engExamRes.oet_exam_date !== this.datePattern
              ? new Date(engExamRes.oet_exam_date)
              : "";
          let pteExamDate =
            engExamRes.pte_exam_date &&
              engExamRes.pte_exam_date !== this.datePattern
              ? new Date(engExamRes.pte_exam_date)
              : "";
          let toeflExamDate =
            engExamRes.toefl_exam_date &&
              engExamRes.toefl_exam_date !== this.datePattern
              ? new Date(engExamRes.toefl_exam_date)
              : "";

          // Calculate Grades
          let cReadGrade = engExamRes.c_reading_score
            ? this.checkGrade(engExamRes.c_reading_score, "c1 Advanced")
            : "";
          let cWriteGrade = engExamRes.c_writing_score
            ? this.checkGrade(engExamRes.c_writing_score, "c1 Advanced")
            : "";
          let cSpeakGrade = engExamRes.c_speaking_score
            ? this.checkGrade(engExamRes.c_speaking_score, "c1 Advanced")
            : "";
          let cListenGrade = engExamRes.c_listening_score
            ? this.checkGrade(engExamRes.c_listening_score, "c1 Advanced")
            : "";
          let cUseEngGrade = engExamRes.c_use_english
            ? this.checkGrade(engExamRes.c_use_english, "c1 Advanced")
            : "";
          let oetListenGrade = engExamRes.oet_listening_score
            ? this.checkGrade(engExamRes.oet_listening_score, "oet")
            : "";
          let oetReadGrade = engExamRes.oet_reading_score
            ? this.checkGrade(engExamRes.oet_reading_score, "oet")
            : "";
          let oetSpeakGrade = engExamRes.oet_speaking_score
            ? this.checkGrade(engExamRes.oet_speaking_score, "oet")
            : "";
          let oetWriteGrade = engExamRes.oet_writing_score
            ? this.checkGrade(engExamRes.oet_writing_score, "oet")
            : "";

          this.setState({
            examtype: engExamRes.exam_type && engExamRes.exam_type != "null" ? engExamRes.exam_type : "",
            c_exam_date: cExamDate,
            c_listening_score: engExamRes.c_listening_score
              ? engExamRes.c_listening_score
              : "",
            c_reading_score: engExamRes.c_reading_score
              ? engExamRes.c_reading_score
              : "",
            c_speaking_score: engExamRes.c_speaking_score
              ? engExamRes.c_speaking_score
              : "",
            c_use_english: engExamRes.c_use_english
              ? engExamRes.c_use_english
              : "",
            c_writing_score: engExamRes.c_writing_score
              ? engExamRes.c_writing_score
              : "",
            // C1 Advance Grades
            c_reading_grade: cReadGrade,
            c_listening_grade: cListenGrade,
            c_writing_grade: cWriteGrade,
            c_speaking_grade: cSpeakGrade,
            c_use_english_grade: cUseEngGrade,
            ilets_exam_date: iletsExamDate,
            ilets_listening_score: engExamRes.ilets_listening_score
              ? engExamRes.ilets_listening_score
              : "",
            ilets_reading_score: engExamRes.ilets_reading_score
              ? engExamRes.ilets_reading_score
              : "",
            ilets_speaking_score: engExamRes.ilets_speaking_score
              ? engExamRes.ilets_speaking_score
              : "",
            ilets_total_score: engExamRes.ilets_total_score
              ? engExamRes.ilets_total_score
              : "",
            ilets_writing_score: engExamRes.ilets_writing_score
              ? engExamRes.ilets_writing_score
              : "",

            duolingo_exam_date: duolingoExamDate,
            duolingo_comprehension_score: engExamRes.duolingo_comprehension_score
              ? engExamRes.duolingo_comprehension_score
              : "",
            duolingo_literacy_score: engExamRes.duolingo_literacy_score
              ? engExamRes.duolingo_literacy_score
              : "",
            duolingo_production_score: engExamRes.duolingo_production_score
              ? engExamRes.duolingo_production_score
              : "",
            duolingo_total_score: engExamRes.duolingo_total_score
              ? engExamRes.duolingo_total_score
              : "",
            duolingo_conversation_score: engExamRes.duolingo_conversation_score
              ? engExamRes.duolingo_conversation_score
              : "",
            oet_exam_date: oetExamDate, //: "0000-00-00"
            oet_listening_score: engExamRes.oet_listening_score
              ? engExamRes.oet_listening_score
              : "", //: "245"
            oet_reading_score: engExamRes.oet_reading_score
              ? engExamRes.oet_reading_score
              : "", //: "145"
            oet_speaking_score: engExamRes.oet_speaking_score
              ? engExamRes.oet_speaking_score
              : "", //: "445"
            oet_writing_score: engExamRes.oet_writing_score
              ? engExamRes.oet_writing_score
              : "", //: "345"
            // OET Grades
            oet_reading_grade: oetReadGrade,
            oet_listening_grade: oetListenGrade,
            oet_writing_grade: oetWriteGrade,
            oet_speaking_grade: oetSpeakGrade,

            pte_exam_date: pteExamDate,
            pte_total_score: engExamRes.pte_total_score
              ? engExamRes.pte_total_score
              : "",
            pte_grammer_rank: engExamRes.pte_grammer_rank
              ? engExamRes.pte_grammer_rank
              : "",
            pte_grammer_score: engExamRes.pte_grammer_score
              ? engExamRes.pte_grammer_score
              : "",
            pte_listening_rank: engExamRes.pte_listening_rank
              ? engExamRes.pte_listening_rank
              : "",
            pte_listening_score: engExamRes.pte_listening_score
              ? engExamRes.pte_listening_score
              : "",
            pte_oral_rank: engExamRes.pte_oral_rank
              ? engExamRes.pte_oral_rank
              : "",
            pte_oral_score: engExamRes.pte_oral_score
              ? engExamRes.pte_oral_score
              : "",
            pte_pron_rank: engExamRes.pte_pron_rank
              ? engExamRes.pte_pron_rank
              : "",
            pte_pron_score: engExamRes.pte_pron_score
              ? engExamRes.pte_pron_score
              : "",
            pte_reading_rank: engExamRes.pte_reading_rank
              ? engExamRes.pte_reading_rank
              : "",
            pte_reading_score: engExamRes.pte_reading_score
              ? engExamRes.pte_reading_score
              : "",
            pte_speaking_rank: engExamRes.pte_speaking_rank
              ? engExamRes.pte_speaking_rank
              : "",
            pte_speaking_score: engExamRes.pte_speaking_score
              ? engExamRes.pte_speaking_score
              : "",
            pte_spelling_rank: engExamRes.pte_spelling_rank
              ? engExamRes.pte_spelling_rank
              : "",
            pte_spelling_score: engExamRes.pte_spelling_score
              ? engExamRes.pte_spelling_score
              : "",
            pte_vocab_rank: engExamRes.pte_vocab_rank
              ? engExamRes.pte_vocab_rank
              : "",
            pte_vocab_score: engExamRes.pte_vocab_score
              ? engExamRes.pte_vocab_score
              : "",
            pte_writing_rank: engExamRes.pte_writing_rank
              ? engExamRes.pte_writing_rank
              : "",
            pte_writing_score: engExamRes.pte_writing_score
              ? engExamRes.pte_writing_score
              : "",
            pte_written_rank: engExamRes.pte_written_rank
              ? engExamRes.pte_written_rank
              : "",
            pte_written_score: engExamRes.pte_written_score
              ? engExamRes.pte_written_score
              : "",
            toefl_exam_date: toeflExamDate,
            toefl_listening_score: engExamRes.toefl_listening_score
              ? engExamRes.toefl_listening_score
              : "",
            toefl_reading_score: engExamRes.toefl_reading_score
              ? engExamRes.toefl_reading_score
              : "",
            toefl_speaking_score: engExamRes.toefl_speaking_score
              ? engExamRes.toefl_speaking_score
              : "",
            toefl_total_score: engExamRes.toefl_total_score
              ? engExamRes.toefl_total_score
              : "",
            toefl_writing_score: engExamRes.toefl_writing_score
              ? engExamRes.toefl_writing_score
              : "",
          });
        }
      })
      .catch((error) => {
        this.props.hideLoader();
      });
  };


  getTestScoresData = async () => {
    try {
      this.props.showLoader();

      let testScoresRes = null;

      if (this.props.type == "program") {
        testScoresRes = await axios.get(baseurl + '/get_testscores/' + localStorage.getItem('studentid'));
        let newTest = testScoresRes
        newTest.data.data = testScoresRes.data

        testScoresRes = newTest
      } else {
        testScoresRes = await axios.get(baseurl + '/get_testscores_app/' + localStorage.getItem('studentid'), {
          params: {
            application_id: this.props.applicationId
          }
        });
      }

      if (testScoresRes && testScoresRes.data.question && testScoresRes.data.question.length) {
        let appQuestions = testScoresRes.data.question
        let appAnswers = appQuestions.filter(question => question.answers).map(question => ({
          question_id: question.question_details.id,
          answer: question.answers
        }))

        this.setState({
          appQuestions,
          appAnswers
        })
      }

      if (testScoresRes.data.data && testScoresRes.data.data.length && testScoresRes.data.data.length > 0) {
        if (
          testScoresRes.data.data[0].exam_date != null &&
          testScoresRes.data.data[0].exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].exam_date)) == false
        ) {
          var examdateN = new Date(testScoresRes.data.data[0].exam_date);
        }
        if (
          testScoresRes.data.data[0].gre_exam_date != null &&
          testScoresRes.data.data[0].gre_exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].gre_exam_date)) == false
        ) {
          var greexamdateN = new Date(testScoresRes.data.data[0].gre_exam_date);
        }
        if (
          testScoresRes.data.data[0].gmat_exam_date != null &&
          testScoresRes.data.data[0].gmat_exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].gmat_exam_date)) == false
        ) {
          var gmatexamdateN = new Date(testScoresRes.data.data[0].gmat_exam_date);
        }
        if (
          testScoresRes.data.data[0].lsat_exam_date != null &&
          testScoresRes.data.data[0].lsat_exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].lsat_exam_date)) == false
        ) {
          var lsatExamDateN = new Date(testScoresRes.data.data[0].lsat_exam_date);
        }
        if (
          testScoresRes.data.data[0].sat_exam_date != null &&
          testScoresRes.data.data[0].sat_exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].sat_exam_date)) == false
        ) {
          var satExamDateN = new Date(testScoresRes.data.data[0].sat_exam_date);
        }
        if (
          testScoresRes.data.data[0].mcat_exam_date != null &&
          testScoresRes.data.data[0].mcat_exam_date !== this.datePattern
          && isNaN(Date.parse(testScoresRes.data.data[0].mcat_exam_date)) == false
        ) {
          var mcatExamDateN = new Date(testScoresRes.data.data[0].mcat_exam_date);
        }

        console.log('log greexamdateN', greexamdateN);

        let updateObj = {
          examtype: testScoresRes.data.data[0].exam_type && testScoresRes.data.data[0].exam_type != "null" ? testScoresRes.data.data[0].exam_type : "",
          englishCheck: testScoresRes.data.data[0].notestscores,
          c_use_english: testScoresRes.data.data[0].use_english,
          examdate: examdateN,
          greexamdate: greexamdateN,
          greverbalscore: testScoresRes.data.data[0].gre_verbal_score,
          greverbalrank: testScoresRes.data.data[0].gre_verbal_rank,
          grequanitativescore: testScoresRes.data.data[0].gre_quanitative_score,
          grequanitativerank: testScoresRes.data.data[0].gre_quanitative_rank,
          grewritingscore: testScoresRes.data.data[0].gre_writing_score,
          grewritingrank: testScoresRes.data.data[0].gre_writing_rank,
          greCheck: testScoresRes.data.data[0].nogrescores,

          gmatexamdate: gmatexamdateN,
          gmatverbalscore: testScoresRes.data.data[0].gmat_verbal_score,
          gmatverbalrank: testScoresRes.data.data[0].gmat_verbal_rank,
          gmatquanitativescore: testScoresRes.data.data[0].gmat_quanitative_score,
          gmatquanitativerank: testScoresRes.data.data[0].gmat_quanitative_rank,
          gmatwritingscore: testScoresRes.data.data[0].gmat_writing_score,
          gmatwritingrank: testScoresRes.data.data[0].gmat_writing_rank,
          gmattotalscore: testScoresRes.data.data[0].gmat_total_score,
          gmattotalrank: testScoresRes.data.data[0].gmat_total_rank,
          gmatCheck: testScoresRes.data.data[0].nogmatscores,

          gretotalscore: testScoresRes.data.data[0].gre_total_score
            ? testScoresRes.data.data[0].gre_total_score
            : "",
          gretotalrank: testScoresRes.data.data[0].gre_total_rank
            ? testScoresRes.data.data[0].gre_total_rank
            : "",

          gmatreasoningscore: testScoresRes.data.data[0].gmat_reading_score
            ? testScoresRes.data.data[0].gmat_reading_score
            : "",
          gmatreasoningrank: testScoresRes.data.data[0].gmat_reading_rank
            ? testScoresRes.data.data[0].gmat_reading_rank
            : "",

          haveSatScore: testScoresRes.data.data[0].nosatscores,
          satExamDate: satExamDateN,

          satWritingScore: testScoresRes.data.data[0].sat_writing_score
            ? testScoresRes.data.data[0].sat_writing_score
            : "",

          satWritingRank: testScoresRes.data.data[0].sat_writing_rank
            ? testScoresRes.data.data[0].sat_writing_rank
            : "",

          satQuantScore: testScoresRes.data.data[0].sat_quanitative_score
            ? testScoresRes.data.data[0].sat_quanitative_score
            : "",

          satQuantRank: testScoresRes.data.data[0].sat_quanitative_rank
            ? testScoresRes.data.data[0].sat_quanitative_rank
            : "",

          satAverageScore: testScoresRes.data.data[0].sat_total_score
            ? testScoresRes.data.data[0].sat_total_score
            : "",
          satAverageRank: testScoresRes.data.data[0].sat_total_rank
            ? testScoresRes.data.data[0].sat_total_rank
            : "",

          haveLSATscore: testScoresRes.data.data[0].nolsatscores && testScoresRes.data.data[0].nolsatscores,
          lsatExamDate: lsatExamDateN,
          lsatTotalScore: testScoresRes.data.data[0].lsat_verbal_score
            ? testScoresRes.data.data[0].lsat_verbal_score
            : "",
          lsatTotalRank: testScoresRes.data.data[0].lsat_verbal_rank
            ? testScoresRes.data.data[0].lsat_verbal_rank
            : "",

          haveMCATscore: testScoresRes.data.data[0].nolmcatscores && testScoresRes.data.data[0].nolmcatscores,
          mcatExamDate: mcatExamDateN,
          mcatTotalScore: testScoresRes.data.data[0].mcat_total_score
            ? testScoresRes.data.data[0].mcat_total_score
            : "",
          mcatTotalRank: testScoresRes.data.data[0].mcat_total_rank
            ? testScoresRes.data.data[0].mcat_total_rank
            : "",
          mcat_bio_rank: testScoresRes.data.data[0].mcat_bio_rank ? testScoresRes.data.data[0].mcat_bio_rank : "",
          mcat_bio_score: testScoresRes.data.data[0].mcat_bio_score
            ? testScoresRes.data.data[0].mcat_bio_score
            : "",
          mcat_chemical_score: testScoresRes.data.data[0].mcat_chemical_score
            ? testScoresRes.data.data[0].mcat_chemical_score
            : "",
          mcat_chemical_rank: testScoresRes.data.data[0].mcat_chemical_rank
            ? testScoresRes.data.data[0].mcat_chemical_rank
            : "",
          mcat_psychology_score: testScoresRes.data.data[0].mcat_psychology_score
            ? testScoresRes.data.data[0].mcat_psychology_score
            : "",
          mcat_psychology_rank: testScoresRes.data.data[0].mcat_psychology_rank
            ? testScoresRes.data.data[0].mcat_psychology_rank
            : "",
          mcat_reading_score: testScoresRes.data.data[0].mcat_reading_score
            ? testScoresRes.data.data[0].mcat_reading_score
            : "",
          mcat_reading_rank: testScoresRes.data.data[0].mcat_reading_rank
            ? testScoresRes.data.data[0].mcat_reading_rank
            : "",
        }

        if (updateObj && updateObj.examtype) {
          let readingscore = testScoresRes.data.data[0].reading_score
          let listeningscore = testScoresRes.data.data[0].listening_score
          let writingscore = testScoresRes.data.data[0].writing_score
          let speakingscore = testScoresRes.data.data[0].speaking_score
          let avg_score = testScoresRes.data.data[0].average_score

          let readingrank = testScoresRes.data.data[0].reading_rank
          let listeningrank = testScoresRes.data.data[0].listening_rank
          let writingrank = testScoresRes.data.data[0].writing_rank
          let speakingrank = testScoresRes.data.data[0].speaking_rank

          switch (updateObj.examtype) {
            case "toefl":
              updateObj.toefl_reading_score = readingscore
              updateObj.toefl_listening_score = listeningscore
              updateObj.toefl_writing_score = writingscore
              updateObj.toefl_speaking_score = speakingscore
              updateObj.toefl_total_score = avg_score

              break;
            case "ielts":
              updateObj.ilets_reading_score = readingscore
              updateObj.ilets_listening_score = listeningscore
              updateObj.ilets_writing_score = writingscore
              updateObj.ilets_speaking_score = speakingscore
              updateObj.ilets_total_score = avg_score

              break;
            case "duolingo":
              updateObj.duolingo_literacy_score = readingscore
              updateObj.duolingo_comprehension_score = listeningscore
              updateObj.duolingo_conversation_score = writingscore
              updateObj.duolingo_production_score = speakingscore
              updateObj.duolingo_total_score = avg_score

              break;
            case "pte":
              updateObj.pte_reading_score = readingscore
              updateObj.pte_listening_score = listeningscore
              updateObj.pte_writing_score = writingscore
              updateObj.pte_speaking_score = speakingscore
              updateObj.pte_total_score = avg_score

              updateObj.pte_reading_rank = readingrank
              updateObj.pte_listening_rank = listeningrank
              updateObj.pte_speaking_rank = speakingrank
              updateObj.pte_writing_rank = writingrank

              break;
            case "oet":
              updateObj.oet_reading_score = readingscore
              updateObj.oet_listening_score = listeningscore
              updateObj.oet_writing_score = writingscore
              updateObj.oet_speaking_score = speakingscore
              // updateObj.oet_total_score = avg_score

              updateObj.oet_reading_grade = readingrank
              updateObj.oet_listening_grade = listeningrank
              updateObj.oet_speaking_grade = speakingrank
              updateObj.oet_writing_grade = writingrank

              break;
            case "c1 Advanced":
              updateObj.c_reading_score = readingscore
              updateObj.c_listening_score = listeningscore
              updateObj.c_writing_score = writingscore
              updateObj.c_speaking_score = speakingscore
              updateObj.c_use_english_grade = avg_score

              updateObj.c_reading_grade = readingrank
              updateObj.c_listening_grade = listeningrank
              updateObj.c_speaking_grade = speakingrank
              updateObj.c_writing_grade = writingrank

              break;
          }
        }

        this.setState(updateObj, () => this.props.hideLoader());

        if (testScoresRes.data.data[0].notestscores) {
          $("#englishCheck").prop("checked", true);
        }
        if (testScoresRes.data.data[0].nogrescores) {
          $("#greCheck").prop("checked", true);
        }
        if (testScoresRes.data.data[0].nogmatscores) {
          $("#gmatCheck").prop("checked", true);
        }

        if (testScoresRes.data.data[0].nosatscores) {
          $("#haveSatScore").prop("checked", true);
        }

        if (testScoresRes.data.data[0].nolsatscores) {
          $("#haveLSATscore").prop("checked", true);
        }

        if (testScoresRes.data.data[0].nolmcatscores) {
          $("#haveMCATscore").prop("checked", true);
        }

      } else {
        this.props.hideLoader();
      }
    } catch (e) {
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    }
  }

  handleTargetChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  }

  handleCheckChange = (event) => {
    this.setState({ [event.target.name]: $(`#${event.target.name}`).is(":checked") });
  };

  handleGmatwritingscoreChange = (event) => {
    this.setState(
      {
        gmatwritingscore: event.target.value,
      },
      () => {
        //  this.calculategmataveragescore()
      }
    );
  };

  handleGmatwritingrankChange = (event) => {
    this.setState(
      {
        gmatwritingrank: event.target.value,
      },
      () => {
        //  this.calculategmataveragerank()
      }
    );
  };

  handleSATScoreChange = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () => {
      this.calSatTotalScore();
    });
  };

  handleLSATscoreAvail = () => {
    this.setState({ haveLSATscore: $("#haveLSATscore").is(":checked") });
  };

  calSatTotalScore = () => {
    const { satWritingScore, satQuantScore } = this.state;
    let writeScore = satWritingScore ? parseInt(satWritingScore) : 0;
    let quantScore = satQuantScore ? parseInt(satQuantScore) : 0;

    let totalScore = writeScore + quantScore;
    this.setState({
      satAverageScore: Math.max(0, parseFloat(totalScore))
        .toString()
        .slice(0, 6),
    });
  };


  validate = () => {
    const errors = {};
    if (!$("#englishCheck").is(":checked")) {
      //alert(1);
      if ($("#examtype").val() === '') {
        errors.examtype = MESSAGES.PLEASE_SELECT_MESSAGE;
      }
      if ($("#examdate").val() === '') {
        errors.examdate = MESSAGES.PLEASE_SELECT_MESSAGE;
      }
      if ($("#readingscore").val() === '') {
        errors.readingscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#listeningscore").val() === '') {
        errors.listeningscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#writingscore").val() === '') {
        errors.writingscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#speakingscore").val() === '') {
        errors.speakingscore = MESSAGES.THIS_IS_REQUIRED;
      }
    }

    if (!$("#greCheck").is(":checked")) {
      //alert(2);
      if ($("#greexamdate").val() === '') {
        errors.greexamdate = MESSAGES.PLEASE_SELECT_MESSAGE;
      }
      if ($("#greverbalscore").val() === '') {
        errors.greverbalscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#greverbalrank").val() === '') {
        errors.greverbalscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#grequanitativescore").val() === '') {
        errors.grequanitativescore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#grequanitativerank").val() === '') {
        errors.grequanitativescore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#grewritingscore").val() === '') {
        errors.grewritingscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#grewritingrank").val() === '') {
        errors.grewritingscore = MESSAGES.THIS_IS_REQUIRED;
      }

      if (
        ($("#gretotalscore").val() && $("#gretotalscore").val() > 340) ||
        ($("#gretotalscore").val() && $("#gretotalscore").val() < 260)
      ) {
        errors.gretotalscore = "Enter total scores between 260 to 340";
      }
      if (
        $("#gretotalscore").val() &&
        $("#gretotalscore").val() === ""
      ) {
        errors.gretotalscore = "This is required";
      }
    }

    if (!$("#gmatCheck").is(":checked")) {
      //alert(3);
      if ($("#gmatexamdate").val() === '') {
        errors.gmatexamdate = MESSAGES.PLEASE_SELECT_MESSAGE;
      }
      if ($("#gmatverbalscore").val() === '') {
        errors.gmatverbalscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#gmatverbalrank").val() === '') {
        errors.gmatverbalscore = MESSAGES.THIS_IS_REQUIRED;
      }

      if ($("#gmatquanitativescore").val() === '') {
        errors.gmatquanitativescore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#gmatquanitativerank").val() === '') {
        errors.gmatquanitativescore = MESSAGES.THIS_IS_REQUIRED;
      }

      if ($("#gmatwritingscore").val() === '') {
        errors.gmatwritingscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#gmatwritingrank").val() === '') {
        errors.gmatwritingscore = MESSAGES.THIS_IS_REQUIRED;
      }

      if ($("#gmattotalscore").val() === '') {
        errors.gmattotalscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#gmattotalrank").val() === '') {
        errors.gmattotalscore = MESSAGES.THIS_IS_REQUIRED;
      }
      if ($("#gmatreasoningscore").val() === "") {
        errors.gmatreasoningscore = "Reasoning score is required";
      }

      if (
        ($("#gmatreasoningscore").val() &&
          $("#gmatreasoningscore").val() > 8) ||
        ($("#gmatreasoningscore").val() &&
          $("#gmatreasoningscore").val() < 1)
      ) {
        errors.gmatreasoningscore = "Enter reasoning scores between 1 to 8";
      }

      if ($("#gmatreasoningrank").val() === "") {
        errors.gmatreasoningrank = "Reasoning rank is required";
      }

      if (
        ($("#gmatreasoningrank").val &&
          $("#gmatreasoningrank").val() > 99) ||
        ($("#gmatreasoningrank").val &&
          $("#gmatreasoningrank").val() < 0)
      ) {
        errors.gmatreasoningrank = "Enter reasoning rank between 0 to 99";
      }
    }

    if ($("#haveSatScore").is(":checked")) {
      if ($("#satExamDate").val() === "") {
        errors.satExamDate = "Please select exam date";
      }

      if ($("#satQuantScore").val() === "") {
        errors.satQuantScore = "This is required";
      }
      if (
        ($("#satQuantScore").val() && $("#satQuantScore").val() > 800) ||
        ($("#satQuantScore").val() && $("#satQuantScore").val() < 200)
      ) {
        errors.satQuantScore = "Enter quanitative scores between 200 to 800";
      }
      if ($("#satQuantRank").val() === "") {
        errors.satQuantRank = "This is required";
      }
      if (
        ($("#satQuantRank").val() && $("#satQuantRank").val() > 99) ||
        ($("#satQuantRank").val() && $("#satQuantRank").val() < 0)
      ) {
        errors.satQuantRank = "Enter quanitative rank between 0 to 99";
      }

      if ($("#satWritingScore").val() === "") {
        errors.satWritingScore = "This is required";
      }
      if (
        ($("#satWritingScore").val() &&
          $("#satWritingScore").val() > 800) ||
        ($("#satWritingScore").val() &&
          $("#satWritingScore").val() < 200)
      ) {
        errors.satWritingScore = "Enter  writing scores between 200 to 800";
      }
      if ($("#satWritingRank").val() === "") {
        errors.satWritingRank = "This is required";
      }
      if (
        ($("#satWritingRank").val() &&
          $("#satWritingRank").val() > 99) ||
        ($("#satWritingRank").val() && $("#satWritingRank").val() < 0)
      ) {
        errors.satWritingRank = "Enter writing rank between 0 to 99";
      }

      if ($("#satAverageScore").val() === "") {
        errors.satAverageScore = "This is required";
      }
      if (
        ($("#satAverageScore").val() &&
          $("#satAverageScore").val() > 1600) ||
        ($("#satAverageScore").val() &&
          $("#satAverageScore").val() < 400)
      ) {
        errors.satAverageScore = "Enter total score between 400 to 1600";
      }
      if ($("#satAverageRank").val() === "") {
        errors.satAverageRank = "This is required";
      }
      if (
        ($("#satAverageRank").val() &&
          $("#satAverageRank").val() > 99) ||
        ($("#satAverageRank").val() && $("#satAverageRank").val() < 0)
      ) {
        errors.satAverageRank = "Enter total rank between 0 to 99";
      }
    }

    if ($("#haveLSATscore").is(":checked")) {
      if ($("#lsatExamDate").val() === "") {
        errors.lsatExamDate = "Please select exam date";
      }

      if ($("#lsatTotalScore").val() === "") {
        errors.lsatTotalScore = "This is required";
      }
      if (
        ($("#lsatTotalScore").val() &&
          $("#lsatTotalScore").val() > 180) ||
        ($("#lsatTotalScore").val() && $("#lsatTotalScore").val() < 120)
      ) {
        errors.lsatTotalScore = "Enter total score between 120 to 180";
      }
      if ($("#lsatTotalRank").val() === "") {
        errors.lsatTotalRank = "This is required";
      }
      if (
        ($("#lsatTotalRank").val() && $("#lsatTotalRank").val() > 99) ||
        ($("#lsatTotalRank").val() && $("#lsatTotalRank").val() < 0)
      ) {
        errors.lsatTotalRank = "Enter total rank between 0 to 99";
      }
    }

    if ($("#haveMCATscore").is(":checked")) {
      if ($("#mcatExamDate").val() === "") {
        errors.mcatExamDate = "Please select exam date";
      }

      if ($("#mcatTotalScore").val() === "") {
        errors.mcatTotalScore = "Score is required";
      }
      if (
        ($("#mcatTotalScore").val() &&
          $("#mcatTotalScore").val() < 472) ||
        ($("#mcatTotalScore").val() && $("#mcatTotalScore").val() > 528)
      ) {
        errors.mcatTotalScore = "Enter total score between 472 to 528";
      }
      if ($("#mcatTotalRank").val() === "") {
        errors.mcatTotalRank = "Rank is required";
      }
      if (
        ($("#mcatTotalRank").val() && $("#mcatTotalRank").val() > 99) ||
        ($("#mcatTotalRank").val() && $("#mcatTotalRank").val() < 0)
      ) {
        errors.mcatTotalRank = "Enter total rank between 0 to 99";
      }

      // Biological and Biochemical Foundations of Living Systems
      if ($("#mcat_bio_score").val() === "") {
        errors.mcat_bio_score = "Score is required";
      }
      if (
        ($("#mcat_bio_score").val() &&
          $("#mcat_bio_score").val() < 118) ||
        ($("#mcat_bio_score").val() && $("#mcat_bio_score").val() > 132)
      ) {
        errors.mcat_bio_score = "Enter total score between 118 to 132";
      }
      if ($("#mcat_bio_rank").val() === "") {
        errors.mcat_bio_rank = "Rank is required";
      }
      if (
        ($("#mcat_bio_rank").val() && $("#mcat_bio_rank").val() < 0) ||
        ($("#mcat_bio_rank").val() && $("#mcat_bio_rank").val() > 99)
      ) {
        errors.mcat_bio_rank = "Enter total rank between 0 to 99";
      }

      // Chemical and Physical Foundations of Biological Systems
      if ($("#mcat_chemical_score").val() === "") {
        errors.mcat_chemical_score = "Score is required";
      }
      if (
        ($("#mcat_chemical_score").val() &&
          $("#mcat_chemical_score").val() < 118) ||
        ($("#mcat_chemical_score").val() &&
          $("#mcat_chemical_score").val() > 132)
      ) {
        errors.mcat_chemical_score = "Enter total score between 118 to 132";
      }
      if ($("#mcat_chemical_rank").val() === "") {
        errors.mcat_chemical_rank = "Rank is required";
      }
      if (
        ($("#mcat_chemical_rank").val() &&
          $("#mcat_chemical_rank").val() < 0) ||
        ($("#mcat_chemical_rank").val() &&
          $("#mcat_chemical_rank").val() > 99)
      ) {
        errors.mcat_chemical_rank = "Enter total rank between 0 to 99";
      }

      // Psychological, Social, and Biological Foundations of Behaviour
      if ($("#mcat_psychology_score").val() === "") {
        errors.mcat_psychology_score = "Score is required";
      }
      if (
        ($("#mcat_psychology_score").val() &&
          $("#mcat_psychology_score").val() < 118) ||
        ($("#mcat_psychology_score").val() &&
          $("#mcat_psychology_score").val() > 132)
      ) {
        errors.mcat_psychology_score = "Enter total score between 118 to 132";
      }
      if ($("#mcat_psychology_rank").val() === "") {
        errors.mcat_psychology_rank = "Rank is required";
      }
      if (
        ($("#mcat_psychology_rank").val() &&
          $("#mcat_psychology_rank").val() < 0) ||
        ($("#mcat_psychology_rank").val() &&
          $("#mcat_psychology_rank").val() > 99)
      ) {
        errors.mcat_psychology_rank = "Enter total rank between 0 to 99";
      }

      // Critical Analysis and Reasoning Skills
      if ($("#mcat_reading_score").val() === "") {
        errors.mcat_reading_score = "Score is required";
      }
      if (
        ($("#mcat_reading_score").val() &&
          $("#mcat_reading_score").val() < 118) ||
        ($("#mcat_reading_score").val() &&
          $("#mcat_reading_score").val() > 132)
      ) {
        errors.mcat_reading_score = "Enter total score between 118 to 132";
      }
      if ($("#mcat_reading_rank").val() === "") {
        errors.mcat_reading_rank = "Rank is required";
      }
      if (
        ($("#mcat_reading_rank").val() &&
          $("#mcat_reading_rank").val() < 0) ||
        ($("#mcat_reading_rank").val() &&
          $("#mcat_reading_rank").val() > 99)
      ) {
        errors.mcat_reading_rank = "Enter total rank between 0 to 99";
      }
    }

    if (this.state.examtype == "ielts") {
      if ($("#examdate").val() === "") {
        errors.examdate = "Please select";
      }
      if ($("#readingscore").val() === "") {
        errors.readingscore = "This is required";
      }
      if ($("#listeningscore").val() === "") {
        errors.listeningscore = "This is required";
      }
      if ($("#writingscore").val() === "") {
        errors.writingscore = "This is required";
      }
      if ($("#speakingscore").val() === "") {
        errors.speakingscore = "This is required";
      }
      if (
        ($("#readingscore").val() &&
          $("#readingscore").val() > 9) ||
        ($("#readingscore").val() &&
          $("#readingscore").val() < 0)
      ) {
        errors.readingscore = "Enter scores between 0 to 9";
      }

      if (
        ($("#listeningscore").val() &&
          $("#listeningscore").val() > 9) ||
        ($("#listeningscore").val() &&
          $("#listeningscore").val() < 0)
      ) {
        errors.listeningscore = "Enter scores between 0 to 9";
      }

      if (
        ($("#writingscore").val() &&
          $("#writingscore").val() > 9) ||
        ($("#writingscore").val() &&
          $("#writingscore").val() < 0)
      ) {
        errors.writingscore = "Enter scores between 0 to 9";
      }

      if (
        ($("#speakingscore").val() &&
          $("#speakingscore").val() > 9) ||
        ($("#speakingscore").val() &&
          $("#speakingscore").val() < 0)
      ) {
        errors.speakingscore = "Enter scores between 0 to 9";
      }
    }

    if (this.state.examtype === "duolingo") {
      console.log("onSubmiterrors", $("#examdate").val(), $("#readingscore").val())
      const { examdate, duolingo_literacy_score, duolingo_comprehension_score, duolingo_conversation_score, duolingo_production_score, duolingo_total_score } = this.state
      if (!examdate) {
        errors.examdate = "Please select";
      }
      if (!duolingo_literacy_score) {
        errors.readingscore = "This is required";
      }
      if (!duolingo_comprehension_score) {
        errors.listeningscore = "This is required";
      }
      if (!duolingo_conversation_score) {
        errors.writingscore = "This is required";
      }
      if (!duolingo_production_score) {
        errors.speakingscore = "This is required";
      }
      if (
        (duolingo_literacy_score &&
          duolingo_literacy_score > 160) ||
        (duolingo_literacy_score &&
          duolingo_literacy_score < 10)
      ) {
        errors.readingscore = "Enter scores between 10 to 160";
      }

      if (
        (duolingo_comprehension_score &&
          duolingo_comprehension_score > 160) ||
        (duolingo_comprehension_score &&
          duolingo_comprehension_score < 10)
      ) {
        errors.listeningscore = "Enter scores between 10 to 160";
      }

      if (
        (duolingo_conversation_score &&
          duolingo_conversation_score > 160) ||
        (duolingo_conversation_score &&
          duolingo_conversation_score < 10)
      ) {
        errors.writingscore = "Enter scores between 10 to 160";
      }

      if (
        (duolingo_production_score &&
          duolingo_production_score > 160) ||
        (duolingo_production_score &&
          duolingo_production_score < 10)
      ) {
        errors.speakingscore = "Enter scores between 10 to 160";
      }
    }

    if (this.state.examtype == "toefl") {
      if ($("#examdate").val() === "") {
        errors.examdate = "Please select";
      }

      if ($("#readingscore").val() === "") {
        errors.readingscore = "This is required";
      }
      if ($("#listeningscore").val() === "") {
        errors.listeningscore = "This is required";
      }
      if ($("#writingscore").val() === "") {
        errors.writingscore = "This is required";
      }
      if ($("#speakingscore").val() === "") {
        errors.speakingscore = "This is required";
      }

      if (
        ($("#readingscore").val() &&
          $("#readingscore").val() > 30) ||
        ($("#readingscore").val() &&
          $("#readingscore").val() < 0)
      ) {
        errors.readingscore = "Enter scores between 0 to 30";
      }

      if (
        ($("#listeningscore").val() &&
          $("#listeningscore").val() > 30) ||
        ($("#listeningscore").val() &&
          $("#listeningscore").val() < 0)
      ) {
        errors.listeningscore = "Enter scores between 0 to 30";
      }

      if (
        ($("#writingscore").val() &&
          $("#writingscore").val() > 30) ||
        ($("#writingscore").val() &&
          $("#writingscore").val() < 0)
      ) {
        errors.writingscore = "Enter scores between 0 to 30";
      }

      if (
        ($("#speakingscore").val() &&
          $("#speakingscore").val() > 30) ||
        ($("#speakingscore").val() &&
          $("#speakingscore").val() < 0)
      ) {
        errors.speakingscore = "Enter scores between 0 to 30";
      }
    }

    if (this.state.examtype == "oet") {
      if ($("#examdate").val() === "") {
        errors.examdate = "Please select";
      }
      if ($("#oet_reading_score").val() === "") {
        errors.oet_reading_score = "This is required";
      }
      if ($("#oet_listening_score").val() === "") {
        errors.oet_listening_score = "This is required";
      }
      if ($("#oet_writing_score").val() === "") {
        errors.oet_writing_score = "This is required";
      }
      if ($("#oet_speaking_score").val() === "") {
        errors.oet_speaking_score = "This is required";
      }

      if (
        ($("#oet_reading_score").val() &&
          $("#oet_reading_score").val() > 500) ||
        ($("#oet_reading_score").val() &&
          $("#oet_reading_score").val() < 0)
      ) {
        errors.oet_reading_score = "Enter scores between 0 to 500";
      }

      if (
        ($("#oet_listening_score").val() &&
          $("#oet_listening_score").val() > 500) ||
        ($("#oet_listening_score").val() &&
          $("#oet_listening_score").val() < 0)
      ) {
        errors.oet_listening_score = "Enter scores between 0 to 500";
      }

      if (
        ($("#oet_writing_score").val() &&
          $("#oet_writing_score").val() > 500) ||
        ($("#oet_writing_score").val() &&
          $("#oet_writing_score").val() < 0)
      ) {
        errors.oet_writing_score = "Enter scores between 0 to 500";
      }

      if (
        ($("#oet_speaking_score").val() &&
          $("#oet_speaking_score").val() > 500) ||
        ($("#oet_speaking_score").val() &&
          $("#oet_speaking_score").val() < 0)
      ) {
        errors.oet_speaking_score = "Enter scores between 0 to 500";
      }
    }

    if (this.state.examtype == "c1 Advanced") {
      if ($("#examdate").val() === "") {
        errors.examdate = "Please select";
      }
      if ($("#c_reading_score").val() === "") {
        errors.c_reading_score = "This is required";
      }
      if ($("#c_listening_score").val() === "") {
        errors.c_listening_score = "This is required";
      }
      if ($("#c_writing_score").val() === "") {
        errors.c_writing_score = "This is required";
      }
      if ($("#c_speaking_score").val() === "") {
        errors.c_speaking_score = "This is required";
      }
      if ($("#useEnglishcore").val() === "") {
        errors.useEnglishcore = "This is required";
      }

      if (
        ($("#c_reading_score").val() &&
          $("#c_reading_score").val() > 210) ||
        ($("#c_reading_score").val() &&
          $("#c_reading_score").val() < 0)
      ) {
        errors.c_reading_score = "Enter scores between 0 to 210";
      }

      if (
        ($("#c_listening_score").val() &&
          $("#c_listening_score").val() > 210) ||
        ($("#c_listening_score").val() &&
          $("#c_listening_score").val() < 0)
      ) {
        errors.c_listening_score = "Enter scores between 0 to 210";
      }

      if (
        ($("#c_writing_score").val() &&
          $("#c_writing_score").val() > 210) ||
        ($("#c_writing_score").val() &&
          $("#c_writing_score").val() < 0)
      ) {
        errors.c_writing_score = "Enter scores between 0 to 210";
      }

      if (
        ($("#c_speaking_score").val() &&
          $("#c_speaking_score").val() > 210) ||
        ($("#c_speaking_score").val() &&
          $("#c_speaking_score").val() < 0)
      ) {
        errors.c_speaking_score = "Enter scores between 0 to 210";
      }

      if (
        ($("#useEnglishcore").val() &&
          $("#useEnglishcore").val() > 210) ||
        ($("#useEnglishcore").val() && $("#useEnglishcore").val() < 0)
      ) {
        errors.useEnglishcore = "Enter scores between 0 to 210";
      }
    }


    if (this.state.examtype == "pte") {
      if ($("#examdate").val() === "") {
        errors.examdate = "Please select";
      }
      if ($("#pte_total_score").val() === "") {
        errors.pte_total_score = "Total score is required";
      }
      if ($("#pte_listening_score").val() === "") {
        errors.pte_listening_score = "Listening score is required";
      }
      if ($("#pte_listening_rank").val() === "") {
        errors.pte_listening_rank = "Listening rank is required";
      }
      if ($("#pte_reading_score").val() === "") {
        errors.pte_reading_score = "Reading score is required";
      }
      if ($("#pte_reading_rank").val() === "") {
        errors.pte_reading_rank = "Reading rank is required";
      }
      if ($("#pte_speaking_score").val() === "") {
        errors.pte_speaking_score = "Speaking score is required";
      }
      if ($("#pte_speaking_rank").val() === "") {
        errors.pte_speaking_rank = "Speaking rank is required";
      }
      if ($("#pte_writing_score").val() === "") {
        errors.pte_writing_score = "Writing score is required";
      }
      if ($("#pte_writing_rank").val() === "") {
        errors.pte_writing_rank = "Writing rank is required";
      }

      // Scores
      if (
        ($("#pte_listening_score").val() &&
          $("#pte_listening_score").val() > 90) ||
        ($("#pte_listening_score").val() &&
          $("#pte_listening_score").val() < 10)
      ) {
        errors.pte_listening_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_reading_score").val() &&
          $("#pte_reading_score").val() > 90) ||
        ($("#pte_reading_score").val() &&
          $("#pte_reading_score").val() < 10)
      ) {
        errors.pte_reading_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_speaking_score").val() &&
          $("#pte_speaking_score").val() > 90) ||
        ($("#pte_speaking_score").val() &&
          $("#pte_speaking_score").val() < 10)
      ) {
        errors.pte_speaking_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_writing_score").val() &&
          $("#pte_writing_score").val() > 90) ||
        ($("#pte_writing_score").val() &&
          $("#pte_writing_score").val() < 10)
      ) {
        errors.pte_writing_score = "Enter score between 10 to 90";
      }

      // Ranks
      if (
        ($("#pte_listening_rank").val() &&
          $("#pte_listening_rank").val() > 99) ||
        ($("#pte_listening_rank").val() &&
          $("#pte_listening_rank").val() < 0)
      ) {
        errors.pte_listening_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_reading_rank").val() &&
          $("#pte_reading_rank").val() > 99) ||
        ($("#pte_reading_rank").val() &&
          $("#pte_reading_rank").val() < 0)
      ) {
        errors.pte_reading_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_speaking_rank").val() &&
          $("#pte_speaking_rank").val() > 99) ||
        ($("#pte_speaking_rank").val() &&
          $("#pte_speaking_rank").val() < 0)
      ) {
        errors.pte_speaking_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_writing_rank").val() &&
          $("#pte_writing_rank").val() > 99) ||
        ($("#pte_writing_rank").val() &&
          $("#pte_writing_rank").val() < 0)
      ) {
        errors.pte_writing_rank = "Enter score between 0 to 99";
      }

      // ******************************************************************************
      if ($("#pte_grammer_score").val() === "") {
        errors.pte_grammer_score = "This is required";
      }
      if ($("#pte_grammer_rank").val() === "") {
        errors.pte_grammer_rank = "This is required";
      }
      if ($("#pte_oral_score").val() === "") {
        errors.pte_oral_score = "This is required";
      }
      if ($("#pte_oral_rank").val() === "") {
        errors.pte_oral_rank = "This is required";
      }
      if ($("#pte_pron_score").val() === "") {
        errors.pte_pron_score = "This is required";
      }
      if ($("#pte_pron_rank").val() === "") {
        errors.pte_pron_rank = "This is required";
      }
      if ($("#pte_spelling_score").val() === "") {
        errors.pte_spelling_score = "This is required";
      }
      if ($("#pte_spelling_rank").val() === "") {
        errors.pte_spelling_rank = "This is required";
      }

      // Scores
      if (
        ($("#pte_grammer_score").val() &&
          $("#pte_grammer_score").val() > 90) ||
        ($("#pte_grammer_score").val() &&
          $("#pte_grammer_score").val() < 10)
      ) {
        errors.pte_grammer_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_oral_score").val() &&
          $("#pte_oral_score").val() > 90) ||
        ($("#pte_oral_score").val() && $("#pte_oral_score").val() < 10)
      ) {
        errors.pte_oral_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_pron_score").val() &&
          $("#pte_pron_score").val() > 90) ||
        ($("#pte_pron_score").val() && $("#pte_pron_score").val() < 10)
      ) {
        errors.pte_pron_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_spelling_score").val() &&
          $("#pte_spelling_score").val() > 90) ||
        ($("#pte_spelling_score").val() &&
          $("#pte_spelling_score").val() < 10)
      ) {
        errors.pte_spelling_score = "Enter score between 10 to 90";
      }

      // Ranks
      if (
        ($("#pte_grammer_rank").val() &&
          $("#pte_grammer_rank").val() > 99) ||
        ($("#pte_grammer_rank").val() &&
          $("#pte_grammer_rank").val() < 0)
      ) {
        errors.pte_grammer_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_oral_rank").val() &&
          $("#pte_oral_rank").val() > 99) ||
        ($("#pte_oral_rank").val() && $("#pte_oral_rank").val() < 0)
      ) {
        errors.pte_oral_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_pron_rank").val() &&
          $("#pte_pron_rank").val() > 99) ||
        ($("#pte_pron_rank").val() && $("#pte_pron_rank").val() < 0)
      ) {
        errors.pte_pron_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_spelling_rank").val() &&
          $("#pte_spelling_rank").val() > 99) ||
        ($("#pte_spelling_rank").val() &&
          $("#pte_spelling_rank").val() < 0)
      ) {
        errors.pte_spelling_rank = "Enter score between 0 to 99";
      }

      // ******************************************************************************

      if ($("#pte_vocab_score").val() === "") {
        errors.pte_vocab_score = "This is required";
      }
      if ($("#pte_vocab_rank").val() === "") {
        errors.pte_vocab_rank = "This is required";
      }
      if ($("#pte_written_score").val() === "") {
        errors.pte_written_score = "This is required";
      }
      if ($("#pte_written_rank").val() === "") {
        errors.pte_written_rank = "This is required";
      }

      // Scores
      if (
        ($("#pte_vocab_score").val() &&
          $("#pte_vocab_score").val() >= 90) ||
        ($("#pte_vocab_score").val() &&
          $("#pte_vocab_score").val() <= 10)
      ) {
        errors.pte_vocab_score = "Enter score between 10 to 90";
      }

      if (
        ($("#pte_written_score").val() &&
          $("#pte_written_score").val() >= 90) ||
        ($("#pte_written_score").val() &&
          $("#pte_written_score").val() <= 10)
      ) {
        errors.pte_written_score = "Enter score between 10 to 90";
      }

      // Ranks
      if (
        ($("#pte_vocab_rank").val() &&
          $("#pte_vocab_rank").val() >= 99) ||
        ($("#pte_vocab_rank").val() && $("#pte_vocab_rank").val() <= 0)
      ) {
        errors.pte_vocab_rank = "Enter score between 0 to 99";
      }

      if (
        ($("#pte_written_rank").val() &&
          $("#pte_written_rank").val() >= 99) ||
        ($("#pte_written_rank").val() &&
          $("#pte_written_rank").val() <= 0)
      ) {
        errors.pte_written_rank = "Enter score between 0 to 99";
      }
    }

    console.log("onSubmiterrors", errors)


    return Object.keys(errors).length === 0 ? null : errors;
  };
  nextTab = () => {
    $("#tab4").addClass("active");
    $("#tab3").removeClass("active");
    $(".AppTestscore").removeClass("active");
    $(".bgtab").addClass("active");

  }

  prevTab = () => {
    $("#tab2").addClass("active");
    $("#tab3").removeClass("active");
    $(".AppTestscore").removeClass("active");
    $(".educationtab").addClass("active");
  }


  onSubmit = (exit, e) => {
    e.preventDefault();
    const errors = this.validate();
    console.log("onSubmiterrors", errors)
    this.setState({ errors });

    let multiCheckArr = [];
    $(".multiCheck").each(function (i) {
      if ($(this).is(":checked")) {
        multiCheckArr.push($(this).val());
      }
      else {
        multiCheckArr.push($(this).val() + '|uncheck');
      }
    });

    let multiTextArr = [];
    $(".ansrTxt").each(function (i) {
      if ($(this).val() != '') {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id"));
      }
      else {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id") + '|uncheck');
      }
    });

    let radioQuesArr = [];
    $(".generalradio").each(function (i) {
      if ($(this).is(":checked")) {
        radioQuesArr.push($(this).val());
      }
    });

    if (errors) return;

    let exmDate =
      $("#examdate").val() !== undefined ? $("#examdate").val() : null;
    let greexmDate =
      $("#greexamdate").val() !== undefined ? $("#greexamdate").val() : null;
    let gmatexmDate =
      $("#gmatexamdate").val() !== undefined ? $("#gmatexamdate").val() : null;
    let satexmDate =
      $("#satExamDate").val() !== undefined ? $("#satExamDate").val() : null;
    let lsatexmDate =
      $("#lsatExamDate").val() !== undefined ? $("#lsatExamDate").val() : null;
    let mcatexmDate =
      $("#mcatExamDate").val() !== undefined ? $("#mcatExamDate").val() : null;

    this.state.formData.append("examtype", this.state.examtype);
    this.state.formData.append("examdate", $("#examdate").val());

    if (
      this.state.examtype
    ) {
      switch (this.state.examtype) {
        case "toefl":
          this.state.formData.append("readingscore", this.state.toefl_reading_score);
          this.state.formData.append("listeningscore", this.state.toefl_listening_score);
          this.state.formData.append("writingscore", this.state.toefl_writing_score);
          this.state.formData.append("speakingscore", this.state.toefl_speaking_score);
          this.state.formData.append("avg_score", this.state.toefl_total_score);
          break;
        case "ielts":
          this.state.formData.append("readingscore", this.state.ilets_reading_score);
          this.state.formData.append("listeningscore", this.state.ilets_listening_score);
          this.state.formData.append("writingscore", this.state.ilets_writing_score);
          this.state.formData.append("speakingscore", this.state.ilets_speaking_score);
          this.state.formData.append("avg_score", this.state.ilets_total_score);
          break;
        case "duolingo":
          this.state.formData.append("readingscore", this.state.duolingo_literacy_score);
          this.state.formData.append("listeningscore", this.state.duolingo_comprehension_score);
          this.state.formData.append("writingscore", this.state.duolingo_conversation_score);
          this.state.formData.append("speakingscore", this.state.duolingo_production_score);
          this.state.formData.append("avg_score", this.state.duolingo_total_score);
          break;
        case "pte":
          this.state.formData.append("readingscore", this.state.pte_reading_score);
          this.state.formData.append("listeningscore", this.state.pte_listening_score);
          this.state.formData.append("writingscore", this.state.pte_writing_score);
          this.state.formData.append("speakingscore", this.state.pte_speaking_score);
          this.state.formData.append("avg_score", this.state.pte_total_score);

          this.state.formData.append('reading_rank', this.state.pte_reading_rank)
          this.state.formData.append('listening_rank', this.state.pte_listening_rank)
          this.state.formData.append('speaking_rank', this.state.pte_reading_rank)
          this.state.formData.append('writing_rank', this.state.pte_writing_rank)

          break;
        case "oet":
          this.state.formData.append("readingscore", this.state.oet_reading_score);
          this.state.formData.append("listeningscore", this.state.oet_listening_score);
          this.state.formData.append("writingscore", this.state.oet_writing_score);
          this.state.formData.append("speakingscore", this.state.oet_speaking_score);
          // this.state.formData.append("avg_score", this.state.pte_total_score);

          this.state.formData.append('reading_rank', this.state.oet_reading_grade)
          this.state.formData.append('listening_rank', this.state.oet_listening_grade)
          this.state.formData.append('speaking_rank', this.state.oet_speaking_grade)
          this.state.formData.append('writing_rank', this.state.oet_writing_grade)
          break;
        case "c1 Advanced":
          this.state.formData.append("readingscore", this.state.c_reading_score);
          this.state.formData.append("listeningscore", this.state.c_listening_score);
          this.state.formData.append("writingscore", this.state.c_writing_score);
          this.state.formData.append("speakingscore", this.state.c_speaking_score);
          this.state.formData.append("avg_score", this.state.c_use_english_grade);

          this.state.formData.append('reading_rank', this.state.c_reading_grade)
          this.state.formData.append('listening_rank', this.state.c_listening_grade)
          this.state.formData.append('speaking_rank', this.state.c_speaking_grade)
          this.state.formData.append('writing_rank', this.state.c_writing_grade)

          break;
      }
    }

    this.state.formData.append("englishCheck", this.state.c_use_english);

    this.state.formData.append("greexamdate", $("#greexamdate").val());
    this.state.formData.append("greverbalscore", this.state.greverbalscore);
    this.state.formData.append("greverbalrank", this.state.greverbalrank);
    this.state.formData.append("grequanitativescore", this.state.grequanitativescore);
    this.state.formData.append("grequanitativerank", this.state.grequanitativerank);
    this.state.formData.append("grewritingscore", this.state.grewritingscore);
    this.state.formData.append("grewritingrank", this.state.grewritingrank);

    this.state.formData.append("exam_type", this.state.examtype);
    this.state.formData.append("ilets_exam_date", exmDate);

    this.state.formData.append(
      "ilets_reading_score",
      this.state.ilets_reading_score
    );
    this.state.formData.append(
      "ilets_listening_score",
      this.state.ilets_listening_score
    );
    this.state.formData.append(
      "ilets_writing_score",
      this.state.ilets_writing_score
    );
    this.state.formData.append(
      "ilets_speaking_score",
      this.state.ilets_speaking_score
    );
    this.state.formData.append(
      "ilets_total_score",
      this.state.ilets_total_score
    );


    this.state.formData.append("duolingo_exam_date", exmDate);

    this.state.formData.append(
      "duolingo_literacy_score",
      this.state.duolingo_literacy_score
    );
    this.state.formData.append(
      "duolingo_comprehension_score",
      this.state.duolingo_comprehension_score
    );
    this.state.formData.append(
      "duolingo_conversation_score",
      this.state.duolingo_conversation_score
    );
    this.state.formData.append(
      "duolingo_production_score",
      this.state.duolingo_production_score
    );
    this.state.formData.append(
      "duolingo_total_score",
      this.state.duolingo_total_score
    );

    this.state.formData.append("toefl_exam_date", exmDate); //this.state.toefl_exam_date);
    this.state.formData.append(
      "toefl_reading_score",
      this.state.toefl_reading_score
    );
    this.state.formData.append(
      "toefl_listening_score",
      this.state.toefl_listening_score
    );
    this.state.formData.append(
      "toefl_writing_score",
      this.state.toefl_writing_score
    );
    this.state.formData.append(
      "toefl_speaking_score",
      this.state.toefl_speaking_score
    );
    this.state.formData.append(
      "toefl_total_score",
      this.state.toefl_total_score
    );

    this.state.formData.append("pte_exam_date", exmDate); //this.state.pte_oral_rank);
    this.state.formData.append(
      "pte_listening_score",
      this.state.pte_listening_score
    );
    this.state.formData.append(
      "pte_listening_rank",
      this.state.pte_listening_rank
    );
    this.state.formData.append(
      "pte_reading_score",
      this.state.pte_reading_score
    );
    this.state.formData.append(
      "pte_reading_rank",
      this.state.pte_reading_rank
    );
    this.state.formData.append(
      "pte_speaking_score",
      this.state.pte_reading_score
    );
    this.state.formData.append(
      "pte_speaking_rank",
      this.state.pte_reading_rank
    );
    this.state.formData.append(
      "pte_writing_score",
      this.state.pte_reading_score
    );
    this.state.formData.append(
      "pte_writing_rank",
      this.state.pte_reading_rank
    );
    this.state.formData.append(
      "pte_grammer_score",
      this.state.pte_grammer_score
    );
    this.state.formData.append(
      "pte_grammer_rank",
      this.state.pte_grammer_rank
    );
    this.state.formData.append(
      "pte_oral_rank",
      this.state.pte_oral_rank
    );
    this.state.formData.append(
      "pte_oral_score",
      this.state.pte_oral_score
    );
    this.state.formData.append(
      "pte_pron_score",
      this.state.pte_pron_score
    );
    this.state.formData.append(
      "pte_pron_rank",
      this.state.pte_pron_rank
    );
    this.state.formData.append(
      "pte_spelling_score",
      this.state.pte_spelling_score
    );
    this.state.formData.append(
      "pte_spelling_rank",
      this.state.pte_spelling_rank
    );
    this.state.formData.append(
      "pte_vocab_score",
      this.state.pte_vocab_score
    );
    this.state.formData.append(
      "pte_vocab_rank",
      this.state.pte_vocab_rank
    );
    this.state.formData.append(
      "pte_written_score",
      this.state.pte_written_score
    );
    this.state.formData.append(
      "pte_written_rank",
      this.state.pte_written_rank
    );
    this.state.formData.append(
      "pte_total_score",
      this.state.pte_total_score
    );

    this.state.formData.append("oet_exam_date", exmDate); //this.state.oet_exam_date);
    this.state.formData.append(
      "oet_reading_score",
      this.state.oet_reading_score
    );
    this.state.formData.append(
      "oet_listening_score",
      this.state.oet_listening_score
    );
    this.state.formData.append(
      "oet_writing_score",
      this.state.oet_writing_score
    );
    this.state.formData.append(
      "oet_speaking_score",
      this.state.oet_speaking_score
    );

    this.state.formData.append("c_exam_date", exmDate); //this.state.c_exam_date);
    this.state.formData.append(
      "c_reading_score",
      this.state.c_reading_score
    );
    this.state.formData.append(
      "c_listening_score",
      this.state.c_listening_score
    );
    this.state.formData.append(
      "c_writing_score",
      this.state.c_writing_score
    );
    this.state.formData.append(
      "c_speaking_score",
      this.state.c_speaking_score
    );
    this.state.formData.append(
      "c_use_english",
      this.state.c_use_english
    );
    this.state.formData.append("c_avg_score", this.state.c_avg_score);
    this.state.formData.append("gre_exam_date", greexmDate);

    this.state.formData.append("greverbalscore", this.state.greverbalscore);
    this.state.formData.append("greverbalrank", this.state.greverbalrank);

    this.state.formData.append(
      "grequanitativescore",
      this.state.grequanitativescore
    );
    this.state.formData.append(
      "grequanitativerank",
      this.state.grequanitativerank
    );



    this.state.formData.append("gretotalscore", this.state.gretotalscore);
    this.state.formData.append("gmatreadingscore", this.state.gmatreasoningscore);
    this.state.formData.append("gmatreadingrank", this.state.gmatreasoningrank);
    this.state.formData.append("haveSatScore", this.state.haveSatScore);
    this.state.formData.append("satExamDate", satexmDate);
    this.state.formData.append("satWritingRank", this.state.satWritingRank);
    this.state.formData.append("satQuantScore", this.state.satQuantScore);
    this.state.formData.append("satQuantRank", this.state.satQuantRank);
    this.state.formData.append("satAverageScore", this.state.satAverageScore);
    this.state.formData.append("satAverageRank", this.state.satAverageRank);
    this.state.formData.append("haveLSATscore", this.state.haveLSATscore);
    this.state.formData.append("lsatExamDate", lsatexmDate);
    this.state.formData.append("lsatTotalScore", this.state.lsatTotalScore);
    this.state.formData.append("lsatTotalRank", this.state.lsatTotalRank);
    this.state.formData.append("haveMCATscore", this.state.haveMCATscore);
    this.state.formData.append("mcatExamDate", mcatexmDate);
    this.state.formData.append("mcatTotalScore", this.state.mcatTotalScore);
    this.state.formData.append("mcatTotalRank", this.state.mcatTotalRank);
    this.state.formData.append("mcat_bio_rank", this.state.mcat_bio_rank);
    this.state.formData.append("mcat_bio_score", this.state.mcat_bio_score);
    this.state.formData.append(
      "mcat_chemical_score",
      this.state.mcat_chemical_score
    );
    this.state.formData.append(
      "mcat_chemical_rank",
      this.state.mcat_chemical_rank
    );
    this.state.formData.append(
      "mcat_psychology_score",
      this.state.mcat_psychology_score
    );
    this.state.formData.append(
      "mcat_psychology_rank",
      this.state.mcat_psychology_rank
    );
    this.state.formData.append(
      "mcat_reading_score",
      this.state.mcat_reading_score
    );
    this.state.formData.append(
      "mcat_reading_rank",
      this.state.mcat_reading_rank
    );



    this.state.formData.append("greCheck", this.state.greCheck);
    this.state.formData.append("gretotalrank", this.state.gretotalrank);

    this.state.formData.append("gmatexamdate", $("#gmatexamdate").val());
    this.state.formData.append("gmatverbalscore", this.state.gmatverbalscore);
    this.state.formData.append("gmatverbalrank", this.state.gmatverbalrank);
    this.state.formData.append("gmatquanitativescore", this.state.gmatquanitativescore);
    this.state.formData.append("gmatquanitativerank", this.state.gmatquanitativerank);
    this.state.formData.append("gmatwritingscore", this.state.gmatwritingscore);
    this.state.formData.append("gmatwritingrank", this.state.gmatwritingrank);
    this.state.formData.append("gmattotalscore", this.state.gmattotalscore);
    this.state.formData.append("gmattotalrank", this.state.gmattotalrank);
    this.state.formData.append("gmatCheck", this.state.gmatCheck);
    this.state.formData.append("uniAnsrMulti", multiCheckArr);
    this.state.formData.append("ansrTxt", multiTextArr);
    this.state.formData.append("uniAnsrRadio", radioQuesArr);
    this.state.formData.append("uni_id", localStorage.getItem("universityid"));
    this.state.formData.append("app_id", localStorage.getItem("app_id"));
    this.state.formData.append("app_student_id", localStorage.getItem("app_student_id"));
    this.state.formData.append("student_id", localStorage.getItem('studentid'));

    this.state.formData.append('application_id', this.props.applicationId)

    this.state.appAnswers.forEach((val, index) => {
      this.state.formData.append(`answer[${index}][question_id]`, val.question_id)
      if (Array.isArray(val.answer)) {
        val.answer.forEach((option, optionIndex) => {
          this.state.formData.append(`answer[${index}][answer][${optionIndex}]`, option)
        })
      } else {
        this.state.formData.append(`answer[${index}][answer]`, val.answer)
      }
    })

    this.setState({ errors: '' });
    this.props.showLoader();
    submitForm(
      "form-data",
      this.state.formData, (msg) => {
        this.props.hideLoader();
        this.props.getApplicationStepStatus()
        this.getTestScoresData()
        if (msg != "error") {
          toast.success(msg.message);
          if (exit) {
            this.props.history.push('/draftapplication')
          }
          this.props.updateTabs()
          setTimeout(function () {
            $("#tab4").addClass("active");
            $("#tab3").removeClass("active");
            $(".educationtab").removeClass("active");
            $(".testscore").addClass("active");
          }, 2000);
        }
      }, 'storetestscores_app'
    );
    return
    if (!this.state.greCheck) {
      this.setState({
        greexamdate: "",
        greverbalscore: "",
        greverbalrank: "",
        grequanitativescore: "",
        grequanitativerank: "",
        grewritingscore: "",
        grewritingrank: "",
        gretotalrank: "",
        gretotalscore: "",
        grereadingscore: "",
        grereadingrank: "",
      });
    }

    if (!this.state.haveSatScore) {
      this.setState({
        satExamDate: "",
        satQuantScore: "",
        satQuantRank: "",
        satWritingScore: "",
        satWritingRank: "",
        satAverageScore: "",
        satAverageRank: "",
      });
    }

    if (!this.state.haveLSATscore) {
      this.setState({
        lsatExamDate: "",
        lsatTotalScore: "",
        lsatTotalRank: "",
      });
    }
    if (!this.state.haveMCATscore) {
      this.setState({
        mcatExamDate: "",
        mcatTotalScore: "",
        mcatTotalRank: "",
        mcat_bio_rank: "",
        mcat_bio_score: "",
        mcat_chemical_score: "",
        mcat_chemical_rank: "",
        mcat_psychology_score: "",
        mcat_psychology_rank: "",
        mcat_reading_score: "",
        mcat_reading_rank: "",
      });
    }

  };
  render() {
    return template.call(this);
  }
}

export default AppTestscore;
