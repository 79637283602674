import React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Progress, Row } from "reactstrap"
import { ReactComponent as RemoveIcon } from "../../../assets/img/close-btn.svg"
import "./ApplicationDocumentsList.scss"
import CorrectIcon from "../../../assets/img/correct-green.png";
import CancelIcon from "../../../assets/img/icons/cross_circle_red_icon.svg";

export function ApplicationDocumentList({
  showRemove,
  fileList,
  onRemove,
  showProgress = false,
  progress,
  showSize = true,
  showPreview = false,
  showSaveSubmit
}) {
  if (fileList.length) {
    return (
      <Row className={`${showSaveSubmit === false ? '' : 'justify-content-center'}`}>
        <Col sm={showSaveSubmit === false ? 12 : 5} className={`${showSaveSubmit === false ? '' : 'pdfList'}`}>
          {fileList.map((val, index) => (
            <div className='selectedPdfs' key={index}>
              <Row>
                <Col xs="12" md="9">
                  <div className='pdfName'>{val.file.name}</div>
                  {showSize ? <div className='pdfSize'>{val.fileSize}</div> : null}
                  {showPreview ? <div className='pdfSize'><Link to={`preview-application-form/${val.id}`} className="viewDoc">View</Link></div> : null}
                </Col>

                <Col xs="12" md="3" className="align-self-center">
                  {showRemove ? (
                    <span className='pdfFileRemove'>
                      <RemoveIcon
                        onClick={() => {
                          onRemove(index, val)
                        }}
                        className='pointer'
                      />
                    </span>
                  ) : null}


                  {
                    showSaveSubmit === false ? (
                      <div className='align-self-center'>
                        {val.status == 2 ? (
                          <div className='verification'>
                            <Button color='pending' onClick={() => { }}>
                              Verification Pending
                            </Button>
                          </div>
                        ) : null}

                        {val.status == 3 ? (
                          <div className='verification'>
                            <Button color='verified' onClick={() => { }}>
                              <img src={CorrectIcon} className='icon' /> Verified by Formee Express
                            </Button>
                          </div>
                        ) : null}

                        {val.status == 4 ? (
                          <div className='verification'>
                            <Button color='rejected' onClick={() => { }}>
                              <img src={CancelIcon} className='icon' /> Rejected
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    ) : null
                  }
                </Col>
                {showProgress ? (
                  <Col sm={12}>
                    <Progress color='success' bar value={progress[index]} className='showProgress' />
                  </Col>
                ) : null}
              </Row>
            </div>
          ))}
        </Col>
      </Row>
    )
  } else {
    return null
  }
}
