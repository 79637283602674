import React from "react"
import { Button, Col, Form, FormGroup, Input, Label, Row, Alert } from "reactstrap";
import "../Table.scss";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as RightTicArrow } from "../../../../assets/img/icons/CheckCircle.svg"
import MaskedInput from "react-text-mask";
import {
	AMERICANEXPRESS,
	OTHERCARDS,
	EXPIRYDATE,
	CCV
} from "../../../../constant/constant";
import FormError from "../../../../common/FormError";
import { ElementsConsumer, Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
	STRIPE_PUBLISH_KEY,
	STRIPE_SECRET
} from "../../../../config.json";
import { toast } from "react-toastify";

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);


// const Wrapper = (props) => (
// 	<Elements stripe={stripePromise}>
// 	  <MyComponent {...props} />
// 	</Elements>
//   );

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDefaultPayment: false,
			universityId: 0
		}
	}

	componentDidMount() {
		let universityId = localStorage.getItem("universityid");
		this.setState({
			universityId
		})
	}

	createCard = () => {
		const { elements, stripe } = this.props;
		console.log(elements, stripe,"jdfgsjgdfkj")
		const cardEle = elements.getElement(CardNumberElement)
		// this.props.showLoader();
		stripe.createToken(
			cardEle
		).then(res => {
			// this.props.hideLoader();
			console.log('cardred stripe', res)
			if (res.error) {
				if (res.error.message) {
					toast.error(res.error.message)
					return
				}
			}
			if (res.token) {
				let request = {
					university_id: this.state.universityId,
					pay_by: "stripe",
					card_token: res.token.id,
					creditcard_number: res.token.card.last4,
					creditcard_name: res.token.card.brand,
					exp_year: res.token.card.exp_year,
					exp_month: res.token.card.exp_month,
					is_default: this.state.isDefaultPayment ? 1 : 0
				};
				console.log('inhere')
				this.props.onCreateSubmit(request)
			}

		}).catch(e => {
			// this.props.hideLoader();
			if (e && e.message) {
				toast.error(e.message)
			}
		})
	}

	render() {
		return (
			<>
				<div id="createPaymentMethod" className={`addPaymentMethodSection createProgram bgWhite`}>
					<Form className="addForm" id="createPaymentMethodForm">
						<Row>
							<Col xs="12" md="6">
								<h5>Add new Credit Card</h5>
								<Row>
									<Col xs="12" className="paymentInput">
										{/* <Elements stripe={stripePromise}> */}
										<Label>Credit Card Number</Label>
										<CardNumberElement />
										<Label>Credit Card Name</Label>
										<Input
											type="text"
											className="form-control "
											placeholder="Name"
											name="name"
											onChange={this.handleChangeName}
											value={this.state.name}
										/>
										<Label>Security code</Label>
										<CardCvcElement />
										<Label>Expiration date</Label>
										<CardExpiryElement />
										{/* </Elements> */}
										<Button
											className="save-btn"
											onClick={this.createCard}
										>
											Save
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</div>
			</>
		)
	}
}

export default function AddPaymentMethod() {
	return (
		<Elements stripe={stripePromise}>
			<ElementsConsumer>
				{({ stripe, elements }) => (
					<CheckoutForm stripe={stripe} elements={elements} />
				)}
			</ElementsConsumer>
		</Elements>
	);
}