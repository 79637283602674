import React from "react";
import {Component} from "react";
import template from "./Addmore.jsx";
import config from "../../config.json";
import $ from "jquery";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {MESSAGES} from "../../constant/messages";
import submitForm from "../../utils/submitForm.js";

var baseurl = `${config.baseurl}`;

class Addmorequestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled:
        localStorage.getItem("fromApplicationType") === "Sent" ? true : false,
      question: [],
      answers: [],
      questionId: "",
      addmoreinput: [[]],
      typeoption1: [""],
      typeoption2: [""],
      typeoption3: [""],
      typeoption4: [""],
      typeoption5: [""],
      typeoption6: [""],
      typeoption7: [""],
      typeoption8: [""],
      handlequestion: [""],
      handleanswers: [""],
      questionsattended: [""],
      selectedOption: [""],
      addOnChange: [false],
      errors: {},
      formData: new FormData(),
    };
  }

  componentDidMount() {
    //this.getQuessummary();
  }

  componentDidUpdate(prevProps) {
    if (this.props.clearAddmore !== prevProps.clearAddmore) {
      if (this.props.clearAddmore) this.clearAddmore();
    }
    if (this.props.selectedQuestion !== prevProps.selectedQuestion) {
      if (this.props.selectedQuestion != null)
        this.onSelectQuestion(this.props.selectedQuestion);
    }
  }

  getQuessummary = () => {
    const param = localStorage.getItem("universityid"); 
    const stdid = localStorage.getItem("studentid");
    this.props.showLoader();
    axios
      .get(baseurl + "/get_answer/" + param + "/" + stdid + "/"+this.props.type)
      .then((response) => {
        if (response && response.data) {
          const questionArr = [];
          response.data.question.map((question, index) => {
            const getquestionid = response.data.question_id[index];
            let getquestion;
            let getquestionType;
            let getoptions;
            let getanswers;
            const options = [];
            if (question.search(",") !== -1) {
              getquestion = question.split(",");
              getanswers = response.data.answers[index].split("~");
              getquestionType = response.data.question_type[index].split(",");
              getoptions = response.data.options[index].split(",");
              getoptions.map((option) => {
                if (option.search("@") !== -1) {
                  const splittedOptions = option.split("@");
                  options.push(splittedOptions);
                } else {
                  options.push([option]);
                }
              });
            } else {
              getquestion = [question];
              getanswers = [response.data.answers[index]];
              getquestionType = [response.data.question_type[index]];
              let splittedOptions = [];
              if (response.data.options[index].search("@") !== -1) {
                splittedOptions = response.data.options[index].split("@");
                options.push(splittedOptions);
              } else {
                options.push([response.data.options[index]]);
              }
            }
            questionArr.push({
              questionId: getquestionid,
              question: getquestion,
              answers: getanswers,
              questionType: getquestionType,
              options,
            });
          });

          this.setState({ question: questionArr },()=>{
            this.props.hideLoader();
          });
          this.props.getQuestiondata(questionArr);
        }
      }).catch((error)=>{
        console.log('error: ', error);
        this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
      });
  };

  clearAddmore = () => {
    this.setState({
      questionsattended: [""],
      questionId: "",
      handlequestion: [""],
      handleanswers: [""],
      selectedOption: [""],
      typeoption1: [""],
      typeoption2: [""],
      typeoption3: [""],
      typeoption4: [""],
      typeoption5: [""],
      typeoption6: [""],
      typeoption7: [""],
      typeoption8: [""],
      addOnChange: [false],
      addmoreinput: [[]],
    });
  };

  onSelectQuestion = (index) => {
    const questionsattended = [];
    const questionId = this.state.question[index].questionId;
    const handlequestion = this.state.question[index].question;
    const handleanswers = this.state.question[index].answers;
    const selectedOption = this.state.question[index].questionType;

    const typeoption1 = [];
    const typeoption2 = [];
    const typeoption3 = [];
    const typeoption4 = [];
    const typeoption5 = [];
    const typeoption6 = [];
    const typeoption7 = [];
    const typeoption8 = [];
    const addOnChange = [];
    const addmoreinput = [];
    this.state.question[index].options.map((option, index) => {
      questionsattended.push("");
      addmoreinput.push([]);
      switch (selectedOption[index]) {
        case "1": {
          typeoption1[index] = option[0];
          typeoption2[index] = option[1];
          break;
        }
        case "2": {
          const getlength = option.length;
          if (getlength === 4) {
            typeoption3[index] = option[0];
            typeoption4[index] = option[1];
            typeoption5[index] = option[2];
            typeoption6[index] = option[3];
          } else {
            let optionclone = [...option];
            typeoption3[index] = optionclone.shift();
            typeoption4[index] = optionclone.shift();
            typeoption5[index] = optionclone.shift();
            typeoption6[index] = optionclone.shift();
            optionclone.map((clonedoption, clonedIndex) => {
              addmoreinput[index][clonedIndex] = clonedoption;
            });
            addOnChange[index] = true;
          }
          break;
        }
      }
    });

    for (let i = 0; i < questionsattended.length; i++) {
      if (typeoption1[i] == null) typeoption1[i] = "";
      if (typeoption2[i] == null) typeoption2[i] = "";
      if (typeoption3[i] == null) typeoption3[i] = "";
      if (typeoption4[i] == null) typeoption4[i] = "";
      if (typeoption5[i] == null) typeoption5[i] = "";
      if (typeoption6[i] == null) typeoption6[i] = "";
    } // change all null values to empty string

    this.setState({
      questionsattended,
      questionId,
      handlequestion,
      handleanswers,
      selectedOption,
      typeoption1,
      typeoption2,
      typeoption3,
      typeoption4,
      typeoption5,
      typeoption6,
      typeoption7,
      typeoption8,
      addOnChange,
      addmoreinput,
    });
  };

  handleQuestionName = (event, index) => {
    const handleQuestionclone = this.state.handleanswers;
    handleQuestionclone[index] = event.target.value;
    this.setState({ handleanswers: handleQuestionclone });
  };

  validate = () => {
    const errors = [];
    this.state.questionsattended.forEach((data, index) => {
      errors.push({});
      if (this.state.handlequestion[index] === "") {
        errors[index].handlequestion = MESSAGES.THIS_IS_REQUIRED;
      }
      if (
        this.state.selectedOption[index] == 1 &&
        this.state.typeoption1[index] === ""
      ) {
        errors[index].typeoption1 = MESSAGES.THIS_IS_REQUIRED;
      }
      if (
        this.state.selectedOption[index] == 1 &&
        this.state.typeoption2[index] === ""
      ) {
        errors[index].typeoption2 = MESSAGES.THIS_IS_REQUIRED;
      }

      if (
        this.state.selectedOption[index] == 2 &&
        this.state.typeoption3[index] === ""
      ) {
        errors[index].typeoption3 = MESSAGES.THIS_IS_REQUIRED;
      }
      if (
        this.state.selectedOption[index] == 2 &&
        this.state.typeoption4[index] === ""
      ) {
        errors[index].typeoption4 = MESSAGES.THIS_IS_REQUIRED;
      }
      if (
        this.state.selectedOption[index] == 2 &&
        this.state.typeoption5[index] === ""
      ) {
        errors[index].typeoption5 = MESSAGES.THIS_IS_REQUIRED;
      }
      if (
        this.state.selectedOption[index] == 2 &&
        this.state.typeoption6[index] === ""
      ) {
        errors[index].typeoption6 = MESSAGES.THIS_IS_REQUIRED;
      }

      if (
        this.state.selectedOption[index] == 2 &&
        this.state.addOnChange[index] == true
      ) {
        errors[index].addmoreinput = [];
        this.state.addmoreinput[index].map((input, inputIndex) => {
          if (input == "")
            errors[index].addmoreinput[inputIndex] = MESSAGES.THIS_IS_REQUIRED;
        });
        if (!(errors[index].addmoreinput.length > 0))
          delete errors[index].addmoreinput;
      }
    });

    return Object.keys(errors).length === 0 ? null : errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    let errors = this.validate();
    this.setState({ errors });
    errors = errors.filter((error) => Object.keys(error).length !== 0);
    if (errors.length > 0) return;

    let handlequestionArr = [];
    let handleanswersArr = [];
    let typeoption1Arr = [];
    let typeoption2Arr = [];
    let typeoption3Arr = [];
    let typeoption4Arr = [];
    let typeoption5Arr = [];
    let typeoption6Arr = [];
    let typeoption7Arr = [];
    let typeoption8Arr = [];
    $(".typeoption1").each(function (i) {
      typeoption1Arr.push($(this).val());
    });
    $(".typeoption2").each(function (i) {
      typeoption2Arr.push($(this).val());
    });
    $(".typeoption3").each(function (i) {
      typeoption3Arr.push($(this).val());
    });
    $(".typeoption4").each(function (i) {
      typeoption4Arr.push($(this).val());
    });
    $(".typeoption5").each(function (i) {
      typeoption5Arr.push($(this).val());
    });
    $(".typeoption6").each(function (i) {
      typeoption6Arr.push($(this).val());
    });
    $(".typeoption7").each(function (i) {
      typeoption7Arr.push($(this).val());
    });
    $(".typeoption8").each(function (i) {
      typeoption8Arr.push($(this).val());
    });

    $(".handlequestion").each(function (i) {
      handlequestionArr.push($(this).val());
    });
    $(".handleanswers").each(function (i) {
      handleanswersArr.push($(this).val());
    });

    let arrayString = this.state.handleanswers.join("~");

    let str2 = arrayString.replace(/",/g, "~");

    let singleSelectionArr = typeoption1Arr.map(
      (e, i) => e + "@" + typeoption2Arr[i]
    );
    let multipleSelectionArr = typeoption3Arr.map(
      (e, i) =>
        e +
        "@" +
        typeoption4Arr[i] +
        "@" +
        typeoption5Arr[i] +
        "@" +
        typeoption6Arr[i]
    );

    let merged = [];

    this.state.selectedOption.map((option, index) => {
      if (option == 1) {
        let firstElement = singleSelectionArr.shift();
        merged.push(firstElement);
      }
      if (option == 2) {
        let firstElement = multipleSelectionArr.shift();
        if (this.state.addOnChange[index]) {
          let addOn;
          addOn = this.state.addmoreinput[index].join("@");
          firstElement = firstElement + "@" + addOn;
        }
        merged.push(firstElement);
      }
      if (option == 0) {
        merged.push(0);
      }
    });

    let selected_value = [];
    let values = this.state.selectedOption;
    values.map((option1, index) => {
      if (option1 == 1 ||option1 == 2||option1 == 0) {
        selected_value.push(option1);
      }
    });

    this.state.formData.append("question_id", this.state.questionId);
    this.state.formData.append("answers", str2);
    this.state.formData.append(
      "university_id",
      localStorage.getItem("universityid")
    );
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("type", this.props.type);
    this.props.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        toast.success(msg.message);
      },
      "store_answers"
    );

    this.setState({ errors: "" });
  };

  render() {
    return template.call(this);
  }
}

export default Addmorequestion;
