import {
	LOGIN,
	LOGIN_SUCCESS,
	ROLE_PERMISSION,
	ROLE_PERMISSION_SUCCESS
  } from "../constants";

const initialState = {
	authUser:{},
	rolePermission: [
        {
            "module_id": 1,
            "module_name": "Applications",
            "permissions": [
                {
                    "permission_id": 3,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 4,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 5,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 6,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 2,
            "module_name": "Programs",
            "permissions": [
                {
                    "permission_id": 7,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 8,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 9,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 10,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 3,
            "module_name": "Payments",
            "permissions": [
                {
                    "permission_id": 11,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 12,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 13,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 14,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 4,
            "module_name": "Inbox",
            "permissions": [
                {
                    "permission_id": 15,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 16,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 17,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 18,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 5,
            "module_name": "Account",
            "permissions": [
                {
                    "permission_id": 19,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 20,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 21,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 22,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 6,
            "module_name": "Profile",
            "permissions": [
                {
                    "permission_id": 23,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 24,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 25,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 26,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 7,
            "module_name": "Dashboard",
            "permissions": [
                {
                    "permission_id": 27,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 28,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 29,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 30,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 8,
            "module_name": "Notification",
            "permissions": [
                {
                    "permission_id": 31,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 32,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 33,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 34,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        },
        {
            "module_id": 9,
            "module_name": "Calender",
            "permissions": [
                {
                    "permission_id": 35,
                    "permission_name": "view",
                    "status": true
                },
                {
                    "permission_id": 36,
                    "permission_name": "create",
                    "status": true
                },
                {
                    "permission_id": 37,
                    "permission_name": "edit",
                    "status": true
                },
                {
                    "permission_id": 38,
                    "permission_name": "delete",
                    "status": true
                }
            ]
        }
    ]
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN:
			return { ...state };
		case LOGIN_SUCCESS:
      console.log("log authUser", this.authUser)
			return { ...state, authUser: action.payload.data, }
      
		// case ROLE_PERMISSION:
		// 	return { ...state };
		// case ROLE_PERMISSION_SUCCESS:
		// 	return { ...state, rolePermission: action.payload }
		default:
			return state;
	}
}
