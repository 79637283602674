import {
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  SAVE_SIDE_PROFILE_ERROR,
  GET_UNIVERSITY_ABOUT,
  GET_UNIVERSITY_ABOUT_ERROR,
  GET_UNIVERSITY_ABOUT_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  SAVE_INTAKE_DATES,
  SAVE_INTAKE_DATES_ERROR,
  SAVE_INTAKE_DATES_SUCCESS,
  GET_INTAKE_DATES_ERROR,
  GET_INTAKE_DATES_SUCCESS,
  GET_INTAKE_DATES,
  SAVE_APPLICATION_FORM_ERROR,
  SAVE_APPLICATION_FORM_SUCCESS,
  SAVE_APPLICATION_FORM,
  GET_APPLICATION_FORM_ERROR,
  GET_APPLICATION_FORM_SUCCESS,
  GET_APPLICATION_FORM,
  GET_PROFILE_STATUS_ERROR,
  GET_PROFILE_STATUS_SUCCESS,
  GET_PROFILE_STATUS,
  GET_EP_SAVED_SEARCH_ERROR,
  GET_EP_SAVED_SEARCH_SUCCESS,
  GET_EP_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_ERROR,
  DELETE_SAVED_SEARCH_SUCCESS,
  DELETE_SAVED_SEARCH,
  GET_PP_SAVED_SEARCH_ERROR,
  GET_PP_SAVED_SEARCH_SUCCESS,
  GET_PP_SAVED_SEARCH,
  DELETE_DOCS_ERROR,
  DELETE_DOCS_SUCCESS,
  DELETE_DOCS,
  GET_PROFILE_FLAGS_ERROR,
  GET_PROFILE_FLAGS_SUCCESS,
  GET_PROFILE_FLAGS,
  GET_EDUCATION_SUB_LEVEL_ERROR,
  GET_EDUCATION_SUB_LEVEL_SUCCESS,
  GET_EDUCATION_SUB_LEVEL,
  PROGRAM_COUNTRY_ERROR,
  PROGRAM_COUNTRY_SUCCESS,
  GET_LOGIN_LOGS,
  GET_LOGIN_LOGS_SUCCESS,
  GET_LOGIN_LOGS_ERROR,
  PROGRAM_COUNTRY
} from "../constants";

export function saveSideProfileAction(session, resolve, reject) {
  return {
    type: SAVE_SIDE_PROFILE,
    payload: session,
    resolve,
    reject
  };
}

export function saveSideProfileSuccess(data) {
  return {
    type: SAVE_SIDE_PROFILE_SUCCESS,
    payload: data
  };
}

export function saveSideProfileError(error) {
  return {
    type: SAVE_SIDE_PROFILE_ERROR,
    payload: error
  };
}

export function getLoginLogsAction(session,resolve, reject) {
  return {
    type: GET_LOGIN_LOGS,
    payload: session,
    resolve,
    reject
  };
}

export function getLoginLogsSuccess(data) {
  return {
    type: GET_LOGIN_LOGS_SUCCESS,
    payload: data
  };
}

export function getLoginLogsError(error) {
  return {
    type: GET_LOGIN_LOGS_ERROR,
    payload: error
  };
}
export function getUniversityAboutAction(session, resolve, reject) {
  return {
    type: GET_UNIVERSITY_ABOUT,
    payload: session,
    resolve,
    reject
  };
}

export function getUniversityAboutSuccess(data) {
  return {
    type: GET_UNIVERSITY_ABOUT_SUCCESS,
    payload: data
  };
}

export function getUniversityAboutError(error) {
  return {
    type: GET_UNIVERSITY_ABOUT_ERROR,
    payload: error
  };
}

export function updateProfileAction(session, resolve, reject) {
  return {
    type: UPDATE_PROFILE,
    payload: session,
    resolve,
    reject
  };
}

export function updateProfileSuccess(data) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data
  };
}

export function updateProfileError(error) {
  return {
    type: UPDATE_PROFILE_ERROR,
    payload: error
  };
}

export function saveIntakeDatesAction(session, resolve, reject) {
  return {
    type: SAVE_INTAKE_DATES,
    payload: session,
    resolve,
    reject
  };
}

export function saveIntakeDatesSuccess(data) {
  return {
    type: SAVE_INTAKE_DATES_SUCCESS,
    payload: data
  };
}

export function saveIntakeDatesError(error) {
  return {
    type: SAVE_INTAKE_DATES_ERROR,
    payload: error
  };
}

export function getIntakeDatesAction(session, resolve, reject) {
  return {
    type: GET_INTAKE_DATES,
    payload: session,
    resolve,
    reject
  };
}

export function getIntakeDatesSuccess(data) {
  return {
    type: GET_INTAKE_DATES_SUCCESS,
    payload: data
  };
}

export function getIntakeDatesError(error) {
  return {
    type: GET_INTAKE_DATES_ERROR,
    payload: error
  };
}

export function saveApplicationFormAction(session, resolve, reject) {
  return {
    type: SAVE_APPLICATION_FORM,
    payload: session,
    resolve,
    reject
  };
}

export function saveApplicationFormSuccess(data) {
  return {
    type: SAVE_APPLICATION_FORM_SUCCESS,
    payload: data
  };
}

export function saveApplicationFormError(error) {
  return {
    type: SAVE_APPLICATION_FORM_ERROR,
    payload: error
  };
}

export function getApplicationFormAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_FORM,
    payload: session,
    resolve,
    reject
  };
}

export function getApplicationFormSuccess(data) {
  return {
    type: GET_APPLICATION_FORM_SUCCESS,
    payload: data
  };
}

export function getApplicationFormError(error) {
  return {
    type: GET_APPLICATION_FORM_ERROR,
    payload: error
  };
}

export function getProfileStatusAction(session, resolve, reject) {
  return {
    type: GET_PROFILE_STATUS,
    payload: session,
    resolve,
    reject
  };
}

export function getProfileStatusSuccess(data) {
  return {
    type: GET_PROFILE_STATUS_SUCCESS,
    payload: data
  };
}

export function getProfileStatusError(error) {
  return {
    type: GET_PROFILE_STATUS_ERROR,
    payload: error
  };
}

export function getEpSavedSearchAction(session, resolve, reject) {
  return {
    type: GET_EP_SAVED_SEARCH,
    payload: session,
    resolve,
    reject
  };
}

export function getEpSavedSearchSuccess(data) {
  return {
    type: GET_EP_SAVED_SEARCH_SUCCESS,
    payload: data
  };
}

export function getEpSavedSearchError(error) {
  return {
    type: GET_EP_SAVED_SEARCH_ERROR,
    payload: error
  };
}

export function deleteSavedSearchAction(session, resolve, reject) {
  return {
    type: DELETE_SAVED_SEARCH,
    payload: session,
    resolve,
    reject
  };
}

export function deleteSavedSearchSuccess(data) {
  return {
    type: DELETE_SAVED_SEARCH_SUCCESS,
    payload: data
  };
}

export function deleteSavedSearchError(error) {
  return {
    type: DELETE_SAVED_SEARCH_ERROR,
    payload: error
  };
}

export function getPpSavedSearchAction(session, resolve, reject) {
  return {
    type: GET_PP_SAVED_SEARCH,
    payload: session,
    resolve,
    reject
  };
}

export function getPpSavedSearchSuccess(data) {
  return {
    type: GET_PP_SAVED_SEARCH_SUCCESS,
    payload: data
  };
}

export function getPpSavedSearchError(error) {
  return {
    type: GET_PP_SAVED_SEARCH_ERROR,
    payload: error
  };
}

export function deleteDocsAction(session, resolve, reject) {
  return {
    type: DELETE_DOCS,
    payload: session,
    resolve,
    reject
  };
}

export function deleteDocsSuccess(data) {
  return {
    type: DELETE_DOCS_SUCCESS,
    payload: data
  };
}

export function deleteDocsError(error) {
  return {
    type: DELETE_DOCS_ERROR,
    payload: error
  };
}

export function getProfileFlagsAction(session, resolve, reject) {
  return {
    type: GET_PROFILE_FLAGS,
    payload: session,
    resolve,
    reject
  };
}

export function getProfileFlagsSuccess(data) {
  return {
    type: GET_PROFILE_FLAGS_SUCCESS,
    payload: data
  };
}

export function getProfileFlagsError(error) {
  return {
    type: GET_PROFILE_FLAGS_ERROR,
    payload: error
  };
}

export function getEducationSublevelAction(session, resolve, reject) {
  return {
    type: GET_EDUCATION_SUB_LEVEL,
    payload: session,
    resolve,
    reject
  };
}

export function getEducationSublevelSuccess(data) {
  return {
    type: GET_EDUCATION_SUB_LEVEL_SUCCESS,
    payload: data
  };
}

export function getEducationSublevelError(error) {
  return {
    type: GET_EDUCATION_SUB_LEVEL_ERROR,
    payload: error
  };
}

export function programCountryAction(session, resolve, reject) {
  return {
    type: PROGRAM_COUNTRY,
    payload: session,
    resolve,
    reject
  };
}

export function programCountrySuccess(data) {
  return {
    type: PROGRAM_COUNTRY_SUCCESS,
    payload: data
  };
}

export function programCountryError(error) {
  return {
    type: PROGRAM_COUNTRY_ERROR,
    payload: error
  };
}