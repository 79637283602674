import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_ERROR,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL,
  ONBOARDING_PROCESS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  AGENT_LIST,
  SAVE_PP_SEARCH,
  EP_LIST,
  UPDATE_EP_FAVOURITE,
  UPDATE_PROGRAM_FAVOURITE,
  GET_AGENT_PROFILE,
  GET_AGENT_REVIEWS,
  LIKE_DISLIKE_COMMENT,
  LIKE_DISLIKE_REVIEW,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  GET_UNIVERSITY_PROFILE,
  GET_UNIVERSITY_INTAKES,
  GET_PROGRAM_DETAILS_FILTERS,
  CHECK_ELIGIBILTY,
  GET_APPLICATION_ADVICE,
  CHECK_APPLIED_STATUS
} from "../constants";
import {
  baseUrl,
  COUNTRY_LIST,
  CURRENCY_LIST,
  ONBOARDING_PROCESS_STORE_API,
  FORGOT_PASSWORD_API,
  RESET_PASSWORD_API,
  AGENT_LIST_API,
  SAVE_PP_SEARCH_API,
  EP_LIST_API,
  EP_FAVOURITE_API,
  PROGRAM_FAVOURITE_API,
  GET_AGENT_PROFILE_API,
  GET_AGENT_REVIEWS_API,
  LIKE_DISLIKE_COMMENT_API,
  LIKE_DISLIKE_REVIEW_API,
  UPDATE_COMMENT_API,
  DELETE_COMMENT_API,
  GET_UNIVERSITY_PROFILE_API,
  GET_UNIVERSITY_INTAKES_API,
  GET_PROGRAM_DETAILS_FILTERS_API,
  CHECK_ELIGIBILITY_API,
  GET_ADVICE_API,
  CHECK_APPLIED_STATUS_API
} from "../../Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  getCountryListError,
  getCountryListSuccess,
  getCurrencyListSuccess,
  getCurencyListError,
  getCurrencyListError,
  onBoardingProcessSuccess,
  onBoardingProcessError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  agentListSuccess,
  agentListError,
  handleErrorResponse,
  savePpSearchSuccess,
  savePpSearchError,
  getEpListSuccess,
  getEpListError,
  updateEpFavouriteSuccess,
  updateEpFavouriteError,
  updateProgramFavouriteSuccess,
  updateProgramFavouriteError,
  getAgentProfileSuccess,
  getAgentProfileError,
  getAgentReviewsSuccess,
  getAgentReviewsError,
  likeDislikeReviewCommentSuccess,
  likeDislikeReviewCommentError,
  likeDislikeReviewSuccess,
  likeDislikeReviewError,
  updateCommentSuccess,
  updateCommentError,
  deleteCommentSuccess,
  deleteCommentError,
  getUniversityProfileSuccess,
  getUniversityProfileError,
  getIntakesSuccess,
  getIntakesError,
  getProgramDetailsFiltersSuccess,
  getProgramDetailsFiltersError,
  checkEligibiltySuccess,
  checkEligibiltyError,
  getApplicationAdviceSuccess,
  getApplicationAdviceError,
  checkAppliedStatusSuccess,
  checkAppliedStatusError
} from '../actions';

function getCountryListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + COUNTRY_LIST,
    // params: params
  });
}

function* fetchCountryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getCountryListApi, payload);
    yield put(getCountryListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getCountryListError(e))
    if (reject) reject()
  }
}

export function* getCountryListActionWatcher() {
  yield takeLatest(GET_COUNTRY_LIST, fetchCountryList)
}

function getCurrencyListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + CURRENCY_LIST,
    // params: params
  });
}

function* fetchCurrencyList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getCurrencyListApi, payload);
    yield put(getCurrencyListSuccess(response.data));
    if (resolve) resolve()
  } catch (e) {
    yield put(getCurrencyListError(e))
    if (reject) reject()
  }
}

export function* getCurrencyListActionWatcher() {
  yield takeLatest(GET_CURRENCY_LIST, fetchCurrencyList)
}

function onBoardingProcessApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + ONBOARDING_PROCESS_STORE_API,
		data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function* onBoardingProcess(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(onBoardingProcessApi, payload);
    yield put(onBoardingProcessSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(onBoardingProcessError(e))
    if (reject) reject()
  }
}

export function* onBoardingProcessActionWatcher() {
  yield takeLatest(ONBOARDING_PROCESS, onBoardingProcess)
}

function forgotPasswordApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + FORGOT_PASSWORD_API,
		data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function* forgotPassword(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(forgotPasswordApi, payload);
    yield put(forgotPasswordSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(forgotPasswordError(e))
    if (reject) reject()
  }
}

export function* forgotPasswordActionWatcher() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword)
}

function resetPasswordApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + RESET_PASSWORD_API,
		data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function* resetPassword(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(resetPasswordApi, payload);
    yield put(resetPasswordSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(resetPasswordError(e))
    if (reject) reject()
  }
}

export function* resetPasswordActionWatcher() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

function agentListApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + AGENT_LIST_API,
		params: params,		
  });
}

function* agentList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(agentListApi, payload);
    yield put(agentListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(agentListError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* agentListActionWatcher() {
  yield takeLatest(AGENT_LIST, agentList)
}

function savePpSearchApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + SAVE_PP_SEARCH_API,
		data: params,		
  });
}

function* savePpSearch(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(savePpSearchApi, payload);
    yield put(savePpSearchSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(savePpSearchError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* savePpSearchWatcher() {
  yield takeLatest(SAVE_PP_SEARCH, savePpSearch)
}

function getEpListApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + EP_LIST_API,
		params: params,		
  });
}

function* getEpList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getEpListApi, payload);
    yield put(getEpListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getEpListError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getEpListActionWatcher() {
  yield takeLatest(EP_LIST, getEpList)
}

function updateEpFavouriteApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + EP_FAVOURITE_API,
		data: params
  });
}

function* updateEpFavourite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(updateEpFavouriteApi, payload);
    yield put(updateEpFavouriteSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(updateEpFavouriteError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* updateEpFavouriteActionWatcher() {
  yield takeLatest(UPDATE_EP_FAVOURITE, updateEpFavourite)
}

function updateProgramFavouriteApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + PROGRAM_FAVOURITE_API,
		data: params
  });
}

function* updateProgramFavourite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(updateProgramFavouriteApi, payload);
    yield put(updateProgramFavouriteSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(updateProgramFavouriteError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* updateProgramFavouriteActionWatcher() {
  yield takeLatest(UPDATE_PROGRAM_FAVOURITE, updateProgramFavourite)
}

function getAgentProfileApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_AGENT_PROFILE_API+"/"+params.id,
    params
  });
}

function* getAgentProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getAgentProfileApi, payload);
    yield put(getAgentProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getAgentProfileError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getAgentProfileActionWatcher() {
  yield takeLatest(GET_AGENT_PROFILE, getAgentProfile)
}

function getAgentReviewsApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_AGENT_REVIEWS_API,
    params
  });
}

function* getAgentReviews(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getAgentReviewsApi, payload);
    yield put(getAgentReviewsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getAgentReviewsError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getAgentReviewsActionWatcher() {
  yield takeLatest(GET_AGENT_REVIEWS, getAgentReviews)
}

function likeDislikeCommentApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + LIKE_DISLIKE_COMMENT_API,
    data: params
  });
}

function* likeDislikeComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(likeDislikeCommentApi, payload);
    yield put(likeDislikeReviewCommentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(likeDislikeReviewCommentError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* likeDislikeCommentActionWatcher() {
  yield takeLatest(LIKE_DISLIKE_COMMENT, likeDislikeComment)
}

function likeDislikeReviewApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + LIKE_DISLIKE_REVIEW_API,
    data: params
  });
}

function* likeDislikeReview(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(likeDislikeReviewApi, payload);
    yield put(likeDislikeReviewSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(likeDislikeReviewError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* likeDislikeReviewActionWatcher() {
  yield takeLatest(LIKE_DISLIKE_REVIEW, likeDislikeReview)
}

function updateCommentApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + UPDATE_COMMENT_API,
    data: params
  });
}

function* updateComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(updateCommentApi, payload);
    yield put(updateCommentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(updateCommentError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* updateCommentActionWatcher() {
  yield takeLatest(UPDATE_COMMENT, updateComment)
}

function deleteCommentApi(params) {
	return axios.request({
		method: "delete",
		url: baseUrl + DELETE_COMMENT_API,
    data: params
  });
}

function* deleteComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteCommentApi, payload);
    yield put(deleteCommentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(deleteCommentError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* deleteCommentActionWatcher() {
  yield takeLatest(DELETE_COMMENT, deleteComment)
}

function getUniversityProfileApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_UNIVERSITY_PROFILE_API+"/"+params.id,
    params
  });
}

function* getUniversityProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getUniversityProfileApi, payload);
    yield put(getUniversityProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getUniversityProfileError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getUniversityProfileActionWatcher() {
  yield takeLatest(GET_UNIVERSITY_PROFILE, getUniversityProfile)
}

function getIntakesActionApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_UNIVERSITY_INTAKES_API,
    params
  });
}

function* getIntakesAction(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getIntakesActionApi, payload);
    yield put(getIntakesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getIntakesError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getIntakesActionWatcher() {
  yield takeLatest(GET_UNIVERSITY_INTAKES, getIntakesAction)
}

function getProgramDetailsFiltersApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_PROGRAM_DETAILS_FILTERS_API,
    params
  });
}

function* getProgramDetailsFilters(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProgramDetailsFiltersApi, payload);
    yield put(getProgramDetailsFiltersSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getProgramDetailsFiltersError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getProgramDetailsFiltersActionWatcher() {
  yield takeLatest(GET_PROGRAM_DETAILS_FILTERS, getProgramDetailsFilters)
}

function checkEligibilityApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + CHECK_ELIGIBILITY_API,
    params
  });
}

function* checkEligibility(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(checkEligibilityApi, payload);
    yield put(checkEligibiltySuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(checkEligibiltyError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* checkEligibilityActionWatcher() {
  yield takeLatest(CHECK_ELIGIBILTY, checkEligibility)
}

function getApplicationAdviceApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + GET_ADVICE_API,
    data: params
  });
}

function* getApplicationAdvice(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getApplicationAdviceApi, payload);
    yield put(getApplicationAdviceSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getApplicationAdviceError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* getApplicationAdviceActionWatcher() {
  yield takeLatest(GET_APPLICATION_ADVICE, getApplicationAdvice)
}

function checkAppliedStatusApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + CHECK_APPLIED_STATUS_API,
    params
  });
}

function* checkAppliedStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(checkAppliedStatusApi, payload);
    yield put(checkAppliedStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(checkAppliedStatusError(e))
    handleErrorResponse(e.response)
    if (reject) reject()
  }
}

export function* checkAppliedStatusActionWatcher() {
  yield takeLatest(CHECK_APPLIED_STATUS, checkAppliedStatus)
}