export const isLoggedIn = () => {
  const studentId = localStorage.getItem("studentid");
  const loggedInCookie = checkUserLoggedIn()

  return ((studentId != "" && studentId != null) || (loggedInCookie && loggedInCookie.loggedIn)) ? true : false;
}

function getCookieByName(name) {
  let cookies = document.cookie
  cookies = cookies.split("; ")?.find(row => row.startsWith(name))?.split("=")[1]

  return cookies
}

function checkUserLoggedIn() {
  let userId = getCookieByName('userId')
  let type = getCookieByName('userType')
  let loggedIn = userId && (type == 'student') ? true : false;

  return loggedIn ? {
    loggedIn,
    type,
    userId
  } : null;
}