import React from 'react';
// import ShareProfile from "../emailTemplate/ShareProfile"
// import AddViewer from "../emailTemplate/AddViewer"
import Hire from "../emailTemplate/Hire"
// import TerminateContract from "../emailTemplate/TerminateContract"

export default function EmailTemplate(props) {

let mailTemplate = () => {
  let element;

  switch (props.item.request_mail_type) {
    case 1:
      element =
        <Hire
          data={props.item}
          updateHireRequest={(data) => {
            return props.updateHireRequest(data)
          }}
          agent={props.agent}
        />;
      break;
    
    default: element = <h1>Email type not found!</h1>
  }

  return element
}

  return <>{mailTemplate()}</>;
}