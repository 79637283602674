import React from "react";
import template from "./AppPersonalinfo.jsx";
import config from "../../config.json";

import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ALN_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  PAS_MAX_REG,
  ZIP_MAX_REGEX,
} from "../../constant/constant.js";
import { MESSAGES } from "../../constant/messages";
import submitForm from "../../utils/submitForm.js";

const baseurl = `${config.baseurl}`;

class AppPersonalinfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      questiondata: [],
      selectedQuestion: null,
      country: [],
      states: [],
      cities: [],
      level: [],
      grade: [],
      uniQuestTextArr: [],
      uniQuestSignleArr: [],
      uniQuestMultiArr: [],
      errors: {},
      fname: '',
      lname: '',
      dateofbirth: '',
      flang: '',
      citiship: '',
      passno: '',
      sgender: '',
      smarital: '',
      saddr: '',
      scity: '',
      scountry: '',
      sstate: '',
      szip: '',
      semail: '',
      sphcode: '',
      sphno: '',
      smbcode: '',
      smbno: '',
      sdob: '',
      addMore: false,
      levelArr: [],
      formData: new FormData(),
      checkedMultiAnsrs: new Map(),
      checkedRadioAnsrs: new Map(),
      textAreaAnsrs: [],
      showCompleteProfileMessage: true,
      edit: true,
      appQuestions: [],
      appAnswers: []
    };

  }

  componentDidMount() {
    this.getGeneraldata();
  }

  componentDidUpdate(prevProps) {
    // if (this.props.type != prevProps.type) {
    //   console.log('log this.props.type', prevProps.type, this.props.type);
    //   this.getGeneraldata()
    // }
    if (this.props.applicationId != prevProps.applicationId) {
      this.getGeneraldata()
    }
    if (prevProps.showCompleteProfileMessage != this.props.showCompleteProfileMessage) {
      this.setState({
        showCompleteProfileMessage: this.props.showCompleteProfileMessage
      })
    }
  }

  setQuestiondata = (index) => {
    this.setState({ selectedQuestion: index, addMore: true });
  };

  getQuestiondata = (question) => {
    this.setState({ questiondata: question });
    console.log(question);
  };

  backBtn = () => {
    let data = localStorage.getItem("apptype");
    if (data === "Sent") {
      window.location.href = "/#/sendapplication";
    } else if (data === "Draft") {
      window.location.href = "/#/draftapplication";
    } else if (data === "Accepted") {
      window.location.href = "/#/Acceptedapplication";
    }
  };

  nexttab = () => {
    $("#tab2").addClass("active");
    $("#tab1").removeClass("active");
    $(".generaltab").removeClass("active");
    $(".educationtab").addClass("active");
  };

  getGeneraldata = () => {
    this.props.showLoader();
    const countryReq = axios.get(baseurl + "/get_country");
    const levelDetailsReq = axios.get(baseurl + "/get_leveldetails");
    let generalInfoAppReq = null
    if (this.props.type && this.props.type == "program") {
      generalInfoAppReq = axios.get(
        baseurl + "/get_generalinfo/" + localStorage.getItem("studentid")
      );
    } else {
      generalInfoAppReq = axios.get(
        baseurl + "/get_generalinfo_app/" + localStorage.getItem("studentid"), {
        params: {
          application_id: this.props.applicationId
        }
      }
      );
    }

    Promise.all([countryReq, levelDetailsReq, generalInfoAppReq])
      .then(async ([countryRes, levelDetailsRes, generalInfoAppRes]) => {
        const levelDetailsResData = levelDetailsRes.data
          ? {
            level: levelDetailsRes.data.level,
            grade: levelDetailsRes.data.grade
          }
          : {};
        let generalInfoAppResData = {};

        if (this.props.type == "program") {
          if (generalInfoAppRes.data && generalInfoAppRes.data.length > 0) {
            if (generalInfoAppRes.data[0].dateof_birth != null
              && isNaN(Date.parse(generalInfoAppRes.data[0].dateof_birth)) == false
            ) {
              var sdob = new Date(generalInfoAppRes.data[0].dateof_birth);
            }
            const stateRes = await axios.get(
              baseurl + "/get_state/" + generalInfoAppRes.data[0].addr_country
            );

            let cityRes = null
            if (generalInfoAppRes.data[0] && generalInfoAppRes.data[0].state) {
              cityRes = await axios.get(
                baseurl + "/get_city/" + generalInfoAppRes.data[0].state
              );
            }
            generalInfoAppResData = {
              cities: cityRes && cityRes.data ? cityRes.data : this.state.cities,
              states: stateRes.data ? stateRes.data : this.state.states,
              title: generalInfoAppRes.data[0].title,
              fname: generalInfoAppRes.data[0].first_name,
              mname: generalInfoAppRes.data[0].middlename,
              lname: generalInfoAppRes.data[0].last_name,
              dateofbirth: sdob,
              flang: generalInfoAppRes.data[0].first_language,
              citiship: generalInfoAppRes.data[0].nationality,
              passno: generalInfoAppRes.data[0].passport_no,
              sgender: generalInfoAppRes.data[0].gender,
              smarital: generalInfoAppRes.data[0].marital_status,

              saddr: generalInfoAppRes.data[0].address,
              saddr2: generalInfoAppRes.data[0].saddr2,
              scity: generalInfoAppRes.data[0].city,
              scountry: generalInfoAppRes.data[0].addr_country,
              sstate: generalInfoAppRes.data[0].state,
              szip: generalInfoAppRes.data[0].zipcode,
              semail: generalInfoAppRes.data[0].addr_email,
              sphcode: generalInfoAppRes.data[0].ph_code,
              sphno: generalInfoAppRes.data[0].phone,
              smbcode: generalInfoAppRes.data[0].cell_code,
              smbno: generalInfoAppRes.data[0].cellphone,
              next_kin_name: generalInfoAppRes.data[0].next_kin_name,
              next_kin_contact: generalInfoAppRes.data[0].next_kin_contact,
            };
          }
        } else {
          if (generalInfoAppRes.data.data && generalInfoAppRes.data.data.length > 0) {
            if (generalInfoAppRes.data.data[0].dateof_birth != null
              && isNaN(Date.parse(generalInfoAppRes.data.data[0].dateof_birth)) == false
              ) {
              var sdob = new Date(generalInfoAppRes.data.data[0].dateof_birth);
            }
            const stateRes = await axios.get(
              baseurl + "/get_state/" + generalInfoAppRes.data.data[0].addr_country
            );

            let cityRes = null
            if (generalInfoAppRes.data.data[0] && generalInfoAppRes.data.data[0].state) {
              cityRes = await axios.get(
                baseurl + "/get_city/" + generalInfoAppRes.data.data[0].state
              );
            }

            generalInfoAppResData = {
              cities: cityRes && cityRes.data ? cityRes.data : this.state.cities,
              states: stateRes.data ? stateRes.data : this.state.states,
              title: generalInfoAppRes.data.data[0].title,
              fname: generalInfoAppRes.data.data[0].first_name,
              mname: generalInfoAppRes.data.data[0].middlename,
              lname: generalInfoAppRes.data.data[0].last_name,
              dateofbirth: sdob,
              flang: generalInfoAppRes.data.data[0].first_language,
              citiship: generalInfoAppRes.data.data[0].nationality,
              passno: generalInfoAppRes.data.data[0].passport_no,
              sgender: generalInfoAppRes.data.data[0].gender,
              smarital: generalInfoAppRes.data.data[0].marital_status,

              saddr: generalInfoAppRes.data.data[0].address,
              saddr2: generalInfoAppRes.data.data[0].saddr2,
              scity: generalInfoAppRes.data.data[0].city,
              scountry: generalInfoAppRes.data.data[0].addr_country,
              sstate: generalInfoAppRes.data.data[0].state,
              szip: generalInfoAppRes.data.data[0].zipcode,
              semail: generalInfoAppRes.data.data[0].addr_email,
              sphcode: generalInfoAppRes.data.data[0].ph_code,
              sphno: generalInfoAppRes.data.data[0].phone,
              smbcode: generalInfoAppRes.data.data[0].cell_code,
              smbno: generalInfoAppRes.data.data[0].cellphone,
              next_kin_name: generalInfoAppRes.data.data[0].next_kin_name,
              next_kin_contact: generalInfoAppRes.data.data[0].next_kin_contact,
            };

            if (generalInfoAppRes.data.data[0] && generalInfoAppRes.data.data[0].question) {
              let appQuestions = generalInfoAppRes.data.data[0].question
              let appAnswers = appQuestions.filter(question => question.answers).map(question => ({
                question_id: question.question_details.id,
                answer: question.answers
              }))
              console.log('log appAnswers first', appAnswers);
              this.setState({
                appQuestions,
                appAnswers
              })
            }
          }
        }

        //alert(JSON.stringify(levelDetailsRes.data.level));
        this.setState({
          country: countryRes.data,
          levelArr: levelDetailsRes.data.level,
          ...levelDetailsResData,
          ...generalInfoAppResData,
        }, () => this.props.hideLoader());
      })
      .catch((error) => {
        console.log('error: ', error);
        this.props.hideLoader()
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        this.props.hideLoader();
      });
  };
  handleTargetChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDobChange = (date) => {
    this.setState({ dateofbirth: date });
  };
  handleScountryChange = (event) => {
    this.setState({ scountry: event.target.value });
    this.props.showLoader();
    axios.get(baseurl + "/get_state/" + event.target.value).then((response) => {
      this.props.hideLoader();
      this.setState({
        states: response.data,
        cities: [],
      }, () => this.props.hideLoader());
    }).catch((e) => {
      console.log('e: ', e);
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
    });
  };
  handleSstateChange = (event) => {
    this.setState({ sstate: event.target.value });
    this.props.showLoader();
    axios.get(baseurl + "/get_city/" + event.target.value).then((response) => {
      this.props.hideLoader();
      this.setState({
        cities: response.data,
      }, () => this.props.hideLoader());
    }).catch((e) => {
      console.log('e: ', e);
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
    });
  };

  handleMultiCheckChange = (event) => {
    var isChecked = event.target.checked;
    var item = event.target.value;
    this.setState(prevState => ({ checkedMultiAnsrs: prevState.checkedMultiAnsrs.set(item, isChecked) }));
  };

  handleRadioCheckChange = (event) => {
    var isChecked = event.target.checked;
    var item = event.target.value;
    this.setState(prevState => ({ checkedRadioAnsrs: prevState.checkedRadioAnsrs.set(item, isChecked) }));
  };

  handleTextArea = (e, index) => {
    this.state.textAreaAnsrs.push(e.target.value);
  };

  validate = () => {
    const errors = {};
    const semail = this.state.semail;

    let regAln = ALN_REGEX;
    let pasmax = PAS_MAX_REG;
    let zipmax = ZIP_MAX_REGEX;
    let minmax = /^.{10,15}$/;
    let code = /^\d{1,2}$/;
    let regNam = NAME_REGEX;
    if (this.state.fname === "") {
      errors.fname = MESSAGES.THIS_IS_REQUIRED;
    } else if (this.state.fname.trim().length < 3) {
      errors.fname = MESSAGES.ENTER_VALID_NAME;
    } else if (!this.state.fname.match(regNam)) {
      errors.fname = MESSAGES.ENTER_VALID_NAME;
    }

    if (this.state.lname === "") {
      errors.lname = MESSAGES.THIS_IS_REQUIRED;
    } else if (this.state.lname.trim().length < 2) {
      errors.lname = MESSAGES.ENTER_VALID_NAME;
    } else if (!this.state.lname.match(regNam)) {
      errors.lname = MESSAGES.ENTER_VALID_NAME;
    }

    if ($("#dateofbirth").val().trim() === "") {
      errors.dateofbirth = MESSAGES.THIS_IS_REQUIRED;
    }

    if (this.state.flang === "") {
      errors.flang = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.citiship === "") {
      errors.citiship = MESSAGES.PLEASE_SELECT_MESSAGE;
    }

    if (this.state.passno === "") {
      errors.passno = MESSAGES.THIS_IS_REQUIRED;
    } else if (!this.state.passno.match(pasmax)) {
      errors.passno = MESSAGES.ENTER_VALID_NUMBER;
    } else if (!this.state.passno.match(regAln)) {
      errors.passno = MESSAGES.ENTER_VALID_NUMBER;
    }

    if (this.state.sgender === "") {
      errors.sgender = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.smarital === "") {
      errors.smarital = MESSAGES.PLEASE_SELECT_MESSAGE;
    }

    if (this.state.saddr === "") {
      errors.saddr = MESSAGES.THIS_IS_REQUIRED;
    } else if (this.state.saddr.trim().length < 15) {
      errors.saddr = MESSAGES.ENTER_VALID_ADDRESS;
    }

    // if (this.state.scity === "") {
    //   errors.scity = MESSAGES.THIS_IS_REQUIRED;
    // }

    if (this.state.scountry === "") {
      errors.scountry = MESSAGES.PLEASE_SELECT_MESSAGE;
    }

    if (this.state.sstate === "") {
      errors.sstate = MESSAGES.THIS_IS_REQUIRED;
    }

    if (this.state.szip === "") {
      errors.szip = MESSAGES.THIS_IS_REQUIRED;
    } else if (!$("#szip").val().match(zipmax)) {
      errors.szip = MESSAGES.ENTER_VALID_ZIP;
    } else if (!$("#szip").val().match(regAln)) {
      errors.szip = MESSAGES.ENTER_VALID_ZIP;
    }
    if (this.state.semail === "") {
      errors.semail = MESSAGES.THIS_IS_REQUIRED;
    } else if (!semail.match(EMAIL_REGEX)) {
      errors.semail = MESSAGES.EMAIL_INVALID;
    }

    if (this.state.sphcode === "") {
      errors.sphno = MESSAGES.CODE_REQUIRED;
    } else if (!$("#sphcode").val().match(code)) {
      errors.sphno = MESSAGES.ENTER_VALID_CODE;
    }
    //else if(!this.state.sphcode.match(code)) { errors.sphno = 'Enter valid code'; }

    if (this.state.sphno === "") {
      errors.sphno = MESSAGES.THIS_IS_REQUIRED;
    } else if (!this.state.sphno.match(minmax)) {
      errors.sphno = MESSAGES.ENTER_VALID_PHONE_NUMBER;
    }

    if (this.state.smbcode === "") {
      errors.smbno = MESSAGES.CODE_REQUIRED;
    } else if (!$("#smbcode").val().match(code)) {
      errors.smbno = MESSAGES.ENTER_VALID_CODE;
    }
    //else if(!this.state.smbcode.match(code)) { errors.smbno = 'Enter valid code'; }

    if (this.state.smbno === "") {
      errors.smbno = MESSAGES.THIS_IS_REQUIRED;
    } else if (!this.state.smbno.match(minmax)) {
      errors.smbno = MESSAGES.ENTER_VALID_PHONE_NUMBER;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  onSubmit = (exit, e) => {
    e.preventDefault();
    const errors = this.validate();
    console.log('log errors', errors);
    this.setState({ errors });

    let multiCheckArr = [];
    $(".multiCheck").each(function (i) {
      if ($(this).is(":checked")) {
        multiCheckArr.push($(this).val());
      }
      else {
        multiCheckArr.push($(this).val() + '|uncheck');
      }
    });

    let multiTextArr = [];
    $(".ansrTxt").each(function (i) {
      if ($(this).val() != '') {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id"));
      }
      else {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id") + '|uncheck');
      }
    });

    let radioQuesArr = [];
    $(".generalradio").each(function (i) {
      if ($(this).is(":checked")) {
        radioQuesArr.push($(this).val());
      }
    });


    if (errors) return;
    this.setState({
      edit: false
    })
    this.state.formData.append("title", this.state.title);
    this.state.formData.append("fname", this.state.fname);
    this.state.formData.append("mname", this.state.mname);
    this.state.formData.append("lname", this.state.lname);
    this.state.formData.append("dateofbirth", $("#dateofbirth").val());
    this.state.formData.append("flang", this.state.flang);
    this.state.formData.append("citiship", this.state.citiship);
    this.state.formData.append("passno", this.state.passno);
    this.state.formData.append("sgender", this.state.sgender);
    this.state.formData.append("smarital", this.state.smarital);
    this.state.formData.append("saddr", this.state.saddr);
    this.state.formData.append("saddr2", this.state.saddr2);
    this.state.formData.append("scity", this.state.scity);
    this.state.formData.append("scountry", this.state.scountry);
    this.state.formData.append("sstate", this.state.sstate);
    this.state.formData.append("szip", this.state.szip);
    this.state.formData.append("semail", this.state.semail);
    this.state.formData.append("sphcode", this.state.sphcode);
    this.state.formData.append("sphno", this.state.sphno);
    this.state.formData.append("smbcode", this.state.smbcode);
    this.state.formData.append("smbno", this.state.smbno);
    this.state.formData.append("next_kin_name", this.state.next_kin_name);
    this.state.formData.append("next_kin_contact", this.state.next_kin_contact);
    this.state.formData.append("uniAnsrMulti", multiCheckArr);
    this.state.formData.append("ansrTxt", multiTextArr);
    this.state.formData.append("uniAnsrRadio", radioQuesArr);
    this.state.formData.append("uni_id", localStorage.getItem("universityid"));

    this.state.formData.append("app_student_id", localStorage.getItem("app_student_id"));
    this.state.formData.append("student_id", localStorage.getItem("studentid"));

    if (this.props.type == "program") {
      this.state.formData.append('course_id', this.props.programId)
    } else {
      this.state.formData.append("application_id", this.props.applicationId);
    }

    this.state.appAnswers.forEach((val, index) => {
      this.state.formData.append(`answer[${index}][question_id]`, val.question_id)
      if (Array.isArray(val.answer)) {
        val.answer.forEach((option, optionIndex) => {
          this.state.formData.append(`answer[${index}][answer][${optionIndex}]`, option)
        })
      } else {
        this.state.formData.append(`answer[${index}][answer]`, val.answer)
      }
    })

    this.setState({ errors: "" });
    if (this.props.type == "program") {
      this.props.saveApplication(this.state.formData)
      return
    }
    this.props.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        if (msg == "error") {
          // toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE)
        } else {
          this.props.getApplicationStepStatus()
          toast.success(msg.message);

          if (msg.data && msg.data.application_id) {
            // this.props.updateStatus(msg.data.application_id)
          }
          if (exit) {
            this.props.history.push('/draftapplication')
          }
          this.props.updateTabs()
          setTimeout(function () {
            $("#tab2").addClass("active");
            $("#tab1").removeClass("active");
            $(".generaltab").removeClass("active");
            $(".educationtab").addClass("active");
          }, 2000);
        }
      },
      "storestudentgeninfo_app"
    )
  };

  render() {
    return template.call(this);
  }
}

export default AppPersonalinfo;
