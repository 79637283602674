import React from "react";
import template from "./AppUploadDocument.jsx";
import config from "../../config.json";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MESSAGES } from "../../constant/messages.js";
import { ALLOWED_FILE_EXTENSION_REGEX } from "../../constant/constant.js";
import submitForm from "../../utils/submitForm.js";
import {
  checkValidFileSize,
  checkValidPdfFile,
  formatBytes,
} from "../../common/commonHelper.js";

const baseurl = `${config.baseurl}`;

class AppUploadDocument extends React.Component {
  constructor(props) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      disabled:
        localStorage.getItem("fromApplicationType") === "Sent" ? true : false,
      foldertype: "",
      docs: [],
      pers: [],
      trans: [],
      errors: {},
      formData: new FormData(),
      upload_photo: [],
      fileLength: 0,
      filename: "",
      showtranscripts: false,
      showpersonal: false,
      fileModalOpen1: false,
      fileModalOpenQuesAnsrs: false,
      fileDropdownOpen: false,
      contentclose2: this.props.contentclose2,
      uploadDocType: "",
      uploadDocType2: "Type of Document",
      type_cat: "",
      docIetsToefl: [],
      docYear12Edu: [],
      preQualiApp: [],
      certifPassport: [],
      optinalDocs: [],
      uniQuestUploadArr: [],
      uniQuestUploadId: "",
      successUpload: false,
      showCompleteProfileMessage: true,
      applicationPdfs: [],
      existingApplicationPdfs: [],
      applicationPdfsProgress: [],
      appDocumentsExtra: [],
      fileTypes: [
        {
          value: "",
          label: "Type of document",
        },
        {
          value: 1,
          label: "English language proficiency",
        },
        {
          value: 2,
          label: "Year 12 education",
        },
        {
          value: 3,
          label: "Other previous qualifications",
        },
        {
          value: 4,
          label: "Certified copy of Passport",
        },
      ],
      selectedFiles: [],
      isExtras: false,
      supportingDocuments: [],
    };
  }

  componentDidMount() {
    this.getPageData();
    if (localStorage.getItem("completeprofile") == 1) {
      $(".disablepro").prop("disabled", true);
      $(".aftersave").attr("style", "display: block !important");
      $(".beforesave").attr("style", "display: none !important");
    } else {
      $(".disablepro").prop("disabled", false);
      $(".beforesave").attr("style", "display: block !important");
      $(".aftersave").attr("style", "display: none !important");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type != this.props.type) {
    }
    if (prevProps.applicationId != this.props.applicationId) {
      this.getPageData();
    }
    if (prevProps.applicationFormId != this.props.applicationFormId) {
      this.getPageData();
    }
    if (
      prevProps.showCompleteProfileMessage !=
      this.props.showCompleteProfileMessage
    ) {
      this.setState({
        showCompleteProfileMessage: this.props.showCompleteProfileMessage,
      });
    }
    if (prevProps.supportingDocuments != this.props.supportingDocuments) {
      this.setState({
        supportingDocuments: this.props.supportingDocuments,
      });
    }
  }

  getPageData = async () => {
    this.props.getApplicationStepStatus();
    try {
      this.props.showLoader();
      const stuDocumentsRes = await axios.get(
        baseurl + "/get_studocuments_app/" + localStorage.getItem("studentid"),
        {
          params: {
            application_id: this.props.applicationId,
          },
        }
      );

      if (stuDocumentsRes.data && stuDocumentsRes.data.data) {
        if (stuDocumentsRes.data.data.answers) {
          let appDocumentsExtra = stuDocumentsRes.data.data.answers;
          let { fileTypes } = this.state;

          appDocumentsExtra.forEach((val) => {
            fileTypes.push({
              value: val.question_details.id,
              label: val.question_details.question,
            });
          });
          this.setState({
            appDocumentsExtra,
            fileTypes,
          });
        }
      }

      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      console.log("error: ", error);
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    }
    console.log(
      "log this.props.applicationFormId",
      this.props.applicationFormId
    );
    try {
      this.props.showLoader();
      if (this.props.applicationFormId) {
        const applicationPdfs = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            this.props.applicationId +
            "/0"
        );
        this.props.hideLoader();
        if (
          applicationPdfs.data &&
          applicationPdfs.data.data &&
          applicationPdfs.data.data.docs
        ) {
          let existingApplicationPdfs = applicationPdfs.data.data.docs.map(
            (val) => ({
              file: {
                name: val.orgfilename,
              },
              id: val.id,
              path: val.filename,
              status: val.status,
            })
          );
          this.setState({
            existingApplicationPdfs,
          });
        }
      } else {
        const ietsToeflDocumentsRes = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            this.props.applicationId +
            "/1"
        );
        const year12EduDocumentsRes = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            this.props.applicationId +
            "/2"
        );
        const preQuaAppDocumentsRes = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            this.props.applicationId +
            "/3"
        );
        const certifPassportDocumentsRes = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            this.props.applicationId +
            "/4"
        );
        const optionalDocumentsRes = await axios.get(
          baseurl +
            "/get_studocuments_app_type/" +
            localStorage.getItem("studentid") +
            "/optional"
        );

        const uniQuestUploadReq = await axios.get(
          baseurl +
            "/uni_app_ques/" +
            localStorage.getItem("universityid") +
            "/documents/4"
        );

        this.setState(
          {
            docIetsToefl: ietsToeflDocumentsRes.data.data.docs,
            docYear12Edu: year12EduDocumentsRes.data.data.docs,
            preQualiApp: preQuaAppDocumentsRes.data.data.docs,
            certifPassport: certifPassportDocumentsRes.data.data.docs,
            optinalDocs: optionalDocumentsRes.data.data.docs,
            uniQuestUploadArr: uniQuestUploadReq.data.data,
          },
          () => this.props.hideLoader()
        );
      }
    } catch (error) {
      this.props.hideLoader();
      console.log("error: ", error);
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    }
  };

  deleteFile = (event) => {
    axios
      .get(
        baseurl +
          "/get_studocuments_app_delete/" +
          localStorage.getItem("studentid") +
          "/" +
          event.currentTarget.getAttribute("data-column")
      )
      .then((data) => {
        this.getPageData();
      });
  };

  deleteQuesAnsrsFile = (event) => {
    axios.get(
      baseurl +
        "/uni_app_ques_docs_delete/" +
        localStorage.getItem("studentid") +
        "/" +
        event.currentTarget.getAttribute("data-column")
    );
    this.getPageData();
  };

  closepop3 = () => {
    this.setState({
      contentclose2: false,
    });
  };

  handleAddFileModal1 = (event) => {
    event.preventDefault();
    this.setState({
      uploadDocType: "1",
      fileModalOpen1: !this.state.fileModalOpen1,
    });
  };

  handleExtraModal = (event, uploadDocType) => {
    event.preventDefault();
    this.setState({
      uploadDocType,
      fileModalOpen1: !this.state.fileModalOpen1,
      isExtras: true,
    });
  };

  handleAddFileModalQuesAnsr = (event) => {
    event.preventDefault();
    this.setState({
      fileModalOpenQuesAnsrs: !this.state.fileModalOpenQuesAnsrs,
    });
  };

  handleAddFileModal2 = (event) => {
    event.preventDefault();
    this.setState({
      uploadDocType: "2",
      fileModalOpen1: !this.state.fileModalOpen1,
    });
  };

  handleAddFileModal3 = (event) => {
    event.preventDefault();
    this.setState({
      uploadDocType: "3",
      fileModalOpen1: !this.state.fileModalOpen1,
    });
  };

  handleAddFileModal4 = (event) => {
    event.preventDefault();
    this.setState({
      uploadDocType: "4",
      fileModalOpen1: !this.state.fileModalOpen1,
    });
  };

  handleAddFileModal6 = (event) => {
    event.preventDefault();
    this.setState({
      type_cat: "optional",
      fileModalOpen1: !this.state.fileModalOpen1,
    });
  };

  handleAddQuesAnsrsMandtry = (event) => {
    event.preventDefault();
    //alert(event.target.getAttribute('data-id'));
    this.setState({
      uniQuestUploadId: event.target.getAttribute("data-id"),
      type_cat: "mandatory",
      fileModalOpenQuesAnsrs: !this.state.fileModalOpenQuesAnsrs,
    });
  };

  handleBrowseFrom = (type) => {
    this.setState({
      browseFrom: type,
      fileDropdownOpen: false,
    });
  };

  handleGPickerFileChange = (data) => {
    if (data && data.docs) {
      let { fileLength } = this.state;
    }
  };

  handleUploadDocTypeChange = (event) => {
    this.setState({ uploadDocType: event });
  };

  handleUploadDocTypeChange2 = (event) => {
    this.setState({ uploadDocType2: event });
  };

  handleBrowseFromDevice = (event) => {
    //console.log(event.target.files);
    let { selectedFiles } = this.state;
    let files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      selectedFiles.push(files[i]);
    }

    this.setState({
      selectedFiles,
      errors: {
        ...this.state.errors,
        fileLength: "",
        docType: "",
      },
    });
  };

  handleDropFiles = (files, event) => {
    let { selectedFiles } = this.state;

    for (let i = 0; i < files.length; i++) {
      selectedFiles.push(files[i]);
    }

    this.setState({
      selectedFiles,
      errors: {
        ...this.state.errors,
        fileLength: "",
        docType: "",
      },
    });
  };

  closeSaveSearches = () => {
    this.setState({
      successUpload: !this.state.successUpload,
    });
  };

  handleFolderChange = (param) => (e) => {
    this.setState({ foldertype: param });
    if (param == 1) {
      $(".personal").addClass("active");
      $(".transcripts").removeClass("active");
      this.setState({ showpersonal: true, showtranscripts: false });
    } else {
      $(".transcripts").addClass("active");
      $(".personal").removeClass("active");
      this.setState({ showtranscripts: true, showpersonal: false });
    }
  };

  onClickDelete = (param) => (e) => {
    this.props.showLoader();
    axios
      .get(
        baseurl +
          "/delete_studocuments/" +
          localStorage.getItem("studentid") +
          "/" +
          param
      )
      .then((response) => {
        this.props.hideLoader();
        toast.success(MESSAGES.DELETED_SUCCESSFULLY);
        this.getPageData();
      })
      .catch((e) => {
        console.log("e: ", e);
        this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
      });
  };

  onFileChange = (event) => {
    this.setState({ fileLength: event.target.files.length });
    if (event.target.files.length < 2) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.setState({
          filename: event.target.files[0].name,
        });
        this.state.formData.append(
          "upload_photo[]",
          event.target.files[i],
          event.target.files[i].name
        );
      }
    }
  };

  prevTab = () => {
    $("#tab4").addClass("active");
    $("#tab5").removeClass("active");
    $(".uploadtab").removeClass("active");
    $(".bgtab").addClass("active");
  };

  /*validate = () => {
    const errors = {};
    const fi = document.getElementById("upfile");
    let filePath = fi.value;
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        const file = Math.round(fsize / 1024);
        if (file >= 5120) {
          errors.fileLength = MESSAGES.FILE_TOO_BIG;
        } else if (!ALLOWED_FILE_EXTENSION_REGEX.exec(filePath)) {
          errors.fileLength = MESSAGES.INVALID_FILE_TYPE;
        }
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };*/

  validate = () => {
    const errors = {};

    if (!this.state.selectedFiles.length) {
      errors.fileLength = MESSAGES.PLEASE_SELECT_FILE;
    }
    if (!this.state.uploadDocType) {
      errors.docType = MESSAGES.PLEASE_SELECT_DOCUMENT_TYPE;
    }
    if (errors) {
      this.setState({ errors });
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleUploadFilesQuesAnsr = () => {
    //const errors = this.validate();
    //if (errors) return;

    this.state.formData.append("que_id", this.state.uniQuestUploadId);
    this.state.formData.append("app_id", this.props.applicationId);
    this.state.formData.append("student_id", localStorage.getItem("studentid"));

    this.uploadFiles(this.state.formData, (msg) => {
      toast.success(MESSAGES.PROFILE_COMPLETED_SUCCESSFULLY);
      this.setState({
        fileModalOpen: false,
        fileModalOpenQuesAnsrs: false,
        filename: [],
        fileLength: 0,
        uniQuestUploadId: "",
        uploadDocType: "",
        foldertype: "",
        imgfile: "",
        upload_photo: "",
        errors: {},
        formData: new FormData(),
      });
      this.getPageData();
      $("#tab5").addClass("active");
      $(".uploadtab").addClass("active");
    });
  };

  handleUploadFiles = () => {
    const errors = this.validate();
    if (errors) return;

    let formData = new FormData();
    formData.append("foldertype", this.state.uploadDocType);
    formData.append("student_id", localStorage.getItem("studentid"));
    formData.append("app_id", this.props.applicationId);
    formData.append("application_id", this.props.applicationId);
    this.state.selectedFiles.forEach((val) => {
      formData.append("upload_photo[]", val);
    });
    if (this.state.isExtras) {
      formData.append("que_id", this.state.uploadDocType);
    }
    this.setState({
      isExtras: false,
    });

    this.uploadFiles(formData, (msg) => {
      toast.success(MESSAGES.PROFILE_COMPLETED_SUCCESSFULLY);
      this.setState({
        fileModalOpen1: false,
        selectedFiles: [],
        errors: {},
        foldertype: "",
      });
      this.getPageData();
      // $("#tab5").addClass("active");
      // $(".uploadtab").addClass("active");
    });
  };

  uploadFiles = (data, setResponse) => {
    this.props.showLoader();
    axios({
      url: baseurl + "/storestudocs_app",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "form-data",
      },
    })
      .then((response) => {
        console.log(response);
        this.props.hideLoader();
        setResponse(response.data);
        if (response.data === "error") {
          toast.error(MESSAGES.PLEASE_LOGIN);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
      });
  };

  uploadFilesQuesAnsr = (data, setResponse) => {
    this.props.showLoader();
    axios({
      url: baseurl + "/storestudocs_ques_ansrs",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "form-data",
      },
    })
      .then((response) => {
        console.log(response);
        this.props.hideLoader();
        setResponse(response.data);
        if (response.data === "error") {
          toast.error(MESSAGES.PLEASE_LOGIN);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        this.props.hideLoader();
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
      });
  };

  handleCancelUpload = () => {
    this.state.formData.delete("foldertype");
    this.state.formData.delete("student_id");
    this.state.formData.delete("doctype");
    this.setState({
      fileModalOpen1: false,
      filename: [],
      fileLength: 0,
      uploadDocType: "",
      errors: {},
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    // this.setState({
    //   successUpload: !this.state.successUpload
    // })
    toast.success("Application saved successfully!");
    this.props.history.push("/draftapplication");

    // const errors = this.validate();
    // this.setState({ errors });
    // if (errors) return;

    // let mess = "";
    //   this.props.showLoader();
    // axios
    //   .get(baseurl + "/get_allappcomplete/" + localStorage.getItem("studentid"))
    //   .then((response) => {
    //       this.props.hideLoader();
    //     mess = response.data.message;

    //     if (mess !== "Not filled") {
    //       this.state.formData.append("foldertype", this.state.foldertype);
    //       this.state.formData.append(
    //         "student_id",
    //         localStorage.getItem("studentid")
    //       );
    //       this.props.showLoader();
    //       submitForm(
    //         "form-data",
    //         this.state.formData,
    //         (msg) => {
    //           this.props.hideLoader();
    //           if (msg === "error") {
    //             toast.error(MESSAGES.PLEASE_LOGIN);
    //           }
    //           setTimeout(function () {
    //             localStorage.setItem("completeprofile", 1);
    //             window.location = "/#/makepayment";
    //           }, 3000);
    //           toast.success(MESSAGES.APPLICATION_SAVED_SUCESSFULLY);
    //         },
    //         "storestudocs_app"
    //       );
    //     } else {
    //       toast.warning(MESSAGES.PLEASE_COMPLETE_PROFILE_FOR_PAYMENT);
    //     }
    //   });

    this.setState({
      foldertype: "",
      imgfile: "",
      upload_photo: "",
      formData: new FormData(),
      errors: "",
    });
  };

  removeSelectedFile = (index) => {
    let { supportingDocuments } = this.state;
    supportingDocuments[index].selectedFile = null;
    supportingDocuments[index].document = null;
    this.setState({ supportingDocuments });
  };

  fileChange = (selectedFile, docType = 0, index = null) => {
    if (selectedFile.length) {
      let { applicationPdfs, supportingDocuments } = this.state;

      if (docType) {
        supportingDocuments[index].selectedFile = selectedFile[0].name;
      }
      console.log({ supportingDocuments });
      for (let f = 0; f < selectedFile.length; f++) {
        let file = selectedFile[f];
        let size = checkValidFileSize(file);
        if (!size) {
          let fileSize = formatBytes(file.size);
          applicationPdfs.push({ file, fileSize });
        } else {
          toast.error(MESSAGES.VALID_IMAGE_SIZE);
        }
      }
      this.setState(
        {
          applicationPdfs,
          supportingDocuments,
        },
        () => {
          this.uploadApplicationPdfs(docType);
        }
      );
    }
  };

  onRemove = (index) => {
    let { applicationPdfs } = this.state;

    applicationPdfs.splice(index, 1);

    this.setState({
      applicationPdfs,
    });
  };

  onRemoveExisting = (index, val) => {
    if (val.id) {
      this.props.showLoader();
      axios
        .get(
          baseurl +
            "/get_studocuments_app_delete/" +
            localStorage.getItem("studentid") +
            "/" +
            val.id
        )
        .then((data) => {
          this.props.hideLoader();
          this.getPageData();
        });
    }
  };

  uploadApplicationPdfs = (docType) => {
    if (this.state.applicationPdfs) {
      this.props.showLoader();

      let fileUploads = this.state.applicationPdfs.map((val, index) => {
        let formData = new FormData();
        formData.append("upload_photo[]", val.file);
        formData.append("file_size", val.fileSize);
        formData.append("student_id", localStorage.getItem("studentid"));
        formData.append("app_id", this.props.applicationId);
        formData.append("application_id", this.props.applicationId);
        formData.append("foldertype", 0);
        if (docType) {
          formData.append("folder", docType);
        }

        return axios.post(baseurl + "/storestudocs_app", formData, {
          onUploadProgress: (data) => {
            let { applicationPdfsProgress } = this.state;
            applicationPdfsProgress[index] = Math.round(
              (100 * data.loaded) / data.total
            );
            this.setState({
              applicationPdfsProgress,
            });
          },
        });
      });

      Promise.allSettled(fileUploads)
        .then((responses) => {
          this.props.hideLoader();
          this.setState({
            applicationPdfs: [],
            applicationPdfsProgress: [],
          });
          this.getPageData();
        })
        .catch((err) => {
          console.log("err", err);
          this.props.hideLoader();
          toast.error("Something went wrong while uploading the file!");
        });
    }
  };

  render() {
    return template.call(this);
  }
}

export default AppUploadDocument;
