import React from 'react';

function FormError({error, className}) {
    return (
        <h6 className={className} style={{color: "red"}}>{error}</h6>
    );
}
FormError.defaultProps = {
    className: '',
}
export default FormError
