import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL
} from "../constants";

const initialState = {
  showPasswordModal: false,
  countryList: [],
  currencyList: []
}

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRY_LIST:
      return { ...state };
    case GET_COUNTRY_LIST_SUCCESS:
      return { ...state, countryList: action.payload }
    case GET_CURRENCY_LIST:
      return { ...state };
    case GET_CURRENCY_LIST_SUCCESS:
      return { ...state, currencyList: action.payload }
    case SHOW_HIDE_UPDATE_PASSWORD_MODAL:
      return { ...state, showPasswordModal: action.payload }
    default:
      return state;
  }
}