import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Container, Nav, NavItem, Popover, PopoverBody, } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler, } from "@coreui/react";
import logo from "../../assets/img/brand/formee-logo.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import config from "../../config.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Noteicon from "../../assets/img/student/noti-high-icon.svg";
import Mailicon from "../../assets/img/student/mail-icon.svg";
import Favricon from "../../assets/img/student/favr-icon.svg";
import Uficon from "../../assets/img/student/user-fill-icon.svg";
import { ReactComponent as LogoutIcon1 } from "../../assets/img/icons/logoutIcon1.svg";
import { ReactComponent as UserIcon } from "../../assets/img/icons/userIcon.svg";
import { ReactComponent as InboxIcon } from "../../assets/img/icons/mail.svg";
import { ReactComponent as FavIcon } from "../../assets/img/icons/fav-icon.svg";
import { countries } from "../../countryCodes";
import Flag from "react-world-flags";
import { MESSAGES } from "../../constant/messages";
import GetNotification from "./getNotification";
import "./header.scss";


var baseurl = `${config.baseurl}`;
let generalUrl = `${config.generalurl}`;

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const style = {
  left: "-10",
};

class HeaderWithLogoUserNoti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      large: false,
      notifyco: "",
      mailco: "",
      fname: "",
      lname: "",
      countryCode: "",
      country: "",
      userProfilePopover: false
    };
  }

  componentDidMount() {
    const studentId = localStorage.getItem("studentid");
    this.myInterval = setInterval(() => {
      if (studentId && studentId != null) {
        const getnotifyvalue = localStorage.getItem("notifyvalue");
        const studNotificationReq = axios.get(
          baseurl + "/get_studentNotification/" + studentId
        );
        const inboxMailNotificationReq = axios.get(
          baseurl + "/get_inboxmail_notification/" + studentId + "/Student"
        );
        Promise.all([studNotificationReq, inboxMailNotificationReq])
          .then(([studNotificationRes, inboxMailNotificationRes]) => {
            this.setState({
              notifyco: studNotificationRes.data
                ? studNotificationRes.data.count
                : this.state.notifyco,
              mailco: inboxMailNotificationRes
                ? inboxMailNotificationRes.data.count
                : this.state.mailco,
            });
            let result = studNotificationRes.data
              ? studNotificationRes.data
                ? studNotificationRes.data.result
                  ? studNotificationRes.data.result
                  : false
                : false
              : false;

            if (result.length) {
              for (let i = 0; i < result.length; i++) {
                if (
                  result[i].is_viewed === 0 &&
                  getnotifyvalue < studNotificationRes.data.count
                ) {
                  localStorage.removeItem("notifyvalue");
                  toast.success(result[i].notification_message);
                  localStorage.setItem(
                    "notifyvalue",
                    studNotificationRes.data.count
                  );
                }
              }
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
          });
      }
    }, 30000);

    if (studentId !== null) {
      axios.get(baseurl + "/get_generalinfo/" + studentId).then((response) => {
        if (response.data && response.data.length && response.data.length > 0) {
          this.setState({
            fname: response.data[0].first_name,
            lname: response.data[0].last_name,
            country: response.data[0].country_name,
          });
          this.findCountryCode(response.data[0].country_name);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval)
    }
  }

  findCountryCode = (countryName) => {
    let code = "";
    countries.forEach((obj) => {
      if (obj.name === countryName) {
        code = obj.code.toLowerCase();
        this.setState({ countryCode: code });
      }
    });
    return code;
  };

  handleChange = (event) => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/#/login";
  };

  removeCount = () => {
    axios
      .get(
        baseurl + "/update_notification/" + localStorage.getItem("studentid")
      )
      .then((response) => {
      });
  };

  removeEmailCount = () => {
    axios
      .get(
        baseurl +
        "/update_inboxmail_notification/" +
        localStorage.getItem("studentid") +
        "/Student"
      )
      .then((response) => {
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <AppNavbarBrand
            full={{ src: logo, width: 249, height: "auto", alt: "Formee Logo" }}
            // minimized={{
            //   src: sygnet,
            //   width: 30,
            //   height: 30,
            //   alt: "CoreUI Logo",
            // }}
            href={generalUrl}
          />

          {/* <AppSidebarToggler className="d-md-down-none d-none" display="lg" /> */}

          <Nav className="ml-auto foe-nav-right" navbar>
            <NavItem className=" nav-right-box iconLink mr-0">
              <GetNotification />
            </NavItem>
            {/* <NavItem className="">
              <AppSidebarToggler display="md" mobile />
            </NavItem> */}
            <NavItem className="mr-0 ">
              <NavLink
                id="userProfile"
                to="#userProfile"
                className="nav-link text-center prof-header ml-0 pl-0"
                onClick={() => { this.setState({ userProfilePopover: true }); }}
              >
                <img src={Uficon} width="25" alt="home-icon" className="uni-icon" />
              </NavLink>
            </NavItem>
          </Nav>
          <Popover placement="bottom" isOpen={this.state.userProfilePopover} target="userProfile"
            className="userProfile"
            trigger="legacy"
            toggle={() => {
              this.setState({
                userProfilePopover: false
              });
            }}
          >
            <PopoverBody>
              <ul>
                <li>
                  <NavLink
                    to="/studentprofile"
                    className={`nav-link ${this.props.match.path === "/studentprofile" ? 'active' : ""}`}
                  >
                    <UserIcon />
                    My Account
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to=""
                    className="nav-link"
                    onClick={this.handleChange}
                  >
                    <LogoutIcon1 />
                    Logout
                  </NavLink>
                </li>
              </ul>
            </PopoverBody>
          </Popover>
          {/* <AppAsideToggler className="d-md-down-none d-none" /> */}
        </Container>
      </React.Fragment>
    );
  }
}

HeaderWithLogoUserNoti.propTypes = propTypes;
HeaderWithLogoUserNoti.defaultProps = defaultProps;

export default withRouter(HeaderWithLogoUserNoti);
