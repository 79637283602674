import "./AppPersonalinfo.css";
import Addmore from "../Addmore/Addmore";
import React from "react";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../common/FormError";
import CompleteProfileMessage from "../AppUploadDocument/CompleteProfileMessage";
import CommentPopover from "../ApplicationAddComment/component/CommentPopover";
import ApplicationQa from "../commonComponents/ApplicationQa/index.js";

const genders = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Non-binary",
    value: "non-Binary",
  },
  {
    label: "I dont want to disclose",
    value: "disclose",
  },
];
const titles = ["Mr", "Mrs", "Miss", "Ms", "I dont want to disclose"];
const marritalStatuses = [
  "single",
  "married",
  "widowed",
  "separated",
  "divorced",
];

function template() {
  const showtab = () => {
    this.setState({ addMore: false, selectedQuestion: null });
  };
  return (
    <div className="tab-wrap apppersonalinfo newBg">
      {/* <CompleteProfileMessage
        {...this.props}
        show={this.state.showCompleteProfileMessage}
        toggleShowHide={() => {
          this.setState({
            showCompleteProfileMessage: false
          })
        }}
      /> */}

      <Row>
        <div className="col-12">
          {/* {this.state.edit ? null : this.props.showComment ? null : (
            <div className="preview"></div>
          )} */}
          <div
            style={{ display: this.state.addMore == false ? "block" : "none" }}
          >
            <div className="personal-box-inner box">
              <Row className="activeTitle">
                <div className="col-sm-12 col-md-8">
                  <h3>
                    Personal Information
                    <small>(As indicated on your passport)</small>
                  </h3>
                </div>
                <div className="col-sm-12 col-md-4 text-right right-btn-align ">
                  {/* {!this.props.showComment ? (
                    false ? (
                      <Button
                        color="primary"
                        className="app-personal-savebtn"
                        type="submit"
                        onClick={this.onSubmit.bind(this)}
                      >
                        SAVE
                      </Button>
                    ) : (
                      true ? (<>
                      </>) : (
                        <Button
                          color="primary"
                          className="app-personal-savebtn"
                          type="submit"
                          onClick={() => {
                            this.setState({ edit: true });
                          }}
                        >
                          EDIT
                        </Button>
                      )
                    )
                  ) : null} */}
                </div>
              </Row>

              {/* <div className="pr-header col-12 row mt-5 pl-0 pr-0 mb-3">
                <h3 className="col-md-10">Personal Information</h3>
                <div className="col-md-2">

                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={this.onSubmit.bind(this)}
                    >
                      SAVE
                    </Button>

                </div>
              </div> */}

              {/*<div className="pr-header col-12 row mt-5 pl-0 pr-0 mb-3">
                <h3 className="col-md-10">Personal Information</h3>
                <div className="col-md-2">
                  {this.state.disabled === false ? (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={this.onSubmit.bind(this)}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      onClick={this.nexttab}
                    >
                      NEXT
                    </Button>
                  )}
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-3">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"student_title"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Title</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="select"
                      id="title"
                      name="title"
                      onChange={this.handleTargetChange}
                      className=""
                      value={this.state.title}
                    >
                      {titles.map((title) => (
                        <option key={title} value={title}>
                          {title}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"first_name"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">First Name</Label>
                    <Input
                      type="text"
                      className="form-control "
                      placeholder="First Name"
                      name="fname"
                      onChange={this.handleTargetChange}
                      value={this.state.fname}
                      disabled={this.state.disabled}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"middle_name"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Middle Name</Label>
                    <Input
                      type="text"
                      className="form-control "
                      placeholder="Middle Name"
                      name="mname"
                      onChange={this.handleTargetChange}
                      value={this.state.mname}
                      disabled={this.state.disabled}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"last_name"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Last Name</Label>
                    <Input
                      type="text"
                      className="form-control "
                      placeholder="Last Name"
                      name="lname"
                      onChange={this.handleTargetChange}
                      value={this.state.lname}
                      disabled={this.state.disabled}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"dob"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Date of Birth</Label>
                    <DatePicker
                      selected={this.state.dateofbirth}
                      onChange={this.handleDobChange}
                      dateFormat="yyyy-MM-dd"
                      className=""
                      id="dateofbirth"
                      dropdownMode="select"
                      placeholderText="YYYY-MMM-DD"
                      maxDate={new Date()}
                      disabled={this.state.disabled}
                    />
                  </FormGroup>
                  {this.state.errors.dateofbirth && (
                    <FormError error={this.state.errors.dateofbirth} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"first_lang"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">First Language</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Language..."
                      onChange={this.handleTargetChange}
                      value={this.state.flang}
                      name="flang"
                      maxLength="30"
                    />
                  </FormGroup>
                  {this.state.errors.flang && (
                    <FormError error={this.state.errors.flang} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover
                      {...this.props}
                      id={"country_of_citizenship"}
                    />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Country of Citizenship</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="select"
                      id="citiship"
                      name="citiship"
                      onChange={this.handleTargetChange}
                      className=""
                      value={this.state.citiship}
                    >
                      <option value="">Select</option>
                      {Array.isArray(this.state.country) &&
                        this.state.country.map((country) => (
                          <option value={country.id} key={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                  {this.state.errors.citiship && (
                    <FormError error={this.state.errors.citiship} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"passport_number"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Passport Number</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      className="form-control "
                      placeholder="Enter Passport Number..."
                      name="passno"
                      onChange={this.handleTargetChange}
                      value={this.state.passno}
                    />
                  </FormGroup>
                  {this.state.errors.passno && (
                    <FormError error={this.state.errors.passno} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"gender"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Gender</Label>
                    <Input
                      type="select"
                      id="sgender"
                      name="sgender"
                      onChange={this.handleTargetChange}
                      className=""
                      value={this.state.sgender}
                    >
                      <option value="">Select..</option>
                      {genders.map((gender) => (
                        <option key={gender.value} value={gender.value}>
                          {gender.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {this.state.errors.sgender && (
                    <FormError error={this.state.errors.sgender} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"merital_status"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Marital Status</Label>
                    <Input
                      type="select"
                      id="smarital"
                      name="smarital"
                      onChange={this.handleTargetChange}
                      className=""
                      value={this.state.smarital}
                    >
                      <option value="">Select..</option>
                      {marritalStatuses.map((marritalStatus) => (
                        <option key={marritalStatus} value={marritalStatus}>
                          {marritalStatus.charAt(0).toUpperCase() +
                            marritalStatus.slice(1)}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {this.state.errors.smarital && (
                    <FormError error={this.state.errors.smarital} />
                  )}
                </div>
              </div>
            </div>

            <div className="personal-box-inner address box">
              <div className="pr-header">
                <h3>Contact Details</h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"address_line_1"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Address Line 1</Label>
                    <Input
                      type="text"
                      className="form-control "
                      placeholder="Enter Address.."
                      name="saddr"
                      onChange={this.handleTargetChange}
                      value={this.state.saddr}
                      disabled={this.state.disabled}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"address_line_2"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Address Line 2</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Address Line 2"
                      onChange={(e) =>
                        this.setState({ saddr2: e.target.value })
                      }
                      value={this.state.saddr2}
                      name="saddr2"
                    />
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"country"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Country</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="select"
                      id="scountry"
                      onChange={this.handleScountryChange}
                      className=""
                      value={this.state.scountry}
                    >
                      <option value="">Select</option>
                      {Array.isArray(this.state.country) &&
                        this.state.country.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                  {this.state.errors.scountry && (
                    <FormError error={this.state.errors.scountry} />
                  )}
                </div>

                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"province"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Province/State</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      id="sstate"
                      onChange={this.handleSstateChange}
                      className=""
                      value={this.state.sstate}
                    />
                  </FormGroup>
                  {this.state.errors.sstate && (
                    <FormError error={this.state.errors.sstate} />
                  )}
                </div>

                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"postcode"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Postal/Zip Code</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      className="form-control "
                      name="szip"
                      placeholder="Enter Postal/Zip Code... "
                      onChange={this.handleTargetChange}
                      value={this.state.szip}
                      id="szip"
                    />
                  </FormGroup>
                  {this.state.errors.szip && (
                    <FormError error={this.state.errors.szip} />
                  )}
                </div>

                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"email"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Email</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      className="form-control "
                      placeholder="Enter Email.."
                      name="semail"
                      onChange={this.handleTargetChange}
                      value={this.state.semail}
                    />
                  </FormGroup>
                  {this.state.errors.semail && (
                    <FormError error={this.state.errors.semail} />
                  )}
                </div>

                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"phone_number"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Phone Number</Label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text static-plus">
                          <input
                            disabled={this.state.disabled}
                            type="text"
                            className="form-control type-bbo "
                            placeholder="+ 01"
                            name="sphcode"
                            onChange={this.handleTargetChange}
                            value={this.state.sphcode}
                            id="sphcode"
                          />
                        </span>
                      </div>
                      <input
                        disabled={this.state.disabled}
                        type="number"
                        className="form-control "
                        placeholder="Phone Number"
                        name="sphno"
                        onChange={this.handleTargetChange}
                        value={this.state.sphno}
                      />
                    </div>
                  </FormGroup>
                  {this.state.errors.sphno && (
                    <FormError error={this.state.errors.sphno} />
                  )}
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"alter_number"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Cellphone number</Label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text static-plus">
                          <input
                            disabled={this.state.disabled}
                            type="text"
                            className="form-control type-bbo "
                            placeholder="+ 01"
                            name="smbcode"
                            onChange={this.handleTargetChange}
                            value={this.state.smbcode}
                            id="smbcode"
                          />
                        </span>
                      </div>
                      <input
                        disabled={this.state.disabled}
                        type="number"
                        className="form-control "
                        placeholder="Cell Number"
                        name="smbno"
                        onChange={this.handleTargetChange}
                        value={this.state.smbno}
                      />
                    </div>
                    {this.state.errors.smbno && (
                      <FormError error={this.state.errors.smbno} />
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"kin_name"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">Next of Kin Name</Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      className="form-control "
                      placeholder="Enter KIN NAME.."
                      name="next_kin_name"
                      onChange={this.handleTargetChange}
                      value={this.state.next_kin_name}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  {this.props.showComment ? (
                    <CommentPopover {...this.props} id={"kin_number"} />
                  ) : null}
                  <FormGroup>
                    <Label className="uni-label">
                      Next of Kin Contact Number
                    </Label>
                    <Input
                      disabled={this.state.disabled}
                      type="text"
                      className="form-control "
                      placeholder="Enter KIN CONTACT NUMBER.."
                      name="next_kin_contact"
                      onChange={this.handleTargetChange}
                      value={this.state.next_kin_contact}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            {
              this.state.appQuestions
                && this.state.appQuestions.length ? (
                <ApplicationQa
                  appAnswers={this.state.appAnswers}
                  appQuestions={this.state.appQuestions}
                  updatedAnswers={(appAnswers) => {
                    this.setState({ appAnswers })
                  }}
                />
              ) : null
            }

            {
              this.props.showComment ? null : (
                <div className="bottomSaveButton">
                  <button
                    type="button"
                    class="beforesave btn btn-secondary"
                    onClick={this.onSubmit.bind(this, "exit")}
                  >
                    Save & Exit
                  </button>
                  <button
                    type="button"
                    class="beforesave btn btn-primary"
                    onClick={this.onSubmit.bind(this, "")}
                  >
                    Next
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </Row>
    </div>
  );
}

export default template;
