// get dashboard data
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";

// get country list
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR";

// get currency list
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";
export const GET_CURRENCY_LIST_ERROR = "GET_CURRENCY_LIST_ERROR";

// show hide update password modal
export const SHOW_HIDE_UPDATE_PASSWORD_MODAL =
  "SHOW_HIDE_UPDATE_PASSWORD_MODAL";

// save side profile
export const SAVE_SIDE_PROFILE = "SAVE_SIDE_PROFILE";
export const SAVE_SIDE_PROFILE_SUCCESS = "SAVE_SIDE_PROFILE_SUCCESS";
export const SAVE_SIDE_PROFILE_ERROR = "SAVE_SIDE_PROFILE_ERROR";

// get LOGIN_LOGS
export const GET_LOGIN_LOGS = "GET_LOGIN_LOGS";
export const GET_LOGIN_LOGS_SUCCESS = "GET_LOGIN_LOGS_SUCCESS";
export const GET_LOGIN_LOGS_ERROR = "GET_LOGIN_LOGS_ERROR";

// get university about
export const GET_UNIVERSITY_ABOUT = "GET_UNIVERSITY_ABOUT";
export const GET_UNIVERSITY_ABOUT_SUCCESS = "GET_UNIVERSITY_ABOUT_SUCCESS";
export const GET_UNIVERSITY_ABOUT_ERROR = "GET_UNIVERSITY_ABOUT_ERROR";

// create new group
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

// get group list
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS";
export const GET_GROUP_LIST_ERROR = "GET_GROUP_LIST_ERROR";

// get role list
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";

// send staff invite
export const SEND_STAFF_INVITE = "SEND_STAFF_INVITE";
export const SEND_STAFF_INVITE_SUCCESS = "SEND_STAFF_INVITE_SUCCESS";
export const SEND_STAFF_INVITE_ERROR = "SEND_STAFF_INVITE_ERROR";

// get group roles list
export const GET_GROUP_ROLES_LIST = "GET_GROUP_ROLES_LIST";
export const GET_GROUP_ROLES_LIST_SUCCESS = "GET_GROUP_ROLES_LIST_SUCCESS";
export const GET_GROUP_ROLES_LIST_ERROR = "GET_GROUP_ROLES_LIST_ERROR";

// add new roles to group
export const ADD_ROLES_TO_GROUP = "ADD_ROLES_TO_GROUP";
export const ADD_ROLES_TO_GROUP_SUCCESS = "ADD_ROLES_TO_GROUP_SUCCESS";
export const ADD_ROLES_TO_GROUP_ERROR = "ADD_ROLES_TO_GROUP_ERROR";

// get role permissions
export const GET_ROLE_PERMISSION = "GET_ROLE_PERMISSION";
export const GET_ROLE_PERMISSION_SUCCESS = "GET_ROLE_PERMISSION_SUCCESS";
export const GET_ROLE_PERMISSION_ERROR = "GET_ROLE_PERMISSION_ERROR";

// set role permissions
export const SET_ROLE_PERMISSION = "SET_ROLE_PERMISSION";
export const SET_ROLE_PERMISSION_SUCCESS = "SET_ROLE_PERMISSION_SUCCESS";
export const SET_ROLE_PERMISSION_ERROR = "SET_ROLE_PERMISSION_ERROR";

// get staff list
export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";

// delete group
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

// get profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

// update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

// delete role
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

// staff invite gruop list
export const GET_GROUP_INVITE_LIST = "GET_GROUP_INVITE_LIST";
export const GET_GROUP_INVITE_LIST_SUCCESS = "GET_GROUP_INVITE_LIST_SUCCESS";
export const GET_GROUP_INVITE_LIST_ERROR = "GET_GROUP_INVITE_LIST_ERROR";

// staff invite list
export const STAFF_INVITE_LIST = "STAFF_INVITE_LIST";
export const STAFF_INVITE_LIST_SUCCESS = "STAFF_INVITE_LIST_SUCCESS";
export const STAFF_INVITE_LIST_ERROR = "STAFF_INVITE_LIST_ERROR";

// delete staff
export const DELETE_STAFF = "DELETE_SAFF";
export const DELETE_SAFF_SUCCESS = "DELETE_SAFF_SUCCESS";
export const DELETE_SAFF_ERROR = "DELETE_SAFF_ERROR";

// delete staff invite
export const DELETE_STAFF_INVITE = "DELETE_STAFF_INVITE_INVITE";
export const DELETE_STAFF_INVITE_SUCCESS = "DELETE_STAFF_INVITE_SUCCESS";
export const DELETE_STAFF_INVITE_ERROR = "DELETE_STAFF_INVITE_ERROR";

// save intake dates
export const SAVE_INTAKE_DATES = "SAVE_INTAKE_DATES";
export const SAVE_INTAKE_DATES_SUCCESS = "SAVE_INTAKE_DATES_SUCCESS";
export const SAVE_INTAKE_DATES_ERROR = "SAVE_INTAKE_DATES_ERROR";

// get intake dates
export const GET_INTAKE_DATES = "GET_INTAKE_DATES";
export const GET_INTAKE_DATES_SUCCESS = "GET_INTAKE_DATES_SUCCESS";
export const GET_INTAKE_DATES_ERROR = "GET_INTAKE_DATES_ERROR";

// save application form
export const SAVE_APPLICATION_FORM = "SAVE_APPLICATION_FORM";
export const SAVE_APPLICATION_FORM_SUCCESS = "SAVE_APPLICATION_FORM_SUCCESS";
export const SAVE_APPLICATION_FORM_ERROR = "SAVE_APPLICATION_FORM_ERROR";

// get application form
export const GET_APPLICATION_FORM = "GET_APPLICATION_FORM";
export const GET_APPLICATION_FORM_SUCCESS = "GET_APPLICATION_FORM_SUCCESS";
export const GET_APPLICATION_FORM_ERROR = "GET_APPLICATION_FORM_ERROR";

// get profile status
export const GET_PROFILE_STATUS = "GET_PROFILE_STATUS";
export const GET_PROFILE_STATUS_SUCCESS = "GET_PROFILE_STATUS_SUCCESS";
export const GET_PROFILE_STATUS_ERROR = "GET_PROFILE_STATUS_ERROR";

// get application list
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";
export const GET_APPLICATION_LIST_SUCCESS = "GET_APPLICATION_LIST_SUCCESS";
export const GET_APPLICATION_LIST_ERROR = "GET_APPLICATION_LIST_ERROR";

// get department list
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_ERROR = "GET_DEPARTMENT_LIST_ERROR";

// get program list
export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";
export const GET_PROGRAM_LIST_SUCCESS = "GET_PROGRAM_LIST_SUCCESS";
export const GET_PROGRAM_LIST_ERROR = "GET_PROGRAM_LIST_ERROR";

// get study level list
export const GET_STUDY_LEVEL_LIST = "GET_STUDY_LEVEL_LIST";
export const GET_STUDY_LEVEL_SUCCESS = "GET_STUDY_LEVEL_SUCCESS";
export const GET_STUDY_LEVEL_ERROR = "GET_STUDY_LEVEL_ERROR";

// delete applications
export const DELETE_APPLICATIONS = "DELETE_APPLICATIONS";
export const DELETE_APPLICATIONS_SUCCESS = "DELETE_APPLICATIONS_SUCCESS";
export const DELETE_APPLICATIONS_ERROR = "DELETE_APPLICATIONS_ERROR";

// get application status
export const GET_APPLICATION_STATUS_LIST = "GET_APPLICATION_STATUS_LIST";
export const GET_APPLICATION_STATUS_LIST_SUCCESS =
  "GET_APPLICATION_STATUS_LIST_SUCCESS";
export const GET_APPLICATION_STATUS_LIST_ERROR =
  "GET_APPLICATION_STATUS_LIST_ERROR";

// get application details
export const GET_APPLICATION_DETAILS = "GET_APPLICATION_DETAILS";
export const GET_APPLICATION_DETAILS_SUCCESS =
  "GET_APPLICATION_DETAILS_SUCCESS";
export const GET_APPLICATION_DETAILS_ERROR = "GET_APPLICATION_DETAILS_ERROR";

// save department dates
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
export const SAVE_DEPARTMENT_ERROR = "SAVE_DEPARTMENT_ERROR";

// get department dates
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";

//Get single department
export const VIEW_DEPARTMENT = "VIEW_DEPARTMENT";
export const VIEW_DEPARTMENT_SUCCESS = "VIEW_DEPARTMENT_SUCCESS";
export const VIEW_DEPARTMENT_ERROR = "VIEW_DEPARTMENT_ERROR";

//update single department
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";
// update status
export const UPDATE_APPLICATION_STATUS = "UPDATE_APPLICATION_STATUS";
export const UPDATE_APPLICATION_STATUS_SUCCESS =
  "UPDATE_APPLICATION_STATUS_SUCCESS";
export const UPDATE_APPLICATION_STATUS_ERROR =
  "UPDATE_APPLICATION_STATUS_ERROR";

// get application chat and messages count
export const GET_APPLICATION_MESSAGE_CHAT_COUNT =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT";
export const GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS";
export const GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR";

// get current application status
export const GET_APPLICATION_STATUS = "GET_APPLICATION_STATUS";
export const GET_APPLICATION_STATUS_SUCCESS = "GET_APPLICATION_STATUS_SUCCESS";
export const GET_APPLICATION_STATUS_ERROR = "GET_APPLICATION_STATUS_ERROR";

// assign application to staff
export const ASSIGN_APPLICATION = "ASSIGN_APPLICATION_STATUS";
export const ASSIGN_APPLICATION_SUCCESS = "ASSIGN_APPLICATION_SUCCESS";
export const ASSIGN_APPLICATION_ERROR = "ASSIGN_APPLICATION_ERROR";

// get application assigned list
export const GET_APPLICATION_ASSIGNEES = "GET_APPLICATION_ASSIGNEES";
export const GET_APPLICATION_ASSIGNEES_SUCCESS =
  "GET_APPLICATION_ASSIGNEES_SUCCESS";
export const GET_APPLICATION_ASSIGNEES_ERROR =
  "GET_APPLICATION_ASSIGNEES_ERROR";

export const SEND_APPLICATION_MESSAGE = "SEND_APPLICATION_MESSAGE";
export const SEND_APPLICATION_MESSAGE_SUCCESS =
  "SEND_APPLICATION_MESSAGE_SUCCESS";
export const SEND_APPLICATION_MESSAGE_ERROR = "SEND_APPLICATION_MESSAGE_ERROR";

export const GET_APPLICATION_MESSAGES = "GET_APPLICATION_MESSAGES";
export const GET_APPLICATION_MESSAGES_SUCCESS =
  "GET_APPLICATION_MESSAGES_SUCCESS";
export const GET_APPLICATION_MESSAGES_ERROR = "GET_APPLICATION_MESSAGES_ERROR";

export const GET_APPLICATION_COMMENTS = "GET_APPLICATION_COMMENTS";
export const GET_APPLICATION_COMMENTS_SUCCESS =
  "GET_APPLICATION_COMMENTS_SUCCESS";
export const GET_APPLICATION_COMMENTS_ERROR = "GET_APPLICATION_COMMENTS_ERROR";

export const ADD_APPLICATION_COMMENT = "ADD_APPLICATION_COMMENT";
export const ADD_APPLICATION_COMMENT_SUCCESS =
  "ADD_APPLICATION_COMMENT_SUCCESS";
export const ADD_APPLICATION_COMMENT_ERROR = "ADD_APPLICATION_COMMENT_ERROR";

export const DELETE_APPLICATION_COMMENT = "DELETE_APPLICATION_COMMENT";
export const DELETE_APPLICATION_COMMENT_SUCCESS =
  "DELETE_APPLICATION_COMMENT_SUCCESS";
export const DELETE_APPLICATION_COMMENT_ERROR =
  "DELETE_APPLICATION_COMMENT_ERROR";

// send document verification to admin
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION";
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS";
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR";

export const GET_EDUCATION_LIST = "GET_EDUCATION_LIST";
export const GET_EDUCATION_LIST_SUCCESS = "GET_EDUCATION_LIST_SUCCESS";
export const GET_EDUCATION_LIST_ERROR = "GET_EDUCATION_LIST_ERROR";

export const GET_REMAIN_CAMPUS_LIST = "GET_REMAIN_CAMPUS_LIST";
export const GET_REMAIN_CAMPUS_LIST_SUCCESS = "GET_REMAIN_CAMPUS_LIST_SUCCESS";
export const GET_REMAIN_CAMPUS_LIST_ERROR = "GET_REMAIN_CAMPUS_LIST_ERROR";

// create new Program
export const CREATE_PROGRAM = "CREATE_PROGRAM";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_ERROR = "CREATE_PROGRAM_ERROR";

// get Language Exam
export const GET_LANGUAGE_EXAM_LIST = "GET_LANGUAGE_EXAM_LIST";
export const GET_LANGUAGE_EXAM_LIST_SUCCESS = "GET_LANGUAGE_EXAM_LIST_SUCCESS";
export const GET_LANGUAGE_EXAM_LIST_ERROR = "GET_LANGUAGE_EXAM_LIST_ERROR";

// get Additional Exam
export const GET_ADDITIONAL_EXAM_LIST = "GET_ADDITIONAL_EXAM_LIST";
export const GET_ADDITIONAL_EXAM_LIST_SUCCESS =
  "GET_ADDITIONAL_EXAM_LIST_SUCCESS";
export const GET_ADDITIONAL_EXAM_LIST_ERROR = "GET_ADDITIONAL_EXAM_LIST_ERROR";

//Serach Program
export const GET_SEARCH_PROGRAM_LIST = "GET_SEARCH_PROGRAM_LIST";
export const GET_SEARCH_PROGRAM_LIST_SUCCESS =
  "GET_SEARCH_PROGRAM_LIST_SUCCESS";
export const GET_SEARCH_PROGRAM_LIST_ERROR = "GET_SEARCH_PROGRAM_LIST_ERROR";

//Delete Program
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR";

// get programs details
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_ERROR = "GET_PROGRAMS_ERROR";

//get single program
export const GET_PROGRAM_DETAILS = "GET_PROGRAM_DETAILS";
export const GET_PROGRAM_DETAILS_SUCCESS = "GET_PROGRAM_DETAILS_SUCCESS";
export const GET_PROGRAM_DETAILS_ERROR = "GET_PROGRAM_DETAILS_ERROR";

// update program
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_ERROR = "UPDATE_PROGRAM_ERROR";

// CONFIRM DEPARTMENT
export const CONFIRM_DEPARTMENT = "CONFIRM_DEPARTMENT";
export const CONFIRM_DEPARTMENT_SUCCESS = "CONFIRM_DEPARTMENT_SUCCESS";
export const CONFIRM_DEPARTMENT_ERROR = "CONFIRM_DEPARTMENT_ERROR";

// CONFIRM PRogram
export const CONFIRM_PROGRAM = "CONFIRM_PROGRAM";
export const CONFIRM_PROGRAM_SUCCESS = "CONFIRM_PROGRAM_SUCCESS";
export const CONFIRM_PROGRAM_ERROR = "CONFIRM_PROGRAM_ERROR";

// DELETE_ALL_PROGRAM_DEPARTMENT
export const DELETE_ALL_PROGRAM_DEPARTMENT = "DELETE_ALL_PROGRAM_DEPARTMENT";
export const DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS =
  "DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS";
export const DELETE_ALL_PROGRAM_DEPARTMENT_ERROR =
  "DELETE_ALL_PROGRAM_DEPARTMENT_ERROR";

// GET_UNIVERSITY_OVERVIEW
export const GET_UNIVERSITY_OVERVIEW = "GET_UNIVERSITY_OVERVIEW";
export const GET_UNIVERSITY_OVERVIEW_SUCCESS =
  "GET_UNIVERSITY_OVERVIEW_SUCCESS";
export const GET_UNIVERSITY_OVERVIEW_ERROR = "GET_UNIVERSITY_OVERVIEW_ERROR";

// get dashboard University data
export const GET_DASHBOARD_UNIVERSITY = "GET_DASHBOARD_UNIVERSITY";
export const GET_DASHBOARD_UNIVERSITY_SUCCESS =
  "GET_DASHBOARD_UNIVERSITY_SUCCESS";
export const GET_DASHBOARD_UNIVERSITY_ERROR = "GET_DASHBOARD_UNIVERSITY_ERROR";

//GET_STUDENT_APPLICATION_STATISTICS
export const GET_STUDENT_APPLICATION_STATISTICS =
  "GET_STUDENT_APPLICATION_STATISTICS";
export const GET_STUDENT_APPLICATION_STATISTICS_SUCCESS =
  "GET_STUDENT_APPLICATION_STATISTICS_SUCCESS";
export const GET_STUDENT_APPLICATION_STATISTICS_ERROR =
  "GET_STUDENT_APPLICATION_STATISTICS_ERROR";

// get Payment Paid List
export const GET_PAYMENT_PAID_LIST = "GET_PAYMENT_PAID_LIST";
export const GET_PAYMENT_PAID_LIST_SUCCESS = "GET_PAYMENT_PAID_LIST_SUCCESS";
export const GET_PAYMENT_PAID_LIST_ERROR = "GET_PAYMENT_PAID_LIST_ERROR";

//CREATE_PAYMENT_METHOD
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS";
export const CREATE_PAYMENT_METHOD_ERROR = "CREATE_PAYMENT_METHOD_ERROR";

//GET_PAYMENT_LIST
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_PAYMENT_LIST_SUCCESS = "GET_PAYMENT_LIST_SUCCESS";
export const GET_PAYMENT_LIST_ERROR = "GET_PAYMENT_LIST_ERROR";

//GET_PAYMENT_DETAILS
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS";
export const GET_PAYMENT_DETAILS_ERROR = "GET_PAYMENT_DETAILS_ERROR";

//UPDATE_PAYMENT_METHOD
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

//DELETE_PAYMENT_METHOD
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR";
// send email save draft
export const COMPOSE_MAIL = "COMPOSE_MAIL";
export const COMPOSE_MAIL_SUCCESS = "COMPOSE_MAIL_SUCCESS";
export const COMPOSE_MAIL_ERROR = "COMPOSE_MAIL_ERROR";

// get inbox listing
export const GET_INBOX = "GET_INBOX";
export const GET_INBOX_SUCCESS = "GET_INBOX_SUCCESS";
export const GET_INBOX_ERROR = "GET_INBOX_ERROR";

// update mail favourite
export const UPDATE_MAIL_FAVOURITE = "UPDATE_MAIL_FAVOURITE";
export const UPDATE_MAIL_FAVOURITE_SUCCESS = "UPDATE_MAIL_FAVOURITE_SUCCESS";
export const UPDATE_MAIL_FAVOURITE_ERROR = "UPDATE_MAIL_FAVOURITE_ERROR";

// delete inbox email
export const DELETE_INBOX_EMAIL = "DELETE_INBOX_EMAIL";
export const DELETE_INBOX_EMAIL_SUCCESS = "DELETE_INBOX_EMAIL_SUCCESS";
export const DELETE_INBOX_EMAIL_ERROR = "DELETE_INBOX_EMAIL_ERROR";

// get unread inbox count
export const GET_UNREAD_INBOX_COUNT = "GET_UNREAD_INBOX_COUNT";
export const GET_UNREAD_INBOX_COUNT_SUCCESS = "GET_UNREAD_INBOX_COUNT_SUCCESS";
export const GET_UNREAD_INBOX_COUNT_ERROR = "GET_UNREAD_INBOX_COUNT_ERROR";

// get mail details
export const GET_INBOX_MAIL_DETAILS = "GET_INBOX_MAIL_DETAILS";
export const GET_INBOX_MAIL_DETAILS_SUCCESS = "GET_INBOX_MAIL_DETAILS_SUCCESS";
export const GET_INBOX_MAIL_DETAILS_ERROR = "GET_INBOX_MAIL_DETAILS_ERROR";

// mark inbox mail read/unread
export const UPDATE_INBOX_READ_STATUS = "UPDATE_INBOX_READ_STATUS";
export const UPDATE_INBOX_READ_STATUS_SUCCESS =
  "UPDATE_INBOX_READ_STATUS_SUCCESS";
export const UPDATE_INBOX_READ_STATUS_ERROR = "UPDATE_INBOX_READ_STATUS_ERROR";

// get timezone list
export const GET_TIMEZONES_LIST = "GET_TIMEZONES_LIST";
export const GET_TIMEZONES_LIST_SUCCESS = "GET_TIMEZONES_LIST_SUCCESS";
export const GET_TIMEZONES_LIST_ERROR = "GET_TIMEZONES_LIST_ERROR";

// create meeting
export const CREATE_MEETING = "CREATE_MEETING";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const CREATE_MEETING_ERROR = "CREATE_MEETING_ERROR";

// get meeting list
export const MEETING_LIST = "MEETING_LIST";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_ERROR = "MEETING_LIST_ERROR";

// get meeting details
export const MEETING_DETAILS = "MEETING_DETAILS";
export const MEETING_DETAILS_SUCCESS = "MEETING_DETAILS_SUCCESS";
export const MEETING_DETAILS_ERROR = "MEETING_DETAILS_ERROR";

// delete meeting
export const DELETE_MEETING = "DELETE_MEETING";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_ERROR = "DELETE_MEETING_ERROR";

// update meeting status
export const UPDATE_MEETING_STATUS = "UPDATE_MEETING_STATUS";
export const UPDATE_MEETING_STATUS_SUCCESS = "UPDATE_MEETING_STATUS_SUCCESS";
export const UPDATE_MEETING_STATUS_ERROR = "UPDATE_MEETING_STATUS_ERROR";

// get contact list
export const GET_CONTACT_LIST = "GET_CONTACT_LIST_STATUS";
export const GET_CONTACT_LIST_SUCCESS = "GET_CONTACT_LIST_SUCCESS";
export const GET_CONTACT_LIST_ERROR = "GET_CONTACT_LIST_ERROR";

export const GET_REACH_OUT_DETAILS = "GET_REACH_OUT_DETAILS";
export const GET_REACH_OUT_DETAILS_SUCCESS = "GET_REACH_OUT_DETAILS_SUCCESS";
export const GET_REACH_OUT_DETAILS_ERROR = "GET_REACH_OUT_DETAILS_ERROR";

//GET_REPLY_TO_MEETING
export const GET_REPLY_TO_MEETING = "GET_REPLY_TO_MEETING";
export const GET_REPLY_TO_MEETING_SUCCESS = "GET_REPLY_TO_MEETING_SUCCESS";
export const GET_REPLY_TO_MEETING_ERROR = "GET_REPLY_TO_MEETING_ERROR";

//GET_STUDENT_APPLICATION_COUNT
export const GET_STUDENT_APPLICATION_COUNT = "GET_STUDENT_APPLICATION_COUNT";
export const GET_STUDENT_APPLICATION_COUNT_SUCCESS =
  "GET_STUDENT_APPLICATION_COUNT_SUCCESS";
export const GET_STUDENT_APPLICATION_COUNT_ERROR =
  "GET_STUDENT_APPLICATION_COUNT_ERROR";

//GET_UNIVERSITY_INCOME_COUNT
export const GET_UNIVERSITY_INCOME_COUNT = "GET_UNIVERSITY_INCOME_COUNT";
export const GET_UNIVERSITY_INCOME_COUNT_SUCCESS =
  "GET_UNIVERSITY_INCOME_COUNT_SUCCESS";
export const GET_UNIVERSITY_INCOME_COUNT_ERROR =
  "GET_UNIVERSITY_INCOME_COUNT_ERROR";

//CALENDER_EVENT_LIST
export const CALENDER_EVENT_LIST = "CALENDER_EVENT_LIST";
export const CALENDER_EVENT_LIST_SUCCESS = "CALENDER_EVENT_LIST_SUCCESS";
export const CALENDER_EVENT_LIST_ERROR = "CALENDER_EVENT_LIST_ERROR";

//GET_MONTH_EVENT_LIST
export const GET_MONTH_EVENT_LIST = "GET_MONTH_EVENT_LIST";
export const GET_MONTH_EVENT_LIST_SUCCESS = "GET_MONTH_EVENT_LIST_SUCCESS";
export const GET_MONTH_EVENT_LIST_ERROR = "GET_MONTH_EVENT_LIST_ERROR";

//ONBOARDING_PROCESS
export const ONBOARDING_PROCESS = "ONBOARDING_PROCESS";
export const ONBOARDING_PROCESS_SUCCESS = "ONBOARDING_PROCESS_SUCCESS";
export const ONBOARDING_PROCESS_ERROR = "ONBOARDING_PROCESS_ERROR";

//FORGOT_PASSWORD
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

//RESET_PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

//GET_PENDING_STUDENT_FEES
export const GET_PENDING_STUDENT_FEES = "GET_PENDING_STUDENT_FEES";
export const GET_PENDING_STUDENT_FEES_SUCCESS =
  "GET_PENDING_STUDENT_FEES_SUCCESS";
export const GET_PENDING_STUDENT_FEES_ERROR = "GET_PENDING_STUDENT_FEES_ERROR";

// get payment proof for the application
export const GET_PAYMENT_PROOF = "GET_PAYMENT_PROOF";
export const GET_PAYMENT_PROOF_SUCCESS = "GET_PAYMENT_PROOF_SUCCESS";
export const GET_PAYMENT_PROOF_ERROR = "GET_PAYMENT_PROOF_ERROR";

// save card to stripe
export const SAVE_CARD = "SAVE_CARD";
export const SAVE_CARD_SUCCESS = "SAVE_CARD_SUCCESS";
export const SAVE_CARD_ERROR = "SAVE_CARD_ERROR";

//GET_INVOICE_PAYMENT_PROOF
export const GET_INVOICE_PAYMENT_PROOF = "GET_INVOICE_PAYMENT_PROOF";
export const GET_INVOICE_PAYMENT_PROOF_SUCCESS =
  "GET_INVOICE_PAYMENT_PROOF_SUCCESS";
export const GET_INVOICE_PAYMENT_PROOF_ERROR =
  "GET_INVOICE_PAYMENT_PROOF_ERROR";

//GET_PREPAID_FEES_COMMISSION_LIST
export const GET_PREPAID_FEES_COMMISSION_LIST =
  "GET_PREPAID_FEES_COMMISSION_LIST";
export const GET_PREPAID_FEES_COMMISSION_LIST_SUCCESS =
  "GET_PREPAID_FEES_COMMISSION_LIST_SUCCESS";
export const GET_PREPAID_FEES_COMMISSION_LIST_ERROR =
  "GET_PREPAID_FEES_COMMISSION_LIST_ERROR";

//GET_POSTPAID_FEES_COMMISSION_LIST
export const GET_POSTPAID_FEES_COMMISSION_LIST =
  "GET_POSTPAID_FEES_COMMISSION_LIST";
export const GET_POSTPAID_FEES_COMMISSION_LIST_SUCCESS =
  "GET_POSTPAID_FEES_COMMISSION_LIST_SUCCESS";
export const GET_POSTPAID_FEES_COMMISSION_LIST_ERROR =
  "GET_POSTPAID_FEES_COMMISSION_LIST_ERROR";

// get default stripe card
export const GET_DEFAULT_CARD = "GET_DEFAULT_CARD";
export const GET_DEFAULT_CARD_SUCCESS = "GET_DEFAULT_CARD_SUCCESS";
export const GET_DEFAULT_CARD_ERROR = "GET_DEFAULT_CARD_ERROR";

// get invoice details
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_ERROR = "GET_INVOICE_DETAILS_ERROR";

// get check hire agent
export const GET_AGENT_CHECK_HIRE = "GET_AGENT_CHECK_HIRE";
export const GET_AGENT_CHECK_HIRE_SUCCESS = "GET_AGENT_CHECK_HIRE_SUCCESS";
export const GET_AGENT_CHECK_HIRE_ERROR = "GET_AGENT_CHECK_HIRE_ERROR";

// get hire agent
export const GET_HIRE_AGENT = "GET_HIRE_AGENT";
export const GET_HIRE_AGENT_SUCCESS = "GET_HIRE_AGENT_SUCCESS";
export const GET_HIRE_AGENT_ERROR = "GET_HIRE_AGENT_ERROR";

// get share profile
export const GET_SHARE_PROFILE = "GET_SHARE_PROFILE";
export const GET_SHARE_PROFILE_SUCCESS = "GET_SHARE_PROFILE_SUCCESS";
export const GET_SHARE_PROFILE_ERROR = "GET_SHARE_PROFILE_ERROR";

// get invoice from application
export const GET_INVOICE_APP = "GET_INVOICE_APP";
export const GET_INVOICE_APP_SUCCESS = "GET_INVOICE_APP_SUCCESS";
export const GET_INVOICE_APP_ERROR = "GET_INVOICE_APP_ERROR";

// stripe payment
export const STRIPE_PAYMENT = "STRIPE_PAYMENT";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_ERROR = "STRIPE_PAYMENT_ERROR";

//LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//ROLE_PERMISSION
export const ROLE_PERMISSION = "ROLE_PERMISSION";
export const ROLE_PERMISSION_SUCCESS = "ROLE_PERMISSION_SUCCESS";
export const ROLE_PERMISSION_ERROR = "ROLE_PERMISSION_ERROR";

// agent hire requests
export const AGENT_HIRE_REQUESTS = "AGENT_HIRE_REQUESTS";
export const AGENT_HIRE_REQUESTS_SUCCESS = "AGENT_HIRE_REQUESTS_SUCCESS";
export const AGENT_HIRE_REQUESTS_ERROR = "AGENT_HIRE_REQUESTS_ERROR";

// update hire request status
export const UPDATE_HIRE_REQUEST = "UPDATE_HIRE_REQUEST";
export const UPDATE_HIRE_REQUEST_SUCCESS = "UPDATE_HIRE_REQUEST_SUCCESS";
export const UPDATE_HIRE_REQUEST_ERROR = "UPDATE_HIRE_REQUEST_ERROR";

// delete hire request
export const DELETE_HIRE_REQUEST = "DELETE_HIRE_REQUEST";
export const DELETE_HIRE_REQUEST_SUCCESS = "DELETE_HIRE_REQUEST_SUCCESS";
export const DELETE_HIRE_REQUEST_ERROR = "DELETE_HIRE_REQUEST_ERROR";

// get ep saved search
export const GET_EP_SAVED_SEARCH = "GET_EP_SAVED_SEARCH";
export const GET_EP_SAVED_SEARCH_SUCCESS = "GET_EP_SAVED_SEARCH_SUCCESS";
export const GET_EP_SAVED_SEARCH_ERROR = "GET_EP_SAVED_SEARCH_ERROR";

// get pp saved search
export const GET_PP_SAVED_SEARCH = "GET_PP_SAVED_SEARCH";
export const GET_PP_SAVED_SEARCH_SUCCESS = "GET_PP_SAVED_SEARCH_SUCCESS";
export const GET_PP_SAVED_SEARCH_ERROR = "GET_PP_SAVED_SEARCH_ERROR";

// delete saved search
export const DELETE_SAVED_SEARCH = "DELETE_SAVED_SEARCH";
export const DELETE_SAVED_SEARCH_SUCCESS = "DELETE_SAVED_SEARCH_SUCCESS";
export const DELETE_SAVED_SEARCH_ERROR = "DELETE_SAVED_SEARCH_ERROR";

// delete profile docs
export const DELETE_DOCS = "DELETE_DOCS";
export const DELETE_DOCS_SUCCESS = "DELETE_DOCS_SUCCESS";
export const DELETE_DOCS_ERROR = "DELETE_DOCS_ERROR";

// get profile steps complete status
export const GET_PROFILE_FLAGS = "GET_PROFILE_FLAGS";
export const GET_PROFILE_FLAGS_SUCCESS = "GET_PROFILE_FLAGS_SUCCESS";
export const GET_PROFILE_FLAGS_ERROR = "GET_PROFILE_FLAGS_ERROR";

// get sub education level
export const GET_EDUCATION_SUB_LEVEL = "GET_EDUCATION_SUB_LEVEL";
export const GET_EDUCATION_SUB_LEVEL_SUCCESS =
  "GET_EDUCATION_SUB_LEVEL_SUCCESS";
export const GET_EDUCATION_SUB_LEVEL_ERROR = "GET_EDUCATION_SUB_LEVEL_ERROR";

// For Supporting Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const STORE_DOCUMENTS = "STORE_DOCUMENTS";
export const DELETE_DOCUMENTS = "DELETE_DOCUMENTS";

export const PROGRAM_COUNTRY = "PROGRAM_COUNTRY";
export const PROGRAM_COUNTRY_SUCCESS = "PROGRAM_COUNTRY_SUCCESS";
export const PROGRAM_COUNTRY_ERROR = "PROGRAM_COUNTRY_ERROR";

export const AGENT_LIST = "AGENT_LIST";
export const AGENT_LIST_SUCCESS = "AGENT_LIST_SUCCESS";
export const AGENT_LIST_ERROR = "AGENT_LIST_ERROR";

// save pp search
export const SAVE_PP_SEARCH = "SAVE_PP_SEARCH";
export const SAVE_PP_SEARCH_SUCCESS = "SAVE_PP_SEARCH_SUCCESS";
export const SAVE_PP_SEARCH_ERROR = "SAVE_PP_SEARCH_ERROR";

// ep list
export const EP_LIST = "EP_LIST";
export const EP_LIST_SUCCESS = "EP_LIST_SUCCESS";
export const EP_LIST_ERROR = "EP_LIST_ERROR";

// update ep fav status
export const UPDATE_EP_FAVOURITE = "UPDATE_EP_FAVOURITE";
export const UPDATE_EP_FAVOURITE_SUCCESS = "UPDATE_EP_FAVOURITE_SUCCESS";
export const UPDATE_EP_FAVOURITE_ERROR = "UPDATE_EP_FAVOURITE_ERROR";

export const UPDATE_PROGRAM_FAVOURITE = "UPDATE_PROGRAM_FAVOURITE";
export const UPDATE_PROGRAM_FAVOURITE_SUCCESS =
  "UPDATE_PROGRAM_FAVOURITE_SUCCESS";
export const UPDATE_PROGRAM_FAVOURITE_ERROR = "UPDATE_PROGRAM_FAVOURITE_ERROR";

export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";
export const GET_AGENT_PROFILE_SUCCESS = "GET_AGENT_PROFILE_SUCCESS";
export const GET_AGENT_PROFILE_ERROR = "GET_AGENT_PROFILE_ERROR";

// get agent reviews
export const GET_AGENT_REVIEWS = "GET_AGENT_REVIEWS";
export const GET_AGENT_REVIEWS_SUCCESS = "GET_AGENT_REVIEWS_SUCCESS";
export const GET_AGENT_REVIEWS_ERROR = "GET_AGENT_REVIEWS_ERROR";

// like dislike revire comments
export const LIKE_DISLIKE_COMMENT = "LIKE_DISLIKE_COMMENT";
export const LIKE_DISLIKE_COMMENT_SUCCESS = "LIKE_DISLIKE_COMMENT_SUCCESS";
export const LIKE_DISLIKE_COMMENT_ERROR = "LIKE_DISLIKE_COMMENT_ERROR";

// like dislike review
export const LIKE_DISLIKE_REVIEW = "LIKE_DISLIKE_REVIEW";
export const LIKE_DISLIKE_REVIEW_SUCCESS = "LIKE_DISLIKE_REVIEW_SUCCESS";
export const LIKE_DISLIKE_REVIEW_ERROR = "LIKE_DISLIKE_REVIEW_ERROR";

// update comment
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_ERROR = "UPDATE_COMMENT_ERROR";

// delete comment
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

// delete inbox permenant
export const DELETE_PERM = "DELETE_PERM";
export const DELETE_PERM_SUCCESS = "DELETE_PERM_SUCCESS";
export const DELETE_PERM_ERROR = "DELETE_PERM_ERROR";
// move to inbox
export const MOVE_TO_INBOX = "MOVE_TO_INBOX";
export const MOVE_TO_INBOX_SUCCESS = "MOVE_TO_INBOX_SUCCESS";
export const MOVE_TO_INBOX_ERROR = "MOVE_TO_INBOX_ERROR";

// get chat sessions list
export const GET_CHAT_SESSIONS_LIST = "GET_CHAT_SESSIONS_LIST";
export const GET_CHAT_SESSIONS_LIST_SUCCESS = "GET_CHAT_SESSIONS_LIST_SUCCESS";
export const GET_CHAT_SESSIONS_LIST_ERROR = "GET_CHAT_SESSIONS_LIST_ERROR";

// get chat messages
export const GET_CHAT_MESSAGES_LIST = "GET_CHAT_MESSAGES_LIST";
export const GET_CHAT_MESSAGES_LIST_SUCCESS = "GET_CHAT_MESSAGES_LIST_SUCCESS";
export const GET_CHAT_MESSAGES_LIST_ERROR = "GET_CHAT_MESSAGES_LIST_ERROR";

// delete restore chat
export const DELETE_CHAT = "DELETE_CHAT";
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_ERROR = "DELETE_CHAT_ERROR";

// star unstar chat
export const ADD_STAR_CHAT = "ADD_STAR_CHAT";
export const ADD_STAR_CHAT_SUCCESS = "ADD_STAR_CHAT_SUCCESS";
export const ADD_STAR_CHAT_ERROR = "ADD_STAR_CHAT_ERROR";

// send meeting
export const SEND_MEETING = "SEND_MEETING";
export const SEND_MEETING_SUCCESS = "SEND_MEETING_SUCCESS";
export const SEND_MEETING_ERROR = "SEND_MEETING_ERROR";

// get univerisy profile
export const GET_UNIVERSITY_PROFILE = "GET_UNIVERSITY_PROFILE";
export const GET_UNIVERSITY_PROFILE_SUCCESS = "GET_UNIVERSITY_PROFILE_SUCCESS";
export const GET_UNIVERSITY_PROFILE_ERROR = "GET_UNIVERSITY_PROFILE_ERROR";

// get intakes
export const GET_UNIVERSITY_INTAKES = "GET_UNIVERSITY_INTAKES";
export const GET_UNIVERSITY_INTAKES_SUCCESS = "GET_UNIVERSITY_INTAKES_SUCCESS";
export const GET_UNIVERSITY_INTAKES_ERROR = "GET_UNIVERSITY_INTAKES_ERROR";

// get program filter
export const GET_PROGRAM_DETAILS_FILTERS = "GET_PROGRAM_DETAILS_FILTERS";
export const GET_PROGRAM_DETAILS_FILTERS_SUCCESS =
  "GET_PROGRAM_DETAILS_FILTERS_SUCCESS";
export const GET_PROGRAM_DETAILS_FILTERS_ERROR =
  "GET_PROGRAM_DETAILS_FILTERS_ERROR";

// check eligibilty
export const CHECK_ELIGIBILTY = "CHECK_ELIGIBILTY";
export const CHECK_ELIGIBILTY_SUCCESS = "CHECK_ELIGIBILTY_SUCCESS";
export const CHECK_ELIGIBILTY_ERROR = "CHECK_ELIGIBILTY_ERROR";

// get application advice
export const GET_APPLICATION_ADVICE = "GET_APPLICATION_ADVICE";
export const GET_APPLICATION_ADVICE_SUCCESS = "GET_APPLICATION_ADVICE_SUCCESS";
export const GET_APPLICATION_ADVICE_ERROR = "GET_APPLICATION_ADVICE_ERROR";

// check application applied status
export const CHECK_APPLIED_STATUS = "CHECK_APPLIED_STATUS";
export const CHECK_APPLIED_STATUS_SUCCESS = "CHECK_APPLIED_STATUS_SUCCESS";
export const CHECK_APPLIED_STATUS_ERROR = "CHECK_APPLIED_STATUS_ERROR";

// check application tabs status
export const GET_APPLICATION_STEP_STATUS = "GET_APPLICATION_STEP_STATUS";
export const GET_APPLICATION_STEP_STATUS_SUCCESS =
  "GET_APPLICATION_STEP_STATUS_SUCCESS";
export const GET_APPLICATION_STEP_STATUS_ERROR =
  "GET_APPLICATION_STEP_STATUS_ERROR";

// save application all steps
export const SAVE_APPLICATION = "SAVE_APPLICATION";
export const SAVE_APPLICATION_SUCCESS = "SAVE_APPLICATION_SUCCESS";
export const SAVE_APPLICATION_ERROR = "SAVE_APPLICATION_ERROR";

// get application data
export const GET_APPLICATION_DATA = "GET_APPLICATION_DATA";

// send application
export const SEND_APPLICATION = "SEND_APPLICATION";
export const SEND_APPLICATION_SUCCESS = "SEND_APPLICATION_SUCCESS";
export const SEND_APPLICATION_ERROR = "SEND_APPLICATION_ERROR";

// get invoice details
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";

export const SAVE_APPLICATION_PDF = "SAVE_APPLICATION_PDF";

export const GET_APPLICATION_NOTE_COUNT = "GET_APPLICATION_NOTE_COUNT";
export const GET_APPLICATION_NOTE_COUNT_SUCCESS =
  "GET_APPLICATION_NOTE_COUNT_SUCCESS";

export const GET_STUDENT_TYPES = "GET_STUDENT_TYPES";

export const GET_SUPPORTING_DOCUMENTS = "GET_SUPPORTING_DOCUMENTS";

export const GET_STUDENT_TYPES_LIST = "GET_STUDENT_TYPES_LIST";
export const GET_STUDENT_TYPES_LIST_SUCCESS = "GET_STUDENT_TYPES_LIST_SUCCESS";
