import {
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  GET_GROUP_LIST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_ROLES_LIST,
  GET_GROUP_ROLES_LIST_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_SUCCESS,
  SEND_STAFF_INVITE,
  SEND_STAFF_INVITE_SUCCESS
} from "../constants";

const initialState = {
  createGroup: null,
  groupList: [],
  rolesList: [],
  groupRolesList: []
};

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_GROUP:
      return { ...state };
    case CREATE_GROUP_SUCCESS:
      return { ...state, createGroup: action.payload };
      // return { ...state, sideProfile: action.payload.data }
    case GET_GROUP_LIST:
      return { ...state };
    case GET_GROUP_LIST_SUCCESS:
      return { ...state, groupList: action.payload };
    case GET_ROLE_LIST:
      return { ...state };
    case GET_ROLE_LIST_SUCCESS:
      return { ...state, rolesList: action.payload };
    case SEND_STAFF_INVITE:
      return { ...state };
    case SEND_STAFF_INVITE_SUCCESS:
      return { ...state };
    case GET_GROUP_ROLES_LIST:
      return { ...state };
    case GET_GROUP_ROLES_LIST_SUCCESS:
      return { ...state, groupRolesList: action.payload }
    default:
      return state;
  }
}