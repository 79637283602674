import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./main.scss";
import {
  DropdownToggle, DropdownMenu, DropdownItem, Badge, Button, Col, Container, Modal, ModalBody, Progress, Row, UncontrolledTooltip, FormGroup, Label, Input, Card, CardBody, UncontrolledDropdown
} from "reactstrap";

import $ from "jquery";

import axios from "axios";
import config from "../../config.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Flag from "react-world-flags";

import ViewFeedBack from "../ViewFeedback";
import AppBackgroundinfo from "./../AppBackgroundinfo/index";
import AppEducation from "./../AppEducation/AppEducation";
import AppPersonalinfo from "./../AppPersonalinfo/AppPersonalinfo";
import AppTestscore from "./../AppTestscore/AppTestscore";
import AppUploadDocument from "./../AppUploadDocument/AppUploadDocument";
import DocumentRequired from "./../DocumentRequired/DocumentRequired";
import backarrow from "../../assets/img/university/back_arrow.svg";
import Closebtn from "../../assets/img/close-btn.svg";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as CommentCountIcon } from "../../assets/img/comment-icon.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getApplicationDetailsAction,
  getApplicationStepStatusAction,
  getProfileAction
} from "../../store/actions";
import moment from "moment";
import FormError from "../../common/FormError";
import { groupArray, groupArrayCustomResponse } from "../../common/commonHelper";
import CommentListElement from "./component/CommentListElement";
import AddComment from "./component/AddComment";
import chaticon1 from "../../assets/img/chat-bluehead.png";
import { MESSAGES } from "../../constant/messages";
import { ReactComponent as Warningicon } from "../../assets/img/icons/waring_error_icon.svg";
import { ReactComponent as Successicon } from "../../assets/img/icons/inactive-correct.svg";
import { baseUrl, GET_APPLICATION_PDF_FILE_API } from "../../Api";
import pdfInstace from "pspdfkit"
import PageLoading from "../../common/PageLoading";

const baseurl = `${config.baseurl}`;

const dropDownValue = [
  {
    value: "all",
    label: "All"
  },
  {
    value: "new",
    label: "Newest"
  },
  {
    value: "old",
    label: "Oldest"
  }
]
class ApplicationOverview extends Component {
  constructor(props) {
    super(props);

    this.generalTabRef = React.createRef();
    this.pdfDocRef = React.createRef()

    this.state = {
      pageTitle: "APPLICATIONS",
      secondMenu: [
        // {
        //   name: "Settings",
        //   path: "/application-settings",
        //   icon: ApplicationSettingIcon,
        // }
      ],
      totalcountmsg: 0,
      country: [],
      country_name: [],
      errors: {},
      fname: "",
      lname: "",
      regdate: "",
      countryCode: "",
      sdob: "",
      studpname: "",
      disabled: true,
      heading: "",
      logo_image: "",
      start_date: "",
      vieweradded: "",
      registersuccess: false,
      agentname: "",
      ParentStatusType: "",
      commentOpen: false,
      messageModalOpen: false,
      messageResposnse: 0,
      applicationId: 0,
      universityId: 0,
      generalData: null,
      educationData: null,
      testscoreData: null,
      backgroundData: null,
      documentsData: null,
      currentStatus: null,
      studentId: 0,
      comments: [],
      openCommentPopover: 0,
      tabComments: [],
      totalCount: 0,
      stepStatus: {
        personal_info_flag: false,
        edu_history_flag: false,
        test_score_flag: false,
        background_info_flag: false,
        upload_doc_flag: false,
      },
      activeTab: "tab1popup",
      ProgressValue: 20,
      currentStep: "general",
      filteredComments: [],
      applicationFormId: 0,
      pdfData: [],
      loaded: false,
      loading: false,
      filterallcommants: "all"
    };
  }



  componentDidMount(prevProps, prevState) {
    let id =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;
    //console.log("id", id);

    let param = localStorage.getItem("studentid");

    this.setState({
      applicationId: id,
      universityId: param
    }, () => {
      this.getGeneraldata();
      this.getApplicationStepStatus()
      this.getAllMessage()
      // this.getComments()
      // this.getMessageCommentCount()
      // this.getEducationData();
      // this.getTestscoreData();
      // this.getBackgroundData();
      // this.getDocumentData();
    });

    // this.disablePro();
    $(".profile-student .nav li").click(function () {
      $(".profile-student .nav a.active")
        .parent(".nav-item")
        .prev("li")
        .addClass("someclass")
        .next("li")
        .removeClass("someclass");
    });

    $(document).ready(function () {
      $(".profile-student ul li a").click(function () {
        $(".profile-student li a").removeClass("selected");
        $(this).addClass("selected");
        $(this).closest("li").prevAll().find("a").addClass("selected");
      });

      $('#clickTab1popup').click(function () {
        $(".tab1_progess").addClass("active");
        // $(".percentNo").text('20');
        // $(".tab2_progess, .tab3_progess, .tab4_progess, .tab5_progess").removeClass("active");
      });

      $('#clickTab2popup').click(function () {
        $(".tab2_progess").addClass("active");
        // $(".percentNo").text('40');
        // $(".tab1_progess, .tab3_progess, .tab4_progess, .tab5_progess").removeClass("active");
      });

      $('#clickTab3popup').click(function () {
        $(".tab3_progess").addClass("active");
        // $(".percentNo").text('60');
        // $(".tab1_progess, .tab2_progess, .tab4_progess, .tab5_progess").removeClass("active");
      });

      $('#clickTab4popup').click(function () {
        $(".tab4_progess").addClass("active");
        // $(".percentNo").text('80');
        // $(".tab1_progess, .tab2_progess, .tab3_progess, .tab5_progess").removeClass("active");
      });

      $('#clickTab5popup').click(function () {
        $(".tab5_progess").addClass("active");
        // $(".percentNo").text('100');
        // $(".tab1_progess, .tab2_progess, .tab3_progess, .tab4_progess").removeClass("active");
      });
    });

    $(".left-list li").on("click", function () {
      $(this).siblings().removeClass("active");
      $(this).addClass("active0");
    });

    if (!this.props.profile) {
      this.props.getProfileAction({ univ_id: localStorage.getItem("studentid") })
    }
  }

  showLoader = () => {
    this.setState({
      loading: true
    })
  }

  hideLoader = () => {
    this.setState({
      loading: false
    })
  }

  getGeneraldata = () => {
    this.showLoader();

    const {
      universityId,
      applicationId
    } = this.state;

    new Promise((resolve, reject) => {
      this.props.getApplicationDetailsAction(
        {
          student_id: universityId,
          application_id: applicationId,
          tab: "general"
        },
        (response) => {
          this.hideLoader();
          let programDetails = null
          let heading = ""
          let logo_image = ""
          let intake = null
          let last_update = "N/A"
          let applicationFormId = 0, applicationData = [], pdfSignatures = []

          if (response.data) {
            last_update = response.data.last_edited
          }
          if (
            response.data
            && response.data.course_info
          ) {
            heading = response.data.course_info.heading
            programDetails = response.data.course_info
          }
          if (
            response.data
            && response.data.university_details
            && response.data.university_details.logo_image
          ) {
            logo_image = response.data.university_details.logo_image
          }
          if (
            response.data
            && response.data.intake
          ) {
            intake = response.data.intake
          }

          if (response.data.application_form_id) {
            applicationFormId = response.data.application_form_id
          }

          if (
            response.data.application_data
            && response.data.application_data.application_data
          ) {
            let values = JSON.parse(response.data.application_data.application_data)
            applicationData = values
          }

          if (
            response.data.application_data
            && response.data.application_data.signature_data
          ) {
            pdfSignatures = response.data.application_data.signature_data.map(val => {
              let currentVal = val
              currentVal.data = JSON.parse(val.data)

              return currentVal
            })
          }

          this.setState({
            heading,
            programDetails,
            logo_image,
            intake,
            last_update,
            applicationFormId,
            pdfSignatures,
            loaded: true
          }, async () => {
            if (applicationFormId) {
              this.setState({
                ProgressValue: 50
              })
              let container = this.pdfDocRef.current
              const headers = new Headers();
              headers.set("Accept", "application/pdf")
              headers.set("Content-typ", "application/pdf")
              const pdfResponse = await fetch(
                `${baseUrl}${GET_APPLICATION_PDF_FILE_API}/${applicationFormId}`,
                {
                  headers
                }
              );
              const documentBuffer = await pdfResponse.arrayBuffer();

              let instanceObj = {
                container,
                document: documentBuffer,
                toolbarItems: [],
                electronicSignatures: {
                  creationModes: [
                    pdfInstace.ElectronicSignatureCreationMode.DRAW,
                    pdfInstace.ElectronicSignatureCreationMode.IMAGE
                  ]
                },
                isEditableAnnotation: function (ann) {
                  return false
                }
              }

              if (applicationData) {
                instanceObj = {
                  ...instanceObj,
                  instantJSON: applicationData
                }
              }

              // pdfInstace.Options.SIGNATURE_SAVE_MODE = pdfInstace.SignatureSaveMode.ALWAYS

              let loadPdf = await pdfInstace.load(instanceObj)
              loadPdf.setViewState(viewState => viewState.set("showToolbar", false))

              loadPdf.addEventListener("viewState.zoom.change", () => { this.updateView(loadPdf) })
              window.onresize = () => { this.updateView(loadPdf) }

              this.updateView(loadPdf)

              this.getApplicationStepStatus()
            }
          })
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject()
        }
      )
    })
  };

  getEducationData = () => {
    this.showLoader();

    const {
      universityId,
      applicationId
    } = this.state;

    new Promise((resolve, reject) => {
      this.props.getApplicationDetailsAction(
        {
          agent_id: universityId,
          application_id: applicationId,
          tab: "edu"
        },
        (response) => {
          this.hideLoader();
          if (response.success) {
            this.setState({
              educationData: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject()
        }
      )
    })
  };

  getTestscoreData = () => {
    this.showLoader();

    const {
      universityId,
      applicationId
    } = this.state;

    new Promise((resolve, reject) => {
      this.props.getApplicationDetailsAction(
        {
          agent_id: universityId,
          application_id: applicationId,
          tab: "test-score"
        },
        (response) => {
          this.hideLoader();
          if (response.success) {
            this.setState({
              testscoreData: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject()
        }
      )
    })
  }

  getBackgroundData = () => {
    this.showLoader();

    const {
      universityId,
      applicationId
    } = this.state;

    new Promise((resolve, reject) => {
      this.props.getApplicationDetailsAction(
        {
          agent_id: universityId,
          application_id: applicationId,
          tab: "background-info"
        },
        (response) => {
          this.hideLoader();
          if (response.success) {
            this.setState({
              backgroundData: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject()
        }
      )
    })
  }

  getDocumentData = () => {
    this.showLoader();

    const {
      universityId,
      applicationId
    } = this.state;

    new Promise((resolve, reject) => {
      this.props.getApplicationDetailsAction(
        {
          student_id: universityId,
          application_id: applicationId,
          tab: "uploaded-document"
        },
        (response) => {
          this.hideLoader();
          if (response.success) {
            this.setState({
              documentsData: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject()
        }
      )
    })
  }


  handleCommentSection = () => {
    // this.getApplicationComments();
    // this.getMessageCommentCount();

    this.setState({
      commentOpen: !this.state.commentOpen
    });
  };

  handleMessageModal = () => {
    this.setState({
      messageModalOpen: !this.state.messageModalOpen,
    });
  };

  getApplicationStatus = () => {
    new Promise((resolveIn, rejectIn) => {
      this.props.getApplicationStatusAction(
        {
          agent_id: this.state.universityId,
          application_id: this.state.applicationId
        },
        resolveIn,
        rejectIn
      )
    })
  }

  addComment = (data) => {
    return new Promise((resolve, reject) => {
      let request = {
        student_id: this.state.universityId,
        application_id: this.state.applicationId,
        tab: data.tab,
        field_id: data.field,
        comment: data.comment
      };
      if (data.comment_id) {
        request.comment_id = data.comment_id
      }
      if (data.reply_to) {
        request.reply_to = data.reply_to
      }
      axios.post(baseurl + '/save_application_comment', request).then(data => {
        if (data.data && data.data.success) {
          this.getAllMessage()
          resolve(data.data)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }

  getAllMessage = () => {
    axios
      .get(
        baseurl +
        "/get_application_comments/" +
        this.state.applicationId
      )
      .then((response) => {
        if (response.data) {
          let groups = {};
          for (var i = 0; i < response.data.length; i++) {
            const groupName = moment(
              response.data[i].created_at
            ).format("yyyy, MMM DD");
            if (!groups[groupName]) {
              groups[groupName] = [];
            }
            groups[groupName].push(response.data[i]);
          }
          let myArray = [];
          for (var groupName in groups) {
            myArray.push({
              group: groupName,
              groupdata: groups[groupName],
            });
          }
          this.setState({
            getallmessagecommant: myArray,
            comments: response.data
          })
        } else {
          this.hideLoader()
          toast.success(MESSAGES.PLEASE_TRY_AGAIN);
        }
      });

  }

  getComments = () => {
    this.props.getClientCommentAction({
      agent_id: this.state.universityId,
      application_id: this.state.applicationId
    }, (response) => {
      if (response.success) {
        if (response.data) {
          let data = response.data;
          let tabComments = [];

          for (let tab in data) {
            if (data.hasOwnProperty(tab)) {
              let fields = data[tab];
              for (let id in fields) {
                if (fields.hasOwnProperty(id)) {
                  let comments = fields[id];
                  let lastComment = "";
                  if (comments.length) {
                    lastComment = comments[comments.length - 1];
                  }
                  comments.map(val => {
                    let currentVal = val;
                    currentVal.options = false
                    currentVal.edit = false

                    return currentVal
                  });
                  console.log('datata comments', comments, data[tab][id])
                  data[tab][id] = comments
                  tabComments.push({
                    tab: tab,
                    id: id,
                    lastComment: lastComment,
                    count: comments.length
                  })
                }
              }
            }

          }
          console.log('datata', data)
          console.log('tabComments', tabComments);
          this.setState({
            comments: data,
            tabComments,
            totalCount: response.data.count
          })
        }

      }
    })
  }

  deleteComment = (id) => {
    this.showLoader()
    return new Promise((resolve, reject) => {
      axios.get(baseurl + "/remove_application_comment/" + id + "/" + this.state.universityId).then(data => {
        this.hideLoader();
        if (data.data.success) {
          toast.success(data.data.message)
          this.getAllMessage()
        }
        resolve(data.data)
      }).catch(err => {
        this.hideLoader();
        reject(err)
      })

    })
  }

  toggleCommentPopup = () => {
    this.setState({
      openCommentPopover: 0
    })
  }

  getApplicationStepStatus = () => {
    if (this.state.universityId && this.state.applicationId) {
      this.showLoader();
      this.props.getApplicationStepStatusAction({
        student_id: this.state.universityId,
        application_id: this.state.applicationId
      }, (response) => {
        this.hideLoader();
        if (response.success) {
          this.setState({
            stepStatus: response.data
          })
        }
      }, (err) => {
        this.hideLoader();
      })
    }
  }

  tabClassName = (tab, per) => {
    let finalClassName = '';
    if (tab === this.state.currentStep) {
      finalClassName += 'selected '
    }
    if (per <= this.state.ProgressValue) {
      finalClassName += 'active'
    }

    return finalClassName
  }

  filterCommants = (e) => {
    const {
      comments
    } = this.state
    this.setState({ filterallcommants: e });
    // this.showLoader();
    if (e !== "all") {
      // const dateTo = moment(new Date()).format("yyyy, MMM DD");
      // const dateFrom = moment().subtract(e, "d").format("yyyy, MMM DD");

      // let filteredComments = this.state.comments.filter(item => {
      //   return (
      //     moment(item.created_date).format("yyyy, MMM DD") >= dateFrom &&
      //     moment(item.created_date).format("yyyy, MMM DD") <= dateTo
      //   );
      // })

      let filteredComments = []

      if (e == "new") {
        filteredComments = comments.sort((first, second) => {
          return new Date(second.updated_at) - new Date(first.updated_at)
        })
      } else {
        filteredComments = comments.sort((first, second) => {
          return new Date(first.updated_at) - new Date(second.updated_at)
        })
      }

      this.setState({
        filteredComments
      })
    } else {
      this.setState({
        filteredComments: []
      }, () => {
        this.getAllMessage()
      })
    }
  };

  updateView = (loadPdf) => {
    this.updateScrollListener(this.updateContainerHeight(loadPdf), loadPdf);
  }

  updateScrollListener = (containerHeight, loadPdf) => {
    let current = this
    let instance = loadPdf
    const rect = document.querySelector(".pdfView").getBoundingClientRect();
    const containerTop = rect.top + 44;
    const containerBottom = containerTop + containerHeight - 44;
    window.onscroll = function () {
      if (window.scrollY > containerTop && window.scrollY < containerBottom) {
        const currentPageIndex = Math.floor(
          (instance.totalPageCount * (window.scrollY - containerTop)) /
          containerHeight
        );
        if (currentPageIndex !== instance.viewState.currentPageIndex) {
          instance.setViewState(viewState =>
            viewState.set("currentPageIndex", currentPageIndex)
          );
        }
      }
    };
  }

  updateContainerHeight = (loadPdf) => {
    let containerHeight = 0;
    for (let i = 0; i < loadPdf.totalPageCount; i++) {
      containerHeight +=
        loadPdf.pageInfoForIndex(i).height + loadPdf.viewState.spreadSpacing;
    }
    containerHeight *= loadPdf.currentZoomLevel; // convert to client dimension
    containerHeight += 44; // Toolbar height
    containerHeight += loadPdf.viewState.viewportPadding.vertical * 2; // viewport padding
    document.querySelector(".pdfView").style.height = `${containerHeight}px`;
    return containerHeight;
  }

  render() {
    const imgpath = `${config.baseurl}`;

    let startDate = "N/A"
    let deadline = "N/A"

    if (this.state.intake) {
      if (this.state.intake.intake_date) {
        startDate = moment(this.state.intake.intake_date).format("DD/MM/YYYY")
      }
      if (this.state.intake.semester_census_date) {
        deadline = moment(this.state.intake.semester_census_date).format("DD/MM/YYYY")
      }
    }

    let percentComplete = 0
    if (this.state.applicationFormId) {
      percentComplete = 50
      for (let step in this.state.stepStatus) {
        if (this.state.stepStatus.hasOwnProperty(step)) {
          if (step == "upload_doc_flag" && this.state.stepStatus[step]) {
            percentComplete += 50
          }
        }
      }
    } else {
      for (let step in this.state.stepStatus) {
        if (this.state.stepStatus.hasOwnProperty(step)) {
          if (this.state.stepStatus[step]) {
            percentComplete += 20
          }
        }
      }
    }

    return (
      <div
        className="animated fadeIn application-form applicationAddComment"
        style={{ backgroundColor: "#fafafa" }}
      >
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading />
          ) : null
        }

        <div className="">
          <Row className="m-0">
            <Col xs="12" lg="8" xl="9" className="p-0">
              <Row>
                <Col xs="12" lg="12" >
                  <div className="commentPageHeader">
                    <Row>
                      <Col xs="12" lg={{ size: 11, offset: 1 }}>
                        <Link
                          to={`/Applications/${this.state.applicationId}`}
                          className="backarrow mt-3 cur-pointer"
                        >
                          <span className="pr-1 mr-5"><img src={backarrow} alt="" /></span>{" "}
                          Back to application overview
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs="12" lg={{ size: 11, offset: 1 }}>
                  <div className="applicationPDFViewWrap">
                    <Scrollbars
                      style={{ height: "78vh" }}
                      className="Scrollbars"
                    >
                      <Card className="profileEditSec editProfile">
                        <CardBody>
                          <Row className="profileEditUserLeft">
                            <Col xs={{ size: 12 }} lg={{ size: 12 }}>
                              <Row>
                                <Col xs={{ size: 7 }} md={{ size: 3 }}>
                                  <div className="profileLogo">
                                    <span className='imgBox'>
                                      <img src={imgpath + this.state.logo_image} alt="" />
                                    </span>
                                  </div>
                                </Col>
                                <Col xs={{ size: 12 }} md={{ size: 8 }}>
                                  <h5 className="app-sub-heading">
                                    {this.state.heading}
                                  </h5>
                                  <ul className="view-app-detail">
                                    <li className="view-app-left">
                                      <strong>Level :</strong>
                                      {
                                        this.state.programDetails ? (this.state.programDetails.study_level ? this.state.programDetails.study_level.level_name : "") : ""
                                      }
                                    </li>
                                    <li className="view-app-left">
                                      <strong>Required Level :</strong>
                                      {
                                        this.state.programDetails ? (
                                          this.state.programDetails.required_eductaion_level ? (
                                            this.state.programDetails.required_eductaion_level.educationlevel_name
                                          ) : "N/A"
                                        ) : "N/A"
                                      }
                                    </li>
                                    <li className="view-app-left">
                                      <strong>Application Fee :</strong>
                                      {
                                        this.state.programDetails ? (
                                          this.state.programDetails.application_fee_string ? (
                                            this.state.programDetails.application_fee_string.toUpperCase()
                                          ) : ""
                                        ) : ""
                                      }
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody className='footer'>
                          <Row className="profileEditUserLeft">
                            <Col xs="12" className="Clearfix view-prof-detail">
                              <Row>
                                <Col xs="6" md="3" lg="3" xl="2" className="date-label">
                                  <label className="card-label">COURSE START DATE:</label>
                                  <FormGroup>
                                    <input type="text" readOnly="readonly" className="form-control col-md-12 bg-white" value={startDate} />
                                  </FormGroup>
                                  {this.state.errors.dateofbirth && <FormError error={this.state.errors.dateofbirth} />}
                                </Col>
                                <Col xs="6" md="3" lg="3" xl="3" className="date-label">
                                  <label className="card-label">APPLICATION DEADLINE:</label>
                                  <FormGroup>
                                    <input type="text" readOnly="readonly" className="form-control col-md-12 bg-white" value={deadline} />
                                  </FormGroup>
                                  {this.state.errors.dateofbirth && <FormError error={this.state.errors.dateofbirth} />}
                                </Col>
                                <Col xs="12" md="6" lg="7" xl="6" className="date-label mobileTop app_top_right_header">
                                  <label className="card-label">
                                    <strong>DRAFT</strong>
                                    <span className="ml-2 font-weight-bold" style={{ color: "#2D6163", textTransform: "capitalize" }}> <span className="percentNo">
                                      {percentComplete}
                                    </span>% Complete</span></label>
                                  <div className="tab-content" style={{ border: '1px solid rgb(45, 97, 99)', backgroundColor: '#F2F2F2', borderRadius: '10px' }}>
                                    <div className="progress-bar-new tab-pane active tab1_progess" role="tabpanel" style={{ width: `${percentComplete}%`, padding: 'inherit' }}></div>

                                  </div>
                                  <div className="mt-2 float-right" style={{ fontSize: '9px' }}>LAST UPDATED:  {
                                    this.state.type == "program" ? "N/A" : this.state.last_update
                                  }</div>
                                </Col>
                                <Col xs="12"><hr /></Col>
                                <Col xs="12" md="6">
                                  <h6 style={{ color: "red", marginLeft: "35%", paddingTop: "5px", }}>
                                    {this.state.errors.studfileLength}
                                  </h6>
                                </Col>
                                <Col xs="12">
                                  <div className='tabStatus'>
                                    {this.state.currentStep === "general"}
                                  </div>
                                  <ul id="ProfileTab" role="tablist">
                                    {
                                      this.state.loaded ? (
                                        this.state.applicationFormId ? (
                                          <li className={this.tabClassName('general', 50)}
                                            onClick={() => { this.setState({ currentStep: "general", ProgressValue: 50 }) }}>
                                            <a className="nav-link " data-toggle="tab" href="#tab1" role="tab" tabTitle="General Info">
                                              Application Form
                                            </a>
                                          </li>
                                        ) : (
                                          <>
                                            <li className={this.tabClassName('general', 20)}
                                              onClick={() => { this.setState({ currentStep: "general", ProgressValue: 20 }) }}>
                                              <a className="nav-link " data-toggle="tab" href="#tab1" role="tab" tabTitle="General Info">
                                                {this.state.stepStatus.personal_info_flag
                                                  ? (<Successicon className="successIcon" />)
                                                  : (<Warningicon className="warningIcon" />)
                                                }
                                                General Info
                                              </a>
                                            </li>
                                            <li className={this.tabClassName('education', 40)}
                                              onClick={() => { this.setState({ currentStep: "education", ProgressValue: 40 }) }}>
                                              <a className="nav-link" data-toggle="tab" href="#tab2" role="tab" tabTitle="Education History">
                                                {this.state.stepStatus.edu_history_flag
                                                  ? (<Successicon className="successIcon" />)
                                                  : (<Warningicon className="warningIcon" />)
                                                }
                                                Education History
                                              </a>
                                            </li>
                                            <li className={this.tabClassName('test', 60)}
                                              onClick={() => { this.setState({ currentStep: "test", ProgressValue: 60 }) }}>
                                              <a className="nav-link" data-toggle="tab" href="#tab3" role="tab" tabTitle="Test Scores">
                                                {this.state.stepStatus.test_score_flag
                                                  ? (<Successicon className="successIcon" />)
                                                  : (<Warningicon className="warningIcon" />)
                                                }
                                                Test Scores
                                              </a>
                                            </li>
                                            <li className={this.tabClassName('background', 80)}
                                              onClick={() => { this.setState({ currentStep: "background", ProgressValue: 80 }) }}>
                                              <a className="nav-link" data-toggle="tab" href="#tab4" role="tab" tabTitle="Background Info">
                                                {this.state.stepStatus.background_info_flag
                                                  ? (<Successicon className="successIcon" />)
                                                  : (<Warningicon className="warningIcon" />)
                                                }
                                                Background Info
                                              </a>
                                            </li>
                                          </>
                                        )
                                      ) : null
                                    }

                                    <li className={this.tabClassName('documents', 100)}
                                      onClick={() => { this.setState({ currentStep: "documents", ProgressValue: 100 }) }}>
                                      <a className="nav-link" data-toggle="tab" href="#tab5" role="tab" tabTitle="Upload Documents">
                                        {this.state.stepStatus.upload_doc_flag
                                          ? (<Successicon className="successIcon" />)
                                          : (<Warningicon className="warningIcon" />)
                                        }
                                        Upload Documents
                                      </a>
                                    </li>
                                  </ul>
                                </Col>
                                <Col xs="12">
                                  <div className='progress-box-23'>
                                    <Progress value={this.state.ProgressValue} />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {/* Tab Content */}
                      <div className="tab-content applicant-content">
                        <div
                          className="tab-pane active"
                          id="app"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="profile-student col-12">

                              <div className="tab-content newTabUI">
                                {
                                  this.state.loaded ? (
                                    this.state.applicationFormId ? (
                                      <div className={this.state.currentStep === "general" ? "tab-pane active" : "tab-pane"}
                                        // className={`tab-pane ${this.state.activeTab == 'tab1popup' ? 'active' : ''}`}
                                        id="tab1popup"
                                        role="tabpanel"
                                      >
                                        <div
                                          ref={ref => this.pdfDocRef.current = ref}
                                          style={{ width: "100%", minHeight: "100vh", height: "100vh" }}
                                          className="pdfView"
                                        >

                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className={this.state.currentStep === "general" ? "tab-pane active" : "tab-pane"}
                                          // className={`tab-pane ${this.state.activeTab == 'tab1popup' ? 'active' : ''}`}
                                          id="tab1popup"
                                          role="tabpanel"
                                        >
                                          {/* <div className="w-100 progress-box-23">
                                    <Progress value="20" />
                                  </div> */}
                                          <AppPersonalinfo
                                            {...this.props}
                                            showLoader={this.showLoader}
                                            hideLoader={this.hideLoader}
                                            showComment={true}
                                            openCommentPopover={this.state.openCommentPopover}
                                            addComment={(data) => {
                                              return this.addComment(data)
                                            }}
                                            tab={"general"}
                                            toggle={this.toggleCommentPopup}
                                            deleteComment={(id) => {
                                              return this.deleteComment(id)
                                            }}
                                            closeSidePopup={() => {
                                              this.setState({
                                                openCommentPopover: ""
                                              })
                                            }}
                                            getApplicationStepStatus={this.getApplicationStepStatus}
                                            applicationId={this.state.applicationId}
                                          />
                                        </div>
                                        <div className={this.state.currentStep === "education" ? "tab-pane active" : "tab-pane"}
                                          // className={`tab-pane ${this.state.activeTab == 'tab2popup' ? 'active' : ''}`}
                                          id="tab2popup"
                                          role="tabpanel"
                                        >
                                          {/* <div className="w-100 progress-box-23">
                                    <Progress value="40" />
                                  </div> */}
                                          <AppEducation
                                            {...this.props}
                                            showLoader={this.showLoader}
                                            hideLoader={this.hideLoader}
                                            openCommentPopover={this.state.openCommentPopover}
                                            showComment={true}
                                            addComment={(data) => {
                                              return this.addComment(data)
                                            }}
                                            comments={this.props.comments}
                                            tab={"education"}
                                            toggle={this.toggleCommentPopup}
                                            deleteComment={(id) => {
                                              return this.deleteComment(id)
                                            }}
                                            closeSidePopup={() => {
                                              this.setState({
                                                openCommentPopover: ""
                                              })
                                            }}
                                            getApplicationStepStatus={this.getApplicationStepStatus}
                                            applicationId={this.state.applicationId}
                                          />
                                        </div>
                                        <div className={this.state.currentStep === "test" ? "tab-pane active" : "tab-pane"}
                                          // className={`tab-pane ${this.state.activeTab == 'tab3popup' ? 'active' : ''}`}
                                          id="tab3popup"
                                          role="tabpanel"
                                        >
                                          {/* <div className="w-100 progress-box-23">
                                    <Progress value="60" />
                                  </div> */}
                                          <AppTestscore
                                            {...this.props}
                                            showLoader={this.showLoader}
                                            hideLoader={this.hideLoader}
                                            openCommentPopover={this.state.openCommentPopover}
                                            showComment={true}
                                            addComment={(data) => {
                                              return this.addComment(data)
                                            }}
                                            comments={this.props.comments}
                                            tab={"test"}
                                            toggle={this.toggleCommentPopup}
                                            deleteComment={(id) => {
                                              return this.deleteComment(id)
                                            }}
                                            closeSidePopup={() => {
                                              this.setState({
                                                openCommentPopover: ""
                                              })
                                            }}
                                            key="from_comments_inside"
                                            getApplicationStepStatus={this.getApplicationStepStatus}
                                            applicationId={this.state.applicationId}
                                          />
                                        </div>
                                        <div className={this.state.currentStep === "background" ? "tab-pane active" : "tab-pane"}
                                          // className={`tab-pane ${this.state.activeTab == 'tab4popup' ? 'active' : ''}`}
                                          id="tab4popup"
                                          role="tabpanel"
                                        >
                                          {/* <div className="w-100 progress-box-23">
                                    <Progress value="80" />
                                  </div> */}
                                          <AppBackgroundinfo
                                            {...this.props}
                                            showLoader={this.showLoader}
                                            hideLoader={this.hideLoader}
                                            openCommentPopover={this.state.openCommentPopover}
                                            showComment={true}
                                            addComment={(data) => {
                                              return this.addComment(data)
                                            }}
                                            comments={this.props.comments}
                                            tab={"background"}
                                            toggle={this.toggleCommentPopup}
                                            deleteComment={(id) => {
                                              return this.deleteComment(id)
                                            }}
                                            closeSidePopup={() => {
                                              this.setState({
                                                openCommentPopover: ""
                                              })
                                            }}
                                            getApplicationStepStatus={this.getApplicationStepStatus}
                                            applicationId={this.state.applicationId}
                                          />
                                        </div>
                                      </>
                                    )
                                  ) : null
                                }

                                <div className={this.state.currentStep === "documents" ? "tab-pane active" : "tab-pane"}
                                  // className={`tab-pane ${this.state.activeTab == 'tab5popup' ? 'active' : ''}`}
                                  id="tab5popup"
                                  role="tabpanel"
                                >
                                  {
                                    console.log('log this.state.applicationFormId', this.state.applicationFormId)
                                  }
                                  {/* <div className="w-100 progress-box-23">
                                    <Progress value="100" />
                                  </div> */}
                                  <AppUploadDocument
                                    {...this.props}
                                    showLoader={this.showLoader}
                                    hideLoader={this.hideLoader}
                                    openCommentPopover={this.state.openCommentPopover}
                                    showComment={true}
                                    addComment={(data) => {
                                      return this.addComment(data)
                                    }}
                                    comments={this.props.comments}
                                    tab={"documents"}
                                    toggle={this.toggleCommentPopup}
                                    deleteComment={(id) => {
                                      return this.deleteComment(id)
                                    }}
                                    closeSidePopup={() => {
                                      this.setState({
                                        openCommentPopover: ""
                                      })
                                    }}
                                    getApplicationStepStatus={this.getApplicationStepStatus}
                                    applicationId={this.state.applicationId}
                                    applicationFormId={this.state.applicationFormId}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane "
                          id="docform"
                          role="tabpanel"
                        >
                          <DocumentRequired {...this.props} />
                        </div>
                      </div>

                      <div className="applicationOverview pb-0">



                      </div>

                      <Modal
                        isOpen={this.state.registersuccess}
                        toggle={this.toggleSuccess}
                        className={
                          "modal-md " + "register-popup sucess-pop"
                        }
                      >
                        <ModalBody>
                          <div className="modal_header mb-4">
                            <span>
                              &nbsp;&nbsp;&nbsp;
                              <img
                                src={Closebtn}
                                alt="close-icon"
                                onClick={this.closeAll}
                                className="uni-icon"
                              />
                            </span>
                          </div>
                          <div className="p-5 addview-popup text-center">
                            <div className="mb-5 sucess-text">
                              <span>
                                Are you sure you want to remove
                                the {this.state.agentname}{" "}
                                viewer from your application?{" "}
                              </span>

                              <p>
                                {" "}
                                {localStorage.getItem(
                                  "course_name"
                                )}
                              </p>
                            </div>
                            <div className="row mx-auto addviewer-btn">
                              <a
                                onClick={() => {
                                  this.removeViewerFromTable()
                                }}
                                style={{
                                  background: "#ffffff",
                                  fontSize: "10px",
                                }}
                                color="link "
                                className="px-0 register-link-a"
                              >
                                Confirm
                              </a>
                              <a
                                onClick={this.closeAll}
                                style={{
                                  background: "#ffffff",
                                  fontSize: "10px",
                                }}
                                color="link "
                                className="px-0 register-link-a"
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    </Scrollbars>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="4" xl="3" className="p-0">
              <div className="commentListSec">
                <div className="comment_top_section">
                  <div className="comment_top_left">
                    <p>Comment</p>
                    <div className="count">
                      <CommentCountIcon />
                      <Badge color="primary">
                        {this.state.comments.length}
                      </Badge>
                    </div>
                  </div>
                  <div className="rightOptions">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>
                        {
                          dropDownValue.map((val, index) => {
                            if (val.value == this.state.filterallcommants) {
                              return val.label
                            }
                          })
                        }
                      </DropdownToggle>
                      <DropdownMenu>
                        {
                          dropDownValue.map((val, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={(e) => this.filterCommants(val.value)}
                              >
                                {val.label}
                              </DropdownItem>
                            )
                          })
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <select
                      className="w-100 cmt-drop"
                      value={this.state.filterallcommants}
                      onChange={(e) => this.filterCommants(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="new">Newest</option>
                      <option value="old">Oldest</option>
                    </select> */}
                  </div>
                </div>

                <Scrollbars
                  style={{ height: 'calc(100vh - 240px)' }}
                  className="Scrollbars"
                >
                  <ul className="commentList">
                    {/* {
                      console.log('log filterallcommants ', this.state.comments, this.state.filteredComments)
                    } */}
                    {
                      this.state.comments
                        && this.state.comments.length ? (
                        this.state.filteredComments.length ? (
                          this.state.filteredComments.map((val, index) => (
                            <CommentListElement
                              val={val}
                              index={index}
                              key={index}
                              studentId={this.state.universityId}
                              profile={this.props.profile}
                              addComment={this.addComment}
                              deleteComment={this.deleteComment}
                            />
                          ))
                        ) : (
                          this.state.comments.map((val, index) => (
                            <CommentListElement
                              val={val}
                              index={index}
                              key={index}
                              studentId={this.state.universityId}
                              profile={this.props.profile}
                              addComment={this.addComment}
                              deleteComment={this.deleteComment}
                            />
                          ))
                        )
                      ) : null
                    }
                    <AddComment
                      addComment={this.addComment}
                    />
                  </ul>
                </Scrollbars>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chatCount: state.applicationReducer.chatCount,
    messageCount: state.applicationReducer.messageCount,
    applicationStatus: state.applicationReducer.applicationStatus,
    appStatusString: state.applicationReducer.appStatusString,
    studentProfilePic: state.applicationReducer.studentProfilePic,
    profile: state.profileReducer.profile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getApplicationDetailsAction,
      getApplicationStepStatusAction,
      getProfileAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationOverview);
