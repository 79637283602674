import { put, takeLatest, all, call } from 'redux-saga/effects';
import axios from "axios";
import {
  baseUrl,
  COMPOSE_MAIL_API,
  CREATE_MEETING_API,
  DELETE_INBOX_EMAIL_API,
  DELETE_MEETING_API,
  GET_CONTACT_LIST_API,
  GET_INBOX_MAIL_DETAILS_API,
  GET_TIMEZONES_LIST_API,
  GET_UNREAD_INBOX_COUNT_API,
  INBOX_LIST_API,
  MEETING_DETAILS_API,
  MEETING_LIST_API,
  UPDATE_INBOX_READ_STATUS_API,
  UPDATE_MAIL_FAVOURITE_API,
  UPDATE_MEETING_STATUS_API,
  GET_REPLY_TO_MEETING_API,
  CHECK_HIRE_AGENT_API,
  GET_HIRE_AGENT_API,
  GET_SHARE_PROFILE_API,
  GET_AGENT_HIRE_REQUESTS_API,
  UPDATE_HIRE_REQUEST_API,
  DELETE_HIRE_REQUEST_API,
  DELETE_PERM_API,
  MOVE_TO_INBOX_API,
  SEND_MEETING_API
} from '../../Api';
import { composeMailError, composeMailSuccess, createMeetingError, createMeetingSuccess, deleteEmailError, deleteEmailSuccess, deleteMeetingError, deleteMeetingSuccess, getContactListError, getContactListSuccess, getInboxError, getInboxMailDetailsError, getInboxMailDetailsSuccess, getInboxSuccess, getTimeZonesListError, getTimeZonesListSuccess, getUnreadInboxCountError, getUnreadInboxCountSuccess, handleErrorResponse, meetingDetailsError, meetingDetailsSuccess, meetingListError, meetingListSuccess, updateFavouriteError, updateFavouriteSuccess, updateInboxReadStatusError, updateInboxReadStatusSuccess, updateMeetingStatusError, updateMeetingStatusSuccess, getReplyToMeetingError, getReplyToMeetingSuccess, getAgentCheckHireSuccess, getAgentCheckHireError, getHireAgentSuccess, getHireAgentError, getShareProfileError, getShareProfileSuccess, getAgentHireRequestsError, getAgentHireRequestsSuccess, updateAgentHireRequestError, updateAgentHireRequestSuccess, deleteAgentHireRequestError, deleteAgentHireRequestSuccess, deletePermSuccess, deletePermError, moveToInboxSuccess, moveToInboxError, sendMeetingSuccess, sendMeetingError } from '../actions';
import { COMPOSE_MAIL, CREATE_MEETING, DELETE_INBOX_EMAIL, DELETE_MEETING, GET_CONTACT_LIST, GET_INBOX, GET_INBOX_MAIL_DETAILS, GET_TIMEZONES_LIST, GET_UNREAD_INBOX_COUNT, MEETING_DETAILS, MEETING_LIST, UPDATE_INBOX_READ_STATUS, UPDATE_MAIL_FAVOURITE, UPDATE_MEETING_STATUS, GET_REPLY_TO_MEETING, GET_AGENT_CHECK_HIRE, GET_HIRE_AGENT, GET_SHARE_PROFILE, AGENT_HIRE_REQUESTS, UPDATE_HIRE_REQUEST, DELETE_HIRE_REQUEST, DELETE_PERM, MOVE_TO_INBOX, MOVE_TO_INBOX_ERROR, SEND_MEETING } from '../constants';

function composeMailApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+COMPOSE_MAIL_API,
    data: params
  });
}

function* composeMail(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(composeMailApi, payload);

    yield put(composeMailSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(composeMailError(e))
    if (reject) reject(e)
  }
}

export function* composeMailWatcher() {
  yield takeLatest(COMPOSE_MAIL, composeMail)
}

function getInboxApi(params) {
  let url = baseUrl+INBOX_LIST_API;
  if (params.handle) {
    url += "/"+params.handle;
  }
  return axios.request({
    method: "get",
    url: url,
    params: params
  });
}

function* getInbox(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getInboxApi, payload);

    yield put(getInboxSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getInboxError(e))
    if (reject) reject(e)
  }
}

export function* getInboxWatcher() {
  yield takeLatest(GET_INBOX, getInbox)
}

function updateFavouriteApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_MAIL_FAVOURITE_API,
    data: params
  });
}

function* updateFavourite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateFavouriteApi, payload);

    yield put(updateFavouriteSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateFavouriteError(e))
    if (reject) reject(e)
  }
}

export function* updateFavouriteWatcher() {
  yield takeLatest(UPDATE_MAIL_FAVOURITE, updateFavourite)
}

function deleteMailApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+DELETE_INBOX_EMAIL_API,
    data: params
  });
}

function* deleteMail(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteMailApi, payload);

    yield put(deleteEmailSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteEmailError(e))
    if (reject) reject(e)
  }
}

export function* deleteMailWatcher() {
  yield takeLatest(DELETE_INBOX_EMAIL, deleteMail)
}

function getUnreadInboxCountApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_UNREAD_INBOX_COUNT_API+"/"+params.handle,
    params: params
  });
}

function* getUnreadInboxCount(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getUnreadInboxCountApi, payload);

    yield put(getUnreadInboxCountSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getUnreadInboxCountError(e))
    if (reject) reject(e)
  }
}

export function* getUnreadInboxCountWatcher() {
  yield takeLatest(GET_UNREAD_INBOX_COUNT, getUnreadInboxCount)
}

function getInboxMailDetailsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_INBOX_MAIL_DETAILS_API,
    params: params
  });
}

function* getInboxMailDetails(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getInboxMailDetailsApi, payload);

    yield put(getInboxMailDetailsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getInboxMailDetailsError(e))
    if (reject) reject(e)
  }
}

export function* getInboxMailDetailsWatcher() {
  yield takeLatest(GET_INBOX_MAIL_DETAILS, getInboxMailDetails)
}

function updateInboxReadStatusApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_INBOX_READ_STATUS_API,
    data: params
  });
}

function* updateInboxReadStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateInboxReadStatusApi, payload);

    yield put(updateInboxReadStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateInboxReadStatusError(e))
    if (reject) reject(e)
  }
}

export function* updateInboxReadStatusWatcher() {
  yield takeLatest(UPDATE_INBOX_READ_STATUS, updateInboxReadStatus)
}

function getTimeZonesListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_TIMEZONES_LIST_API,
    params: params
  });
}

function* getTimeZonesList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getTimeZonesListApi, payload);

    yield put(getTimeZonesListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getTimeZonesListError(e))
    if (reject) reject(e)
  }
}

export function* getTimeZonesListWatcher() {
  yield takeLatest(GET_TIMEZONES_LIST, getTimeZonesList)
}

function createMeetingApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+CREATE_MEETING_API,
    data: params
  });
}

function* createMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(createMeetingApi, payload);

    yield put(createMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(createMeetingError(e))
    if (reject) reject(e)
  }
}

export function* createMeetingWatcher() {
  yield takeLatest(CREATE_MEETING, createMeeting)
}

function meetingListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+MEETING_LIST_API+"/"+params.handle,
    params: params
  });
}

function* meetingList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(meetingListApi, payload);

    yield put(meetingListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(meetingListError(e))
    if (reject) reject(e)
  }
}

export function* meetingListWatcher() {
  yield takeLatest(MEETING_LIST, meetingList)
}

function meetingDetailsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+MEETING_DETAILS_API,
    params: params
  });
}

function* meetingDetails(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(meetingDetailsApi, payload);

    yield put(meetingDetailsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(meetingDetailsError(e))
    if (reject) reject(e)
  }
}

export function* meetingDetailsWatcher() {
  yield takeLatest(MEETING_DETAILS, meetingDetails)
}

function deleteMeetingApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_MEETING_API,
    data: params
  });
}

function* deleteMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteMeetingApi, payload);

    yield put(deleteMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteMeetingError(e))
    if (reject) reject(e)
  }
}

export function* deleteMeetingWatcher() {
  yield takeLatest(DELETE_MEETING, deleteMeeting)
}

function updateMeetingStatusApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_MEETING_STATUS_API+"/"+params.status,
    data: params
  });
}

function* updateMeetingStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateMeetingStatusApi, payload);

    yield put(updateMeetingStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateMeetingStatusError(e))
    if (reject) reject(e)
  }
}

export function* updateMeetingStatusWatcher() {
  yield takeLatest(UPDATE_MEETING_STATUS, updateMeetingStatus)
}

function getContactListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_CONTACT_LIST_API,
    params: params
  });
}

function* getContactList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getContactListApi, payload);

    yield put(getContactListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getContactListError(e))
    if (reject) reject(e)
  }
}

export function* getContactListWatcher() {
  yield takeLatest(GET_CONTACT_LIST, getContactList)
}

function getReplyToMeetingApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_REPLY_TO_MEETING_API,
    params: params
  });
}

function* getReplyToMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getReplyToMeetingApi, payload);

    yield put(getReplyToMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getReplyToMeetingError(e))
    if (reject) reject(e)
  }
}

export function* getReplyToMeetingWatcher() {
  yield takeLatest(GET_REPLY_TO_MEETING, getReplyToMeeting)
}

function getAgentCheckApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+CHECK_HIRE_AGENT_API + '/' + params.agent_id + '/'+ params.student_id
  });
}

function* getAgentCheck(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getAgentCheckApi, payload);

    yield put(getAgentCheckHireSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentCheckHireError(e))
    if (reject) reject(e)
  }
}

export function* getAgentCheckWatcher() {
  yield takeLatest(GET_AGENT_CHECK_HIRE, getAgentCheck)
}

function getHireAgentApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+GET_HIRE_AGENT_API,
    data: params
  });
}

function* getHireAgent(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getHireAgentApi, payload);

    yield put(getHireAgentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getHireAgentError(e))
    if (reject) reject(e)
  }
}

export function* getHireAgentWatcher() {
  yield takeLatest(GET_HIRE_AGENT, getHireAgent)
}

function getShareProfileApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+GET_SHARE_PROFILE_API,
    data: params
  });
}

function* getShareProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getShareProfileApi, payload);

    yield put(getShareProfileSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getShareProfileError(e))
    if (reject) reject(e)
  }
}

export function* getShareProfileWatcher() {
  yield takeLatest(GET_SHARE_PROFILE, getShareProfile)
}

function getAgentHireRequestsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_AGENT_HIRE_REQUESTS_API,
    params: params
  });
}

function* getAgentHireRequests(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getAgentHireRequestsApi, payload);

    yield put(getAgentHireRequestsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentHireRequestsError(e))
    if (reject) reject(e)
  }
}

export function* getAgentHireRequestsWatcher() {
  yield takeLatest(AGENT_HIRE_REQUESTS, getAgentHireRequests)
}

function updateAgentHireRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_HIRE_REQUEST_API,
    data: params
  });
}

function* updateAgentHireRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateAgentHireRequestApi, payload);

    yield put(updateAgentHireRequestSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateAgentHireRequestError(e))
    if (reject) reject(e)
  }
}

export function* updateAgentHireRequestWatcher() {
  yield takeLatest(UPDATE_HIRE_REQUEST, updateAgentHireRequest)
}

function deleteAgentHireRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+DELETE_HIRE_REQUEST_API,
    data: params
  });
}

function* deleteAgentHireRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteAgentHireRequestApi, payload);

    yield put(deleteAgentHireRequestSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteAgentHireRequestError(e))
    if (reject) reject(e)
  }
}

export function* deleteAgentHireRequestWatcher() {
  yield takeLatest(DELETE_HIRE_REQUEST, deleteAgentHireRequest)
}

function deletePermApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_PERM_API,
    data: params
  });
}

function* deletePerm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deletePermApi, payload);

    yield put(deletePermSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deletePermError(e))
    if (reject) reject(e)
  }
}

export function* deletePermActionWatcher() {
  yield takeLatest(DELETE_PERM, deletePerm)
}

function moveToInboxApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+MOVE_TO_INBOX_API,
    data: params
  });
}

function* moveToInbox(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(moveToInboxApi, payload);

    yield put(moveToInboxSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(moveToInboxError(e))
    if (reject) reject(e)
  }
}

export function* moveToInboxActionWatcher() {
  yield takeLatest(MOVE_TO_INBOX, moveToInbox)
}

function sendMeetingApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+SEND_MEETING_API,
    data: params
  });
}

function* sendMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(sendMeetingApi, payload);

    yield put(sendMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(sendMeetingError(e))
    if (reject) reject(e)
  }
}

export function* sendMeetingActionWatcher() {
  yield takeLatest(SEND_MEETING, sendMeeting)
}