import { GET_INBOX_SUCCESS } from "../constants";

const initialState = {
  inboxList: []
}

export default function inboxReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INBOX_SUCCESS:
      if (action.payload) {
        console.log('inininininin', action.payload)
      }
      return {
        ...state
      }
    default:
      return state;
  }
}