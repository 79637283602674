import React, { Component } from "react";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import downloadicon from "../../assets/img/university/download.png";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import "./style.css";
import { EMAIL_REGEX } from "../../constant/constant";
import { MESSAGES } from "../../constant/messages";
import submitForm from '../../utils/submitForm';
const baseurl = `${config.baseurl}`;

class Applicationfeedbacksent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalrecords: "",
      fileLength: "",
      disabled:
        localStorage.getItem("fromApplicationType") === "Sent" ? true : false,
      opt: "",
      docname: [],
      docid: [],
      docid1: [],
      optionid: [],
      formData: new FormData(),
    };
  }

  componentDidMount() {
    // this.getPageData();
  }

  getPageData = () => {
    this.props.showLoader();
    const univid = localStorage.getItem("universityid");
    const documentLookupReq = axios.get(baseurl + "/get_documentlookup/" + univid);
    const requiredDocumentsReq = axios
      .get(
        baseurl +
        "/documentsrequired_student/" +
        localStorage.getItem("applicationid")
      );
    Promise.all([
      documentLookupReq,
      requiredDocumentsReq
    ]).then(([
      documentLookupRes,
      requiredDocumentsRes,
    ]) => {
      const documentLookupResData = documentLookupRes.data ? {
        docname: documentLookupRes.data.data.doc_name,
        docid: documentLookupRes.data.data.doc_id,
        optionid: documentLookupRes.data.data.option_id,
      } : {};
      const requiredDocumentsResData = requiredDocumentsRes.data ? {
        docid1: requiredDocumentsRes.data.data,
      } : {};
      this.setState({
        ...documentLookupResData,
        ...requiredDocumentsResData,
      }, () => this.props.hideLoader())
    }).catch((error) => {
      this.props.hideLoader()
      console.log('error: ', error);
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
      this.props.hideLoader();
    });
  };

  multipleFileDownloadZip = (files) => {
    let zip = new JSZip();
    let count = 0;
    let zipFilename = "Document-Required.zip";

    files.forEach(function ({ link, name }) {
      let filename = "filename.jpeg";
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(link, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(name, data, { binary: true });
        count++;
        if (count == files.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  };

  onFileChange1 = (param) => (event) => {
    this.setState({ fileLength: event.target.files.length });
    if (event.target.files.length < 3) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.state.formData.append(
          "upload_photo[]",
          event.target.files[i],
          event.target.files[i].name
        );
        this.state.formData.append("docid[]", param);
      }
    }
  };

  onSubmit1 = (e) => {
    this.state.formData.append("studentid", localStorage.getItem("studentid"));
    this.state.formData.append(
      "applicationid",
      localStorage.getItem("applicationid")
    );
    this.props.showLoader();
    submitForm(
      "multipart/form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        toast.success(msg.message);
        this.getPageData();
      },
      "insertdocument"
    )
  };

  handleDocumentLabel = (opt, doc_name, index) => {
    let docid = this.state.docid[index];
    let universityid = localStorage.getItem("universityid");
    const uploadedFiles = this.state.docid1.filter((doc) => doc.docid == docid);

    let optLabel = "";
    if (opt === "1") optLabel = "Hide";
    else if (opt === "2") optLabel = "Optional";
    else if (opt === "3") optLabel = "Required";
    if (optLabel === "Hide") {
      // return (<tr><td>Hide</td></tr>);
      return;
    } else {
      return (
        <tr key={index}>
          {optLabel === "Required" ? (
            <td className="req-feild">Required</td>
          ) : (
            <td className="req-feild greyfield">Optional</td>
          )}
          <td className="des-detail">
            <div>
              <b> {doc_name}</b>
              <b>({docid})</b>
              <b>{universityid}</b>
            </div>
          </td>

          {this.state.disabled === false ? (
            uploadedFiles.length > 0 ? (
              <td>
                {uploadedFiles.map(({ orgfilename, filename }) => (
                  <span key={filename}>
                    <a href={baseurl + filename} target="_blank" download>
                      {orgfilename}
                      <br />
                    </a>
                  </span>
                ))}
              </td>
            ) : (
              <td className="add-filebtn">
                <Input
                  type="file"
                  id="about_upload_photo"
                  name="upload_photo[]"
                  accept="image/*"
                  multiple
                  onChange={this.onFileChange1(this.state.docid[index])}
                  ref={(ref) => (this.fileInput = ref)}
                />
              </td>
            )
          ) : null}
        </tr>
      );
    }
  };

  multiFilesDownload = (e) => {
    e.preventDefault();
    const filesInfo = [];
    this.state.docid1.map(({ filename, orgfilename }) => {
      // let link = URL.createObjectURL(new Blob([baseurl + filename], {type: 'text/plain'}));
      let fileName = orgfilename;
      let fileLink = filename;
      let fileDetail = {
        link: fileLink,
        name: fileName,
      };
      filesInfo.push(fileDetail);
    });
    this.multipleFileDownloadZip(filesInfo);
  };

  render() {
    return (
      <div className="foe-studen-container  app-feedback-page pt-5">
        <div className="foe-student-box pt-5">
          <Container>
            <Row>
              <Col xs="12" sm="12" xl="12" md="12" lg="12" className="mx-auto">
                <Card className="uni-right-card app-inprogress">
                  <div className="offer-header p-3">
                    <Row className="mt-2">
                      <div className="col-md-8">
                        <p>
                          <b>
                            Please attach files to complete your application
                          </b>
                        </p>
                        <span>
                          * {this.state.totalrecords} requirements to be
                          completed
                        </span>
                      </div>
                      <div
                        className=" text-right col-md-4 app-download"
                        onClick={this.multiFilesDownload}
                      >
                        <span>Download All</span>{" "}
                        <img src={downloadicon} alt="" width="14" />
                      </div>
                    </Row>

                    <Row>
                      <div className="card-body table-responsive univ-new-table">
                        <table className="draft-table table table-responsive-sm display nowrap dataTable dtr-inline collapsed">
                          <tbody>
                            {this.state.docname.map((doc_name, index) => {
                              return this.handleDocumentLabel(
                                this.state.optionid[index],
                                doc_name,
                                index
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                    {this.state.disabled === false ? (
                      <Button
                        color="success"
                        onClick={this.onSubmit1.bind(this)}
                        className="px-4 save-btn"
                      >
                        Save
                      </Button>
                    ) : null}
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Applicationfeedbacksent;
