import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Badge, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import jQuery from "jquery";
import { ReactComponent as Notificationicon } from "../../assets/img/noti-icon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import axios from "axios";
import config from "../../config.json";
import { MESSAGES } from "../../constant/messages";
import { toast } from "react-toastify";

var baseurl = `${config.baseurl}`;

class GetNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      agentId: 0,
      total: 0,
      notifications: []
    }
  }

  componentDidMount() {
    let param = localStorage.getItem("studentid");
    this.setState({
      agentId: param
    }, () => {
      this.getNotificationData()
    })
  }

  // getNotifications = () => {
  //   this.setState({
  //     popoverOpen: !this.state.popoverOpen
  //   });
  //   this.props.getNotificationListAction({
  //     id: this.state.agentId,
  //     type: 0,
  //     limit: 4
  //   }, (response) => {
  //     console.log('resposne notify', response);
  //     if (response.success) {
  //       this.setState({
  //         notifications: response.data.data,
  //         total: response.data.total
  //       })
  //     }
  //   }, (err) => {

  //   })
  // }

  getNotificationData = async () => {
    const studentId = localStorage.getItem("studentid");
    
    //requests
    const studentFeedbackReq = axios.get(
      baseurl + "/getfeedbackststudent" + "/" + studentId
    );
    const sharedHiredNotificationReq = axios.get(
      baseurl + "/get_shared_hired_notification/" + studentId + "/Student"
    );
    const studentNotificationReq = axios.get(
      baseurl + "/get_studentNotification/" + studentId
    );
    //this api giving error and also not used anywhere, need to confirm - @supriya
    // const fieldCommentsReq = axios.get(
    //     baseurl +
    //     "/getfieldcomments/" +
    //     localStorage.getItem("app_id") +
    //     "/" +
    //     localStorage.getItem("agentid") +
    //     "/" +
    //     this.state.student_id
    // );

    //this api giving error - @supriya
    // const updateSharedHiredNoticationReq =  await axios.get(baseurl +"update_sharedhired_notification/" +studentId +"/Student");
    Promise.all([
      studentFeedbackReq,
      sharedHiredNotificationReq,
      studentNotificationReq,
    ])
      .then(
        async ([
          studentFeedbackRes,
          sharedHiredNotificationRes,
          studentNotificationRes,
        ]) => {
          
          const sharedHiredNotificationResData = sharedHiredNotificationRes.data
            ? {
              sharedData: sharedHiredNotificationRes.data.result,
            }
            : {};
          let studentNotificationResData = {};
          if (studentNotificationRes.data) {
            studentNotificationResData = {
              notifycontent: studentNotificationRes.data.result,
            };
            if (
              studentNotificationRes.data.result &&
              studentNotificationRes.data.result.length
            ) {
              for (
                let i = 0;
                i < studentNotificationRes.data.result.length;
                i++
              ) {
                if (studentNotificationRes.data.result[i].is_viewed == "0") {
                  await axios.get(
                    baseurl +
                    "/update_notification/" +
                    localStorage.getItem("studentid")
                  );
                  localStorage.removeItem("notifyvalue");
                }
              }
            }
          }
          let notifications = [];
          if (
            studentFeedbackRes && 
            studentFeedbackRes.data && 
            studentFeedbackRes.data.data
          ) {
            studentFeedbackRes.data.data.forEach(element => {
              let newObj = {
                title: "Referral Partners Application Feedback",
                date: moment(element.created_date).format(
                    "MMM DD, YYYY LTS"
                  ),
                name: element.company_name
              }
              notifications.push(newObj)
            });
          }
          if (
            sharedHiredNotificationResData &&
            sharedHiredNotificationResData.sharedData
          ) {
            sharedHiredNotificationResData.sharedData.forEach(element => {
              let newObj = {
                title: "Referral Partners"+element.is_terminated == 1
                  ? "Profile Terminated"
                  : element.is_shared == 1
                    ? "Profile Shared"
                    : element.is_hired == 1
                    ? "Profile Hired"
                    : null,
                date: moment(element.created_date).format(
                    "MMM DD, YYYY LTS"
                  ),
                name: element.student_name
              }
              notifications.push(newObj)
            });
          }

          if (
            studentNotificationResData &&
            studentNotificationResData.notifycontent
          ) {
            studentNotificationResData.notifycontent.forEach(element => {
              let newObj = {
                title: 'Institutes'+" "+
                element.status == "1"
                  ? "Application Applyed"
                  : element.status == "2"
                    ? "Paid Application Fees"
                    : element.status == "3"
                      ? "Application Approved"
                      : element.status == "4"
                        ? "Application Rejected"
                        : element.status == "5"
                          ? "Application Message"
                          : element.status == "6"
                            ? "Payment Pending"
                            : element.status == "7"
                              ? "Paid"
                              : element.status == "8"
                                ? "Enrollment confirmed"
                                : element.status == "9"
                                  ? "Application declined"
                                  : element.status == "10"
                                    ? "Application Respond"
                                    : element.status == "11"
                                      ? "Application Feedback"
                                      : "",
                date: moment(element.action_date).format(
                    "MMM DD, YYYY LTS"
                  ),
                name: element.institute_name
              }
              notifications.push(newObj)
            });
          }
          
          notifications = notifications.slice(0, 2);

          this.setState({
            notifications
          }, () => {
            console.log('notifications', notifications);
          })
        }
      )
      .catch((error) => {
        console.log("error: ", error);
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
      });
  };

  render() {
    // let id = ""
    // const useraccess = this.state.user_access;

    //let popoverOpen = this.state.popoverOpen;



    // if (jQuery.inArray(id, useraccess) !== -1) {
    //   return (
    //     <NavLink to="/profilenotification" className="nav-link"
    //       onClick={() => {
    //         this.getNotifications()
    //       }}
    //     >
    //       <Notificationicon className="uni-icon path" />
    //       {this.state.notificationCount <= 0 ? null : (
    //         <Badge pill color="danger">{this.state.notificationCount}</Badge>
    //       )}
    //     </NavLink>
    //   );
    // } else {
    return (
      <>
        <span id="notification" className={`nav-link notiLink ${this.props.match.path === "/profilenotification" ? 'active' : ""}`}
          onClick={() => {
            // this.getNotifications()
            this.setState({
              popoverOpen: true
            });
          }}
        >
          <Notificationicon className="uni-icon path" />
          {this.state.notificationCount <= 0 ? null : (
            <Badge pill color="danger">{this.state.notificationCount}</Badge>
          )}
        </span>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="notification"
          className="notification"
          trigger="legacy"
          toggle={() => {
            this.setState({
              popoverOpen: false
            });
          }}
        >
          <PopoverBody>
            <ul>
              {
                this.state.notifications.map(val => {
                  return (
                    <li className="read">
                      <div className="avatarText" style={{ backgroundColor: "#ddd" }}>
                        {
                          val.name.charAt(0)
                        }
                      </div>
                      <div className="details">
                        <p>
                          {
                            val.title
                          }
                        </p>
                        <date>
                          {
                            val.date
                          }
                        </date>
                      </div>
                    </li>
                  )
                })
              }
              
            </ul>
            {/* <ul>
                {
                  this.state.notifications.map((val, index) => {
                    return (
                      <li
                        className={val.status == 0 ? "unread" : "read"}
                      >
                        {
                          val.sender_user ? (
                            <div
                              className="avatarText"
                              style={{ backgroundColor: val.sender_user.color_code }}
                            >
                              {
                                val.sender_user.first_name.charAt(0)
                              }
                            </div>
                          ) : null
                        }

                        <div className="details">
                          <p>
                            {
                              val.notification_text
                            }
                          </p>
                          <date>
                            {
                              moment(val.created_at).format('dddd, MMMM Do YYYY, h:mm a')
                            }
                          </date>
                        </div>
                      </li>
                    )
                  })
                }

              </ul> */}
          </PopoverBody>
          <PopoverHeader className="text-center">
            <Link
              to="/profilenotification"
              onClick={() => {
                this.setState({
                  popoverOpen: false
                });
              }}
            >
              View All Notifications
            </Link>
          </PopoverHeader>
        </Popover>
      </>
    );
    // }
  }
}



export default withRouter(GetNotification);
