import React, { useState } from "react";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { Editor } from "@tinymce/tinymce-react";
import Dropzone from "react-dropzone";
import TagField from "../../../common/TagField";
import "./replyMessage.scss"

import UploadIcon from "../../../assets/img/icons/upload_white_icon.svg";
import RemoveFileIcon from "../../../assets/img/icons/inbox/file-close.svg";
import DownloadAttachemnet from "../../../assets/img/icons/download_attachemnet.svg";
import ReplySVGIcon from "../../../assets/img/icons/inbox/reply_icon.svg";
import ReplyAllIcon from "../../../assets/img/icons/inbox/reply_all_icon.svg";
import ForwardIcon from "../../../assets/img/icons/inbox/forward_icon.svg";
import AddAttachment from "../../../assets/img/icons/inbox/attachemnt_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/inbox/mail_delete_icon.svg";
import { ReactComponent as HasAttachmentIcon } from "../../../assets/img/icons/has_attachment_icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/img/icons/inbox/sent_gray_icon.svg";
import { EMAIL_REGEX } from "../../../constant/constant";
import { checkValidFileSize } from "../../../common/commonHelper";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../constant/messages";
import config from "../../../config.json";

import CustomTags from 'custom-react-tags'
import 'custom-react-tags/dist/index.css'

const baseurl = `${config.baseurl}/`;

function ReplyMessage() {
  const handleInit = (evt, editor) => {
    this.replyEditorRef.current = editor
  };

  let toEmail = this.state.toEmails.map(email => ({ label: email }))
  let ccEmails = this.state.ccEmails.map(email => ({ label: email }))
  let bccEmails = this.state.bccEmails.map(email => ({ label: email }))

  return (
    <div
      className={
        `replyBox ${this?.state?.showReply === true ?
          "show" : "hide"}`
      }
      id="ReplyMessage"
    >
      <div className="replySection">
        <header>
          <UncontrolledDropdown>
            <DropdownToggle caret color="link">
              <img
                src={
                  this.state.replyType === "Reply"
                    ? ReplySVGIcon
                    : this.state.replyType === "ReplyAll"
                      ? ReplyAllIcon
                      : this.state.replyType === "Forward"
                        ? ForwardIcon
                        : ReplySVGIcon
                }
                width="18px"
                height="14px"
                alt="" />

            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  this.setState({
                    showReply: true,
                    parentFlag: 0
                  });
                  this.setReplyState(false)
                }}
              >
                <img width="18px" height="14px" src={ReplySVGIcon} alt="" /> Reply
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  this.setState({
                    showReply: true,
                    parentFlag: 0
                  });
                  this.setReplyState(true)
                }}
              >
                <img width="18px" height="14px" src={ReplyAllIcon} alt="" /> Reply All
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  this.setState({
                    showReply: true,
                    parentFlag: 1
                  })
                  this.setForwardState()
                }}
              >
                <img width="18px" height="14px" src={ForwardIcon} alt="" /> Forward
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <ul className="emailAdddress">
            <li className="show">
              <span className="type">To:</span>
              {toEmail.length ?
                <CustomTags
                  initialValue={toEmail}
                  onChange={(val) => {
                    let toEmails = val.map(email => email.label)
                    this.setState({ toEmails });
                  }}
                  key="inif"
                />
                : <CustomTags
                  onChange={(val) => {
                    let toEmails = val.map(email => email.label)
                    this.setState({ toEmails });
                  }}
                  initialValue={[]}
                  key="inelse"
                />
              }
            </li>

            <li
              className={
                this.state.ccEmails.length ||
                  this.state.mailToTypeCc ?
                  "show" : "hide"
              }
            >
              <span className="type">Cc:</span>
              {
                ccEmails ? (
                  <CustomTags
                    initialValue={ccEmails}
                    onChange={(val) => {
                      let ccEmails = val.map(email => email.label)
                      this.setState({ ccEmails });
                    }}
                    validation={EMAIL_REGEX}
                    key="ccinif"
                  />
                ) : (
                  <CustomTags
                    onChange={(val) => {
                      let ccEmails = val.map(email => email.label)
                      this.setState({ ccEmails });
                    }}
                    validation={EMAIL_REGEX}
                    key="ccinelse"
                  />
                )
              }
            </li>

            <li
              className={
                this.state.bccEmails.length ||
                  this.state.mailToTypeBcc ?
                  "show" : "hide"
              }
            >
              <span className="type">Bcc:</span>
              {
                bccEmails ? (
                  <CustomTags
                    initialValue={bccEmails}
                    onChange={(val) => {
                      let ccEmails = val.map(email => email.label)
                      this.setState({ ccEmails });
                    }}
                    validation={EMAIL_REGEX}
                    key="bccinif"
                  />
                ) : (
                  <CustomTags
                    onChange={(val) => {
                      let ccEmails = val.map(email => email.label)
                      this.setState({ ccEmails });
                    }}
                    validation={EMAIL_REGEX}
                    key="bccinelse"
                  />
                )
              }
            </li>
          </ul>
          <div className="rightIcons">
            <span onClick={() => {
              this.setState({ mailToTypeBcc: !this.state.mailToTypeBcc });
            }}>Bcc</span>
            <span onClick={() => {
              this.setState({ mailToTypeCc: !this.state.mailToTypeCc });
            }}>Cc</span>
            <DeleteIcon className="DeleteIcon" width="13px" height="16px"
              onClick={() => {
                this.setState({
                  showReply: false,
                  toEmails: [],
                  ccEmails: [],
                  bccEmails: [],
                  subject: ""
                });
                this.replyEditorRef.current.setContent("");
              }}
            />
          </div>
        </header>
        <div className="replyBody">
          <div className="editorSection">
            <Editor
              //apiKey={tinyKey}
              // onInit={(evt, editor) =>
              // 	(this.editorRef.current =
              // 		editor)
              // }
              onInit={handleInit}
              initialValue=""
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link charmap print anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code emoticons",
                ],
                toolbar: "undo redo | bold italic backcolor strikethrough superscript subscript | link emoticons removeformat| formatselect | fontsizeselect lineheight fontselect | bullist numlist outdent indent alignleft aligncenter alignright alignjustify",

                toolbar_mode: "sliding",
                branding: false,
                statusbar: false,

              }}

            />
            {this.state.showAddAttachment &&
              <Dropzone
                onDrop={this.onDrop}
                multiple
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dragNdrop">
                    <div
                      {...getRootProps({
                        className: "dropzone",
                      })}
                    >
                      <input
                        {...getInputProps({
                          //accept: "application/pdf",
                        })}
                        onChange={(event) => {
                          let filesList = event.target.files;
                          let noOfFiles = event.target.files.length;
                          let {
                            files
                          } = this.state;

                          for (let file = 0; file < noOfFiles; file++) {
                            console.log('files[file]', filesList[file])
                            let size = checkValidFileSize(filesList[file]);
                            if (!size) {

                              files.push(filesList[file]);
                            } else {
                              toast.error(MESSAGES.VALID_IMAGE_SIZE);
                              return;
                            }
                          }

                          this.setState({
                            files
                          })
                        }}
                      />
                      <span className="btn btn-primary">
                        <img
                          src={UploadIcon}
                          alt="upload"
                        />{" "}
                        Choose Files
                      </span>
                      <p>or drop here</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            }

            {
              this.state.files.length ||
                this.state.existingFiles.length ? (
                <div className="fileList">
                  <div className="detail">
                    <HasAttachmentIcon className="HasAttachmentIcon" />
                    {
                      this.state.files.length +
                      this.state.existingFiles.length
                    }{" "}
                    Attachment
                  </div>
                  {
                    this.state.files.map((file, index) => {
                      return (
                        <div
                          className="box"
                          key={file.name}
                        >
                          <div className="fileName">
                            {file.name}
                            <div className="fileSize">
                              [{(file.size / 1024).toFixed(2)}{" "}KB]
                            </div>
                          </div>
                          <img
                            src={DownloadAttachemnet}
                            alt="Download"
                            className="DownloadAttachemnet"
                          />

                          <img
                            src={RemoveFileIcon}
                            alt="remove"
                            className="fileRemove"
                            onClick={() => {
                              let {
                                files
                              } = this.state;

                              files.splice(index, 1);

                              this.setState({
                                files
                              });
                            }}
                          />
                        </div>
                      )
                    })
                  }

                  {
                    this.state.existingFiles.map((file, index) => (
                      <div
                        className="box"
                        key={index}
                      >
                        <div className="fileName">
                          {file.original_filename}
                          <div className="fileSize">
                            &nbsp;
                          </div>
                        </div>
                        <img
                          src={DownloadAttachemnet}
                          alt="Download"
                          className="DownloadAttachemnet"
                        />

                        <img
                          src={RemoveFileIcon}
                          alt="remove"
                          className="fileRemove"
                          onClick={() => {
                            let {
                              existingFiles
                            } = this.state;

                            existingFiles.splice(index, 1);

                            this.setState({
                              existingFiles
                            });
                          }}
                        />
                      </div>
                    ))
                  }
                </div>
              ) : null
            }
          </div>
          {/*
          <div className="attachement">
            {this.attachemtList.length > 0 && (
              <>
                <div className="detail">
                  <HasAttachmentIcon className="HasAttachmentIcon" />
                  {this.attachemtList.length}{" "}
                  Attachment
                </div>
              </>
            )}
            {this.attachemtList.length > 0 && (
              <div className="fileList">
                {this.attachemtList.map(
                  (file) => (
                    <div
                      className="box"
                      key={file.name}
                    >
                      <div className="fileName">
                        <u>{file	.name}</u>
                        <div className="fileSize">
                          [{(file.size / 1024).toFixed(2)}{" "}KB]
                        </div>
                      </div>

                      <img
                        src={DownloadAttachemnet}
                        alt="Download"
                        className="DownloadAttachemnet"
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        */}

        </div>
        <footer className="d-flex align-items-center justify-space-between">
          <img src={AddAttachment} alt="Add Attachment" className="AddAttachment"
            onClick={() => {
              this.setState({
                showAddAttachment: !this.state.showAddAttachment
              })
            }}
          />

          <Button color="primary"
            onClick={() => {
              this.sendEmail()
            }}
          >
            <SendIcon width="14px" />	Send
          </Button>
        </footer>


      </div >
    </div >

  )
}
export default ReplyMessage;
