import React from "react";
import template from "./AppBackgroundinfo.jsx";
import config from "../../config.json";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MESSAGES } from "../../constant/messages.js";
import submitForm from "../../utils/submitForm.js";
const baseurl = `${config.baseurl}`;
class AppBackgroundinfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      refusedvisa: "",
      visapermit: [],
      txtdetails: "",
      errors: {},
      formData: new FormData(),
      BackgroundAddMore: false,
      clearBackgroundAddmore: false,
      questiondata3: [],
      selectedQuestion3: null,
      uniQuestTextArr: [],
      uniQuestSignleArr: [],
      uniQuestMultiArr: [],
      visaOptions: [
        {
          value: 1,
          label: "I don't have this"
        },
        {
          value: 2,
          label: 'USA F1 Visa'
        },
        {
          value: 3,
          label: 'Canadian Study Permit or Visitor Visa'
        },
        {
          value: 4,
          label: 'UK Student Visa (Tier 4) or Short Term Study Visa'
        },
        {
          value: 5,
          label: 'Australian Student Visa'
        }
      ],
      showCompleteProfileMessage: true,
      edit: true,
      appQuestions: [],
      appAnswers: []
    };
  }

  componentDidMount() {
    this.getBackgroundInfoData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type != this.props.type) {
      this.getBackgroupData()
    }
    if (prevProps.applicationId != this.props.applicationId) {
      this.getBackgroundInfoData()
    }
    if (prevProps.showCompleteProfileMessage != this.props.showCompleteProfileMessage) {
      this.setState({
        showCompleteProfileMessage: this.props.showCompleteProfileMessage
      })
    }
  }

  getQuestionData3 = (question) => {
    this.setState({ questiondata3: question });
  };

  setQuestionData3 = (index) => {
    this.setState({ selectedQuestion3: index, BackgroundAddMore: true });
  };

  nextTab = () => {
    $("#tab5").addClass("active");
    $("#tab4").removeClass("active");
    $(".bgtab").removeClass("active");
    $(".uploadtab").addClass("active");
  };

  prevTab = () => {
    $("#tab3").addClass("active");
    $("#tab4").removeClass("active");
    $(".bgtab").removeClass("active");
    $(".testscore").addClass("active");
  };

  getBackgroupData = () => {
    if (this.props.type == "program") {
      axios
        .get(
          baseurl + "/get_backgroundinfo/" + localStorage.getItem("studentid")
        )
        .then((response) => {
          console.log('log response.data', response.data);
          if (response.data && response.data.length && response.data.length > 0) {
            let selectedVisa = [];
            if (
              response.data &&
              response.data[0] &&
              response.data[0].validpermit_visa
            ) {
              if (Array.isArray(response.data[0].validpermit_visa)) {
                response.data[0].validpermit_visa.map(val => {
                  let selected = this.state.visaOptions.find(option => option.value == val)
                  console.log('log loop', val, selected);
                  if (selected) {
                    selectedVisa.push(selected)
                  }
                })
              }
            }

            this.setState({
              refusedvisa: response.data[0].refused_visa,
              visapermit: selectedVisa,
              txtdetails: response.data[0].details,
            }, () => { this.props.hideLoader(); console.log('log refusedvisa', this.state.refusedvisa); });

            if (response.data.data[0].refused_visa) {
              $("input[name=refusedvisa]").val(["yes"]);
            } else {
              $("input[name=refusedvisa]").val(["no"]);
            }
          } else this.props.hideLoader()
        }).catch(() => {
          this.props.hideLoader()
        });
    } else {
      axios
        .get(
          baseurl + "/get_backgroundinfo_app/" + localStorage.getItem("studentid"), {
          params: {
            application_id: this.props.applicationId
          }
        }
        )
        .then((response) => {
          console.log('log backdata', response.data.data.length);
          if (response.data.data && response.data.data.length) {
            if (response.data.data[0]) {
              let selectedVisa = [];
              if (
                response.data.data[0]
                && response.data.data[0].validpermit_visa
                && response.data.data[0].validpermit_visa.length
              ) {

                response.data.data[0].validpermit_visa.map(val => {
                  let selected = this.state.visaOptions.find(option => option.value == val)
                  if (selected) {
                    selectedVisa.push(selected)
                  }
                })
              }

              this.setState({
                refusedvisa: response.data.data[0].refused_visa ? "yes" : "no",
                visapermit: selectedVisa,
                txtdetails: response.data.data[0].details,
              }, () => { this.props.hideLoader() });

              if (response.data.data[0].refused_visa) {
                $("input[name=refusedvisa]").val(["yes"]);
              } else {
                $("input[name=refusedvisa]").val(["no"]);
              }
            }
            if (response.data.data[0].question) {
              let appQuestions = response.data.data[0].question
              let appAnswers = appQuestions.filter(question => question.answers).map(question => ({
                question_id: question.question_details.id,
                answer: question.answers
              }))

              this.setState({
                appQuestions,
                appAnswers
              })
            }
          } else this.props.hideLoader()
        }).catch(() => {
          this.props.hideLoader()
        });
    }
  }

  getBackgroundInfoData = () => {
    this.props.showLoader();
    this.getBackgroupData()
  };

  handleTargetChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validate = () => {
    const errors = {};
    let radioValue = $("input[name='refusedvisa']:checked").val();
    if (!radioValue) {
      errors.refusedvisa = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.visapermit === "") {
      errors.visapermit = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (radioValue === "yes" || this.state.visapermit === "1") {
      if (
        $("#txtdetails").val().trim() === "" ||
        $("#txtdetails").val().trim() === null
      ) {
        errors.txtdetails = MESSAGES.PLEASE_ENTER_PROVIDE_DETAILS;
      }
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  onSubmit = (exit, e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors });

    let multiCheckArr = [];
    $(".multiCheck").each(function (i) {
      if ($(this).is(":checked")) {
        multiCheckArr.push($(this).val());
      }
      else {
        multiCheckArr.push($(this).val() + '|uncheck');
      }
    });

    let multiTextArr = [];
    $(".ansrTxt").each(function (i) {
      if ($(this).val() != '') {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id"));
      }
      else {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id") + '|uncheck');
      }
    });

    let radioQuesArr = [];
    $(".generalradio").each(function (i) {
      if ($(this).is(":checked")) {
        radioQuesArr.push($(this).val());
      }
    });

    if (errors) return;

    this.state.formData.append(
      "refusedvisa",
      $("input[name='refusedvisa']:checked").val()
    );

    if (this.state.visapermit.length) {
      this.state.visapermit.map(val => {
        this.state.formData.append("visapermit[]", val.value);
      })
    }

    this.state.formData.append("txtdetails", this.state.txtdetails);
    this.state.formData.append("uniAnsrMulti", multiCheckArr);
    this.state.formData.append("ansrTxt", multiTextArr);
    this.state.formData.append("uniAnsrRadio", radioQuesArr);
    this.state.formData.append("uni_id", localStorage.getItem("universityid"));
    this.state.formData.append("app_id", localStorage.getItem("app_id"));
    this.state.formData.append("app_student_id", localStorage.getItem("app_student_id"));
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("application_id", this.props.applicationId);

    this.setState({ errors: "" });

    this.state.appAnswers.forEach((val, index) => {
      this.state.formData.append(`answer[${index}][question_id]`, val.question_id)
      if (Array.isArray(val.answer)) {
        val.answer.forEach((option, optionIndex) => {
          this.state.formData.append(`answer[${index}][answer][${optionIndex}]`, option)
        })
      } else {
        this.state.formData.append(`answer[${index}][answer]`, val.answer)
      }
    })

    this.props.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        if (msg != "error") {
          this.props.getApplicationStepStatus()
          this.props.hideLoader();
          toast.success(msg.message);
          if (exit) {
            this.props.history.push('/draftapplication')
          }
          this.props.updateTabs()
          setTimeout(function () {
            $("#tab5").addClass("active");
            $("#tab4").removeClass("active");
            $(".bgtab").removeClass("active");
            $(".uploadtab").addClass("active");
          }, 2000);
        }
      },
      "storestudentbackgroundinfo_app"
    );
  };

  render() {
    const { errors } = this.state;
    return template.call(this);
  }
}

export default AppBackgroundinfo;
