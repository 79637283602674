import config from "../config.json";

export const baseUrl = `${config.baseurl}/`;

export const LOGIN_API = "university/university-login";
export const ROLE_PERMISSION_API = "university/permission-menu";

export const DASHBOARD_DATA = "universitydashboard";
export const COUNTRY_LIST = "get_country";
export const CURRENCY_LIST = "get_currency";
export const LOGIN_LOGS = "student/login-logs";
export const SIDE_PROFILE = "storeprofile";
export const UNIVERSITY_ABOUT = "getuniversityabout";
export const CREATE_GROUP_API = "create-group";
export const GET_GROUP_LIST_API = "get-grouplist";
export const GET_ROLE_LIST_API = "get-rolelist";
export const SEND_STAFF_INVITE_API = "add-staffmember";
export const GET_GROUP_ROLES_LIST_API = "get-grouproleslist";
export const ADD_ROLE_TO_GROUP_API = "add-roles";
export const GET_ROLE_PERMISSIONS_API = "get-permission";
export const SET_ROLE_PERMISSIONS_API = "set-permission";
export const GET_STAFF_LIST_API = "get-stafflist";
export const DELETE_GROUP_API = "delete-group";
export const GET_PROFILE_API = "get_generalinfo";
export const UPDATE_PROFILE_API = "university/profile/update";
export const DELETE_ROLE_API = "role-delete";
export const STAFF_INVITE_LIST_API = "get-staffinvitelist";
export const DELETE_SATFF_API = "delete-staff";
export const DELETE_STAFF_INVITE_API = "delete-staff-invite";
export const SAVE_INTAKE_DATES_API = "university/intake-dates/save";
export const GET_INTAKE_DATES_API = "general/university/intake-list";
export const SAVE_APPLICATION_FORM_API =
  "university/application-form/save";
export const GET_APPLICATION_FORM_API =
  "university/application-form/get-questions";

export const GET_PROFILE_STATUS_API =
  "university/profile/status";

export const GET_APPLICATION_LIST_API =
  "application/list";

export const GET_DEPARTMENT_LIST_API = "department/list";

// export const GET_PROGRAM_LIST_API = "program/list";

export const GET_STUDY_LEVEL_LIST_API = "study-level-list";

export const DELETE_APPLICATIONS_API = "application/delete";

export const GET_APPLICATION_STATUS_LIST_API = "application/status/list";

export const GET_APPLICATION_DETAILS_API =
  "student/application/details";

export const GET_DEPARTMENTS_API = "program/get-department-list";
export const SAVE_DEPARTMENT_API = "program/department/add-department";
export const GET_CAMPUS_LIST_API = "university/profile/campus_list";
export const VIEW_DEPARTMENT_API = "program/department/get-department";
export const UPDATE_DEPARTMENT_API = "program/department/edit-department"
export const UPDATE_APPLICATION_STATUS_API = "application/status/update";

export const GET_APPLICATION_MESSAGE_CHAT_COUNT_API =
  "application/comment-message/count";

export const GET_APPLICATION_STATUS_API =
  "student/application/status";

export const ASSIGN_APPLICATION_API =
  "application/status/update/assign"

export const GET_APPLICATION_ASSIGNEE_LIST_API =
  "application/status/assign/view"

export const SEND_APPLICATION_MESSAGE_API =
  "student/application/message/add";

export const GET_APPLICATION_MESSAGES_API =
  "student/application/message/list";

export const GET_APPLICATION_COMMENTS_API =
  "application/comment/list";

export const ADD_APPLICATION_COMMENT_API =
  "application/comment/add";

export const DELETE_APPLICATION_COMMENT_API =
  "application/comment/delete";

export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_API =
  "application/document/status/update";

export const GET_EDUCATION_LIST_API = "program/get_education_list";
export const GET_REMAIN_CAMPUS_LIST_API = "program/campus-by-program";
export const CREATE_PROGRAM_API = "program/add-program";
export const GET_EXAM_LIST_API = "exam-list";
export const GET_SEARCH_PROGRAM_LIST_API = "program/search";
export const DELETE_PROGRAM_API = "program/delete-program";
export const GET_PROGRAMS_API = "program/get-program-list";
// export const GET_PROGRAM_DETAILS_API = "program/get-program-details";
export const UPDATE_PROGRAM_API = "program/edit-program";
export const CONFIRM_DEPARTMENT_API = "program/department/delete-department";
export const CONFIRM_PROGRAM_API = "program/department/confirm-department";
export const DELETE_ALL_PROGRAM_DEPARTMENT_API = "program/department/delete-all-program";
export const GET_UNIVERSITY_OVERVIEW_API = "program/department/review-counter";

export const GET_DASHBOARD_UNIVERSITY_API = "university/dashboard/get-university-detail";

export const GET_PAYMENT_PAID_LIST_API = "payment/history";
export const CREATE_PAYMENT_METHOD_API = "payments/add-payment";
export const GET_PAYMENT_LIST_API = "student/payment-list";
export const GET_PAYMENT_DETAILS_API = "payments/get-payment";
export const UPDATE_PAYMENT_METHOD_API = "payment/set-default-card";
export const DELETE_PAYMENT_METHOD_API = "payment/delete-payment";

export const COMPOSE_MAIL_API = "inbox/mail/composeStudent";

export const INBOX_LIST_API = "inbox/mail/listStudent";

export const UPDATE_MAIL_FAVOURITE_API = "inbox/mail/star/changeStudent";

export const DELETE_INBOX_EMAIL_API = "inbox/mail/delete/changeStudent";

export const GET_UNREAD_INBOX_COUNT_API =
  "inbox/mail/count";

export const GET_INBOX_MAIL_DETAILS_API =
  "inbox/mail/viewStudent";

export const UPDATE_INBOX_READ_STATUS_API =
  "inbox/mail/mark/unreadStudent";

export const DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS =
  "inbox/mail/attachment/downloadStudent";

export const GET_TIMEZONES_LIST_API =
  "timezone/listStudent";
export const CREATE_MEETING_API = "inbox/meeting/createStudent";
export const MEETING_LIST_API = "inbox/meeting/listStudent";
export const MEETING_DETAILS_API = "inbox/meeting/viewStudent";
export const DELETE_MEETING_API = "inbox/meeting/cancel";
export const UPDATE_MEETING_STATUS_API =
  "inbox/meeting/status/updateStudent";
export const GET_CONTACT_LIST_API =
  "inbox/meeting/contact-searchStudent";

export const GET_STUDENT_APPLICATION_STATISTICS_API = "university/dashboard/get-student-application";
export const GET_REACH_OUT_DETAILS_API = "university/dashboard/get-total-reachout";
export const GET_REPLY_TO_MEETING_API = "inbox/meeting/get-participant-by-meetingid";
export const GET_STUDENT_APPLICATION_COUNT_API = "university/dashboard/get-application-statastic";
export const GET_UNIVERSITY_INCOME_COUNT_API = "university/dashboard/get-total-income";
export const CALENDER_EVENT_LIST_API = "university/calendar/get-calendar-events";
export const GET_MONTH_EVENT_LIST_API = "university/calendar/get-event-by-month-year";

export const ONBOARDING_PROCESS_STORE_API = "onboarding/registration";
export const FORGOT_PASSWORD_API = "checkemail";
export const RESET_PASSWORD_API = "resetpassword";
export const GET_PENDING_STUDENT_FEES_API = "payment/pending-payment-listStudent";
export const GET_PAYMENT_PROOF_API =
  "application/proof-of-payment";

export const SAVE_CARD_API = "student/add-card";
export const SAVE_CARD_PROFILE_API = "student/add-card";

export const GET_INVOICE_PAYMENT_PROOF_API = "payments/proof-of-payment";
export const GET_PREPAID_FEES_COMMISSION_LIST_API = "university/payment/prepaid-invoices";
export const GET_POSTPAID_FEES_COMMISSION_LIST_API = "university/payment/postpaid-invoices";

export const GET_DEFAULT_CARD_API = 
  "payment/defaultStudent";

export const GET_INVOICE_DETAILS_API = 
  "university/payment/invoice";

  export const GET_INVOICE_APP_API = 
  "get_invoice_id";

export const STRIPE_PAYMENT_API = 
  "invoice-payment-student";

export const CHECK_HIRE_AGENT_API = 
  "check_hire_agent";

export const GET_HIRE_AGENT_API = 
  "hire-agent";

export const GET_SHARE_PROFILE_API = 
  "share-profile";

export const GET_AGENT_HIRE_REQUESTS_API = "hire-request-list";
export const UPDATE_HIRE_REQUEST_API = "hire-request-response";
export const DELETE_HIRE_REQUEST_API = "delete-hire-request";

export const GET_EP_SAVED_SEARCH_API = "student/saved-search/provider-list";
export const GET_PP_SAVED_SEARCH_API = "student/saved-search/partner-list";
export const DELETE_SAVED_SEARCH_API = "student/saved-search/delete";

export const DELETE_DOCS_API = "delete_studocuments";

export const GET_PROFILE_FLAGS_API = "get-personal-information-flag";

export const GET_EDUCATION_SUBLEVEL_API = "getEducatLevelStdProfile";
export const STUDENT_DOCUMENTS_API = "student/documents";

export const GET_PROGRAM_COUNTRY_API = "program-country";

export const AGENT_LIST_API = "search-agent"

export const SAVE_PP_SEARCH_API = "store_searches"

export const EP_LIST_API = "getalluniversitylist"

export const EP_FAVOURITE_API = "update-ep-favourite"

export const PROGRAM_FAVOURITE_API = "program-favorite"

export const GET_AGENT_PROFILE_API = "agent-profile"

export const GET_AGENT_REVIEWS_API = "agent-review-list"

export const LIKE_DISLIKE_REVIEW_API = "like-dislike-review"
export const LIKE_DISLIKE_COMMENT_API = "like-dislike-comment"
export const UPDATE_COMMENT_API = "review-comment-update"
export const DELETE_COMMENT_API = "comment-delete"

export const DELETE_PERM_API = "inbox/mail/delete-permanently"
export const MOVE_TO_INBOX_API = "inbox/mail/move-to-inbox"

export const GET_CHAT_SESSIONS_LIST_API = "https://livechat.formeeexpress.com/api/ticketList";
export const GET_CHAT_MESSAGES_API = "https://livechat.formeeexpress.com/api/chatHistory";
export const DELETE_CHAT_SESSIONS_API = "https://livechat.formeeexpress.com/api/deletemessage";
export const STAR_CHAT_MESSAGES_API = "https://livechat.formeeexpress.com/api/starmessage";

export const SEND_MEETING_API = "sent-meeting"

export const GET_UNIVERSITY_PROFILE_API = "university-profile"

export const GET_UNIVERSITY_INTAKES_API = "university-intake-list"

export const GET_PROGRAM_DETAILS_FILTERS_API = "program-filter-title"

export const GET_PROGRAM_LIST_API = "getstudentprogramlist"

export const CHECK_ELIGIBILITY_API = "checkelegibility_new"

export const GET_PROGRAM_DETAILS_API = "student/program/get-details"

export const GET_ADVICE_API = "student/application/advice"

export const CHECK_APPLIED_STATUS_API = "student/application/flag"

export const GET_APPLICATION_STEP_STATUS_API = "student/application/tab-flag"

export const SAVE_APPLICATION_API = "draft-application"

export const ADD_APPLICATION_VIEWER_API = ""

export const SEND_APPLICATION_API = "student/application/sent"

export const GET_INVOICE_API = "student/application/invoice"

export const GET_INVOICE_PDF_URL = "xero/invoice/pdf"

export const GET_APPLICATION_PDF_FILE_API = "university/profile/application/form/pdf"
export const SAVE_APPLICATION_PDF_API = "student/application/save-pdf"
export const START_PDF_FORM_APPLICATION_API = "student/application/pdf-flag"

export const GET_APPLICATION_NOTES_API = "student/application/notes"
export const SAVE_APPLICATION_NOTE_API = "student/application/notes/save"
export const DELETE_APPLICATION_NOTE_API = "student/application/notes/delete"
export const GET_APPLICATION_NOTE_COUNT_API = "student/application/notes/count"

export const GET_STUDENT_TYPES_API = "student/program/student-type"

export const GET_SUPPORTING_DOCUMENT_API = "student/application/supporting-document"