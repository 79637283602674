import React, { Component } from "react";
import {
  Container, Row, Col, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup,
  Collapse
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import PageLoading from "../../common/PageLoading";
import "./CreateMeeting.scss"
import { Scrollbars } from "react-custom-scrollbars";
import { ReactComponent as BackArrowWhite } from "../../assets/img/icons/left-arrow.svg";
import { ReactComponent as UsersIcon } from "../../assets/img/Users.svg";
import PersonalIcon from "../../assets/img/personal-icon.svg";
import { ReactComponent as XCloseIcon } from "../../assets/img/X_white_icon.svg";
import SearchIcon from "../../assets/img/search-icon.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getTimeZonesListAction,
  createMeetingAction,
  meetingDetailsAction,
  getContactListAction
} from "../../store/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MESSAGES } from "../../constant/messages";
import FormError from "../../common/FormError";
import moment from "moment";
import Select from "react-select";

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#e0e0e0",
    primary: "#444444",
    neutral60: "hsl(0, 0%, 40%)",
  },
});

class CreateMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignModal: false,
      alphaList: [
        "All",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      contactList: [],
      filteredList:[],
      selectedAlphabet: "All",
      selectedAssign: [],
      seconds: [
        '00', 15, 30, 45
      ],
      toTime: "00:00",
      toTimePost: "AM",
      fromTime: "00:00",
      fromTimePost: "AM",
      meetingTitle: "",
      meetingDetails: "",
      meetingDate: null,
      timeZone: null,
      timeZoneList: [],
      loading: false,
      studentId: 0,
      errors: {},
      meetingId: 0,
      details: null,
      contactSearch: "",
      selectedAlpha: "All",
      selectedTimeZone: 0
    }
  }

  componentDidMount() {
    let studentId = localStorage.getItem("studentid");
    let id =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    this.setState({
      studentId,
      meetingId: id
    }, () => {
      this.getContactList()
      this.getTimeZonesList();
      if (id) {
        this.getDetails()
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.timeZoneList && this.state.timeZoneList.length) {
      if (
        this.state.meetingId
        && this.state.selectedTimeZone
        && !this.state.timeZone
      ) {
        let timeZone = null
        this.state.timeZoneList.map(val => {
          if (val.value == this.state.selectedTimeZone) {
            timeZone = {
              label: val.label,
              value: val.value
            }
          }
        })

        if (timeZone) {
          this.setState({
            timeZone,
            selectedTimeZone: 0
          })
        }
      }
    }
  }

  getContactList = () => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getContactListAction(
        {
          student_id: this.state.studentId,
          search: this.state.contactSearch,
          // alpha_search: this.state.selectedAlpha == "All" ? "" : this.state.selectedAlpha
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            console.log('response.data', response.data)
            this.setState({
              contactList: response.data,
              filteredList: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  handleParticipantSearch = (e) => {
    let contactSearch = e.target.value.trim();
    let { contactList, filteredList, selectedAlphabet } = this.state;
    let mainlist = filteredList.length ? filteredList : contactList
    if (contactSearch) {
      filteredList = mainlist.filter((contact) => {
        let name = contact.first_name + " " + contact.last_name
        return (contact.email.toLowerCase().indexOf(contactSearch.toLowerCase()) > -1) || (name.toLowerCase().indexOf(contactSearch.toLowerCase()) > -1)
      })
    } else {
      filteredList = contactList;
      selectedAlphabet = "All"
    }
    this.setState({ filteredList, contactSearch, selectedAlphabet })

  }
  filterListByAlphabet = (selectedAlphabet) => {
    let { contactList, filteredList } = this.state;
    document.getElementById("contactSearch").value = "";
    let mainlist = filteredList.length ? filteredList : contactList
    if (selectedAlphabet !== "All")
      filteredList = mainlist.filter((contact) => contact.first_name.toUpperCase().startsWith(selectedAlphabet));
    else
      filteredList = contactList;
    this.setState({ filteredList, selectedAlphabet, contactSearch: "" })
  }
  getDetails = () => {
    this.showLoading()
    new Promise((resolve, reject) => {
      this.props.meetingDetailsAction(
        {
          student_id: this.state.studentId,
          meeting_id: this.state.meetingId
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            let startTime = response.data.meeting_start_time_val;
            let start = startTime.split(" ");
            let fromTimePost = start[1];
            fromTimePost = fromTimePost.toUpperCase();
            let fromTime = start[0];

            let endTime = response.data.meeting_end_time;
            let end = endTime.split(" ");
            let toTimePost = end[1];
            toTimePost = toTimePost.toUpperCase();
            let toTime = end[0];

            let selectedAssign = [];
            response.data.participants.map((val) => {
              selectedAssign.push({
                color: val.participant.color_code,
                name: val.participant.first_name + " " + val.participant.last_name,
                email: val.participant.email
              });
            })

            this.setState({
              details: response.data,
              meeting_link: "link",
              meetingTitle: response.data.title,
              meetingDetails: response.data.description,
              meetingDate: new Date(response.data.meeting_date),
              fromTime,
              fromTimePost,
              toTime,
              toTimePost,
              // timeZone: response.data.time_zone,
              selectedTimeZone: response.data.time_zone,
              selectedAssign
            })
          }
          resolve();
        },
        (err) => {
          console.log('err', err)
          this.hideLoading();
          reject();
        }
      )
    })
  }

  getTimeZonesList = () => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getTimeZonesListAction(
        {
          student_id: this.state.studentId
        },
        (response) => {
          this.hideLoading();
          let timeZoneList = response.data.map(val => {
            return {
              label: val.label,
              value: val.id
            }
          })
          this.setState({
            timeZoneList
          })
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  openAssignModal = () => {
    this.setState({ assignModal: true });
  };
  closeAssignModal = () => {
    this.setState({ assignModal: false });
  };

  handleChangeContactList = (e) => {
    let exists = [];
    this.state.selectedAssign.map((exist, index) => {
      if (exist.email == e.email) {
        exists.push(index);
      }
    });
    let {
      selectedAssign
    } = this.state;

    if (exists.length) {
      selectedAssign.splice(exists[0], 1);
    } else {
      let obj = {
        color: e.color_code,
        name: e.first_name + " " + e.last_name,
        email: e.email
      };

      selectedAssign.push(obj)
    }

    this.setState({
      selectedAssign
    }, () => {
      // console.log("contacts", this.state.selectedAssign);
    });
  };

  handleSubmitContactList = (e) => {
    if (this.state.selectedAssign === []) {
      alert("Select List");
    } else {

      //this.setState({ selectedAssign: e.target.value });
      this.closeAssignModal();
    }
  };

  handleDateChange = (date, name) => {
    this.setState(
      {
        [name]: date,
      }, () => {
        this.validate()
      }
    );
  };

  handleTargetChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.validate()
    });
  };

  validate = () => {
    let {
      errors
    } = this.state;
    let status = false;

    if (!this.state.meetingTitle) {
      errors.meetingTitle = MESSAGES.PLEASE_ENTER_TITLE
      status = true;
    } else {
      errors.meetingTitle = "";
    }

    if (!this.state.meetingDate) {
      errors.meetingDate = MESSAGES.PLEASE_ENTER_MEETING_DATE
      status = true;
    } else {
      errors.meetingDate = "";
    }

    if (!this.state.fromTime) {
      errors.fromTime = MESSAGES.PLEASE_ENTER_FROM_TIME
      status = true;
    } else {
      errors.fromTime = "";
    }

    console.log('this.state.toTime', this.state.toTime)
    if (!this.state.toTime) {
      errors.toTime = MESSAGES.PLEASE_ENTER_TO_TIME
      status = true;
    } else {
      errors.toTime = "";
    }

    if (
      this.state.fromTime &&
      this.state.toTime
    ) {
      let fromTime = '1/1/1999 ' + this.state.fromTime +
        " " + this.state.fromTimePost;
      let toTime = '1/1/1999 ' + this.state.toTime +
        " " + this.state.toTimePost;

      console.log('before times', fromTime, toTime);

      let fromTimeFormat = new Date(fromTime);
      let toTimeFormat = new Date(toTime);

      console.log('fromTimeFormat', fromTimeFormat, toTimeFormat);

      if (fromTimeFormat >= toTimeFormat) {
        errors.fromTime = MESSAGES.MEETING_TIME_ERROR;
      } else {
        errors.fromTime = "";
      }
    }

    if (!this.state.meetingDetails) {
      errors.meetingDetails = MESSAGES.DESCRIPTION_REQUIRED
      status = true;
    } else {
      errors.meetingDetails = "";
    }

    if (!this.state.timeZone) {
      errors.timeZone = MESSAGES.MEETING_TIMEZONE_REQUIRED
      status = true;
    } else {
      errors.timeZone = "";
    }

    // if (!this.state.selectedAssign) {

    // }
    this.setState({
      errors
    })
    return {
      errors,
      status
    }
  }

  createNewMeeting = (draft) => {
    let participants = []
    if (!draft) {
      let {
        errors,
        status
      } = this.validate()

      if (status) {
        this.setState({
          errors
        })
        return;
      }
      participants = this.state.selectedAssign.map(val => val.email)
      if (!participants.length) {
        toast.error(MESSAGES.MEETING_PARTICIPANTS_REQUIRED);
        return;
      }
    }


    let requestObj = {
      student_id: this.state.studentId,
      meeting_link: "link",
      title: this.state.meetingTitle,
      description: this.state.meetingDetails,
      meeting_start_time: this.state.fromTime + " " + this.state.fromTimePost,
      meeting_end_time: this.state.toTime + " " + this.state.toTimePost,
      status: draft ? 0 : 1,
      participant: participants
    };

    if (this.state.meetingDate) {
      requestObj.meeting_date = moment(this.state.meetingDate).format('DD/MM/YYYY')
    }

    if (this.state.timeZone) {
      requestObj.time_zone = this.state.timeZone.value
    }

    if (this.state.meetingId) {
      requestObj.meeting_id = this.state.meetingId;
    }

    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.createMeetingAction(
        requestObj,
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            if (!this.state.meetingId) {
              if (response.data.meeting_id) {
                this.setState({
                  meetingId: response.data.meeting_id
                })
              }
            }
            if (!draft) {
              this.props.history.push("/upcoming-meetings");
            }
          }
          resolve();
        },
        (err) => {
          console.log('errrrr', err)
          this.hideLoading();
          reject();
        }
      )
    })
  }

  // handleChangeSorting = (e) => {
  //   this.setState({
  //     selectedAlpha: e.target.value
  //   }, () => {
  //     this.getContactList()
  //   })
  // }

  loading = () => <PageLoading loading={true} />;
  render() {
    return (
      <>
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={this.state.loading} />
          ) : null
        }

        <div className="blueStrip">
          <Container>
            <Row>
              <Col xs="12" md="6" className="leftSection">
                <BackArrowWhite className="backArrow cur-pointer"
                  onClick={() => { this.props.history.push("/meetings-pending-invites") }}
                /> {
                  this.state.meetingId ?
                    "Edit Meeting" :
                    "Create New Meeting"
                }
              </Col>
              <Col xs="12" md="6" className="rightSection">
                <Button
                  color="white"
                  outline
                  onClick={() => {
                    this.props.history.push("/meetings-pending-invites")
                  }}
                >
                  Cancel
                </Button>

                {
                  this.state.details ? (
                    this.state.details.status ? null : (
                      <Button color="white"
                        outline
                        onClick={() => {
                          this.createNewMeeting(true)
                        }}
                      >
                        Save as Draft
                      </Button>
                    )
                  ) : (
                    <Button color="white"
                      outline
                      onClick={() => {
                        this.createNewMeeting(true)
                      }}
                    >
                      Save as Draft
                    </Button>
                  )
                }

                <Button
                  color="white"
                  onClick={() => {
                    this.createNewMeeting(false)
                  }}
                >
                  {
                    this.state.meetingId ?
                      "Save Changes" :
                      "Create Meeting"
                  }
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="CreateMeeting">
          <Container >
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} className="mt-40">
                <section>
                  <head>
                    You have created the following event.
                  </head>
                  <div className="content">
                    <div className="basicForm">
                      <FormGroup row>
                        <Label for="title" sm={2}>Title</Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="meetingTitle" id="title"
                            placeholder="..."
                            value={this.state.meetingTitle}
                            onChange={this.handleTargetChange}
                          />
                          {
                            this.state.errors.meetingTitle && (
                              <FormError
                                error={this.state.errors.meetingTitle}
                                className="left-error-msg"
                              />
                            )
                          }
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="details" sm={2}>Details</Label>
                        <Col sm={10}>
                          <Input
                            type="textarea" name="meetingDetails"
                            id="meetingDetails"
                            placeholder="..."
                            value={this.state.meetingDetails}
                            onChange={this.handleTargetChange}
                          />
                          {
                            this.state.errors.meetingDetails && (
                              <FormError
                                error={this.state.errors.meetingDetails}
                                className="left-error-msg"
                              />
                            )
                          }
                        </Col>
                      </FormGroup>
                      <FormGroup row className="timeSection">
                        <Label for="date" sm={2}>Date</Label>
                        <Col sm={4} lg={4}>
                          <DatePicker
                            selected={
                              this.state.meetingDate
                            }
                            onChange={(
                              date
                            ) =>
                              this.handleDateChange(
                                date,
                                "meetingDate"
                              )
                            }
                            dateFormat="dd/MM/yyyy"
                            className="disablepro form-control"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="dd/mm/yyyy"
                            minDate={
                              new Date()
                            }
                            onChangeRaw={(
                              e
                            ) => {
                              e.preventDefault();
                            }}
                            onBlur={() => {
                              // this.updateValidations();
                            }}
                          />
                          {
                            this.state.errors.meetingDate && (
                              <FormError
                                error={this.state.errors.meetingDate}
                                className="left-error-msg"
                              />
                            )
                          }
                        </Col>
                      </FormGroup>
                      <div className="timeAreaWrap">
                        <FormGroup row className="timeSection timeArea">
                          <Label sm={2}>Time</Label>
                          <Col sm={2}>
                            <Input
                              type="select"
                              name="fromTime" id="fromTime"
                              className="time"
                              value={this.state.fromTime}
                              onChange={this.handleTargetChange}
                            >
                              {
                                [...Array(12).keys()].map((min) => {
                                  return (
                                    this.state.seconds.map((sec) => {
                                      let currentMin = min.toString();
                                      if (currentMin.length < 2) {
                                        currentMin = '0' + currentMin
                                      }
                                      return (
                                        <option value={currentMin + ":" + sec}>
                                          {currentMin + ":" + sec}
                                        </option>
                                      )
                                    })
                                  )
                                })
                              }
                            </Input>

                          </Col>
                          <Col sm={2}>
                            <Input
                              type="select" name="fromTimePost"
                              id="fromTimePost"
                              className="time"
                              value={this.state.fromTimePost}
                              onChange={this.handleTargetChange}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row className="timeSection timeArea">
                          <Col sm={1} className="to">
                            to
                          </Col>
                          <Col sm={2}>
                            <Input
                              type="select"
                              name="toTime"
                              id="toTime"
                              className="time"
                              value={this.state.toTime}
                              onChange={this.handleTargetChange}
                            >
                              {
                                [...Array(12).keys()].map((min) => {
                                  return (
                                    this.state.seconds.map((sec) => {
                                      let currentMin = min.toString();
                                      if (currentMin.length < 2) {
                                        currentMin = '0' + currentMin
                                      }
                                      return (
                                        <option value={currentMin + ":" + sec}>
                                          {currentMin + ":" + sec}
                                        </option>
                                      )
                                    })
                                  )
                                })
                              }
                            </Input>

                          </Col>
                          <Col sm={2}>
                            <Input
                              type="select"
                              name="toTimePost"
                              id="v"
                              className="time"
                              value={this.state.toTimePost}
                              onChange={this.handleTargetChange}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </Input>
                          </Col>

                          <Col
                            sm={2}
                          >
                          </Col>

                          <Col
                            sm={5}
                          >
                            {
                              this.state.errors.fromTime && (
                                <FormError
                                  error={this.state.errors.fromTime}
                                  className="left-error-msg"
                                />
                              )
                            }
                          </Col>

                          <Col
                            sm={5}
                          >
                            {
                              this.state.errors.toTime && (
                                <FormError
                                  error={this.state.errors.toTime}
                                  className="left-error-msg"
                                />
                              )
                            }
                          </Col>
                        </FormGroup>
                      </div>
                      <FormGroup row className="timeSection">
                        <Label for="timezone" sm={2}>Time zone</Label>
                        <Col sm={9}>
                          <Select
                            options={this.state.timeZoneList}
                            clearable={false}
                            theme={theme}
                            className="studylevelOptions bannerSelect"
                            value={this.state.timeZone}
                            onChange={(e) => {
                              this.setState({
                                timeZone: e,
                              }, () => {
                                this.validate()
                              });
                            }}
                          />
                          {
                            this.state.errors.timeZone && (
                              <FormError
                                error={this.state.errors.timeZone}
                                className="left-error-msg"
                              />
                            )
                          }
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12}>
                          <hr />
                        </Col>
                      </FormGroup>

                      <div className="addParticipantSec">
                        <FormGroup row>
                          <Col xs={5}>
                            <h4>Participants</h4>
                          </Col>
                          <Col xs={7} className="text-right">
                            <Button
                              outline
                              color="primary"
                              className="addParticipants"
                              onClick={() => {
                                this.openAssignModal();
                              }}
                            >
                              + Add Participants
                            </Button>
                          </Col>
                        </FormGroup>
                      </div>

                      <FormGroup row>
                        <Col sm={12}>
                          <ul className="participantsList">
                            {
                              this.state.selectedAssign.length ?
                                this.state.selectedAssign.map((item, index) => {
                                  const personName = item.name;
                                  return (
                                    <li>
                                      <span
                                        style={{
                                          backgroundColor: item.color ? item.color : null
                                        }}// take color from Api
                                      >
                                        {personName.charAt(0)}
                                      </span>
                                      <em>
                                        {item.name}
                                      </em>
                                      <span
                                        className="closeIcon"
                                        onClick={() => {
                                          let {
                                            selectedAssign
                                          } = this.state;

                                          selectedAssign.splice(index, 1)
                                          this.setState({
                                            selectedAssign
                                          })
                                        }}
                                      >
                                        <XCloseIcon
                                        />
                                      </span>
                                    </li>
                                    //item.name.charAt(0) +
                                  )
                                }) : null
                            }
                          </ul>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </div>


        <Modal
          //size="md"
          centered
          isOpen={this.state.assignModal}
          className="primaryModal assignModal"
        >
          <ModalHeader
            className="text-left font16"
            close={
              <button
                className="closeBtn"
                onClick={() => {
                  this.closeAssignModal();
                }}
              >
                <XCloseIcon />
              </button>
            }
          >
            <span className="whiteIcon mr-10">
              <UsersIcon />
            </span>
            Add participants
          </ModalHeader>
          <ModalBody className="pt-0 pl-30 pb-0 pr-10">
            <Form>
              <div className="box">
                <InputGroup className="assignModalSearch">
                  <Input
                    placeholder="Enter name or email"
                    name="contactSearch"
                    id="contactSearch"
                    onChange={this.handleParticipantSearch}
                  />
                  <Button
                    onClick={() => {
                      this.getContactList()
                    }}
                  >
                    <img
                      src={SearchIcon} alt=""
                    />
                  </Button>
                </InputGroup>

                <div className="listing">
                  <FormGroup tag="fieldset">
                    <Scrollbars style={{ height: "67vh" }}>
                      <ul className="staff list">
                        {
                          this.state.filteredList &&
                            this.state.filteredList.length ? (
                            this.state.filteredList.map(
                              (item) => {
                                return (
                                  <li>
                                    <Label
                                      check
                                      className={this.state.selectedAssign.find(element => element.email === item.email) && "selected"}
                                    >
                                      <Input
                                        type="checkbox"
                                        name="contactCheckbox"
                                        checked={this.state.selectedAssign.find(element => element.email === item.email)}
                                        value={item.name}
                                        onChange={() => {
                                          this.handleChangeContactList(item)
                                        }}
                                      />
                                      <span className="profilePic UsersIcon"
                                      style={{ backgroundImage: `url(${(item.avatar && item.avatar !== null) ? item.avatar : PersonalIcon})` }}
                                    >{item.name}</span>
                                      <div className="personalDetials">
                                        <strong>{item.first_name + " " + item.last_name}</strong>
                                        <span>{item.email}</span>
                                      </div>
                                      <div className="groupName">{item.group}</div>
                                    </Label>
                                  </li>
                                );
                              }
                            )) : (
                            <li>No contacts found!</li>
                          )
                        }
                      </ul>
                    </Scrollbars>

                  </FormGroup>
                </div>
              </div>
              <div className="alphaSorting">
                <FormGroup
                  tag="fieldset"
                  onChange={this.handleChangeSorting}
                >
                  <Scrollbars style={{ height: "72vh" }}>
                    <ul>
                      {this.state.alphaList &&
                        this.state.alphaList.length ? (

                        this.state.alphaList.map(
                          (item, index) => {
                            return (
                              <li key={index}>
                                <Label check onClick={() => this.filterListByAlphabet(item)}>
                                  <Input
                                    type="radio"
                                    name="alphaRadio"
                                    value={item}
                                  />
                                  {this.state.selectedAlphabet ===
                                    item ? (
                                    <strong>
                                      {item}
                                    </strong>
                                  ) : (
                                    item
                                  )}
                                </Label>
                              </li>
                            );
                          }
                        )
                      ) : (
                        <li>
                          No contacts found!
                        </li>
                      )
                      }
                    </ul>
                  </Scrollbars>
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter className="text-center offWhite">
            <Button
              color="primary"
              onClick={this.handleSubmitContactList}
              className="w-30"
              type="submit"
            >
              Add
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {

  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTimeZonesListAction,
      createMeetingAction,
      meetingDetailsAction,
      getContactListAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateMeeting);
