import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_UNIVERSITY,
  GET_STUDENT_APPLICATION_STATISTICS,
  GET_REACH_OUT_DETAILS,
  GET_STUDENT_APPLICATION_COUNT,
  GET_UNIVERSITY_INCOME_COUNT
} from "../constants";
import {
  baseUrl,
  DASHBOARD_DATA,
  GET_DASHBOARD_UNIVERSITY_API,
  GET_STUDENT_APPLICATION_STATISTICS_API,
  GET_REACH_OUT_DETAILS_API,
  GET_STUDENT_APPLICATION_COUNT_API,
  GET_UNIVERSITY_INCOME_COUNT_API
} from "../../Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
	getDashboardDataError,
	getDashboardDataSuccess,
	getDashboardUniversityError,
	getDashboardUniversitySuccess,
	getStudentApplicationStatisticsError,
	getStudentApplicationStatisticsSuccess,
	getReachOutDetailsSuccess,
	getReachOutDetailsError,
	getStudentApplicationCountSuccess,
	getStudentApplicationCountError,
	getUniversityIncomeCountSuccess,
	getUniversityIncomeCountError
} from '../actions/dashboard';

function getDashboardDataApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+DASHBOARD_DATA+"/"+params.id,
    // params: params
  });
}

function* fetchDashboardData(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getDashboardDataApi, payload);
    console.log('response dashdata', response);

    yield put(getDashboardDataSuccess(response.data));
    if (resolve) resolve()
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getDashboardDataError(e))
    if (reject) reject()
  }
}

export function* getDashboardDataActionWatcher() {
  yield takeLatest(GET_DASHBOARD_DATA, fetchDashboardData)
}

function getDashboardUniversityActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_DASHBOARD_UNIVERSITY_API + "/" + params.university_id
	});
}

function* getDashboardUniversityAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getDashboardUniversityActionApi, payload);
		yield put(getDashboardUniversitySuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getDashboardUniversityError(e))
		if (reject) reject(e)
	}
}

export function* getDashboardUniversityActionWatcher() {
	yield takeLatest(GET_DASHBOARD_UNIVERSITY, getDashboardUniversityAction)
}

function getStudentApplicationStatisticsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_STUDENT_APPLICATION_STATISTICS_API,
	  params: params
	});
}

function* getStudentApplicationStatisticsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getStudentApplicationStatisticsActionApi, payload);
		yield put(getStudentApplicationStatisticsSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getStudentApplicationStatisticsError(e))
		if (reject) reject(e)
	}
}

export function* getStudentApplicationStatisticsActionWatcher() {
	yield takeLatest(GET_STUDENT_APPLICATION_STATISTICS, getStudentApplicationStatisticsAction)
}

function getReachOutDetailsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_REACH_OUT_DETAILS_API,
	  params: params
	});
}

function* getReachOutDetailsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getReachOutDetailsActionApi, payload);
		yield put(getReachOutDetailsSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getReachOutDetailsError(e))
		if (reject) reject(e)
	}
}

export function* getReachOutDetailsActionWatcher() {
	yield takeLatest(GET_REACH_OUT_DETAILS, getReachOutDetailsAction)
}

function getStudentApplicationCountApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_STUDENT_APPLICATION_COUNT_API,
	  params: params
	});
}

function* getStudentApplicationCount(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getStudentApplicationCountApi, payload);
		yield put(getStudentApplicationCountSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getStudentApplicationCountError(e))
		if (reject) reject(e)
	}
}

export function* getStudentApplicationCountWatcher() {
	yield takeLatest(GET_STUDENT_APPLICATION_COUNT, getStudentApplicationCount)
}

function getUniversityIncomeCountApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_UNIVERSITY_INCOME_COUNT_API,
	  params: params
	});
}

function* getUniversityIncomeCount(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getUniversityIncomeCountApi, payload);
		yield put(getUniversityIncomeCountSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getUniversityIncomeCountError(e))
		if (reject) reject(e)
	}
}

export function* getUniversityIncomeCountWatcher() {
	yield takeLatest(GET_UNIVERSITY_INCOME_COUNT, getUniversityIncomeCount)
}

