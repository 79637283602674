import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_UNIVERSITY,
  GET_DASHBOARD_UNIVERSITY_SUCCESS,
  GET_DASHBOARD_UNIVERSITY_ERROR,
  GET_STUDENT_APPLICATION_STATISTICS,
  GET_STUDENT_APPLICATION_STATISTICS_SUCCESS,
  GET_STUDENT_APPLICATION_STATISTICS_ERROR,
  GET_REACH_OUT_DETAILS,
  GET_REACH_OUT_DETAILS_SUCCESS,
  GET_REACH_OUT_DETAILS_ERROR,
  GET_STUDENT_APPLICATION_COUNT,
  GET_STUDENT_APPLICATION_COUNT_SUCCESS,
  GET_STUDENT_APPLICATION_COUNT_ERROR,
  GET_UNIVERSITY_INCOME_COUNT,
  GET_UNIVERSITY_INCOME_COUNT_SUCCESS,
  GET_UNIVERSITY_INCOME_COUNT_ERROR
} from "../constants";

export function getDashboardDataAction(session, resolve, reject){
  return {
    type: GET_DASHBOARD_DATA,
    payload: session,
    resolve,
    reject
  };
}

export function getDashboardDataSuccess(data) {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload: data
  };
}

export function getDashboardDataError(error) {
  return {
    type: GET_DASHBOARD_DATA_ERROR,
    payload: error
  };
}

export function getDashboardUniversityAction(session, resolve, reject){
	return {
	  type: GET_DASHBOARD_UNIVERSITY,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getDashboardUniversitySuccess(data) {
	return {
		type: GET_DASHBOARD_UNIVERSITY_SUCCESS,
		payload: data
	};
}

export function getDashboardUniversityError(error) {
	return {
		type: GET_DASHBOARD_UNIVERSITY_ERROR,
	  	payload: error
	};
}

export function getStudentApplicationStatisticsAction(session, resolve, reject){
	return {
	  type: GET_STUDENT_APPLICATION_STATISTICS,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getStudentApplicationStatisticsSuccess(data) {
	return {
		type: GET_STUDENT_APPLICATION_STATISTICS_SUCCESS,
		payload: data
	};
}

export function getStudentApplicationStatisticsError(error) {
	return {
		type: GET_STUDENT_APPLICATION_STATISTICS_ERROR,
	  	payload: error
	};
}

export function getReachOutDetailsAction(session, resolve, reject){
	return {
	  type: GET_REACH_OUT_DETAILS,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getReachOutDetailsSuccess(data) {
	return {
		type: GET_REACH_OUT_DETAILS_SUCCESS,
		payload: data
	};
}

export function getReachOutDetailsError(error) {
	return {
		type: GET_REACH_OUT_DETAILS_ERROR,
	  	payload: error
	};
}

export function getStudentApplicationCount(session, resolve, reject){
	return {
	  type: GET_STUDENT_APPLICATION_COUNT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getStudentApplicationCountSuccess(data) {
	return {
		type: GET_STUDENT_APPLICATION_COUNT_SUCCESS,
		payload: data
	};
}

export function getStudentApplicationCountError(error) {
	return {
		type: GET_STUDENT_APPLICATION_COUNT_ERROR,
	  	payload: error
	};
}
export function getUniversityIncomeCount(session, resolve, reject){
	return {
	  type: GET_UNIVERSITY_INCOME_COUNT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getUniversityIncomeCountSuccess(data) {
	return {
		type: GET_UNIVERSITY_INCOME_COUNT_SUCCESS,
		payload: data
	};
}

export function getUniversityIncomeCountError(error) {
	return {
		type: GET_UNIVERSITY_INCOME_COUNT_ERROR,
	  	payload: error
	};
}
