import React from "react"
import { Input } from "reactstrap"

class ApplicationQa extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      appQuestions: [],
      appAnswers: []
    }
  }

  componentDidMount() {
    this.setState({
      appQuestions: this.props.appQuestions
    })
  }

  componentDidUpdate() {
    if (this.state.appAnswers != this.props.appAnswers) {
      this.setState({
        appAnswers: this.props.appAnswers
      })
    }
  }

  getAnswerFormat = (answer, question, appAnswer) => {
    let currentAnswer = null
    if (question.question_details.format == 1) {
      currentAnswer = {
        question_id: question.question_details.id,
        answer
      }
    }
    if (question.question_details.format == 2) {
      currentAnswer = {
        question_id: question.question_details.id,
        answer
      }
    }
    if (question.question_details.format == 3) {
      let answers = appAnswer && appAnswer.answer ? appAnswer.answer : []
      if (answers && answers.length) {
        let answerIndex = answers.findIndex(val => val == answer)
        if (answerIndex === -1) {
          answers.push(answer)
        } else {
          answers.splice(answerIndex, 1)
        }
      } else {
        answers.push(answer)
      }
      currentAnswer = {
        question_id: question.question_details.id,
        answer: answers
      }
    }

    return Promise.resolve(currentAnswer)
  }

  updateAppAnswers = async (answer, question) => {
    try {
      let {
        appAnswers
      } = this.state
      console.log('log appAnswers', appAnswers);
      let answerExists = appAnswers.findIndex(val => val.question_id == question.question_details.id)
      if (answerExists === -1) {
        let currentAnswer = await this.getAnswerFormat(answer, question)
        appAnswers.push(currentAnswer)
      } else {
        let currentAnswer = await this.getAnswerFormat(answer, question, appAnswers[answerExists])
        appAnswers[answerExists] = currentAnswer
      }

      this.setState({
        appAnswers
      }, () => {
        this.props.updatedAnswers(appAnswers)
      })
    } catch (err) {
      console.log('log err', err);
    }
  }

  render() {
    return (
      <div className="personal-box-inner box">
        <div className="pr-header">
          <h3>Other Information</h3>
        </div>
        {
          this.state.appQuestions.map((val, index) => {
            let answer = null
            console.log('log this.props.appAnswers', this.props.appAnswers);
            let answerExists = this.props.appAnswers.find(answer => answer.question_id == val.question_details.id)

            if (answerExists) {
              answer = answerExists.answer
            }

            let question = val.question_details

            return (
              <div
                className={`col-md-12 ${question.format == 1 ? 'text-answr mt-5' : (
                  question.format == 2 ? 'single-slct-answr mt-5' : 'multi-slct-answr mt-3'
                )}`}
                key={index}
              >
                <div className="row font-weight-bold">
                  {question.question}
                </div>

                {
                  question.format == 2 || question.format == 3 ? (
                    question.question_options
                      && question.question_options.length ? (
                        question.question_options.map((option, optionIndex) => {
                        let checked = false
                        if (answer) {
                          if (question.format == 2) {
                            if (option.text == answer) {
                              checked = true
                            } else {
                              checked = false
                            }
                          } else {
                            if (answer && answer.includes(option.text)) {
                              checked = true
                            } else {
                              checked = false
                            }
                          }
                        }

                        return (
                          <div className="row mt-3 ml-3" key={optionIndex}>
                            <Input
                              type={`${question.format == 2 ? 'radio' : 'checkbox'}`}
                              className={`${question.format == 3 ? 'multiCheck' : 'generalradio'}`}
                              onChange={(e) => {
                                this.updateAppAnswers(e.target.value, val)
                              }}
                              value={option.text}
                              checked={checked}
                            />
                            <div className="ansr-chck-txt">{option.text}</div>
                          </div>
                        )
                      })
                    ) : null
                  ) : (
                    <textarea
                      className="ansrTxt"
                      rows="4"
                      cols="100"
                      key={index}
                      onChange={(e) => {
                        this.updateAppAnswers(e.target.value, val)
                      }}
                      value={answer}
                    ></textarea>
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default ApplicationQa