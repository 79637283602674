import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledPopover, PopoverBody } from 'reactstrap';
import "./emailTemplate.scss";

import { ReactComponent as DownArrowIcon } from "../../../../assets/img/arrow_down_gray_icon.svg";
import { ReactComponent as GreenTickIcon } from "../../../../assets/img/icons/tick_circle_green_icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close-btn.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/img/icons/warning_icon.svg";
import { baseUrl } from '../../../../Api';
import { toast } from 'react-toastify';

export default class Hire extends Component {
  //console.log("data", props.data);
  constructor(props) {
    super(props);

    this.state = {
      openModalAccept: false,
      openModalDecline: false,
      openModalAdded: false,
      selectedAssign: {},
      item: null,
      referenceId: 0,
      applicationId: 0,
      applicationNo: '',
      agentId: 0,
      agentName: ""
    }
  }

  componentDidMount() {
    this.setState({
      item: this.props.data,
      referenceId: this.props.data.request_reference_id
    }, () => {
      if (this.state.item) {
        let updates = {};
        if (this.state.item.email_from_details) {
          if (this.state.item.email_from_details.agentabout) {
            let agent = this.state.item.email_from_details.agentabout;
            updates['agentName'] = agent.company_name
          }
          updates['agentId'] = this.state.item.email_from_details.id;

          this.setState(updates)
        }
      }
    })
  }

  cancelRequest = () => {
    let data = {
      id: this.state.referenceId,
      status: 5
    };
    this.setOpenModalDecline(false)
    this.props.updateHireRequest(data).then(response => {
      console.log('response', response);
    }).catch(err => {
      console.log('err', err)
    })
  }

  acceptRequest = () => {
    let data = {
      id: this.state.referenceId,
      status: 3,
    };

    this.setOpenModalAccept(false);
    this.props.updateHireRequest(data).then(response => {
      this.setOpenModalAdded(true);
    }).catch(err => {
      console.log('err', err)
    })
  }

  setOpenModalAdded = (stat) => {
    this.setState({
      openModalAdded: stat
    })
  }

  setOpenModalAccept = (stat) => {
    this.setState({
      openModalAccept: stat
    })
  }

  setOpenModalDecline = (stat) => {
    this.setState({
      openModalDecline: stat
    })
  }

  setSelectedAssign = (stat) => {
    this.setState({
      selectedAssign: stat
    })
  }

  // const handleChangeContactList = (e) => {
  //   // setSelectedAssign(e.target.value)
  //   console.log("contacts", e);
  // }

  render() {
    let {
      agentName
    } = this.state;

    return (
      <>
        <div className="emailContent">
          <div className="header bigFont">
            <p>
              <strong>
                {agentName}
              </strong>
              {" "}has send request to you
            </p>
          </div>
          {
            this.props.agent && this.props.agent.hire_flag ? (
              this.props.agent.hire && this.props.agent.hire.hire_status == 3 ? (
                'Request is already accepted!'
              ) : (
                'Request is already declined!'
              )
              
            ) : (
              <>
              <div className="contentBox mt-30">
            <p className="bigFont">
              <strong>
                {
                  agentName
                }
              </strong>
              {" "}has send request to hire as your Preferred Partner.
            </p>
            <Button color="green" outline
              onClick={() => { this.setOpenModalDecline(true) }}
            >
              Decline
            </Button>
            <Button color="green"
              onClick={() => { this.acceptRequest() }}
            >
              Accept & Add to <br />
              Agent Contract
            </Button>

            <p className="smallFonts">Unexpected email? To contact Formee Express team, send email to <strong><a href="mailto:admin@formeeexpress.com">admin@formeeexpress.com</a></strong></p>
          </div>
          <div className="footer">
            <p><strong>Need Support?</strong></p>
            <p>Feel free to email us if you have any questions, comments or suggestions.<br />
              We’ll be happy to resolve your issues.</p>
          </div>
              </>
            )
          }
        </div>

        {/* Accept */}
        <Modal className="primaryModal noRadius viewAdded" isOpen={this.state.openModalAdded}>
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalAdded(false) }} />
            <GreenTickIcon className="greenTickIcon" />
            <h2 className="title">Added to Agent Contract</h2>
            <p>
              <Link to={`/agentcontracts`}>
                Go to agent contract
              </Link>
            </p>

          </ModalBody>
        </Modal>

        {/* Decline */}
        <Modal
          className="primaryModal noRadius viewAdded"
          isOpen={this.state.openModalDecline}
        >
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalDecline(false) }} />
            <WarningIcon className="WarningIcon" />
            <h2 className="title">Are you sure you want to decline the hire request?</h2>
            <p>If you change your mind, you can go to Hiring requests</p>
            <div className="mt-40">
              <Button onClick={() => { this.setOpenModalDecline(false) }} color="green w-30" outline>No</Button>
              <Button onClick={() => { this.cancelRequest() }} color="green w-30">Yes</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}