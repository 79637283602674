import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Container, Nav, NavItem, Popover, PopoverBody, UncontrolledTooltip, } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler, } from "@coreui/react";
import logo from "../../assets/img/brand/formee-logo.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import config from "../../config.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Noteicon from "../../assets/img/student/noti-high-icon.svg";
import Mailicon from "../../assets/img/student/mail-icon.svg";
import Favricon from "../../assets/img/student/favr-icon.svg";
import Uficon from "../../assets/img/student/user-fill-icon.svg";
import { ReactComponent as UserFillIcon } from "../../assets/img/student/user-fill-icon.svg";
import { ReactComponent as LogoutIcon1 } from "../../assets/img/icons/logoutIcon1.svg";
import { ReactComponent as UserIcon } from "../../assets/img/icons/userIcon.svg";
import { ReactComponent as InboxIcon } from "../../assets/img/icons/mail.svg";
import { ReactComponent as FavIcon } from "../../assets/img/icons/fav-icon.svg";
import { countries } from "../../countryCodes";
import Flag from "react-world-flags";
import { MESSAGES } from "../../constant/messages";
import GetNotification from "./getNotification";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./header.scss";
import { getProfileAction } from "../../store/actions"


var baseurl = `${config.baseurl}`;
let generalUrl = `${config.generalurl}`;

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const style = {
  left: "-10",
};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      large: false,
      notifyco: "",
      mailco: "",
      fname: "",
      lname: "",
      countryCode: "",
      country: "",
      userProfilePopover: false
    };
  }

  componentDidMount() {
    const studentId = localStorage.getItem("studentid");
    this.myInterval = setInterval(() => {
      if (studentId && studentId != null) {
        const getnotifyvalue = localStorage.getItem("notifyvalue");
        const studNotificationReq = axios.get(
          baseurl + "/get_studentNotification/" + studentId
        );
        const inboxMailNotificationReq = axios.get(
          baseurl + "/get_inboxmail_notification/" + studentId + "/Student"
        );
        Promise.all([studNotificationReq, inboxMailNotificationReq])
          .then(([studNotificationRes, inboxMailNotificationRes]) => {
            this.setState({
              notifyco: studNotificationRes.data
                ? studNotificationRes.data.count
                : this.state.notifyco,
              mailco: inboxMailNotificationRes
                ? inboxMailNotificationRes.data.count
                : this.state.mailco,
            });
            let result = studNotificationRes.data
              ? studNotificationRes.data
                ? studNotificationRes.data.result
                  ? studNotificationRes.data.result
                  : false
                : false
              : false;

            if (result.length) {
              for (let i = 0; i < result.length; i++) {
                if (
                  result[i].is_viewed === 0 &&
                  getnotifyvalue < studNotificationRes.data.count
                ) {
                  localStorage.removeItem("notifyvalue");
                  toast.success(result[i].notification_message);
                  localStorage.setItem(
                    "notifyvalue",
                    studNotificationRes.data.count
                  );
                }
              }
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
          });
      }
    }, 30000);

    if (studentId !== null) {
      axios.get(baseurl + "/get_generalinfo/" + studentId).then((response) => {
        if (response.data && response.data.length && response.data.length > 0) {
          this.setState({
            fname: response.data[0].first_name,
            lname: response.data[0].last_name,
            country: response.data[0].country_name,
          });
          this.findCountryCode(response.data[0].country_name);
        }
      });
    }

    if (!this.props.profile) {
      this.props.getProfileAction({ univ_id: studentId })
    }
  }

  componentDidUpdate() {
    if (!this.props.profile || (this.props.profile && this.props.profile.student_id != localStorage.getItem("studentid"))) {
      this.props.getProfileAction({ univ_id: localStorage.getItem("studentid") })
    }
  }

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval)
    }
  }

  findCountryCode = (countryName) => {
    let code = "";
    countries.forEach((obj) => {
      if (obj.name === countryName) {
        code = obj.code.toLowerCase();
        this.setState({ countryCode: code });
      }
    });
    return code;
  };

  handleChange = (event) => {
    localStorage.clear();
    sessionStorage.clear();
    let hostname = window.location.hostname;
    let domain = (hostname.split('.').reverse()[1] ? "." + hostname.split('.').reverse()[1] + "." : "") + hostname.split('.').reverse()[0];
    document.cookie = `userId=;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`;
    document.cookie = `userType=;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`;

    document.cookie = `fx_email=;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`;
    document.cookie = `fx_name=;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`;

    window.location = "/#/login";
  };

  removeCount = () => {
    axios
      .get(
        baseurl + "/update_notification/" + localStorage.getItem("studentid")
      )
      .then((response) => {
      });
  };

  removeEmailCount = () => {
    axios
      .get(
        baseurl +
        "/update_inboxmail_notification/" +
        localStorage.getItem("studentid") +
        "/Student"
      )
      .then((response) => {
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <AppNavbarBrand
            full={{ src: logo, width: 249, height: "auto", alt: "Formee Logo" }}
            // minimized={{
            //   src: sygnet,
            //   width: 30,
            //   height: 30,
            //   alt: "CoreUI Logo",
            // }}
            href={generalUrl}
          />

          {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}
          <AppSidebarToggler className="d-md-down-none d-none" display="lg" />

          <Nav className="ml-auto foe-nav-right" navbar>
            {/* <NavItem className="d-md-down-none">
              <NavLink to="/studentlibrary" className="nav-link">
                Search
              </NavLink>
            </NavItem> */}
            <NavItem className="d-md-down-none">
              <NavLink to="/studentlibrary" className="nav-link">
                Library
              </NavLink>
            </NavItem>
            <NavItem className="d-md-down-none">
              <NavLink to="/preferedpartnerlist" className="nav-link">
                Preferred Partners
              </NavLink>
            </NavItem>
            <NavItem className="d-md-down-none">
              <NavLink to="/institutelist" className="nav-link">
                Education Providers
              </NavLink>
            </NavItem>
            <NavItem className="d-md-down-none">
              <NavLink to="/draftapplication" className="nav-link">
                applications
              </NavLink>
            </NavItem>
            {/* <NavItem className="d-md-down-none">
              <NavLink to="" className="nav-link" onClick={this.handleChange}>
                Logout
              </NavLink>
            </NavItem> */}
            <NavItem className="d-md-down-none nav-right-box iconLink mr-0">
              <GetNotification />
              {/* <NavLink
                to="/profilenotification"
                className="nav-link col-4"
                onClick={() => this.removeCount()}
              >
                <h6>
                  <span
                    className="badge notify rounded-pill bg-danger"
                    style={style}
                  >
                    {this.state.notifyco}
                  </span>
                </h6>
                <img src={Noteicon} alt="home-icon" className="uni-icon" />
              </NavLink> */}
              <NavLink to="/profilewishlist" className="nav-link favLink">
                <FavIcon />
                {/* <img src={Favricon} alt="home-icon" className="uni-icon" /> */}
              </NavLink>
              <NavLink to="/inbox" className="nav-link inboxLink">
                <h6>
                  <span
                    className="badge notifymail rounded-pill bg-danger"
                    style={{ left: "52%!important" }}
                    onClick={() => this.removeEmailCount()}
                  >
                    {this.state.mailco}
                  </span>
                </h6>
                <InboxIcon />
                {/* <img src={Mailicon} alt="home-icon" className="uni-icon" /> */}
              </NavLink>

              {/*<UncontrolledDropdown nav direction="down" className="mr-0">
                                <DropdownMenu right>
                                    <DropdownItem header tag="div" className="text-center">
                                        <strong>Account</strong>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>*/}
            </NavItem>
            <NavItem className="d-lg-none">
              <AppSidebarToggler display="md" mobile />
            </NavItem>
            {/* <NavLink
              to="/studentprofile"
              className="nav-link text-center prof-header-flag"
            >
              <Flag
                className="rounded flg-img"
                code={this.state.countryCode}
                height="23"
                fallback={<span>Unknown</span>}
              />
              <span className="head-country">{this.state.country}</span>
            </NavLink> */}

            <NavLink
              id="userProfile"
              to="#userProfile"
              className="nav-link text-center prof-header"
              onClick={() => {
                // this.getNotifications()
                this.setState({
                  userProfilePopover: true
                });
              }}
            >
              <img
                src={Uficon}
                width="25"
                alt="home-icon"
                className="uni-icon"
              />
              {/* <span className="head-account">
                {" "}
                {this.state.fname} {this.state.lname}{" "}
              </span> */}
            </NavLink>
          </Nav>
          <Popover placement="bottom-end" isOpen={this.state.userProfilePopover} target="userProfile"
            className="userProfile"
            trigger="legacy"
            toggle={() => {
              this.setState({
                userProfilePopover: false
              });
            }}
          >
            <PopoverBody>
              <ul>
                {
                  this.props.profile ? (
                    <li>
                      <UncontrolledTooltip placement='left-start' target={"userDetails"}>
                        <span>
                          {
                            this.props.profile.first_name + " " + this.props.profile.last_name
                          }
                        </span><br />
                        <span>
                          {
                            this.props.profile.login_email
                          }
                        </span>
                      </UncontrolledTooltip>
                      <NavLink
                        to="#nolink"
                        className={`nav-link`}
                        id="userDetails"
                      >
                        <UserFillIcon width="16" height="16" />
                        <span>
                          {
                            this.props.profile.first_name + " " + this.props.profile.last_name
                          }
                        </span>
                        <span>
                          {
                            this.props.profile.login_email
                          }
                        </span>
                      </NavLink>
                    </li>
                  ) : null
                }
                <li>
                  <NavLink
                    to="/Viewprofile"
                    className={`nav-link ${this.props.match.path === "/Viewprofile" ? 'active' : ""}`}
                  >
                    <UserIcon width="16" height="16" />
                    My Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to=""
                    className="nav-link"
                    onClick={this.handleChange}
                  >
                    <LogoutIcon1 width="16" height="16" />
                    Logout
                  </NavLink>
                </li>
              </ul>
            </PopoverBody>
          </Popover>
          <AppAsideToggler className="d-md-down-none d-none" />
        </Container>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;


function mapStateToProps(state) {
  return {
    profile: state.profileReducer.profile
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProfileAction
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));

// export default withRouter(DefaultHeader);
