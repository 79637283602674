import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import { MESSAGES } from "../../constant/messages";
import "./MakePayment.scss";

import { ReactComponent as FormeeLogo } from "../../assets/img/brand/formee-logo.svg";
import { ReactComponent as LockIcon } from "../../assets/img/lock_icon.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/img/back_arrow.svg";
import { ReactComponent as MasterCardLogo } from "../../assets/img/masterCardLogo.svg";
import { ReactComponent as BlueTickIcon } from "../../assets/img/icons/tick_icon_blue.svg";
import PaypalLogo from "../../assets/img/PaypalLogo.svg";
import { ReactComponent as UploadIcon } from "../../assets/img/uploadicon.svg";
import RemoveFileIcon from "../../assets/img/close-white.svg";
import JPGIcon from "../../assets/img/icons/jpg_icon.svg";
import PDFIcon from "../../assets/img/icons/pdf_icon.png";
import Cross from "../../assets/img/coolicon.svg";
import UniLogo from "../../assets/img/university/deakin_logo.jpg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getDefaultCardAction,
  getInvoiceDetailsAction,
  stripePaymentAction,
  getInvoiceAppAction,
  saveCardAction,
  getInvoiceAction
} from "../../store/actions";
import {
  PAYPAL_CLIENT_ID,
  STRIPE_PUBLISH_KEY
} from "../../config.json";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { checkValidFileSize } from "../../common/commonHelper";
import PageLoading from "../../common/PageLoading";
import AddPaymentMethod from "./component/PaymentMethodList/AddPaymentMethod";

import { ElementsConsumer, Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

class MakePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMethod: "",
      filesList: [],
      studentId: 0,
      stripeCard: null,
      invoiceId: 0,
      invoice: null,
      paypalTransactionId: 0,
      loading: false,
      applicationId: 0,
      stripeData: '',
      elementData: '',
      nameCard: '',
      addCard: ''
    }
  }

  componentDidMount() {
    let studentId = localStorage.getItem("studentid");
    let applicationId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;
    this.setState({
      studentId,
      applicationId
    }, () => {
      this.getDefaultPayment();
      this.getInvoiceId()
    })
  }

  getDefaultPayment = () => {
    // this.props.showLoading();
    this.props.getDefaultCardAction(
      {
        student_id: this.state.studentId
      },
      (response) => {
        // this.props.hideLoading();
        if (response.success) {
          this.setState({
            stripeCard: response.data
          })
        }

      },
      (err) => {
        // this.props.hideLoading();
      }
    )
  }

  getInvoiceId = () => {
    // this.props.showLoading();
    this.props.getInvoiceAppAction(
      {
        app_id: this.state.applicationId
      },
      (response) => {
        // this.props.hideLoading();
        if (response) {
          this.setState({
            invoiceId: response
          })
          this.getInvoice();
        }

      },
      (err) => {
        // this.props.hideLoading();
      }
    )
  }

  getInvoice = () => {
    this.props.getInvoiceAction(
      {
        invoice_id: this.state.invoiceId,
        application_id: this.state.applicationId
      },
      (response) => {
        if (response.success) {
          this.setState({
            invoice: response.data.invoice
          })
        }
      },
      (err) => {

      }
    )
  }

  onDrop = (files) => {
    let noOfFiles = files.length;
    let {
      filesList
    } = this.state;

    for (let file = 0; file < noOfFiles; file++) {
      console.log('files[file]', files[file])
      let size = checkValidFileSize(files[file]);
      if (!size) {

        filesList.push(files[file]);
      } else {
        toast.error(MESSAGES.VALID_IMAGE_SIZE);
        return;
      }
    }

    this.setState({
      filesList
    })
  }

  makePayment = () => {
    let {
      activeMethod,
      studentId,
      paypalTransactionId,
      stripeCard,
      filesList,
      invoiceId
    } = this.state;
    console.log('activeMethod', activeMethod);

    if (!activeMethod) {
      toast.error('Please select a payment method!')
      return
    }

    let formData = new FormData();
    formData.append('student_id', studentId);
    formData.append('active_method', activeMethod);
    formData.append('invoice_id', invoiceId);

    if (activeMethod == "Credit_Card") {
      if (!stripeCard) {
        toast.error("You do not have any cards saved, please select another payment method or add card from the payment methods!")
        return;
      }
    }

    if (paypalTransactionId) {
      formData.append('transaction_id', paypalTransactionId)
    }

    if (activeMethod == "UploadProof") {
      if (!filesList.length) {
        toast.error("Please select files!")
        return;
      }
      if (filesList.length) {
        filesList.map(val => {
          formData.append('payment_proof[]', val)
        })
      }
    }

    this.showLoading();

    this.props.stripePaymentAction(
      formData,
      (response) => {
        if (response.success) {
          if (response.message) {
            toast.success(response.message)
          }
          setTimeout(() => {
            this.props.history.push("/sendapplication")
          }, 2000);
        }
        this.hideLoading();
      },
      (err) => {
        this.hideLoading();
      }
    )
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }


  createCard = () => {
    const { elementData, stripeData } = this.state;
    const elements = elementData;
    const stripe = stripeData;
    const cardEle = elements.getElement(CardNumberElement)
    // this.props.showLoader();
    stripe.createToken(
      cardEle
    ).then(res => {
      // this.props.hideLoader();
      console.log('cardred stripe', res, elements.getElement(CardNumberElement))
      if (res.error) {
        if (res.error.message) {
          toast.error(res.error.message)
          return
        }
      }
      if (res.token) {
        let request = {
          student_id: this.state.studentId,
          pay_by: "stripe",
          card_token: res.token.id,
          creditcard_number: res.token.card.last4,
          creditcard_name: res.token.card.brand,
          exp_year: res.token.card.exp_year,
          exp_month: res.token.card.exp_month,
          is_default: this.state.isDefaultPayment ? 1 : 0
        };
        console.log('inhere')
        this.onCreateSubmit(request)
      }

    }).catch(e => {
      // this.props.hideLoader();
      if (e && e.message) {
        toast.error(e.message)
      }
    })
  }

  onCreateSubmit = async (data) => {
    // this.props.showLoader();
    new Promise((resolve, reject) => {
      this.props.saveCardAction(
        data,
        (response) => {
          // this.props.hideLoader();
          if (response.success) {
            // this.getPaymentList();
            // this.setState({paymentModelShow: false});
            this.getDefaultPayment()
            this.makePayment()
            if (response.message) {
              toast.success(response.message);
            }
          }
          resolve();
        },
        (err) => {
          // this.props.hideLoader();
          reject();
        }
      )
    })
  }


  handleChangeName = (e, stripe, elements) => {
    this.setState({
      stripeData: stripe,
      elementData: elements,
      nameCard: e.target.value
    })
  }

  addNewCard = () => {
    this.setState({
      addCard: true,
      activeMethod: "Credit_Card"
    })
  }

  closeNewCard = () => {
    this.setState({
      addCard: false
    })
  }

  render() {
    let {
      loading
    } = this.state;

    return (
      <div className="whiteBg">
        <ToastContainer />
        {
          loading ? (
            <PageLoading loading={loading} />
          ) : null
        }
        <header>
          <Container>
            <Row>
              <Col xs="12" className="d-flex align-items-center justify-space-between">
                <Link to="/profiledashboard">
                  <FormeeLogo className="logo" />
                </Link>
                <span className="rightText">
                  <LockIcon /> Safe and secure checkout
                </span>
              </Col>
            </Row>
          </Container>
        </header>
        <Container >
          <Row className="row-eq-height">
            <Col xs="12" md="8" className="cardSection">
              <div className="head">
                <Link
                  to={"/pending-payments"}
                >
                  <BackArrowIcon /> Make a PAYMENT
                </Link>
              </div>
              <ul className="selectOptions">
                <li className={this.state.activeMethod === "Credit_Card" ? "active" : ""}>
                  <Label check for="one">
                    <Input type="radio" name="radio1" id="one"
                      checked={this.state.activeMethod == "Credit_Card" ? true : false}
                      onClick={() => {
                        this.setState({
                          activeMethod: "Credit_Card"
                        })
                      }}
                    />
                    <div className="details">
                      <table cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <div className="d-flex justify-content-between">
                                <div><strong className="title">Credit Card</strong></div>
                                <div><a href="javascript:void(0)" onClick={() => this.addNewCard()}>+ Add a new credit card</a></div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {
                              this.state.stripeCard && !this.state.addCard ? (
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <div className="text-capitalize">
                                      {
                                        this.state.stripeCard.creditcard_name
                                      }
                                    </div>
                                    <div>
                                      Ending in
                                      {
                                        "***" + this.state.stripeCard.creditcard_number.substr(this.state.stripeCard.creditcard_number.length - 4)
                                      }
                                    </div>
                                  </div>
                                </td>
                              ) : null
                            }

                            {/* <td className="text-right">
                              Ending in ***4512 <MasterCardLogo className="cardImage" />
                            </td> */}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Label>
                  {
                    this.state.activeMethod == "Credit_Card" && this.state.addCard ?
                      // <AddPaymentMethod /> 
                      (
                        <div className="mt-3">
                          <div className="d-flex justify-content-between">
                            <strong className="title">Add New Credit Card</strong>
                            <div><img className="hand-cursor" src={Cross} onClick={() => this.closeNewCard()} /></div>
                          </div>
                          <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                              {({ stripe, elements }) => (
                                <>
                                  <Label className="my-3">Credit Card Number</Label>
                                  <CardNumberElement />
                                  <Label className="my-3">Credit Card Name</Label>
                                  <Input
                                    type="text"
                                    className="form-control "
                                    placeholder="Name"
                                    name="name"
                                    onChange={(e) => this.handleChangeName(e, stripe, elements)}
                                    value={this.state.nameCard}
                                    required
                                  />
                                  <div className="d-flex justify-content-between">
                                    <div className="w-50">
                                      <Label className="my-3">Security code</Label>
                                      <CardCvcElement />
                                    </div>
                                    <div className="w-50">
                                      <Label className="my-3">Expiration date</Label>
                                      <CardExpiryElement />
                                    </div>
                                  </div>
                                  {/* <Button
                                  className="save-btn"
                                  onClick={()=>this.createCard (stripe, elements)}
                                >
                                  Save
                                </Button> */}
                                </>
                              )}
                            </ElementsConsumer>
                          </Elements>
                        </div>
                      )
                      : null
                  }
                </li>
                <li className={this.state.activeMethod === "Paypal" ? "active" : ""}>
                  <Label check for="two">
                    <Input type="radio" name="radio1" id="two"
                      onClick={() => {
                        this.setState({
                          activeMethod: "Paypal",
                          addCard: false
                        })
                      }}
                    />
                    <div className="details">
                      <table cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr>
                            <td><strong className="title">Paypal</strong></td>
                            <td><img src={PaypalLogo} className="paypalLogo" /></td>
                          </tr>
                          <tr>
                            <td>You will be redirected to PayPal website to complete<br />
                              your purchase securely.</td>
                            <td className="text-right">
                              <Link to="#" className="btn btn-verified"><BlueTickIcon /> Verified</Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Label>
                </li>
                <li className={this.state.activeMethod === "UploadProof" ? "active" : ""}>
                  <Label check for="three">
                    <Input type="radio" name="radio1" id="three"
                      onClick={() => { this.setState({ activeMethod: "UploadProof" }) }}
                    />
                    <div className="details">
                      <table cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr>
                            <td><strong className="title">Upload proof of payment</strong></td>
                          </tr>
                          <tr>
                            <td>
                              <p>If you wish to pay through direct bank transfer or outside the Formee Express portal, please use the bank details below:</p>
                              <p className="bankDetails">
                                Acc No. 123e445<br />
                                BSB 12344<br />
                                Swift code 367282<br />
                                Bank Name ANZ<br />
                              </p>
                              <p>Please upload the proof of payment document to continue.</p>

                              <Dropzone
                                onDrop={this.onDrop}
                                multiple
                                accept="application/pdf, image/jpeg, image/png"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className="dragNdrop">
                                    <div
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                    >
                                      <input
                                        {...getInputProps({
                                          //accept: "application/pdf",
                                        })}
                                        onChange={(event) => {
                                          let files = event.target.files;
                                          let noOfFiles = event.target.files.length;
                                          let {
                                            filesList
                                          } = this.state;

                                          for (let file = 0; file < noOfFiles; file++) {
                                            console.log('files[file]', files[file])
                                            let size = checkValidFileSize(files[file]);
                                            if (!size) {

                                              filesList.push(files[file]);
                                            } else {
                                              toast.error(MESSAGES.VALID_IMAGE_SIZE);
                                              return;
                                            }
                                          }

                                          this.setState({
                                            filesList
                                          })
                                        }}
                                      />
                                      <span className="btn btn-primary">
                                        <UploadIcon />
                                        Upload or drop your files here
                                      </span>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>

                              <div className="fileList">
                                {this.state.filesList &&
                                  this.state.filesList.map((file) => (
                                    <div className="box" key={file.name}>
                                      <div className="fileName">
                                        {file.type === "application/pdf"
                                          ? <img src={PDFIcon} alt="PDF" className="fileIcon" />
                                          : (file.type === "image/jpeg")
                                            ? <img src={JPGIcon} alt="JPEG" className="fileIcon" />
                                            : null}
                                        <div>
                                          {file.name}
                                          <div className="fileSize">
                                            [{(file.size / 1024).toFixed(2)} KB]
                                          </div>
                                        </div>
                                      </div>

                                      <img
                                        src={RemoveFileIcon}
                                        alt="remove"
                                        className="fileRemove"
                                        onClick={() => {
                                          this.setState({
                                            filesList: []
                                          })
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Label>
                </li>
              </ul>
            </Col>
            <Col xs="12" md="4" className="cartSection">
              <div className="head">
                <strong className="title">Checkout</strong>
                <span>1</span>
              </div>
              <table>
                <tbody>
                  {/* <tr>
                    <td><div className="uniLogo"><img src={UniLogo} alt="uniLogo" /></div></td>
                    <td className="uniName">Bachelor of Arts - Business Administration - Management - Human Resource Management</td>
                  </tr> */}
                  <tr>
                    <td>Invoice</td>
                    <td className="text-right">
                      {
                        this.state.invoice ? (
                          this.state.invoice.invoice_number
                        ) : ''
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Commission</td>
                    <td className="text-right">
                      {
                        this.state.invoice ? (
                          this.state.invoice.amount_string
                        ) : ''
                      }
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" height="40px"></td>
                  </tr>
                  {/* <tr className="subTotal">
                    <td>Subtotal</td>
                    <td className="text-right"><strong>$20,000</strong></td>
                  </tr>
                  <tr className="subTotal2">
                    <td>GST</td>
                    <td className="text-right"><strong>$100</strong></td>
                  </tr> */}
                  <tr className="total">
                    <td>Total</td>
                    <td className="text-right">
                      <strong>
                        {
                          this.state.invoice ? (
                            this.state.invoice.amount_string
                          ) : ''
                        }
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
              {
                this.state.activeMethod == "Paypal" ? (
                  <>
                    <PayPalScriptProvider
                      options={{
                        "client-id": PAYPAL_CLIENT_ID,
                        "buyer-country": "AU"
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "horizontal" }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: this.state.invoice.amount
                                }
                              }
                            ]
                          }).then((orderId) => {
                            console.log('orderid', orderId)
                            return orderId;
                          })
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then(data => {
                            console.log('datata', data)
                            if (data.id) {
                              this.setState({
                                paypalTransactionId: data.id
                              }, () => {
                                this.makePayment();
                              })
                            }
                          })
                        }}
                      />
                    </PayPalScriptProvider>
                  </>
                ) : (
                  this.state.activeMethod == "Credit_Card" && this.state.addCard ?


                    <Button
                      color="primary"
                      onClick={() => {
                        this.createCard()
                      }}
                    >
                      Send Payment
                    </Button>
                    :
                    <Button
                      color="primary"
                      onClick={() => {
                        this.makePayment()
                      }}
                    >
                      Send Payment
                    </Button>
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    //studyLevelList: state.applicationReducer.studyLevelList
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDefaultCardAction,
      getInvoiceDetailsAction,
      stripePaymentAction,
      getInvoiceAppAction,
      saveCardAction,
      getInvoiceAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);