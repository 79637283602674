import React from "react";
import template from "./AppEducation.jsx";
import config from "../../config.json";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MESSAGES } from "../../constant/messages";
import { ALN_REGEX, EMAIL_REGEX, NAME_REGEX, ZIP_MAX_REGEX, } from "../../constant/constant";
import submitForm from '../../utils/submitForm';
const baseurl = `${config.baseurl}`;

class AppEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      level: [],
      grade: [],
      graderange: [],
      elevel: [],
      edusublevel: [],
      counter: 1,
      EduaddMore: false,
      educountryid: "",
      highedulevel: "",
      gradingscheme: "",
      gradingscale: "",
      gradeaverage: "",
      recentcheck: 0,
      recentcheck2: 0,
      recent_summ: "",
      recent_summ2: "",
      edulevel: [""],
      insticountry: [""],
      institutename: [""],
      //langinstruction: [""],
      attendedfrom: [""],
      attendedto: [""],
      awardeddegree: [""],
      awardedon: [""],
      questiondata1: [],
      selectedQuestion1: null,
      instemail: [""],
      instphone: [""],
      insaddr: [""],
      schoolsattended: [{
        id: 0,
        edulevel: "",
        insticountry: "",
        institutename: "",
        attendedfrom: "",
        attendedto: "",
        awardeddegree: "",
        awardedon: "",
        instemail: "",
        instphone: "",
        insaddr: "",
        instate: "",
        inscity: "",
        inszip: "",
        institutename_other: "",
        school_grading_scheme: "",
        schoolaveragescheme: ""
      }],
      inscity: [""],
      instate: [""],
      inestate: [],
      inecity: [],
      inszip: [""],
      sdate1: "",
      sdate2: "",
      sdate3: "",
      errors: {},
      formData: new FormData(),
      content: true,
      onchangeDate: [""],
      countryUnisArr: [],
      school_gradingscheme: [""],
      school_gradeaverage: [""],
      institutename_other: [""],
      uniQuestTextArr: [],
      uniQuestSignleArr: [],
      uniQuestMultiArr: [],
      showCompleteProfileMessage: true,
      edit: true,
      appAnswers: [],
      appQuestions: []
    };
  }

  componentDidMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type != this.props.type) {
      this.getPageData()
    }
    if (prevProps.showCompleteProfileMessage != this.props.showCompleteProfileMessage) {
      this.setState({
        showCompleteProfileMessage: this.props.showCompleteProfileMessage
      })
    }
  }

  getPageData = async () => {
    try {
      this.props.showLoader();
      const countryRes = await axios.get(baseurl + "/get_country");
      //const levelRes = await axios.get(baseurl+'/geteducatlevel');
      const detailRes = await axios.get(baseurl + "/get_leveldetails");
      this.setState({
        country: countryRes.data,
        //edusublevel: levelRes.data,
        level: detailRes.data.level,
        //edusublevel: response.data.edusublevel,
        grade: detailRes.data.grade,
        graderange: detailRes.data.graderange,
        elevel: detailRes.data.edulevel,
      });
      this.getEdusummary();
      this.props.hideLoader();
    } catch (e) {
      console.log('e: ', e);
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    }
  };

  onDelEducation = (index) => {
    let {
      schoolsattended
    } = this.state

    schoolsattended.splice(index)

    this.setState({ schoolsattended })
  };

  getQuestionDataOne = (question) => {
    this.setState({ questiondata1: question });
  };

  setQuestionDataOne = (index) => {
    this.setState({ selectedQuestion1: index, EduaddMore: true });
  };

  getEdusummary = async () => {
    try {
      this.props.showLoader();

      let eduSummaryRes = null;

      if (this.props.type == "program") {
        eduSummaryRes = await axios.get(
          baseurl +
          "/get_edusummary/" +
          localStorage.getItem("studentid")
        );
      } else {
        eduSummaryRes = await axios.get(
          baseurl +
          "/get_edusummary_app/" +
          localStorage.getItem("studentid")
          , {
            params: {
              application_id: this.props.applicationId
            }
          });
      }

      const uniQuestTextReq = await axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/education/1');
      const uniQuestSignleReq = await axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/education/2');
      const uniQuestMultiReq = await axios.get(baseurl + "/uni_app_ques/" + localStorage.getItem("universityid") + '/education/3');

      if (this.props.type == "program") {
        let newRes = eduSummaryRes
        newRes.data.data = eduSummaryRes.data
        eduSummaryRes = newRes
      } else {

      }

      if (eduSummaryRes.data.data) {
        let summaryData = {};
        let schoolData = {};
        if (
          eduSummaryRes.data.data.summary &&
          eduSummaryRes.data.data.summary.length &&
          eduSummaryRes.data.data.summary.length > 0
        ) {
          summaryData = {
            ...summaryData,
            educountryid:
              eduSummaryRes.data.data.summary[0].education_country_id,
            highedulevel:
              eduSummaryRes.data.data.summary[0].highest_educationlevel_id,
            gradingscheme:
              eduSummaryRes.data.data.summary[0].grading_scheme_id,
            gradingscale:
              eduSummaryRes.data.data.summary[0].grading_scheme_range_id,
            gradeaverage: eduSummaryRes.data.data.summary[0].grade_average,
            recentcheck2: eduSummaryRes.data.data.summary[0].recent_check,
            uniQuestTextArr: uniQuestTextReq.data.data,
            uniQuestSignleArr: uniQuestSignleReq.data.data,
            uniQuestMultiArr: uniQuestMultiReq.data.data
          };
          const educatLevelRes = await axios.get(
            baseurl +
            //"/geteducatlevel2/" +
            "/geteducatlevel/" +
            eduSummaryRes.data.data.summary[0].education_country_id
          );
          summaryData = {
            ...summaryData,
            edusublevel: educatLevelRes.data,
          };
          const levelDetailsCountryRes = await axios.get(
            baseurl +
            "/get_leveldetailscountry/" +
            eduSummaryRes.data.data.summary[0].education_country_id
          );
          if (levelDetailsCountryRes.data) {
            summaryData = {
              ...summaryData,
              grade: levelDetailsCountryRes.data.grade,
              graderange: levelDetailsCountryRes.data.graderange,
            };
          }
          if (eduSummaryRes.data.data.summary[0].recent_check == "1") {
            summaryData = {
              ...summaryData,
              recent_summ2: "checked",
            };
          }
        }
        let updateval = {
          ...summaryData
        }
        
        if (
          eduSummaryRes.data.data.schools &&
          eduSummaryRes.data.data.schools.length &&
          eduSummaryRes.data.data.schools.length > 0
        ) {
          let schoolsattended = eduSummaryRes.data.data.schools.map(val => {
            return {
              id: val.id,
              edulevel: val.educationlevel_id,
              insticountry: val.institute_country_id,
              institutename: val.institute_name,
              attendedfrom: new Date(val.attended_from),
              attendedto: new Date(val.attended_to),
              awardeddegree: val.awarded_degree,
              awardedon: new Date(val.awarded_on),
              instemail: val.institute_email,
              instphone: val.institute_phone,
              insaddr: val.address,
              instate: val.state,
              inscity: val.city,
              inszip: val.zipcode,
              institutename_other: val.institute_name_other,
              school_grading_scheme: val.grading_scheme,
              schoolaveragescheme: val.average_scheme
            }
          })

          if (schoolsattended.length) {
            updateval.schoolsattended = schoolsattended
          }
        }
        this.setState(updateval);

        this.countryUnis();

        if (
          eduSummaryRes.data.data.question &&
          eduSummaryRes.data.data.question.length
        ) {
          let appQuestions = eduSummaryRes.data.data.question
          let appAnswers = appQuestions.filter(question => question.answers).map(question => ({
            question_id: question.question_details.id,
            answer: question.answers
          }))
          this.setState({
            appQuestions,
            appAnswers
          })
        }

        this.props.hideLoader();
      } else {
        this.props.hideLoader();
      }
    } catch (error) {
      console.log('error: ', error);
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    }
  }

  handleEduCountryIdChange = (event) => {
    this.setState({ educountryid: event.target.value });
    this.props.showLoader();
    const educatLevelReq = axios
      .get(baseurl + "/geteducatlevel/" + event.target.value);
    const levelDetailReq = axios
      .get(baseurl + "/get_leveldetailscountry/" + event.target.value);
    Promise.all([
      educatLevelReq,
      levelDetailReq
    ]).then(([
      educatLevelRes,
      levelDetailRes
    ]) => {
      let levelDetailResData = levelDetailRes.data ? {
        grade: levelDetailRes.data.grade,
        graderange: levelDetailRes.data.graderange,
      } : {};
      this.setState({
        edusublevel: educatLevelRes.data,
        ...levelDetailResData,
      }, () => { this.props.hideLoader() })
    }).catch((error) => {
      console.log('error: ', error);
      this.props.hideLoader();
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    });
  };

  handleTargetChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /*handleMostRecentChange = (event) => {
      let datefal = 0;
      let recent_summ = "";
      if (event.target.value == "0"){
          datefal = 1;
          recent_summ = "checked";
      } else {
          datefal = 0;
          recent_summ = "";
      }
      this.setState({recentcheck: datefal, recent_summ: recent_summ});
  };*/


  handleMostRecentChange = (event) => {
    const recentChange = {
      recentcheck2: event.target.value === "0" ? 1 : 0,
      recent_summ2: event.target.value === "0" ? "checked" : "",
    };
    this.setState(recentChange);
  };

  handleCloneChange = (event, index) => {
    let { schoolsattended } = this.state
    schoolsattended[index][event.target.name] = event.target.value

    this.setState({ schoolsattended })

    // const clone = this.state[event.target.name];
    // clone[index] = event.target.value;
    // this.setState({ [event.target.name]: clone });
  };

  handleDateChange = (date, index, name) => {
    const onDate = this.state[name];
    onDate[index] = date;
    let obj = {};
    if (name === "awardedon") obj = { [name]: onDate, onchangeDate: date };
    else obj = { [name]: onDate };

    this.setState(obj);
  };

  handleInsticountryChange = (event, index) => {
    let {
      schoolsattended
    } = this.state

    schoolsattended[index][event.target.name] = event.target.value;

    this.setState({ schoolsattended });

    this.props.showLoader();
    axios.get(baseurl + "/get_state/" + event.target.value).then((response) => {
      if (response && response.data) {
        const inestate = this.state.inestate;
        const inecity = this.state.inecity;
        inestate[index] = response.data;
        inecity[index] = [];
        this.setState({
          inestate,
          inecity,
        }, () => this.props.hideLoader());
      } else this.props.hideLoader()
    }).catch((error) => {
      this.props.hideLoader();
      console.log('error: ', error);
      toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
    });
  };

  handleGradingScheme = (event, index) => {
    let {
      schoolsattended
    } = this.state

    schoolsattended[index][event.target.name] = event.target.value

    this.setState({ schoolsattended })

  }

  updateDates = (index, name, date) => {
    let { schoolsattended } = this.state

    schoolsattended[index][name] = date

    this.setState({ schoolsattended })
  }

  handleGradeAverage = (event, index) => {
    var eventValue = event.target.value;
    var school_gradeaverage = [];
    school_gradeaverage[index] = eventValue;

    this.setState({
      school_gradeaverage
    })
  }

  // handleInsticountryChange = async (event, index) => {
  //   this.props.showLoader();
  //   var eventValue = event.target.value;
  //   try {
  //     const educatLevelRes = await axios
  //       .get(baseurl + "/geteducatlevel/" + eventValue);
  //     const countryRes = await axios.get(
  //       baseurl + "/get_country/" + eventValue
  //     );

  //     const countryName = countryRes.data[0];
  //     const instituteRes = await axios.get(
  //       "http://universities.hipolabs.com/search?country=" + countryName
  //     );
  //     //console.log(instituteRes.data);
  //     const key = 'institute_list_' + index;
  //     var insticountry = [];
  //     insticountry[index] = eventValue;

  //     this.setState({
  //       countryUnisArr: instituteRes.data,
  //       insticountry
  //     }, () => this.props.hideLoader())
  //   } catch (error) {
  //     console.log('error: ', error);
  //     this.props.hideLoader();
  //     toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
  //   }
  // };

  countryUnis = async () => {
    this.props.showLoader();
    let url = '/get_country';
    if (this.state.insticountry) {
      let url = "/get_country/" + this.state.insticountry;

    }
    const countryRes = await axios.get(
      baseurl + url
    );

    const countryName = countryRes.data[0];
    // const instituteRes = await axios.get(
    //   "http://universities.hipolabs.com/search?country=" + countryName
    // );
    //console.log(instituteRes.data + this.state.insticountry);

    this.setState({
      // countryUnisArr: instituteRes.data,
    }, () => this.props.hideLoader());

    //console.log(this.state.countryUnisArr);

  };


  handleInstateChange = (event, index) => {
    const instateclone = this.state.instate;
    instateclone[index] = event.target.value;
    this.setState({ instate: instateclone });
    this.props.showLoader();
    axios.get(baseurl + "/get_city/" + event.target.value).then((response) => {
      if (response && response.data) {
        let inecity = this.state.inecity;
        inecity[index] = response.data;
        this.setState({ inecity }, () => this.props.hideLoader());
      } else this.props.hideLoader()
    }).catch(() => {
      this.props.hideLoader();
    });
  };

  validate = () => {
    const errors = [];
    const instemail = this.state.instemail;

    let regAln = ALN_REGEX;
    let zipmax = ZIP_MAX_REGEX;
    let minmax = /^.{10,15}$/;

    let regNam = NAME_REGEX;

    this.state.schoolsattended.forEach((data, index) => {
      errors.push({});
      if (data.edulevel === "") {
        errors[index].edulevel = MESSAGES.PLEASE_SELECT_MESSAGE;
      }
      if (data.insticountry === "") {
        errors[index].insticountry = MESSAGES.PLEASE_SELECT_MESSAGE;
      }

      if (data.institutename.trim() === "") {
        errors[index].institutename = MESSAGES.THIS_IS_REQUIRED;
      } else if (data.institutename.trim().length < 3) {
        errors[index].institutename = MESSAGES.ENTER_VALID_NAME;
      } else if (!data.institutename.match(regNam)) {
        errors[index].institutename = MESSAGES.ENTER_VALID_NAME;
      }

      /*if (this.state.langinstruction[index].trim() === "") {
          errors[index].langinstruction = MESSAGES.THIS_IS_REQUIRED;
      } else if (this.state.langinstruction[index].trim().length < 3) {
          errors[index].langinstruction = MESSAGES.ENTER_VALID_NAME;
      } else if (!this.state.langinstruction[index].match(regNam)) {
          errors[index].langinstruction = MESSAGES.ENTER_VALID_NAME;
      }*/

      if (data.attendedfrom === "") {
        errors[index].attendedfrom = MESSAGES.THIS_IS_REQUIRED;
      }
      if (data.attendedto === "") {
        errors[index].attendedto = MESSAGES.THIS_IS_REQUIRED;
      }

      let startDate = new Date(data.attendedfrom);
      let endDate = new Date(data.attendedto);

      if (startDate > endDate) {
        errors[index].attendedto = MESSAGES.INSTITUTE_ATTENDANCE_RULE;
      }

      if (data.awardeddegree === "") {
        errors[index].awardeddegree = MESSAGES.THIS_IS_REQUIRED;
      }
      if (data.awardedon === "") {
        errors[index].awardedon = MESSAGES.THIS_IS_REQUIRED;
      }

      if (data.instemail.trim() === "") {
        errors[index].instemail = MESSAGES.THIS_IS_REQUIRED;
      } else if (!data.instemail.match(EMAIL_REGEX)) {
        errors[index].instemail = MESSAGES.EMAIL_INVALID;
      }

      if (data.instphone === "") {
        errors[index].instphone = MESSAGES.THIS_IS_REQUIRED;
      } else if (!data.instphone.match(minmax)) {
        errors[index].instphone = MESSAGES.ENTER_VALID_PHONE_NUMBER;
      }

      // if (data.insaddr.trim() === "") {
      //   errors[index].insaddr = MESSAGES.THIS_IS_REQUIRED;
      // } else if (data.insaddr.trim().length < 15) {
      //   errors[index].insaddr = MESSAGES.ENTER_VALID_ADDRESS;
      // }

      // if (data.inscity === "") {
      //   errors[index].inscity = MESSAGES.THIS_IS_REQUIRED;
      // }
      // if (data.instate === "") {
      //   errors[index].instate = MESSAGES.THIS_IS_REQUIRED;
      // }
      if (data.inszip === "") {
        errors[index].inszip = MESSAGES.THIS_IS_REQUIRED;
      } else if (!data.inszip.match(zipmax)) {
        errors[index].inszip = MESSAGES.ENTER_VALID_CODE;
      } else if (!data.inszip.match(regAln)) {
        errors[index].inszip = MESSAGES.ENTER_VALID_CODE;
      }

      if (data.school_grading_scheme === "") {
        errors[index].school_gradingscheme = MESSAGES.THIS_IS_REQUIRED;
      }
      if (data.schoolaveragescheme === "") {
        errors[index].school_gradeaverage = MESSAGES.THIS_IS_REQUIRED;
      }
      // if (data.institutename_other === "") {
      //   errors[index].institutename_other = MESSAGES.THIS_IS_REQUIRED;
      // }


    });

    return Object.keys(errors).length === 0 ? null : errors;
  };

  onAddEducation = () => {
    const schoolsattendedArr = this.state.schoolsattended;
    schoolsattendedArr.push("");
    this.setState({ schoolsattended: schoolsattendedArr });
    const {
      edulevel,
      insticountry,
      institutename,
      //langinstruction,
      attendedfrom,
      attendedto,
      awardeddegree,
      awardedon,
      instemail,
      instphone,
      insaddr,
      inscity,
      instate,
      inszip,
      inestate,
      inecity,
      school_gradingscheme,
      school_gradeaverage,
      institutename_other,
    } = this.state;

    edulevel.push("");
    insticountry.push("");
    institutename.push("");
    //langinstruction.push("");
    attendedfrom.push("");
    attendedto.push("");
    awardeddegree.push("");
    awardedon.push("");
    instemail.push("");
    instphone.push("");
    insaddr.push("");
    inscity.push("");
    instate.push("");
    inszip.push("");
    inestate.push([]);
    inecity.push([]);
    school_gradingscheme.push("");
    school_gradeaverage.push("");
    institutename_other.push("");

    this.setState({
      edulevel,
      insticountry,
      institutename,
      //langinstruction,
      attendedfrom,
      attendedto,
      awardeddegree,
      awardedon,
      instemail,
      instphone,
      insaddr,
      inscity,
      instate,
      inszip,
      inestate,
      inecity,
      school_gradingscheme,
      school_gradeaverage,
      institutename_other,
    });
  };

  onSubmit = (exit, e) => {
    e.preventDefault();

    let errors = this.validate();

    console.log('log errors', errors);
    this.setState({ errors });

    let multiCheckArr = [];
    $(".multiCheck").each(function (i) {
      if ($(this).is(":checked")) {
        multiCheckArr.push($(this).val());
      }
      else {
        multiCheckArr.push($(this).val() + '|uncheck');
      }
    });

    let multiTextArr = [];
    $(".ansrTxt").each(function (i) {
      if ($(this).val() != '') {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id"));
      }
      else {
        multiTextArr.push($(this).val() + '|' + $(this).attr("data-id") + '|uncheck');
      }
    });

    let radioQuesArr = [];
    $(".generalradio").each(function (i) {
      if ($(this).is(":checked")) {
        radioQuesArr.push($(this).val());
      }
    });


    errors = errors.filter((error) => Object.keys(error).length !== 0);
    if (errors.length > 0) return;

    let edulevelArr = [];
    let insticountryArr = [];
    let institutenameArr = [];
    let institutename_otherArr = [];
    //let langinstructionArr = [];
    let school_grading_schemeArr = [];
    let school_average_schemeArr = [];
    let attendedfromArr = [];
    let attendedtoArr = [];
    let awardeddegreeArr = [];
    let awardedonArr = [];
    let instemailArr = [];
    let instphoneArrArr = [];
    let insaddrArr = [];
    let inscityArr = [];
    let instateArr = [];
    let inszipArr = [];

    $(".edulevel").each(function (i) {
      edulevelArr.push($(this).val());
    });
    $(".insticountry").each(function (i) {
      insticountryArr.push($(this).val());
    });
    $(".institutename").each(function (i) {
      institutenameArr.push($(this).val());
    });
    $(".institutename_other").each(function (i) {
      institutename_otherArr.push($(this).val());
    });
    $(".school_grading_scheme").each(function (i) {
      school_grading_schemeArr.push($(this).val());
    });
    $(".school_average_scheme").each(function (i) {
      school_average_schemeArr.push($(this).val());
    });
    /*$(".langinstruction").each(function (i) {
        langinstructionArr.push($(this).val());
    });*/
    $(".attendedfrom").each(function (i) {
      attendedfromArr.push($(this).val());
    });
    $(".attendedto").each(function (i) {
      attendedtoArr.push($(this).val());
    });
    $(".awardeddegree").each(function (i) {
      awardeddegreeArr.push($(this).val());
    });
    $(".awardedon").each(function (i) {
      awardedonArr.push($(this).val());
    });
    $(".instemail").each(function (i) {
      instemailArr.push($(this).val());
    });
    $(".instphone").each(function (i) {
      instphoneArrArr.push($(this).val());
    });
    $(".insaddr").each(function (i) {
      insaddrArr.push($(this).val());
    });
    $(".inscity").each(function (i) {
      inscityArr.push($(this).val());
    });
    $(".instate").each(function (i) {
      instateArr.push($(this).val());
    });
    $(".inszip").each(function (i) {
      inszipArr.push($(this).val());
    });

    insaddrArr = insaddrArr.join("+");

    //this.state.formData.append("examdate", $("#examdate").val());
    this.state.formData.append("recent_check", this.state.recentcheck2);
    this.state.formData.append("educountryid", this.state.educountryid);
    this.state.formData.append("highedulevel", this.state.highedulevel);
    this.state.formData.append("gradingscheme", this.state.gradingscheme);
    this.state.formData.append("gradingscale", this.state.gradingscale);
    this.state.formData.append("gradeaverage", this.state.gradeaverage);

    this.state.formData.append("edulevel", edulevelArr);
    this.state.formData.append("insticountry", insticountryArr);
    //this.state.formData.append("institutename", institutenameArr);
    this.state.formData.append("institutename", [institutenameArr[0]]);
    this.state.formData.append("institutename_other", institutename_otherArr);
    this.state.formData.append("grading_scheme", school_grading_schemeArr);
    this.state.formData.append("average_scheme", school_average_schemeArr);
    //this.state.formData.append("langinstruction", langinstructionArr);
    this.state.formData.append("attendedfrom", attendedfromArr);
    this.state.formData.append("attendedto", attendedtoArr);
    this.state.formData.append("awardeddegree", awardeddegreeArr);
    this.state.formData.append("awardedon", awardedonArr);
    this.state.formData.append("instemail", instemailArr);
    this.state.formData.append("instphone", instphoneArrArr);
    this.state.formData.append("insaddr", insaddrArr);
    this.state.formData.append("inscity", inscityArr);
    this.state.formData.append("instate", instateArr);
    this.state.formData.append("inszip", inszipArr);
    this.state.formData.append("uniAnsrMulti", multiCheckArr);
    this.state.formData.append("ansrTxt", multiTextArr);
    this.state.formData.append("uniAnsrRadio", radioQuesArr);
    this.state.formData.append("uni_id", localStorage.getItem("universityid"));
    this.state.formData.append("app_id", localStorage.getItem("app_id"));
    this.state.formData.append("app_student_id", localStorage.getItem("app_student_id"));
    this.state.formData.append("student_id", localStorage.getItem("studentid"));

    this.state.formData.append("application_id", this.props.applicationId);

    if (this.state.schoolsattended.length) {
      this.state.schoolsattended.forEach((val, index) => {
        for (let [key, value] of Object.entries(val)) {
          this.state.formData.append(`schoolsAttended[${index}][${key}]`, value)
        }
      })
    }

    this.state.appAnswers.forEach((val, index) => {
      this.state.formData.append(`answer[${index}][question_id]`, val.question_id)
      if (Array.isArray(val.answer)) {
        val.answer.forEach((option, optionIndex) => {
          this.state.formData.append(`answer[${index}][answer][${optionIndex}]`, option)
        })
      } else {
        this.state.formData.append(`answer[${index}][answer]`, val.answer)
      }
    })

    this.setState({ errors: "" });
    this.props.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        this.props.hideLoader();
        if (msg == "error") {
          return
        }
        this.props.getApplicationStepStatus()
        
        toast.success(msg.message);
        if (exit) {
          this.props.history.push('/draftapplication')
        }
        this.props.updateTabs()
        setTimeout(function () {
          $("#tab3").addClass("active");
          $("#tab2").removeClass("active");
          $(".educationtab").removeClass("active");
          $(".testscore").addClass("active");
        }, 2000);
      },
      "storestuedusummary_app"
    );
  };

  nexttab = () => {
    $("#tab3").addClass("active");
    $("#tab2").removeClass("active");
    $(".educationtab").removeClass("active");
    $(".testscore").addClass("active");
  };

  prevtab = () => {
    $("#tab1").addClass("active");
    $("#tab2").removeClass("active");
    $(".educationtab").removeClass("active");
    $(".generaltab").addClass("active");
  };

  render() {
    return template.call(this);
  }
}

export default AppEducation;
