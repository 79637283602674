import React, { Component, Suspense } from "react";
import { Link, withRouter } from "react-router-dom";
import bargraph from "../../assets/img/university/bar_graph.svg";
import contlogo from "../../assets/img/university/australia_circle_flag.svg";
import favourites from "../../assets/img/university/favourites.svg";
import eyeicon from "../../assets/img/university/view_simple.svg";
import ratingfive from "../../assets/img/university/rating-five.svg";
import rating from "../../assets/img/university/rating.svg";
import ratingthree from "../../assets/img/university/rating-three.svg";
import ratingtwo from "../../assets/img/university/rating-two.svg";
import ratingone from "../../assets/img/university/rating-one.svg";
import backarrow from "../../assets/img/university/back_arrow.svg";
import location from "../../assets/img/navi-icon.svg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import { MESSAGES } from "../../constant/messages";
import {
  AppHeader,
} from "@coreui/react";
import HeaderWithLogoUserNoti from "../../containers/DefaultLayout/HeaderWithLogoUserNoti";
import "./LeaveReview.scss"
import PageLoading from "../../common/PageLoading";

const baseurl = `${config.baseurl}`;

class LeaveReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      overall_star: 0,
      agentdetails: [],
      agentabout: [],
      review: "",
      likecount: "",
      ratings: "",
      rank: "",
      loading: false,
      selectedOption: '5'
    };
  }

  componentDidMount() {
    this.getGeneralData();
  }

  getGeneralData = () => {
    this.showLoader();
    const id = localStorage.getItem("agentid");
    const studentid = localStorage.getItem("studentid");
    const agentDetailsReq = axios.get(baseurl + "/get_agentreviewdetails/" + id + "/" + studentid);
    const agentStudentReviewsReq = axios.get(
      baseurl + "/get_agent_student_reviews_byagentid/" + id
    );
    const agentsDataReq = axios.get(
      baseurl + "/getAgentsData/" + localStorage.getItem("agent_id")
    );
    Promise.all([agentDetailsReq, agentStudentReviewsReq, agentsDataReq])
      .then(([agentDetailsRes, agentStudentReviewsRes, agentsDataRes]) => {
        this.setState({
          agentdetails: agentDetailsRes.data
            ? agentDetailsRes.data.agentdetails
            : this.state.agentdetails,
          agentabout: agentDetailsRes.data
            ? agentDetailsRes.data.agentabout
            : this.state.agentabout,
          ratings: agentDetailsRes.data
            ? agentDetailsRes.data.agentreview
            : this.state.ratings,

          selectedOption: agentDetailsRes.data
            ? agentDetailsRes.data.agentreview.tot_point_review
            : '5',

          review: agentDetailsRes.data
            ? agentDetailsRes.data.agentreview.review_message
            : this.state.review,

          items: agentStudentReviewsRes.data.data,
          likecount: agentsDataRes.data.fav_count,
          rank: agentsDataRes.data.rank,
        });
        this.hideLoader();
      })
      .catch((e) => {
        console.log('e: ', e);
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        this.hideLoader();
      });
  };

  handleLayoutChange = (e) => {
    let review = this.state.ratings
    review.no_of_ratings = e.target.value;
    this.setState({
      ratings: review,
      selectedOption: e.target.value,
    });
  };
  handleChangeOne = (e) => {
    this.setState({
      review: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({
      selectedOption: e.target.value
    })
  }

  handleSubmit = (e) => {
    const student_id = localStorage.getItem("studentid");
    const agent_id = localStorage.getItem("agentid");

    e.preventDefault();

    let products = {
      agent_id: agent_id,
      student_id: student_id,
      no_of_ratings: this.state.selectedOption,
      review_message: this.state.review,
    };
    if (this.state.ratings.id) {
      products.id = this.state.ratings.id
    }

    const uri = baseurl + "/store_agent_student_reviews";

    axios.post(uri, products).then((response) => {
      this.props.history.push("/agentcontracts");
      toast.success(MESSAGES.SAVED_SUCCESSFULLY);
    });
  };


  deleteReview = (e) => {

    e.preventDefault();

    let products = {
      id: this.state.ratings.id,
      category: "main"
    };

    const uri = baseurl + "/deletereviews_studentagent";

    axios.post(uri, products).then((response) => {
      this.props.history.push("/agentcontracts");
      toast.success(MESSAGES.SAVED_SUCCESSFULLY);
    });
  }

  showLoader = () => {
    this.setState({
      loading: true
    })
  }

  hideLoader = () => {
    this.setState({
      loading: false
    })
  }

  render() {
    const imgpath = `${config.baseurl}`;
    let overall_star = 0;

    this.state.items.forEach((item, index) => {
      overall_star = overall_star + item.no_of_ratings;
    });
    const overall_review = overall_star / this.state.items.length;
    return (
      <>
        <div className='app'>
          <ToastContainer />
          {
            this.state.loading ? (
              <PageLoading />
            ) : null
          }
          <AppHeader fixed className="header app-header navbar">
            <Suspense>
              <HeaderWithLogoUserNoti />
              {/* <DefaultHeader onLogout={(e) => this.signOut(e)} /> */}
            </Suspense>
          </AppHeader>
          <div className="animated fadeIn gray-bg-300 foe-viewus leaveReviewPage">
            <form className="was-validated" onSubmit={this.handleSubmit}>
              <div className="container-fluid">
                <div className="top-section">
                  <div className="container">
                    <div className='leave-review'>
                      <div className="row align-items-start">
                        <div className='col-md-2 pt-10'>
                          <a
                            className="backarrow"
                            href="/#/agentcontracts"
                          >
                            <span className="pr-1">
                              <img src={backarrow} alt="" />
                            </span>
                            Back
                          </a>
                        </div>
                        <div className="col-md-10 leaveReviewRight">
                          <div className="content-block partner-info">
                            {/* <div className="col-md-4 my-4">
                      <img
                        src={
                          imgpath +
                          "/Agent/" +
                          this.state.agentdetails.company_logo
                        }
                        alt=""
                      />{" "}
                    </div> */}
                            <div className="cont-rgt">
                              <h3>Leave review</h3>
                              {/* <h5 className="cont-rgt-head">Inter education consultants</h5> */}
                              <h5 className="cont-rgt-head">
                                {this.state.agentdetails.company_name}
                              </h5>
                              <div className="">
                                <span className="reviewLoc">
                                  <h4>  <img src={location} alt="" /> {this.state.agentdetails.street_address}, {this.state.agentdetails.city} {this.state.agentdetails.state} {this.state.agentdetails.country_name} </h4>
                                  {/* <h4>  <img src={location} alt="" />1221 Burwood Highway, Burwood VIC 3125 Australia</h4> */}
                                </span>

                                {/* <span className="pr-1">
                          <img src={contlogo} alt="" />
                        </span> */}
                              </div>
                              {/* <div className="mt-2 cont-rgt-grap">
                        <span className="pr-1">
                          <img src={bargraph} alt="" />
                        </span>
                        The World Rankings : {this.state.rank}
                      </div>
                      <div className="mt-2 row px-3 mobile-response mx-0">
                        <div className="icon-align pt-2 row">
                          <div className="pr-1">
                            <img src={eyeicon} alt="" />
                          </div>
                          <div>
                            0<br />
                            Views
                          </div>
                        </div>
                        <div className="icon-align pt-2 row">
                          <div className="pr-1">
                            <img src={favourites} alt="" />
                          </div>
                          <div>
                            {this.state.likecount}
                            <br />
                            Favourites
                          </div>
                        </div>
                        <div className="icon-align pt-2">
                          <div>
                            {overall_review.toFixed() === 5 ? (
                              <img src={ratingfive} alt="" />
                            ) : overall_review.toFixed() === 4 ? (
                              <img src={rating} alt="" />
                            ) : overall_review.toFixed() === 3 ? (
                              <img src={ratingthree} alt="" />
                            ) : overall_review.toFixed() === 2 ? (
                              <img src={ratingtwo} alt="" />
                            ) : (
                              <img src={ratingone} alt="" />
                            )}
                          </div>
                          <div>Reviews ({this.state.items.length})</div>
                        </div>
                      </div> */}
                            </div>
                          </div>
                          {/* <hr className="review-subactor" /> */}
                          <div className="">
                            <div className="leaveReviewAdd">
                              <div className="reviewTitle">
                                <label className="card-label">Your review</label>
                              </div>

                              <div className="reviewStar">
                                <div onChange={this.handleLayoutChange}>
                                  <div className="col-12 mb-3">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        onChange={this.handleChange}
                                        type="radio"
                                        value="5"
                                        className="custom-control-input"
                                        name="example1"
                                        checked={this.state.selectedOption === '5'}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      >
                                        <img src={ratingfive} alt="" />{" "}
                                      </label>{" "}
                                      5 Excellent
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        onChange={this.handleChange}
                                        type="radio"
                                        className="custom-control-input"
                                        value="4"
                                        name="example1"
                                        checked={this.state.selectedOption === '4'}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      >
                                        <img src={rating} alt="" />{" "}
                                      </label>{" "}
                                      4 Very good
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        onChange={this.handleChange}
                                        className="custom-control-input"
                                        value="3"
                                        name="example1"
                                        checked={this.state.selectedOption === '3'}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      >
                                        <img src={ratingthree} alt="" />{" "}
                                      </label>{" "}
                                      3 Average
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        onChange={this.handleChange}
                                        className="custom-control-input"
                                        value="2"
                                        name="example1"
                                        checked={this.state.selectedOption==="2"}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      >
                                        <img src={ratingtwo} alt="" />{" "}
                                      </label>{" "}
                                      2 Poor
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        onChange={this.handleChange}
                                        className="custom-control-input"
                                        value="1"
                                        name="example1"
                                        checked={this.state.selectedOption==="1"}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      >
                                        <img src={ratingone} alt="" />{" "}
                                      </label>{" "}
                                      1 Terrible
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="textareaSec">
                            <div className="">
                              <div className="">
                                <label className="card-label">Your review</label>
                                <textarea
                                  id="review"
                                  name="review"
                                  onChange={this.handleChangeOne}
                                  className="form-control w-100 checksame"
                                  placeholder="Write a review..."
                                  value={this.state.review}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <hr className="review-subactor" /> */}
                          <div className="text-center pb-4 reviewBtn">
                            {
                              this.state.ratings.id ?
                                <>
                                  <button type="submit" className="btn btn-outline-secondary">
                                    Update Review
                                  </button>
                                  <a href="javascript:void(0)" onClick={(e) => this.deleteReview(e)} className="mt-4 d-block">
                                    Delete My Review
                                  </a>
                                </>
                                :

                                <button type="submit" className="btn btn-outline-secondary">
                                  Send Review
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div >
                </div >
              </div >
            </form >
          </div >
        </div >
      </>
    );
  }
}

export default withRouter(LeaveReview);
