import { DELETE_DOCUMENTS, GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS, STORE_DOCUMENTS } from "../constants";

export function getDocumentsAction(session, resolve, reject) {
  return {
    type: GET_DOCUMENTS,
    payload: session,
    resolve,
    reject,
  };
}

export function setDocumentsAction(session, resolve, reject) {
  return {
    type: GET_DOCUMENTS_SUCCESS,
    payload: session,
    resolve,
    reject,
  };
}

export function storeDocumentsAction(session, resolve, reject) {
  return {
    type: STORE_DOCUMENTS,
    payload: session,
    resolve,
    reject,
  };
}

export function deleteDocumentsAction(session, resolve, reject) {
  return {
    type: DELETE_DOCUMENTS,
    payload: session,
    resolve,
    reject,
  };
}
