import React  from 'react';
import {HashLoader} from 'react-spinners'

function PageLoading() {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 999999
        }}><HashLoader color={'#13656c'} loading={true} /></div>
    );
}

export default PageLoading