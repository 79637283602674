import { put, takeLatest, call } from 'redux-saga/effects';
import {
	LOGIN,
	ROLE_PERMISSION
  } from "../constants";
import {
	baseUrl,
	LOGIN_API,
	ROLE_PERMISSION_API
} from "../../Api";
import axios from "axios";
// import axios from "../../Api/axios"

import {
	loginSuccess,
	loginError,
	getRolePermissionsSuccess,
	getRolePermissionsError
} from '../actions/auth';

function loginActionApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + LOGIN_API,
	  data: params
	});
}

function* loginAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(loginActionApi, payload);
		yield put(loginSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(loginError(e))
		if (reject) reject(e)
	}
}

export function* loginActionWatcher() {
	yield takeLatest(LOGIN, loginAction)
}

function getRolePermissionsActionApi(params) {
	console.log("asd : ",axios.defaults);
	// return axios.get(baseUrl + ROLE_PERMISSION_API,{
	// 	headers: authToken
	// });
	// axios.defaults.headers = {
	// 	Accept: "application/json",
	// 	Authorization: `bearer ${params.token}`,
	// }
	// //axios.defaults.headers.common['Authorization'] = `bearer ${params.token}`;
	// // axios.interceptors.request.use(
	// // 	config => {
	// // 	  config.headers.authorization = `bearer ${params.token}`;
	// // 	  return config;
	// // 	});
	return axios.request({
	  method: "get",
	  url: baseUrl + ROLE_PERMISSION_API
	});
}

function* getRolePermissionsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getRolePermissionsActionApi, payload);
		yield put(getRolePermissionsSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getRolePermissionsError(e))
		if (reject) reject(e)
	}
}

export function* getRolePermissionsActionWatcher() {
	yield takeLatest(ROLE_PERMISSION, getRolePermissionsAction)
}
