import React from "react"
import { toast } from "react-toastify"
import { Button } from "reactstrap"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"

class AddComment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      comment: ""
    }
  }

  sendComment = () => {
    if (!this.state.comment) {
      toast.error('Please enter comment!')
      return
    }
    let request = {
      comment: this.state.comment
    }
    if (this.props.commentId) {
      request.comment_id = this.props.commentId;
    }
    if (this.props.replyTo) {
      request.reply_to = this.props.replyTo
    }
    this.props.addComment(request).then(data => {
      if (data.success) {
        if (this.props.inEdit || this.props.replyTo) {
          this.props.onCancel()
        }
        this.setState({
          comment: ""
        })
      }
    })
  }

  componentDidMount() {
    this.setState({
      comment: this.props.comment
    })
  }

  render() {
    return (
      <li
        key={"newComment"}
        className={this.props.inEdit || this.props.replyTo ? "commentBoxIn" : 'commentBox'}
      >
        <textarea
          className="addComment"
          placeholder={"Add a comment.."}
          onChange={(e) => {
            this.setState({
              comment: e.target.value
            })
          }}
          value={this.state.comment}
        ></textarea>

        <div className="d-flex justify-space-between align-items-center">
          <div>
            {
              this.props.inEdit ? null : (
                <span
                  className={`avatar`}
                  style={{
                    backgroundColor: `${this.props.profile && this.props.profile.color_code
                      ? this.props.profile.color_code : "lightgray"}`
                  }}
                >
                  {
                    this.props.profile && this.props.profile ? (
                      this.props.profile.first_name.charAt(0).toUpperCase()
                    ) : ""
                  }
                </span>
              )
            }
          </div>

          <div>
            {
              this.props.inEdit || this.props.replyTo ? (
                <>
                  <Button
                    onClick={this.props.onCancel}
                    className="grayBtn"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={this.sendComment}
                    className="blueBtn"
                  >
                    {
                      this.props.inEdit ? 'Save' : 'Reply'
                    }
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  onClick={this.sendComment}
                  className="blueBtn"
                >
                  Send
                </Button>
              )
            }
          </div>
        </div>
      </li>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profileReducer.profile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddComment)
