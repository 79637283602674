import "./AppTestscore.css";
import React from "react";
import Addmore from "../Addmore/Addmore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../common/FormError";
import { Button, FormGroup, Input, Label } from "reactstrap";
import CompleteProfileMessage from "../AppUploadDocument/CompleteProfileMessage";
import CommentPopover from "../ApplicationAddComment/component/CommentPopover";
import ApplicationQa from "../commonComponents/ApplicationQa";

function template() {
  const testtab = () => {
    this.setState({ TestAddMore: false, selectedQuestion2: null });
  };

  const {
    examtype,
    toefl_reading_score,
    ilets_reading_score,
    duolingo_literacy_score,
    pte_reading_score,
    oet_reading_score,
    c_reading_score,
    toefl_listening_score,
    ilets_listening_score,
    duolingo_comprehension_score,
    pte_listening_score,
    oet_listening_score,
    c_listening_score,
    toefl_writing_score,
    ilets_writing_score,
    duolingo_conversation_score,
    pte_writing_score,
    oet_writing_score,
    c_writing_score,
    toefl_speaking_score,
    ilets_speaking_score,
    duolingo_production_score,
    pte_speaking_score,
    oet_speaking_score,
    c_speaking_score,
    oet_reading_grade,
    oet_listening_grade,
    oet_writing_grade,
    oet_speaking_grade,
    c_use_english,
    c_reading_grade,
    c_listening_grade,
    c_writing_grade,
    c_speaking_grade,
    c_use_english_grade,
    pte_oral_score,
    pte_oral_rank,
    pte_pron_score,
    pte_pron_rank,
    pte_spelling_score,
    pte_spelling_rank,
    pte_vocab_score,
    pte_vocab_rank,
    pte_grammer_score,
    pte_grammer_rank,
    pte_written_score,
    pte_written_rank,
    pte_listening_rank,
    pte_writing_rank,
    pte_speaking_rank,
    pte_reading_rank,
    toefl_exam_date,
    ilets_exam_date,
    duolingo_exam_date,
    pte_exam_date,
    oet_exam_date,
    c_exam_date,
    examdate,
    ilets_total_score,
    duolingo_total_score,
    toefl_total_score,
    pte_total_score,
  } = this.state;

  return (
    <div className="tab-wrap apptestscore newBg">
      {/* <CompleteProfileMessage
        {...this.props}
        show={this.state.showCompleteProfileMessage}
        toggleShowHide={() => {
          this.setState({
            showCompleteProfileMessage: false
          })
        }}
      /> */}

      <div className="row">
        <div className="col-12 per-box pb-4">
          <div className="personal-box-inner address score-grt score-mark-box aQ-box box">
            <div className="row activeTitle">
              <div className="col-8">
                <h3>English Exam</h3>
              </div>

              <div className="col-4 text-right right-btn-align">
                {/* {this.props.showComment ? null : (
                  this.state.edit ? (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={this.onSubmit.bind(this)}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="app-personal-savebtn"
                      type="submit"
                      onClick={() => {
                        this.setState({
                          edit: true,
                        });
                      }}
                    >
                      EDIT
                    </Button>
                  )
                )} */}
              </div>
            </div>
            {this.state.edit || this.props.showComment ? null : (
              <div className="preview"></div>
            )}
            <div
              style={{
                display: this.state.TestAddMore === false ? "block" : "none",
              }}
            >
              <div className="destination-box">
                {/*<div className="col-md-2">
                  <div className="col-12 d-flex">
                    {this.state.disabled === false ? (
                      <Button
                        color="primary"
                        className="app-personal-savebtn "
                        type="submit"
                        onClick={this.onSubmit.bind(this)}
                      >
                        SAVE
                      </Button>
                    ) : (
                      <div className="col-12 d-flex justify-content-end">
                        <Button
                          color="primary"
                          className="score-back mx-4 aftersave"
                          onClick={this.prevTab}
                        >
                          BACK
                        </Button>
                        <Button
                          color="primary"
                          className="app-personal-savebtn aftersave"
                          onClick={this.nextTab}
                        >
                          NEXT
                        </Button>
                      </div>
                    )}
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover
                        {...this.props}
                        id={"eng_exam_type"}
                        key="eng_exam_type"
                      />
                    ) : null}
                    <FormGroup>
                      <Label className="uni-label">English Exam Type</Label>
                      <Input
                        // disabled={this.state.disabled}
                        type="select"
                        id="examtype"
                        name="examtype"
                        onChange={this.handleTargetChange}
                        value={this.state.examtype ? this.state.examtype : ""}
                        className=""
                      >
                        <option value="">I don’t have this</option>
                        <option value="toefl">TOEFL</option>
                        <option value="ielts">IELTS</option>
                        <option value="duolingo">Duolingo</option>
                        <option value="pte">PTE</option>
                        <option value="oet">OET</option>
                        <option value="c1 Advanced">C1 Advanced</option>
                      </Input>
                    </FormGroup>
                    {this.state.errors.examtype && (
                      <FormError error={this.state.errors.examtype} />
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.props.showComment ? (
                      <CommentPopover
                        {...this.props}
                        id={"eng_exam_date"}
                        key="eng_exam_date"
                      />
                    ) : null}
                    <FormGroup>
                      <Label className="uni-label"> Exam Date</Label>
                      <DatePicker
                        // disabled={this.state.disabled}
                        selected={this.state.examdate}
                        onChange={(e) =>
                          this.handleExamdateChanges(e, "examdate")
                        }
                        dateFormat="yyyy-MM-dd"
                        className=""
                        id="examdate"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="YYYY-MMM-DD"
                      />
                    </FormGroup>
                    {this.state.errors.examdate && (
                      <FormError error={this.state.errors.examdate} />
                    )}
                  </div>
                </div>
              </div>

              {/* For IELTS and TOEFL Exams */}
              {(examtype === "ielts" || examtype === "duolingo" || examtype === "toefl") ? (
                <div className="scroe-box ielts-toef-scror-box">
                  {/* Total Score */}
                  <div className="pr-header">
                    <h3>Input Exact Scores</h3>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-bl">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"overall_score"}
                          key="overall_score"
                        />
                      ) : null}
                      <FormGroup className="mb-0 tscolumn">
                        <Label className="uni-label">Total Score</Label>
                        <Input
                          type="number"
                          className="form-control total-score"
                          id={
                            examtype === "ielts"
                              ? "ilets_total_score"
                              : examtype === "duolingo"
                                ? "duolingo_total_score"
                                : "toefl_total_score"
                          }
                          value={
                            examtype === "ielts"
                              ? ilets_total_score
                              : examtype === "duolingo"
                                ? duolingo_total_score
                                : toefl_total_score
                          }
                          placeholder={examtype === "toefl" ? "" : ""}
                          onInput={(e) => {
                            if (e.target.value) {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }
                          }}
                          disabled
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row uni-no-mar">
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"reading_scores"}
                          key="reading_scores"
                        />
                      ) : null}
                      <FormGroup className="mb-0">
                        <Label className="uni-label">{examtype === "duolingo" ? "Literacy" : "Reading"} Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          onChange={this.handleReadingScoreChange}
                          id={
                            examtype === "ielts"
                              ? "ilets_reading_score"
                              :
                              examtype === "duolingo"
                                ? "duolingo_literacy_score"
                                : "toefl_reading_score"
                          }
                          value={
                            examtype === "ielts"
                              ? ilets_reading_score
                              :
                              examtype === "duolingo"
                                ? duolingo_literacy_score
                                : toefl_reading_score
                          }
                          placeholder="Enter Scores"
                          min={examtype === "duolingo" ? "10" : "00"}
                          max={examtype === "duolingo" ? "160" : examtype === "ielts" ? "9" : "30"}
                          step={examtype === "duolingo" ? "1" : examtype === "ielts" ? "0.5" : ""}
                          onInput={(e) => {
                            if (e.target.value) {
                              e.target.value =
                                examtype === "ielts"
                                  ? Math.round(e.target.value / 0.5) * 0.5
                                  : examtype === "duolingo"
                                    ? Math.round(e.target.value / 1) * 1
                                    : Math.round(e.target.value);
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }
                            // e.target.value = (e.target.value%1)>0.5 ? parseInt(e.target.value)+1 : parseInt(e.target.value)+0.5;
                          }}
                        />
                      </FormGroup>
                      <h6 style={{ color: "red" }}>
                        {this.state.errors && this.state.errors.readingscore
                          ? this.state.errors.readingscore
                          : ""}
                      </h6>
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br no-br-l">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"listening_score"}
                          key="listening_score"
                        />
                      ) : null}
                      <FormGroup className="mb-0">
                        <Label className="uni-label">{examtype === "duolingo" ? "Comprehension" : "Listening"} Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          onChange={this.handleListeningScoreChange}
                          id={
                            examtype === "ielts"
                              ? "ilets_listening_score"
                              : examtype === "duolingo"
                                ? "duolingo_comprehension_score"
                                : "toefl_listening_score"
                          }
                          value={
                            examtype === "ielts"
                              ? ilets_listening_score
                              : examtype === "duolingo"
                                ? duolingo_comprehension_score
                                : toefl_listening_score
                          }
                          placeholder="Enter Scores"
                          min={examtype === "duolingo" ? "10" : "00"}
                          max={examtype === "duolingo" ? "160" : examtype === "ielts" ? "9" : "30"}
                          step={examtype === "duolingo" ? "1" : examtype === "ielts" ? "0.5" : ""}
                          onInput={(e) => {
                            if (e.target.value) {
                              e.target.value =
                                examtype === "ielts"
                                  ? Math.round(e.target.value / 0.5) * 0.5
                                  : Math.round(e.target.value);
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }
                          }}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.listeningscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.listeningscore}
                        </h6>
                      ) : null}
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br no-br-l">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"writing_score"}
                          key="writing_score"
                        />
                      ) : null}
                      <FormGroup className="mb-0">
                        <Label className="uni-label">{examtype === "duolingo" ? "Conversation" : "Writing"} Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          onChange={this.handleWritingScoreChange}
                          id={
                            examtype === "ielts"
                              ? "ilets_writing_score"

                              : examtype === "duolingo"
                                ? "duolingo_conversation_score"
                                : "toefl_writing_score"
                          }
                          value={
                            examtype === "ielts"
                              ? ilets_writing_score

                              : examtype === "duolingo"
                                ? duolingo_conversation_score
                                : toefl_writing_score
                          }
                          placeholder="Enter Scores"
                          min={examtype === "duolingo" ? "10" : "00"}
                          max={examtype === "duolingo" ? "160" : examtype === "ielts" ? "9" : "30"}
                          step={examtype === "duolingo" ? "1" : examtype === "ielts" ? "0.5" : ""}
                          onInput={(e) => {
                            if (e.target.value) {
                              e.target.value =
                                examtype === "ielts"
                                  ? Math.round(e.target.value / 0.5) * 0.5
                                  : examtype === "ielts"
                                    ? Math.round(e.target.value / 0.5) * 0.5
                                    : Math.round(e.target.value);
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }
                          }}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.writingscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.writingscore}
                        </h6>
                      ) : null}
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-bl">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"speaking_score"}
                          key="speaking_score"
                        />
                      ) : null}
                      <FormGroup className="mb-0">
                        <Label className="uni-label">{examtype === "duolingo" ? "Production" : "Speaking"} Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          onChange={this.handleSpeakingScoreChange}
                          id={
                            examtype === "ielts"
                              ? "ilets_speaking_score"
                              : examtype === "duolingo"
                                ? "duolingo_production_score"
                                : "toefl_speaking_score"
                          }
                          value={
                            examtype === "ielts"
                              ? ilets_speaking_score
                              : examtype === "duolingo"
                                ? duolingo_production_score
                                : toefl_speaking_score
                          }
                          placeholder="Enter Scores"
                          min={examtype === "duolingo" ? "10" : "00"}
                          max={examtype === "duolingo" ? "160" : examtype === "ielts" ? "9" : "30"}
                          step={examtype === "duolingo" ? "1" : examtype === "ielts" ? "0.5" : ""}
                          onInput={(e) => {
                            if (e.target.value) {
                              e.target.value =
                                examtype === "ielts"
                                  ? Math.round(e.target.value / 0.5) * 0.5
                                  : examtype === "ielts"
                                    ? Math.round(e.target.value / 0.5) * 0.5
                                    : Math.round(e.target.value);
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }
                          }}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.speakingscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.speakingscore}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  {this.state.errors && this.state.errors.englishCheck ? (
                    <h6 style={{ color: "red" }}>
                      {this.state.errors.englishCheck}
                    </h6>
                  ) : null}
                </div>
              ) : null}

              {/* For OET and C1 Advance */}
              {examtype === "oet" || examtype === "c1 Advanced" ? (
                <div className="scroe-box oet-c1-score-box">
                  {/* Total Score */}
                  {/* {examtype === "c1 Advanced"  ?
                <div className="pr-header">
                  <h3>Input Exact Scores</h3>
                  <div className="col-md-3 uni-no-padd no-bl">
                    <FormGroup className="mb-0">
                      <Label className="uni-label">Total Score</Label>
                      <Input type="number" className="form-control total-score" disabled
                        id="averagescore" value={this.state.c_avg_score}
                        placeholder={"Enter A Test Score Between 142 and 210"}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 6)
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
                :
                null
              } */}

                  <div className="pr-header">
                    <h3>Input Exact Scores</h3>
                  </div>

                  <div className="row uni-no-mar">
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br">
                      {this.props.showComment ? (
                        <CommentPopover
                          {...this.props}
                          id={"speaking_score"}
                          key="speaking_score"
                        />
                      ) : null}
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Reading Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_reading_score"
                              : "c_reading_score"
                          }
                          value={
                            examtype === "oet"
                              ? oet_reading_score
                              : c_reading_score
                          }
                          onChange={this.handleReadingScoreChange}
                          placeholder="Enter Scores"
                          min="0"
                          max={examtype === "oet" ? "500" : "210"}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                            // e.target.value = (e.target.value%1)>0.5 ? parseInt(e.target.value)+1 : parseInt(e.target.value)+0.5;
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="text"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_reading_grade"
                              : "c_reading_grade"
                          }
                          value={
                            examtype === "oet"
                              ? oet_reading_grade
                              : c_reading_grade
                          }
                          placeholder={"Grade"}
                          readOnly
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.oet_reading_score ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.oet_reading_score}
                          </h6>
                        </>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.c_reading_score ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.c_reading_score}
                          </h6>
                        </>
                      ) : null}
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br no-br-l">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Listening Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_listening_score"
                              : "c_listening_score"
                          }
                          value={
                            examtype === "oet"
                              ? oet_listening_score
                              : c_listening_score
                          }
                          onChange={this.handleListeningScoreChange}
                          placeholder="Enter Scores"
                          min="0"
                          max={examtype === "oet" ? "500" : "210"}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="text"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_listening_grade"
                              : "c_listening_grade"
                          }
                          value={
                            examtype === "oet"
                              ? oet_listening_grade
                              : c_listening_grade
                          }
                          placeholder={"Grade"}
                          readOnly
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.oet_listening_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.oet_listening_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.c_listening_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.c_listening_score}
                        </h6>
                      ) : null}
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-br no-br-l">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Writing Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_writing_score"
                              : "c_writing_score"
                          }
                          value={
                            examtype === "oet"
                              ? oet_writing_score
                              : c_writing_score
                          }
                          onChange={this.handleWritingScoreChange}
                          placeholder="Enter Scores"
                          min="0"
                          max={examtype === "oet" ? "500" : "210"}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="text"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_writing_grade"
                              : "c_writing_grade"
                          }
                          value={
                            examtype === "oet"
                              ? oet_writing_grade
                              : c_writing_grade
                          }
                          placeholder={"Grade"}
                          readOnly
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.oet_writing_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.oet_writing_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.c_writing_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.c_writing_score}
                        </h6>
                      ) : null}
                    </div>
                    <div className="mt-2 col-6 col-md-3 uni-no-padd no-bl">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Speaking Scores</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_speaking_score"
                              : "c_speaking_score"
                          }
                          value={
                            examtype === "oet"
                              ? oet_speaking_score
                              : c_speaking_score
                          }
                          onChange={this.handleSpeakingScoreChange}
                          placeholder="Enter Scores"
                          min="0"
                          max={examtype === "oet" ? "500" : "210"}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="text"
                          className="form-control "
                          id={
                            examtype === "oet"
                              ? "oet_speaking_grade"
                              : "c_speaking_grade"
                          }
                          value={
                            examtype === "oet"
                              ? oet_speaking_grade
                              : c_speaking_grade
                          }
                          placeholder={"Grade"}
                          readOnly
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.oet_speaking_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.oet_speaking_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.c_speaking_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.c_speaking_score}
                        </h6>
                      ) : null}
                    </div>
                    {examtype === "c1 Advanced" ? (
                      <div className="mt-2 col-6 col-md-3 uni-no-padd no-bl useof-english">
                        <FormGroup className="mb-0">
                          <Label className="uni-label">Use of English</Label>
                          <Input
                            type="number"
                            className="form-control "
                            placeholder="Enter Scores"
                            id="useEnglishcore"
                            onChange={this.handleUseOfEngChange}
                            value={c_use_english}
                            min="0"
                            max="210"
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <Input
                            type="text"
                            className="form-control "
                            id="c_use_english_grade"
                            value={c_use_english_grade}
                            placeholder={"Grade"}
                            readOnly
                          />
                        </FormGroup>
                        {this.state.errors &&
                          this.state.errors.useEnglishcore ? (
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.useEnglishcore}
                          </h6>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {this.state.errors && this.state.errors.englishCheck ? (
                    <h6 style={{ color: "red" }}>
                      {this.state.errors.englishCheck}
                    </h6>
                  ) : null}
                </div>
              ) : null}

              {/* PTE Exam start */}
              {examtype === "pte" ? (
                <div className="scroe-box pte-score-box col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                  <div className="pr-header">
                    <h3>Input Exact Scores</h3>
                    <div className="col-6 col-md-6 uni-no-padd mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Total Score</Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="pte_total_score"
                          value={pte_total_score}
                          min="10"
                          max="90"
                          onChange={(e) =>
                            this.setState(
                              { pte_total_score: e.target.value },
                              () => {
                                let errors = this.validate();
                                this.setState({ errors });
                              }
                            )
                          }
                          placeholder={"Total score 10-90"}
                          onInput={(e) => {
                            // e.target.value = e.target.value < 10 ? 10 : e.target.value > 90 ? 90 : e.target.value;
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_total_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_total_score}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  <div className="row uni-no-mar">
                    <div className="col-6 col-md-3 uni-no-padd no-br mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Listening</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_listening_score"
                          onChange={this.handleListeningScoreChange}
                          value={pte_listening_score}
                          placeholder="Enter Scores"
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                            // e.target.value = (e.target.value%1)>0.5 ? parseInt(e.target.value)+1 : parseInt(e.target.value)+0.5;
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_listening_rank"
                          value={pte_listening_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({
                              pte_listening_rank: e.target.value,
                            })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_listening_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_listening_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_listening_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_listening_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-br no-br-l mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Reading</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_reading_score"
                          onChange={this.handleReadingScoreChange}
                          value={pte_reading_score}
                          placeholder="Enter Scores"
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_reading_rank"
                          value={pte_reading_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_reading_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_reading_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_reading_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_reading_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_reading_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-br no-br-l mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Speaking</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_speaking_score"
                          onChange={this.handleSpeakingScoreChange}
                          value={pte_speaking_score}
                          placeholder="Enter Scores"
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_speaking_rank"
                          value={pte_speaking_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_speaking_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_speaking_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_speaking_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_speaking_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_speaking_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-bl mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Writing</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_writing_score"
                          onChange={this.handleWritingScoreChange}
                          value={pte_writing_score}
                          placeholder="Enter Scores"
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_writing_rank"
                          value={pte_writing_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_writing_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_writing_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_writing_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_writing_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_writing_rank}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  <div className="row uni-no-mar">
                    <div className="col-6 col-md-3 uni-no-padd no-br mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Grammar</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_grammer_score"
                          onChange={(e) =>
                            this.setState({ pte_grammer_score: e.target.value })
                          }
                          value={pte_grammer_score}
                          placeholder="Enter Scores"
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                            // e.target.value = (e.target.value%1)>0.5 ? parseInt(e.target.value)+1 : parseInt(e.target.value)+0.5;
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_grammer_rank"
                          value={pte_grammer_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_grammer_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_grammer_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_grammer_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_grammer_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_grammer_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-br no-br-l mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Oral Fluency</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_oral_score"
                          value={pte_oral_score}
                          placeholder="Enter Scores"
                          onChange={(e) =>
                            this.setState({ pte_oral_score: e.target.value })
                          }
                          min="10"
                          max="90"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_oral_rank"
                          value={pte_oral_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_oral_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.pte_oral_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_oral_score}
                        </h6>
                      ) : null}
                      {this.state.errors && this.state.errors.pte_oral_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_oral_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-br no-br-l mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Pronunciation</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_pron_score"
                          min="10"
                          max="90"
                          value={pte_pron_score}
                          placeholder="Enter Scores"
                          onChange={(e) =>
                            this.setState({ pte_pron_score: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_pron_rank"
                          value={pte_pron_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_pron_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.pte_pron_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_pron_score}
                        </h6>
                      ) : null}
                      {this.state.errors && this.state.errors.pte_pron_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_pron_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-bl mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Spelling</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_spelling_score"
                          min="10"
                          max="90"
                          value={pte_spelling_score}
                          placeholder="Enter Scores"
                          onChange={(e) =>
                            this.setState({
                              pte_spelling_score: e.target.value,
                            })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_spelling_rank"
                          value={pte_spelling_rank}
                          min="0"
                          max="99"
                          onChange={(e) =>
                            this.setState({ pte_spelling_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                          placeholder={"Rank 0-99"}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_spelling_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_spelling_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_spelling_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_spelling_rank}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  <div className="row uni-no-mar">
                    <div className="col-6 col-md-3 uni-no-padd no-br mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Vocabulary</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_vocab_score"
                          min="10"
                          max="90"
                          placeholder="Score 10-99"
                          value={pte_vocab_score}
                          onChange={(e) =>
                            this.setState({ pte_vocab_score: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_vocab_rank"
                          min="0"
                          max="99"
                          placeholder="Rank 0-99"
                          value={pte_vocab_rank}
                          onChange={(e) =>
                            this.setState({ pte_vocab_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_vocab_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_vocab_score}
                        </h6>
                      ) : null}
                      {this.state.errors && this.state.errors.pte_vocab_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_vocab_rank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-6 col-md-3 uni-no-padd no-bl mt-2">
                      <FormGroup className="mb-0">
                        <Label className="uni-label">Written Discourse</Label>
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_written_score"
                          min="10"
                          max="90"
                          placeholder="Score 10-99"
                          value={pte_written_score}
                          onChange={(e) =>
                            this.setState({ pte_written_score: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Input
                          type="number"
                          className="form-control "
                          id="pte_written_rank"
                          min="0"
                          max="99"
                          placeholder="Rank 0-99"
                          value={pte_written_rank}
                          onChange={(e) =>
                            this.setState({ pte_written_rank: e.target.value })
                          }
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                      </FormGroup>
                      {this.state.errors &&
                        this.state.errors.pte_written_score ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_written_score}
                        </h6>
                      ) : null}
                      {this.state.errors &&
                        this.state.errors.pte_written_rank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.pte_written_rank}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  {/* </div> */}
                </div>
              ) : null}
              {/* PTE Exam end */}
            </div>
          </div>

          {/* // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* Additional Exams Section */}
          {/* GRE Exam */}
          <div
            className="personal-box-inner address score-grt score-mark-box aQ-box col-12 box"
            style={{ borderBottom: "none", paddingBottom: "0" }}
          >
            <div className="pr-header mt-4">
              <h3 className="add_qua">Additional Qualifications</h3>
              <div className="personal-box-inner address score-grt">
                <div className="pr-header ">
                  <div className="toggle_head_sec d-flex align-items-center justify-content-between">
                    <h5>GRE exam scores</h5>
                    <label className="switch togle_switch">
                      <input
                        type="checkbox"
                        className=""
                        value="1"
                        id="greCheck"
                        onChange={this.handleGrecheckChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {this.state.greCheck ? (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="uni-label">GRE Exam Date</Label>
                      <DatePicker
                        selected={this.state.greexamdate}
                        onChange={this.handleGreexamdateChange}
                        dateFormat="yyyy-MM-dd"
                        className=""
                        id="greexamdate"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="YYYY-MMM-DD"
                        maxDate={new Date()}
                      />
                    </FormGroup>
                    {this.state.errors && this.state.errors.greexamdate ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.greexamdate}
                      </h6>
                    ) : null}
                  </div>
                </div>
                <div className="row qual_spacing">
                  <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-r-0">
                    <Label className="uni-label">Verbal</Label>
                    <div className="score-list flex-column  b-t-r-r b-b-r-r b-r-0">
                      <div className="score-name border-gray">
                        <div className="scor-1"> Score </div>
                        <div className="score-in ">
                          <Input
                            type="number"
                            min="130"
                            max="170"
                            className="form-control "
                            value={this.state.greverbalscore}
                            id="greverbalscore"
                            onChange={this.handleGreverbalscoreChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                      <div className="score-name">
                        <div className="scor-1"> Rank % </div>
                        <div className="score-in ">
                          <Input
                            type="number"
                            min="0"
                            max="99"
                            className="form-control "
                            value={this.state.greverbalrank}
                            id="greverbalrank"
                            onChange={this.handleGreverbalrankChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.errors && this.state.errors.greverbalscore ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.greverbalscore}
                      </h6>
                    ) : null}
                    <br />
                    {this.state.errors && this.state.errors.greverbalrank ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.greverbalrank}
                      </h6>
                    ) : null}
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-l-0 p-r-0">
                    <Label className="uni-label">Quantitative</Label>
                    <div className="score-list flex-column b-b-l-r b-b-r-r b-t-r-r b-t-l-r b-r-0">
                      <div className="score-name border-gray">
                        <div className="scor-1"> Score </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="130"
                            max="170"
                            className="form-control "
                            value={this.state.grequanitativescore}
                            id="grequanitativescore"
                            onChange={this.handleGrequanitativescoreChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                      <div className="score-name">
                        <div className="scor-1"> Rank % </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="0"
                            max="99"
                            className="form-control "
                            value={this.state.grequanitativerank}
                            id="grequanitativerank"
                            onChange={this.handleGrequanitativerankChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.errors &&
                      this.state.errors.grequanitativescore ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.grequanitativescore}
                      </h6>
                    ) : null}
                    <br />
                    {this.state.errors &&
                      this.state.errors.grequanitativerank ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.grequanitativerank}
                      </h6>
                    ) : null}
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-l-0 p-r-0">
                    <Label className="uni-label">Analytical Writing</Label>
                    <div className="score-list flex-column b-b-l-r b-b-r-r b-t-r-r b-t-l-r b-r-0">
                      <div className="score-name border-gray">
                        <div className="scor-1"> Score </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="0"
                            max="6"
                            step="0.5"
                            className="form-control "
                            value={this.state.grewritingscore}
                            id="grewritingscore"
                            onChange={this.handleGrewritingscoreChange}
                            onInput={(e) => {
                              e.target.value =
                                Math.round(e.target.value / 0.5) * 0.5;
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                      <div className="score-name">
                        <div className="scor-1"> Rank % </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="0"
                            max="99"
                            className="form-control "
                            value={this.state.grewritingrank}
                            id="grewritingrank"
                            onChange={this.handleGrewritingrankChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.errors && this.state.errors.grewritingscore ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.grewritingscore}
                      </h6>
                    ) : null}
                    <br />
                    {this.state.errors && this.state.errors.grewritingrank ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.grewritingrank}
                      </h6>
                    ) : null}
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-l-0">
                    <Label className="uni-label">Total</Label>
                    <div className="score-list flex-column b-t-l-r b-b-l-r">
                      <div className="score-name border-gray">
                        <div className="scor-1"> Score </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="260"
                            max="340"
                            className="form-control "
                            value={this.state.gretotalscore}
                            id="gretotalscore"
                            onChange={this.handleGretotalscoreChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                      <div className="score-name">
                        <div className="scor-1"> Rank % </div>
                        <div className="score-in">
                          <Input
                            type="number"
                            min="0"
                            max="99"
                            className="form-control "
                            value={this.state.gretotalrank}
                            id="gretotalrank"
                            onChange={this.handleGretotalrankChange}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseFloat(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.errors && this.state.errors.gretotalscore ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.gretotalscore}
                      </h6>
                    ) : null}
                    <br />
                    {this.state.errors && this.state.errors.gretotalrank ? (
                      <h6 style={{ color: "red" }}>
                        {this.state.errors.gretotalrank}
                      </h6>
                    ) : null}
                  </div>
                </div>

                {/* <div className="form-check mb-3">
                  <input type="checkbox" className="form-check-input disablepro" value="1" id="greCheck" onChange={this.handleGrecheckChange} />
                  <label className="form-check-label">I don’t have GRE exam scores</label>
                </div>
                <h6 style={{ color: 'red' }}>{this.state.errors.greCheck}</h6> */}
              </div>
            ) : (
              ""
            )}

            {/* GMAT Exam */}
            <div className="personal-box-inner address score-grt">
              <div className="pr-header ">
                <div className="toggle_head_sec d-flex align-items-center justify-content-between">
                  <h5>GMAT exam scores</h5>
                  <label className="switch togle_switch">
                    <input
                      type="checkbox"
                      className=""
                      value="1"
                      id="gmatCheck"
                      onChange={this.handleGmatCheckChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {this.state.gmatCheck ? (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="uni-label">GMAT Exam Date</Label>
                        <DatePicker
                          selected={this.state.gmatexamdate}
                          onChange={this.handleGmatexamdateChange}
                          dateFormat="yyyy-MM-dd"
                          className=""
                          id="gmatexamdate"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="YYYY-MMM-DD"
                          maxDate={new Date()}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.gmatexamdate ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatexamdate}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  <div className="row qual_spacing">
                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-r-0 ">
                      <Label className="uni-label">Verbal</Label>
                      <div className="score-list flex-column b-b-r-r b-t-r-r b-r-0">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="51"
                              className="form-control "
                              value={this.state.gmatverbalscore}
                              id="gmatverbalscore"
                              onChange={this.handleGmatverbalscoreChange}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.gmatverbalrank}
                              id="gmatverbalrank"
                              onChange={this.handleGmatverbalrankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.gmatverbalscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatverbalscore}
                        </h6>
                      ) : null}
                      <br />
                      {this.state.errors && this.state.errors.gmatverbalrank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatverbalrank}
                        </h6>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6  p-r-0 p-l-0">
                      <Label className="uni-label">Quantitative</Label>
                      <div className="score-list flex-column b-b-l-r b-b-r-r b-t-r-r b-t-l-r b-r-0">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="51"
                              className="form-control "
                              value={this.state.gmatquanitativescore}
                              id="gmatquanitativescore"
                              onChange={this.handleGmatquanitativescoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.gmatquanitativerank}
                              id="gmatquanitativerank"
                              onChange={this.handleGmatquanitativerankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.gmatquanitativescore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatquanitativescore}
                        </h6>
                      ) : null}
                      <br />
                      {this.state.errors &&
                        this.state.errors.gmatquanitativerank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatquanitativerank}
                        </h6>
                      ) : null}
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6  p-r-0 p-l-0">
                      <Label className="uni-label">Integrated Reasoning</Label>
                      <div className="score-list flex-column b-b-l-r b-b-r-r b-t-r-r b-t-l-r b-r-0">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="1"
                              max="8"
                              className="form-control "
                              value={this.state.gmatreasoningscore}
                              id="gmatreasoningscore"
                              onChange={this.handleGmatreasoningscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.gmatreasoningrank}
                              id="gmatreasoningrank"
                              onChange={this.handleGmatreasoningrankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.gmatreasoningscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatreasoningscore}
                        </h6>
                      ) : null}
                      <br />
                      {this.state.errors &&
                        this.state.errors.gmatreasoningrank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatreasoningrank}
                        </h6>
                      ) : null}
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6  p-l-0">
                      <Label className="uni-label">Writing</Label>
                      <div className="score-list flex-column b-b-l-r b-b-r-r b-t-r-r b-t-l-r">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="6"
                              className="form-control "
                              value={this.state.gmatwritingscore}
                              id="gmatwritingscore"
                              onChange={this.handleGmatwritingscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.gmatwritingrank}
                              id="gmatwritingrank"
                              onChange={this.handleGmatwritingrankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.gmatwritingscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatwritingscore}
                        </h6>
                      ) : null}
                      <br />
                      {this.state.errors &&
                        this.state.errors.gmatwritingrank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmatwritingrank}
                        </h6>
                      ) : null}
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-r-0">
                      <Label className="uni-label">Total</Label>
                      <div className="score-list flex-column">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="200"
                              max="800"
                              className="form-control "
                              value={this.state.gmattotalscore}
                              id="gmattotalscore"
                              onChange={this.handleGmattotalscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.gmattotalrank}
                              id="gmattotalrank"
                              onChange={this.handleGmattotalrankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors && this.state.errors.gmattotalscore ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmattotalscore}
                        </h6>
                      ) : null}
                      <br />
                      {this.state.errors && this.state.errors.gmattotalrank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.gmattotalrank}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              {/* <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input disablepro" value="1"
                id="gmatCheck" onChange={this.handleGmatCheckChange}
              />
              <label className="form-check-label">I don’t have GMAT exam scores</label>
            </div>
            <h6 style={{ color: 'red' }}>{this.state.errors.gmatCheck}</h6> */}
            </div>

            {/* SAT Exam */}
            {/* New Qualification Start */}
            <div className="personal-box-inner address score-grt">
              <div className="pr-header ">
                <div className="toggle_head_sec d-flex align-items-center justify-content-between">
                  <h5>SAT exam scores</h5>
                  <label className="switch togle_switch">
                    <input
                      type="checkbox"
                      className=""
                      value="1"
                      id="haveSatScore"
                      name="haveSatScore"
                      onChange={this.handleSatScoreAvail}
                      checked={this.state.haveSatScore}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {this.state.haveSatScore ? (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="uni-label">SAT Exam Date</Label>
                        <DatePicker
                          selected={this.state.satExamDate}
                          onChange={(date) =>
                            this.setState({ satExamDate: date })
                          }
                          dateFormat="yyyy-MM-dd"
                          className=""
                          id="satExamDate"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="YYYY-MMM-DD"
                          maxDate={new Date()}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.satExamDate ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.satExamDate}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="row qual_spacing">
                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-r-0">
                      <Label className="uni-label">Reading/Writing</Label>
                      <div className="score-list flex-column  b-b-r-r b-t-r-r b-r-0">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="200"
                              max="800"
                              className="form-control "
                              value={this.state.satWritingScore}
                              id="satWritingScore"
                              onChange={this.handleSATScoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.satWritingRank}
                              id="satWritingRank"
                              onChange={this.handleSATScoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.satWritingScore ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.satWritingScore}
                          </h6>
                          <br />
                        </>
                      ) : null}
                      {this.state.errors && this.state.errors.satWritingRank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.satWritingRank}
                        </h6>
                      ) : null}
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-l-0 p-r-0">
                      <Label className="uni-label">Maths</Label>
                      <div className="score-list flex-column b-b-r-r b-t-r-r b-r-0 b-b-l-r b-t-l-r">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="200"
                              max="800"
                              className="form-control "
                              value={this.state.satQuantScore}
                              id="satQuantScore"
                              onChange={this.handleSATScoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.satQuantRank}
                              id="satQuantRank"
                              onChange={this.handleSATScoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors && this.state.errors.satQuantScore ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.satQuantScore}
                          </h6>
                          <br />
                        </>
                      ) : null}
                      {this.state.errors && this.state.errors.satQuantRank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.satQuantRank}
                        </h6>
                      ) : null}
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-l-0">
                      <Label className="uni-label">Total Score</Label>
                      <div className="score-list flex-column b-b-l-r b-t-l-r">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              readOnly
                              min="400"
                              max="1600"
                              className="form-control"
                              value={this.state.satAverageScore}
                              id="satAverageScore"
                              // onChange={this.handleGmattotalscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control"
                              value={this.state.satAverageRank}
                              id="satAverageRank"
                              onChange={this.handleSattotalRankChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors &&
                        this.state.errors.satAverageScore ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.satAverageScore}
                          </h6>
                          <br />
                        </>
                      ) : null}

                      {this.state.errors && this.state.errors.satAverageRank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.satAverageRank}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {/* LSAT Exam */}
            <div className="personal-box-inner address score-grt">
              <div className="pr-header ">
                <div className="toggle_head_sec d-flex align-items-center justify-content-between">
                  <h5>LSAT exam scores</h5>
                  <label className="switch togle_switch">
                    <input
                      type="checkbox"
                      className=""
                      value="1"
                      id="haveLSATscore"
                      name="haveLSATscore"
                      onChange={this.handleLSATscoreAvail}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {this.state.haveLSATscore ? (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="uni-label">LSAT Exam Date</Label>
                        <DatePicker
                          selected={this.state.lsatExamDate}
                          onChange={(date) =>
                            this.setState({ lsatExamDate: date })
                          }
                          dateFormat="yyyy-MM-dd"
                          className=""
                          id="lsatExamDate"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="YYYY-MMM-DD"
                          maxDate={new Date()}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.lsatExamDate ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.lsatExamDate}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="row qual_spacing">
                    <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-6 p-r-0">
                      <Label className="uni-label">Total Score</Label>
                      <div className="score-list flex-column">
                        <div className="score-name border-gray">
                          <div className="scor-1"> Score </div>
                          <div className="score-in ">
                            <Input
                              type="number"
                              min="120"
                              max="180"
                              className="form-control "
                              value={this.state.lsatTotalScore}
                              id="lsatTotalScore"
                              onChange={this.handleLSATscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in ">
                            <Input
                              type="number"
                              min="0"
                              max="99"
                              className="form-control "
                              value={this.state.lsatTotalRank}
                              id="lsatTotalRank"
                              onChange={this.handleLSATscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors && this.state.errors.lsatTotalScore ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.lsatTotalScore}
                          </h6>
                          <br />
                        </>
                      ) : null}

                      {this.state.errors && this.state.errors.lsatTotalRank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.lsatTotalRank}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {/* MCAT Exam */}
            <div className="personal-box-inner address score-grt">
              <div className="pr-header ">
                <div className="toggle_head_sec d-flex align-items-center justify-content-between">
                  <h5>MCAT exam scores</h5>
                  <label className="switch togle_switch">
                    <input
                      type="checkbox"
                      className=""
                      value="1"
                      id="haveMCATscore"
                      name="haveMCATscore"
                      onChange={this.handleMCATscoreAvail}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {this.state.haveMCATscore ? (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="uni-label">MCAT Exam Date</Label>
                        <DatePicker
                          selected={this.state.mcatExamDate}
                          onChange={(date) =>
                            this.setState({ mcatExamDate: date })
                          }
                          dateFormat="yyyy-MM-dd"
                          className=""
                          id="mcatExamDate"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="YYYY-MMM-DD"
                          maxDate={new Date()}
                        />
                      </FormGroup>
                      {this.state.errors && this.state.errors.mcatExamDate ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.mcatExamDate}
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="row qual_spacing">
                    <div className="col-md-8 col-lg-8 col-xl-8 col-sm-6 col-12">
                      <Label className="uni-label">Total Score</Label>
                      <div className="score-list flex-column ents-box">
                        <div className="score-name border-gray ets-box">
                          <div className="scor-1 tts">
                            {" "}
                            Enter a test score between 472 and 528{" "}
                          </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              className="form-control "
                              value={this.state.mcatTotalScore}
                              id="mcatTotalScore"
                              onChange={this.handleLSATscoreChange}
                              min="472"
                              max="528"
                              placeholder="Score"
                            />
                          </div>
                        </div>
                        <div className="score-name">
                          <div className="scor-1"> Rank % </div>
                          <div className="score-in">
                            <Input
                              type="number"
                              className="form-control "
                              value={this.state.mcatTotalRank}
                              id="mcatTotalRank"
                              onChange={this.handleLSATscoreChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseFloat(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              min="472"
                              max="528"
                              placeholder="Rank"
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.errors && this.state.errors.mcatTotalScore ? (
                        <>
                          <h6 style={{ color: "red" }}>
                            {this.state.errors.mcatTotalScore}
                          </h6>
                          <br />
                        </>
                      ) : null}

                      {this.state.errors && this.state.errors.mcatTotalRank ? (
                        <h6 style={{ color: "red" }}>
                          {this.state.errors.mcatTotalRank}
                        </h6>
                      ) : null}
                    </div>
                  </div>

                  {/*  */}
                  <div>
                    <div className="row bb-ts-box qual_spacing">
                      <div className="col-12">
                        <div className="table-list-wrap">
                          <Label className="uni-label label-tb">
                            {" "}
                            Biological and biochemical foundations of living
                            systems{" "}
                          </Label>
                          <div className="score-list flex-column">
                            <div className="score-name border-gray">
                              <div className="scor-1"> Score </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="118"
                                  max="132"
                                  className="form-control "
                                  value={this.state.mcat_bio_score}
                                  id="mcat_bio_score"
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_bio_score: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  placeholder="Score"
                                />
                              </div>
                              {this.state.errors &&
                                this.state.errors.mcat_bio_score ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_bio_score}
                                </h6>
                              ) : null}
                            </div>
                            <div className="score-name">
                              <div className="scor-1"> Rank % </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="0"
                                  max="99"
                                  className="form-control "
                                  value={this.state.mcat_bio_rank}
                                  id="mcat_bio_rank"
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_bio_rank: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Rank"
                                />
                              </div>
                              {this.state.errors &&
                                this.state.errors.mcat_bio_rank ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_bio_rank}
                                </h6>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="table-list-wrap">
                          <Label className="uni-label label-tb">
                            {" "}
                            Chemical and physical foundations of biological
                            systems{" "}
                          </Label>
                          <div className="score-list flex-column">
                            <div className="score-name border-gray">
                              <div className="scor-1"> Score </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="118"
                                  max="132"
                                  id="mcat_chemical_score"
                                  className="form-control "
                                  value={this.state.mcat_chemical_score}
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_chemical_score: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Score"
                                />
                              </div>

                              {this.state.errors &&
                                this.state.errors.mcat_chemical_score ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_chemical_score}
                                </h6>
                              ) : null}
                            </div>
                            <div className="score-name">
                              <div className="scor-1"> Rank % </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="0"
                                  max="99"
                                  id="mcat_chemical_rank"
                                  className="form-control "
                                  value={this.state.mcat_chemical_rank}
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_chemical_rank: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Rank"
                                />
                              </div>
                              {this.state.errors &&
                                this.state.errors.mcat_chemical_rank ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_chemical_rank}
                                </h6>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="table-list-wrap">
                          <Label className="uni-label label-tb">
                            {" "}
                            Psychological, social, and biological foundations of
                            behaviour{" "}
                          </Label>
                          <div className="score-list flex-column">
                            <div className="score-name border-gray">
                              <div className="scor-1"> Score </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="118"
                                  max="132"
                                  id="mcat_psychology_score"
                                  className="form-control "
                                  value={this.state.mcat_psychology_score}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        mcat_psychology_score: e.target.value,
                                      },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Score"
                                />
                              </div>

                              {this.state.errors &&
                                this.state.errors.mcat_psychology_score ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_psychology_score}
                                </h6>
                              ) : null}
                            </div>
                            <div className="score-name">
                              <div className="scor-1"> Rank % </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="0"
                                  max="99"
                                  id="mcat_psychology_rank"
                                  className="form-control "
                                  value={this.state.mcat_psychology_rank}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        mcat_psychology_rank: e.target.value,
                                      },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Rank"
                                />
                              </div>

                              {this.state.errors &&
                                this.state.errors.mcat_psychology_rank ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_psychology_rank}
                                </h6>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="table-list-wrap">
                          <Label className="uni-label label-tb">
                            {" "}
                            Critical analysis and reasoning skills{" "}
                          </Label>
                          <div className="score-list flex-column">
                            <div className="score-name border-gray">
                              <div className="scor-1"> Score </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="118"
                                  max="132"
                                  className="form-control "
                                  value={this.state.mcat_reading_score}
                                  id="mcat_reading_score"
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_reading_score: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Score"
                                />
                              </div>
                              {this.state.errors &&
                                this.state.errors.mcat_reading_score ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_reading_score}
                                </h6>
                              ) : null}
                            </div>
                            <div className="score-name">
                              <div className="scor-1"> Rank % </div>
                              <div className="score-in">
                                <Input
                                  type="number"
                                  min="0"
                                  max="99"
                                  className="form-control "
                                  value={this.state.mcat_reading_rank}
                                  id="mcat_reading_rank"
                                  onChange={(e) => {
                                    this.setState(
                                      { mcat_reading_rank: e.target.value },
                                      this.updateErrors
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder="Rank"
                                />
                              </div>
                              {this.state.errors &&
                                this.state.errors.mcat_reading_rank ? (
                                <h6 style={{ color: "red" }}>
                                  {this.state.errors.mcat_reading_rank}
                                </h6>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              ) : null}
            </div>
            {/* New Qualification End */}
          </div>

          {
            this.state.appQuestions && this.state.appQuestions.length ? (
              <ApplicationQa
                appQuestions={this.state.appQuestions}
                appAnswers={this.state.appAnswers}
                updatedAnswers={(appAnswers) => {
                  this.setState({ appAnswers })
                }}
              />
            ) : null
          }

          {this.props.showComment ? null : (
            <div className="bottomSaveButton">
              <button
                type="button"
                class="beforesave btn btn-secondary"
                onClick={this.onSubmit.bind(this, "exit")}
              >
                Save & Exit
              </button>
              <button
                type="button"
                class="beforesave btn btn-primary"
                onClick={this.onSubmit.bind(this, "")}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default template;
