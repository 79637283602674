import { all } from "redux-saga/effects";

import {
  getDashboardDataActionWatcher,
  getDashboardUniversityActionWatcher,
  getStudentApplicationStatisticsActionWatcher,
  getReachOutDetailsActionWatcher,
  getStudentApplicationCountWatcher,
  getUniversityIncomeCountWatcher,
} from "./dashboard";

import {
  getDocumentsWatcher,
  storeDocumentsWatcher,
  deleteDocumentsWatcher,
} from "./documents";

import {
  getCountryListActionWatcher,
  onBoardingProcessActionWatcher,
  forgotPasswordActionWatcher,
  resetPasswordActionWatcher,
  agentListActionWatcher,
  savePpSearchWatcher,
  getEpListActionWatcher,
  updateEpFavouriteActionWatcher,
  updateProgramFavouriteActionWatcher,
  getAgentProfileActionWatcher,
  getAgentReviewsActionWatcher,
  likeDislikeCommentActionWatcher,
  likeDislikeReviewActionWatcher,
  updateCommentActionWatcher,
  deleteCommentActionWatcher,
  getUniversityProfileActionWatcher,
  getIntakesActionWatcher,
  getProgramDetailsFiltersActionWatcher,
  checkEligibilityActionWatcher,
  getApplicationAdviceActionWatcher,
  checkAppliedStatusActionWatcher,
} from "./general";

import {
  saveSideProfileActionWatcher,
  getUniversityAboutActionWatcher,
  getProfileActionWatcher,
  updateProfileActionWatcher,
  saveIntakeDatesActionWatcher,
  getIntakeDatesActionWatcher,
  saveApplicationFormActionWatcher,
  getApplicationFormActionWatcher,
  getProfileStatusWatcher,
  getEpSavedSearchWatcher,
  getLoginLogsActionWatcher,
  deleteSavedSearchWatcher,
  getPpSearchWatcher,
  deleteDocsWatcher,
  getProfileFlagsWatcher,
  getEducationSublevelWatcher,
  getProgramCountryWatcher,
} from "./profile";

import {
  createGroupActionWatcher,
  getGroupListActionWatcher,
  getRoleListActionWatcher,
  sendStaffInviteActionWatcher,
  getGroupRolesListActionWatcher,
  addRolesToGroupActionWatcher,
  getRolePermissionActionWatcher,
  setRolePermissionActionWatcher,
  getStaffListActionWatcher,
  deleteGroupActionWatcher,
  deleteRoleActionWatcher,
  getGroupInviteActionWatcher,
  getStaffInviteListActionWatcher,
  deleteStaffActionWatcher,
  deleteStaffInviteActionWatcher,
} from "./staff";

import {
  getApplicationListActionWatcher,
  getDepartmentListActionWatcher,
  getProgramListActionWatcher,
  getStudyLevelListActionWatcher,
  deleteApplicationsActionWatcher,
  getApplicationStatusActionWatcher,
  getApplicationDetailsActionWatcher,
  updateApplicationStatusActionWatcher,
  getApplicationStatusWatcher,
  assignApplicationWatcher,
  getApplicationAssigneesWatcher,
  sendApplicationMessageWatcher,
  getApplicationMessageChatCountWatcher,
  getApplicationMessagesWatcher,
  getApplicationCommentsWatcher,
  addApplicationCommentWatcher,
  deleteApplicationCommentWatcher,
  requestDocumentVerificationWatcher,
  getPaymentProofWatcher,
  getApplicationStepStatusActionWatcher,
  saveApplicationActionWatcher,
  sendApplicationActionWatcher,
  getInvoiceActionWatcher,
  saveApplicationPdfActionWatcher,
  getApplicationNoteCountActionWatcher,
  getSupportingDocumentActionWatcher,
  getStudentTypesListActionWatcher,
} from "./application";
import {
  saveDepartmentActionWatcher,
  viewDepartmentActionWatcher,
  updateDepartmentActionWatcher,
  getEducationListActionWatcher,
  getRemainCampusListActionWatcher,
  createProgramActionWatcher,
  getLanguageExamListActionWatcher,
  getAdditionalExamListActionWatcher,
  getSearchProgramListActionWatcher,
  deleteProgramActionWatcher,
  getDepartmentsActionWatcher,
  getProgramsActionWatcher,
  getProgramDetailsActionWatcher,
  updateProgramActionWatcher,
  confirmDepartmentActionWatcher,
  confirmProgramActionWatcher,
  deleteAllProgramDepartmentActionWatcher,
  getUniversityOverviewActionWatcher,
  getStudentTypesActionWatcher,
} from "./department";

import {
  getPaymentPaidListActionWatcher,
  createPaymentMethodActionWatcher,
  getPaymentListActionWatcher,
  getPaymentDetailsActionWatcher,
  updatePaymentMethodActionWatcher,
  deletePaymentMethodActionWatcher,
  getPendingStudentFeesActionWatcher,
  saveCardWatcher,
  getInvoicePaymentProofWatcher,
  getPrepaidFeesCommissionListWatcher,
  getPostpaidFeesCommissionListWatcher,
  getDefaultCardWatcher,
  getInvoiceDetailsWatcher,
  stripePaymentWatcher,
  getInvoiceAppWatcher,
} from "./payment";

import {
  composeMailWatcher,
  getInboxWatcher,
  updateFavouriteWatcher,
  deleteMailWatcher,
  getUnreadInboxCountWatcher,
  getInboxMailDetailsWatcher,
  updateInboxReadStatusWatcher,
  getTimeZonesListWatcher,
  createMeetingWatcher,
  meetingListWatcher,
  meetingDetailsWatcher,
  deleteMeetingWatcher,
  updateMeetingStatusWatcher,
  getContactListWatcher,
  getReplyToMeetingWatcher,
  getAgentCheckWatcher,
  getHireAgentWatcher,
  getShareProfileWatcher,
  getAgentHireRequestsWatcher,
  updateAgentHireRequestWatcher,
  deleteAgentHireRequestWatcher,
  deletePermActionWatcher,
  moveToInboxActionWatcher,
  sendMeetingActionWatcher,
} from "./inbox";

import {
  calendarEventListActionWatcher,
  getMonthEventListActionWatcher,
} from "./calender";

import { loginActionWatcher, getRolePermissionsActionWatcher } from "./auth";

import {
  getChatSessionListWatcher,
  getChatMessagesListWatcher,
  starChatWatcher,
  deleteChatWatcher,
} from "./chat";

export default function* rootSaga() {
  yield all([
    getDashboardDataActionWatcher(),
    getCountryListActionWatcher(),
    saveSideProfileActionWatcher(),
    getUniversityAboutActionWatcher(),
    createGroupActionWatcher(),
    getGroupListActionWatcher(),
    getRoleListActionWatcher(),
    sendStaffInviteActionWatcher(),
    getGroupRolesListActionWatcher(),
    addRolesToGroupActionWatcher(),
    getRolePermissionActionWatcher(),
    setRolePermissionActionWatcher(),
    getStaffListActionWatcher(),
    deleteGroupActionWatcher(),

    getProfileActionWatcher(),
    updateProfileActionWatcher(),

    deleteRoleActionWatcher(),

    getGroupInviteActionWatcher(),
    getStaffInviteListActionWatcher(),

    deleteStaffActionWatcher(),
    deleteStaffInviteActionWatcher(),

    saveIntakeDatesActionWatcher(),
    getIntakeDatesActionWatcher(),

    saveApplicationFormActionWatcher(),
    getApplicationFormActionWatcher(),

    getProfileStatusWatcher(),

    getApplicationListActionWatcher(),
    getDepartmentListActionWatcher(),
    getProgramListActionWatcher(),
    getStudyLevelListActionWatcher(),
    deleteApplicationsActionWatcher(),
    getApplicationStatusActionWatcher(),

    getApplicationDetailsActionWatcher(),
    updateApplicationStatusActionWatcher(),
    getApplicationStatusWatcher(),
    assignApplicationWatcher(),
    getApplicationAssigneesWatcher(),

    saveDepartmentActionWatcher(),
    viewDepartmentActionWatcher(),
    updateDepartmentActionWatcher(),
    getEducationListActionWatcher(),
    getRemainCampusListActionWatcher(),
    createProgramActionWatcher(),
    getLanguageExamListActionWatcher(),
    getAdditionalExamListActionWatcher(),
    getSearchProgramListActionWatcher(),
    deleteProgramActionWatcher(),
    getDepartmentsActionWatcher(),
    getProgramsActionWatcher(),
    getProgramDetailsActionWatcher(),
    updateProgramActionWatcher(),
    confirmDepartmentActionWatcher(),
    confirmProgramActionWatcher(),
    getLoginLogsActionWatcher(),
    deleteAllProgramDepartmentActionWatcher(),
    getUniversityOverviewActionWatcher(),

    sendApplicationMessageWatcher(),
    getApplicationMessageChatCountWatcher(),
    getApplicationMessagesWatcher(),
    getApplicationCommentsWatcher(),
    addApplicationCommentWatcher(),
    deleteApplicationCommentWatcher(),
    requestDocumentVerificationWatcher(),
    getDashboardUniversityActionWatcher(),

    getPaymentPaidListActionWatcher(),
    createPaymentMethodActionWatcher(),
    getPaymentListActionWatcher(),
    getPaymentDetailsActionWatcher(),
    updatePaymentMethodActionWatcher(),
    deletePaymentMethodActionWatcher(),

    composeMailWatcher(),
    getInboxWatcher(),
    updateFavouriteWatcher(),
    deleteMailWatcher(),
    getUnreadInboxCountWatcher(),
    getInboxMailDetailsWatcher(),
    updateInboxReadStatusWatcher(),
    getTimeZonesListWatcher(),
    createMeetingWatcher(),
    meetingListWatcher(),
    meetingDetailsWatcher(),
    deleteMeetingWatcher(),
    updateMeetingStatusWatcher(),
    getContactListWatcher(),
    getStudentApplicationStatisticsActionWatcher(),
    getReachOutDetailsActionWatcher(),
    getReplyToMeetingWatcher(),
    getAgentCheckWatcher(),
    getHireAgentWatcher(),
    getShareProfileWatcher(),
    getStudentApplicationCountWatcher(),
    getUniversityIncomeCountWatcher(),
    calendarEventListActionWatcher(),
    getMonthEventListActionWatcher(),
    onBoardingProcessActionWatcher(),
    forgotPasswordActionWatcher(),
    resetPasswordActionWatcher(),
    getPendingStudentFeesActionWatcher(),
    getPaymentProofWatcher(),
    
    getDocumentsWatcher(),
    storeDocumentsWatcher(),
    deleteDocumentsWatcher(),

    saveCardWatcher(),

    getInvoicePaymentProofWatcher(),
    getPrepaidFeesCommissionListWatcher(),
    getPostpaidFeesCommissionListWatcher(),

    getDefaultCardWatcher(),
    getInvoiceDetailsWatcher(),
    stripePaymentWatcher(),
    getInvoiceAppWatcher(),

    loginActionWatcher(),
    getRolePermissionsActionWatcher(),

    getAgentHireRequestsWatcher(),
    updateAgentHireRequestWatcher(),
    deleteAgentHireRequestWatcher(),

    getEpSavedSearchWatcher(),
    deleteSavedSearchWatcher(),
    getPpSearchWatcher(),

    deleteDocsWatcher(),

    getProfileFlagsWatcher(),
    getEducationSublevelWatcher(),

    getProgramCountryWatcher(),

    agentListActionWatcher(),

    savePpSearchWatcher(),

    getEpListActionWatcher(),

    updateEpFavouriteActionWatcher(),

    updateProgramFavouriteActionWatcher(),

    getAgentProfileActionWatcher(),

    getAgentReviewsActionWatcher(),

    likeDislikeCommentActionWatcher(),
    likeDislikeReviewActionWatcher(),
    updateCommentActionWatcher(),
    deleteCommentActionWatcher(),

    deletePermActionWatcher(),
    moveToInboxActionWatcher(),

    getChatSessionListWatcher(),
    getChatMessagesListWatcher(),
    starChatWatcher(),
    deleteChatWatcher(),

    sendMeetingActionWatcher(),

    getUniversityProfileActionWatcher(),

    getIntakesActionWatcher(),

    getProgramDetailsFiltersActionWatcher(),

    checkEligibilityActionWatcher(),

    getApplicationAdviceActionWatcher(),

    checkAppliedStatusActionWatcher(),

    getApplicationStepStatusActionWatcher(),
    saveApplicationActionWatcher(),
    sendApplicationActionWatcher(),

    getInvoiceActionWatcher(),

    saveApplicationPdfActionWatcher(),

    getApplicationNoteCountActionWatcher(),

    getStudentTypesActionWatcher(),

    getSupportingDocumentActionWatcher(),

    getStudentTypesListActionWatcher()
  ]);
}
