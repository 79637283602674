import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  ADD_APPLICATION_COMMENT,
  ASSIGN_APPLICATION,
  DELETE_APPLICATIONS,
  DELETE_APPLICATION_COMMENT,
  GET_APPLICATION_ASSIGNEES,
  GET_APPLICATION_COMMENTS,
  GET_APPLICATION_DETAILS,
  GET_APPLICATION_DETAILS_GENERAL,
  GET_APPLICATION_LIST,
  GET_APPLICATION_MESSAGES,
  GET_APPLICATION_MESSAGE_CHAT_COUNT,
  GET_APPLICATION_NOTE_COUNT,
  GET_APPLICATION_STATUS,
  GET_APPLICATION_STATUS_LIST,
  GET_APPLICATION_STEP_STATUS,
  GET_DEPARTMENT_LIST,
  GET_INVOICE,
  GET_PAYMENT_PROOF,
  GET_PROGRAM_LIST,
  GET_STUDENT_TYPES_LIST,
  GET_STUDY_LEVEL_LIST,
  GET_SUPPORTING_DOCUMENTS,
  REQUEST_APPLICATION_DOCUMENT_VERIFICATION,
  SAVE_APPLICATION,
  SAVE_APPLICATION_PDF,
  SEND_APPLICATION,
  SEND_APPLICATION_MESSAGE,
  UPDATE_APPLICATION_STATUS
} from "../constants";
import {
  ADD_APPLICATION_COMMENT_API,
  ASSIGN_APPLICATION_API,
  baseUrl,
  DELETE_APPLICATIONS_API,
  DELETE_APPLICATION_COMMENT_API,
  GET_APPLICATION_ASSIGNEE_LIST_API,
  GET_APPLICATION_COMMENTS_API,
  GET_APPLICATION_DETAILS_API,
  GET_APPLICATION_LIST_API,
  GET_APPLICATION_MESSAGES_API,
  GET_APPLICATION_MESSAGE_CHAT_COUNT_API,
  GET_APPLICATION_NOTE_COUNT_API,
  GET_APPLICATION_STATUS_API,
  GET_APPLICATION_STATUS_LIST_API,
  GET_APPLICATION_STEP_STATUS_API,
  GET_DEPARTMENT_LIST_API,
  GET_INVOICE_API,
  GET_PAYMENT_PROOF_API,
  GET_PROGRAM_LIST_API,
  GET_STUDENT_TYPES_API,
  GET_STUDY_LEVEL_LIST_API,
  GET_SUPPORTING_DOCUMENT_API,
  REQUEST_APPLICATION_DOCUMENT_VERIFICATION_API,
  SAVE_APPLICATION_API,
  SAVE_APPLICATION_PDF_API,
  SEND_APPLICATION_API,
  SEND_APPLICATION_MESSAGE_API,
  UPDATE_APPLICATION_STATUS_API
} from "../../Api";
import { toast } from "react-toastify";

import { addApplicationCommentError, addApplicationCommentSuccess, assignApplicationError, assignApplicationSuccess, deleteApplicationCommentError, deleteApplicationCommentSuccess, deleteApplicationsError, deleteApplicationsSuccess, getApplicationAssigneesError, getApplicationAssigneesSuccess, getApplicationCommentsError, getApplicationCommentsSuccess, getApplicationDetailsSuccess, getApplicationListError, getApplicationListSuccess, getApplicationMessageChatCountError, getApplicationMessageChatCountSuccess, getApplicationMessagesError, getApplicationMessagesSuccess, getApplicationNoteCountSuccess, getApplicationStatusError, getApplicationStatusListError, getApplicationStatusListSuccess, getApplicationStatusSuccess, getApplicationStepStatusError, getApplicationStepStatusSuccess, getDepartmentListError, getDepartmentListSuccess, getInvoiceError, getInvoiceSuccess, getPaymentProofError, getPaymentProofSuccess, getProgramListError, getProgramListSuccess, getStudentTypesListSuccess, getStudyLevelError, getStudyLevelSuccess, handleErrorResponse, requestDocumentVerificationError, requestDocumentVerificationSuccess, saveApplicationError, saveApplicationSuccess, sendApplicationError, sendApplicationMessageError, sendApplicationMessageSuccess, sendApplicationSuccess, updateApplicationStatusError, updateApplicationStatusSuccess } from '../actions';

import axios from "../../Api/axios";

function getApplicationListApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: GET_APPLICATION_LIST_API,
    params: params
  });
}

function* getApplicationList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getApplicationListApi, payload);

    yield put(getApplicationListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getApplicationListError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationListActionWatcher() {
  yield takeLatest(GET_APPLICATION_LIST, getApplicationList)
}

function getDepartmentListApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_DEPARTMENT_LIST_API,
    params: params
  });
}

function* getDepartmentList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getDepartmentListApi, payload);
    
    yield put(getDepartmentListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getDepartmentListError(e))
    if (reject) reject(e)
  }
}

export function* getDepartmentListActionWatcher() {
  yield takeLatest(GET_DEPARTMENT_LIST, getDepartmentList)
}

function getProgramListApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_PROGRAM_LIST_API,
    params: params
  });
}

function* getProgramList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProgramListApi, payload);
    
    yield put(getProgramListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getProgramListError(e))
    if (reject) reject(e)
  }
}

export function* getProgramListActionWatcher() {
  yield takeLatest(GET_PROGRAM_LIST, getProgramList)
}

function getStudyLevelListApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_STUDY_LEVEL_LIST_API,
    params: params
  });
}

function* getStudyLevelList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getStudyLevelListApi, payload);
    
    yield put(getStudyLevelSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getStudyLevelError(e))
    if (reject) reject(e)
  }
}

export function* getStudyLevelListActionWatcher() {
  yield takeLatest(GET_STUDY_LEVEL_LIST, getStudyLevelList)
}

function deleteApplicationsApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_APPLICATIONS_API,
    data: params
  });
}

function* deleteApplications(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(deleteApplicationsApi, payload);

    yield put(deleteApplicationsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteApplicationsError(e))
    if (reject) reject(e)
  }
}

export function* deleteApplicationsActionWatcher() {
  yield takeLatest(DELETE_APPLICATIONS, deleteApplications)
}

function getApplicationStatusListApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_STATUS_LIST_API,
    data: params
  });
}

function* getApplicationStatusList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationStatusListApi, payload);

    yield put(getApplicationStatusListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // handleErrorResponse(e.response);
    yield put(getApplicationStatusListError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationStatusActionWatcher() {
  yield takeLatest(GET_APPLICATION_STATUS_LIST, getApplicationStatusList)
}

function getApplicationDetailsApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_DETAILS_API,
    params: params
  });
}

function* getApplicationDetails(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationDetailsApi, payload);

    yield put(getApplicationDetailsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getApplicationDetailsSuccess(e))
    if (reject) reject(e)
  }
}

export function* getApplicationDetailsActionWatcher() {
  yield takeLatest(GET_APPLICATION_DETAILS, getApplicationDetails)
}

function updateApplicationStatusApi(params) {
  console.log('action.payload', params);
  // let statusVal = params.status;
  // if (!statusVal) {
  //   statusVal = params.get('status')
  // }
  return axios.request({
    method: "POST",
    url: baseUrl+UPDATE_APPLICATION_STATUS_API+"/"+params.status,
    data: params
  });
}

function* updateApplicationStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(updateApplicationStatusApi, payload);

    yield put(updateApplicationStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateApplicationStatusError(e))
    if (reject) reject(e)
  }
}

export function* updateApplicationStatusActionWatcher() {
  yield takeLatest(UPDATE_APPLICATION_STATUS, updateApplicationStatus)
}

function getApplicationMessageChatCountApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_MESSAGE_CHAT_COUNT_API,
    params: params
  });
}

function* getApplicationMessageChatCount(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationMessageChatCountApi, payload);

    yield put(getApplicationMessageChatCountSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // handleErrorResponse(e.response);
    yield put(getApplicationMessageChatCountError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationMessageChatCountWatcher() {
  yield takeLatest(GET_APPLICATION_MESSAGE_CHAT_COUNT, getApplicationMessageChatCount)
}

function getApplicationStatusApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_STATUS_API,
    params: params
  });
}

function* getApplicationStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationStatusApi, payload);

    yield put(getApplicationStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // handleErrorResponse(e.response);
    yield put(getApplicationStatusError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationStatusWatcher() {
  yield takeLatest(GET_APPLICATION_STATUS, getApplicationStatus)
}

function assignApplicationApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+ASSIGN_APPLICATION_API,
    data: params
  });
}

function* assignApplication(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(assignApplicationApi, payload);

    yield put(assignApplicationSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(assignApplicationError(e))
    if (reject) reject(e)
  }
}

export function* assignApplicationWatcher() {
  yield takeLatest(ASSIGN_APPLICATION, assignApplication)
}

function getApplicationAssigneesApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_ASSIGNEE_LIST_API,
    params: params
  });
}

function* getApplicationAssignees(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationAssigneesApi, payload);

    yield put(getApplicationAssigneesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // handleErrorResponse(e.response);
    yield put(getApplicationAssigneesError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationAssigneesWatcher() {
  yield takeLatest(GET_APPLICATION_ASSIGNEES, getApplicationAssignees)
}

function sendApplicationMessageApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+SEND_APPLICATION_MESSAGE_API,
    data: params
  });
}

function* sendApplicationMessage(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(sendApplicationMessageApi, payload);

    yield put(sendApplicationMessageSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(sendApplicationMessageError(e))
    if (reject) reject(e)
  }
}

export function* sendApplicationMessageWatcher() {
  yield takeLatest(SEND_APPLICATION_MESSAGE, sendApplicationMessage)
}

function getApplicationMessagesApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_MESSAGES_API,
    params: params
  });
}

function* getApplicationMessages(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationMessagesApi, payload);

    yield put(getApplicationMessagesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getApplicationMessagesError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationMessagesWatcher() {
  yield takeLatest(GET_APPLICATION_MESSAGES, getApplicationMessages)
}

function getApplicationCommentsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_COMMENTS_API,
    params: params
  });
}

function* getApplicationComments(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationCommentsApi, payload);

    yield put(getApplicationCommentsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    // handleErrorResponse(e.response);
    yield put(getApplicationCommentsError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationCommentsWatcher() {
  yield takeLatest(GET_APPLICATION_COMMENTS, getApplicationComments)
}

function addApplicationCommentApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+ADD_APPLICATION_COMMENT_API,
    data: params
  });
}

function* addApplicationComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(addApplicationCommentApi, payload);

    yield put(addApplicationCommentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(addApplicationCommentError(e))
    if (reject) reject(e)
  }
}

export function* addApplicationCommentWatcher() {
  yield takeLatest(ADD_APPLICATION_COMMENT, addApplicationComment)
}

function deleteApplicationCommentApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_APPLICATION_COMMENT_API,
    data: params
  });
}

function* deleteApplicationComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(deleteApplicationCommentApi, payload);

    yield put(deleteApplicationCommentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteApplicationCommentError(e))
    if (reject) reject(e)
  }
}

export function* deleteApplicationCommentWatcher() {
  yield takeLatest(DELETE_APPLICATION_COMMENT, deleteApplicationComment)
}

function requestDocumentVerificationApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+REQUEST_APPLICATION_DOCUMENT_VERIFICATION_API,
    data: params
  });
}

function* requestDocumentVerification(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(requestDocumentVerificationApi, payload);

    yield put(requestDocumentVerificationSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(requestDocumentVerificationError(e))
    if (reject) reject(e)
  }
}

export function* requestDocumentVerificationWatcher() {
  yield takeLatest(REQUEST_APPLICATION_DOCUMENT_VERIFICATION, requestDocumentVerification)
}

function getPaymentProofApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_PAYMENT_PROOF_API,
    params: params
  });
}

function* getPaymentProof(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getPaymentProofApi, payload);

    yield put(getPaymentProofSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getPaymentProofError(e))
    if (reject) reject(e)
  }
}

export function* getPaymentProofWatcher() {
  yield takeLatest(GET_PAYMENT_PROOF, getPaymentProof)
}

function getApplicationStepStatusApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_APPLICATION_STEP_STATUS_API,
    params: params
  });
}

function* getApplicationStepStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getApplicationStepStatusApi, payload);

    yield put(getApplicationStepStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getApplicationStepStatusError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationStepStatusActionWatcher() {
  yield takeLatest(GET_APPLICATION_STEP_STATUS, getApplicationStepStatus)
}

function saveApplicationApi(params) {
  let url = baseUrl+SAVE_APPLICATION_API
  if (params.status) {
    url += `/${params.status}`
  }
  return axios.request({
    method: "post",
    url,
    data: params
  });
}

function* saveApplication(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(saveApplicationApi, payload);

    yield put(saveApplicationSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(saveApplicationError(e))
    if (reject) reject(e)
  }
}

export function* saveApplicationActionWatcher() {
  yield takeLatest(SAVE_APPLICATION, saveApplication)
}

function sendApplicationApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+SEND_APPLICATION_API,
    data: params
  });
}

function* sendApplication(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(sendApplicationApi, payload);

    yield put(sendApplicationSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(sendApplicationError(e))
    if (reject) reject(e)
  }
}

export function* sendApplicationActionWatcher() {
  yield takeLatest(SEND_APPLICATION, sendApplication)
}

function getInvoiceApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_INVOICE_API,
    params
  });
}

function* getInvoice(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(getInvoiceApi, payload);

    yield put(getInvoiceSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getInvoiceError(e))
    if (reject) reject(e)
  }
}

export function* getInvoiceActionWatcher() {
  yield takeLatest(GET_INVOICE, getInvoice)
}

function saveApplicationPdfApi(data) {
  return axios.request({
    method: "post",
    url: baseUrl+SAVE_APPLICATION_PDF_API,
    data
  });
}

function* saveApplicationPdf(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(saveApplicationPdfApi, payload);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    if (reject) reject(e)
  }
}

export function* saveApplicationPdfActionWatcher() {
  yield takeLatest(SAVE_APPLICATION_PDF, saveApplicationPdf)
}

function getApplicationNoteCountApi(data) {
  return axios.request({
    method: "get",
    url: GET_APPLICATION_NOTE_COUNT_API+"/"+data.application_id
  });
}

function* getApplicationNoteCount(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getApplicationNoteCountApi, payload);
    yield put(getApplicationNoteCountSuccess(response.data))
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    if (reject) reject(e)
  }
}

export function* getApplicationNoteCountActionWatcher() {
  yield takeLatest(GET_APPLICATION_NOTE_COUNT, getApplicationNoteCount)
}

function getSupportingDocumentApi(params) {
  return axios.request({
    method: "get",
    url: GET_SUPPORTING_DOCUMENT_API,
    params
  });
}

function* getSupportingDocument(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getSupportingDocumentApi, payload);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    if (reject) reject(e)
  }
}

export function* getSupportingDocumentActionWatcher() {
  yield takeLatest(GET_SUPPORTING_DOCUMENTS, getSupportingDocument)
}

function getStudentTypesListApi(params) {
  return axios.request({
    method: "get",
    url: GET_STUDENT_TYPES_API,
    params
  });
}

function* getStudentTypesList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getStudentTypesListApi, payload);
    yield put(getStudentTypesListSuccess(response.data))
    if (resolve) resolve(response.data);
  } catch (e) {
    handleErrorResponse(e.response);
    if (reject) reject(e)
  }
}

export function* getStudentTypesListActionWatcher() {
  yield takeLatest(GET_STUDENT_TYPES_LIST, getStudentTypesList)
}
