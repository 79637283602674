import {
	GET_PAYMENT_PAID_LIST,
	GET_PAYMENT_PAID_LIST_SUCCESS,
	GET_PAYMENT_PAID_LIST_ERROR,
	CREATE_PAYMENT_METHOD,
	CREATE_PAYMENT_METHOD_SUCCESS,
	CREATE_PAYMENT_METHOD_ERROR,
	GET_PAYMENT_LIST,
	GET_PAYMENT_LIST_SUCCESS,
	GET_PAYMENT_LIST_ERROR,
	GET_PAYMENT_DETAILS,
	GET_PAYMENT_DETAILS_SUCCESS,
	GET_PAYMENT_DETAILS_ERROR,
	UPDATE_PAYMENT_METHOD,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_ERROR,
	DELETE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD_SUCCESS,
	DELETE_PAYMENT_METHOD_ERROR,
	GET_PENDING_STUDENT_FEES,
	GET_PENDING_STUDENT_FEES_SUCCESS,
	GET_PENDING_STUDENT_FEES_ERROR,
	SAVE_CARD_ERROR,
	SAVE_CARD_SUCCESS,
	SAVE_CARD,

	GET_INVOICE_PAYMENT_PROOF,
	GET_INVOICE_PAYMENT_PROOF_SUCCESS,
	GET_INVOICE_PAYMENT_PROOF_ERROR,
	GET_PREPAID_FEES_COMMISSION_LIST,
	GET_PREPAID_FEES_COMMISSION_LIST_SUCCESS,
	GET_PREPAID_FEES_COMMISSION_LIST_ERROR,
	GET_POSTPAID_FEES_COMMISSION_LIST,
	GET_POSTPAID_FEES_COMMISSION_LIST_SUCCESS,
	GET_POSTPAID_FEES_COMMISSION_LIST_ERROR,
	GET_DEFAULT_CARD_ERROR,
	GET_DEFAULT_CARD_SUCCESS,
	GET_DEFAULT_CARD,
	GET_INVOICE_DETAILS,
	GET_INVOICE_DETAILS_ERROR,
	GET_INVOICE_DETAILS_SUCCESS,
	STRIPE_PAYMENT_ERROR,
	STRIPE_PAYMENT_SUCCESS,
	STRIPE_PAYMENT,
	GET_INVOICE_APP_ERROR,
	GET_INVOICE_APP_SUCCESS,
	GET_INVOICE_APP
} from "../constants";

export function getPaymentPaidListAction(session, resolve, reject){
	return {
		type: GET_PAYMENT_PAID_LIST,
		payload: session,
		resolve,
		reject
	};
}

export function getPaymentPaidListSuccess(data) {
	return {
		type: GET_PAYMENT_PAID_LIST_SUCCESS,
		payload: data
	};
}

export function getPaymentPaidListError(error) {
	return {
		type: GET_PAYMENT_PAID_LIST_ERROR,
		payload: error
	};
}

export function createPaymentMethodAction(session, resolve, reject){
	return {
		type: CREATE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function createPaymentMethodSuccess(data) {
	return {
		type: CREATE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function createPaymentMethodError(error) {
	return {
		type: CREATE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function getPaymentListAction(session, resolve, reject){
	return {
		type: GET_PAYMENT_LIST,
		payload: session,
		resolve,
		reject
	};
}

export function getPaymentListSuccess(data) {
	return {
		type: GET_PAYMENT_LIST_SUCCESS,
		payload: data
	};
}

export function getPaymentListError(error) {
	return {
		type: GET_PAYMENT_LIST_ERROR,
		payload: error
	};
}

export function getPaymentDetailsAction(session, resolve, reject){
	return {
		type: GET_PAYMENT_DETAILS,
		payload: session,
		resolve,
		reject
	};
}

export function getPaymentDetailsSuccess(data) {
	return {
		type: GET_PAYMENT_DETAILS_SUCCESS,
		payload: data
	};
}

export function getPaymentDetailsError(error) {
	return {
		type: GET_PAYMENT_DETAILS_ERROR,
		payload: error
	};
}

export function updatePaymentMethodAction(session, resolve, reject){
	return {
		type: UPDATE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function updatePaymentMethodSuccess(data) {
	return {
		type: UPDATE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function updatePaymentMethodError(error) {
	return {
		type: UPDATE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function deletePaymentMethodAction(session, resolve, reject){
	return {
		type: DELETE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function deletePaymentMethodSuccess(data) {
	return {
		type: DELETE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function deletePaymentMethodError(error) {
	return {
		type: DELETE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function getPendingStudentFeesAction(session, resolve, reject){
	return {
		type: GET_PENDING_STUDENT_FEES,
		payload: session,
		resolve,
		reject
	};
}

export function getPendingStudentFeesSuccess(data) {
	return {
		type: GET_PENDING_STUDENT_FEES_SUCCESS,
		payload: data
	};
}

export function getPendingStudentFeesError(error) {
	return {
		type: GET_PENDING_STUDENT_FEES_ERROR,
		payload: error
	};
}

export function saveCardAction(session, resolve, reject){
	return {
		type: SAVE_CARD,
		payload: session,
		resolve,
		reject
	};
}

export function saveCardSuccess(data) {
	return {
		type: SAVE_CARD_SUCCESS,
		payload: data
	};
}

export function saveCardError(error) {
	return {
		type: SAVE_CARD_ERROR,
		payload: error
	};
}

export function getInvoicePaymentProofAction(session, resolve, reject){
	return {
	  type: GET_INVOICE_PAYMENT_PROOF,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getInvoicePaymentProofSuccess(data) {
	return {
	  type: GET_INVOICE_PAYMENT_PROOF_SUCCESS,
	  payload: data
	};
}

export function getInvoicePaymentProofError(error) {
	return {
	  type: GET_INVOICE_PAYMENT_PROOF_ERROR,
	  payload: error
	};
}

export function getPrepaidFeesCommissionListAction(session, resolve, reject){
	return {
	  type: GET_PREPAID_FEES_COMMISSION_LIST,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getPrepaidFeesCommissionListSuccess(data) {
	return {
	  type: GET_PREPAID_FEES_COMMISSION_LIST_SUCCESS,
	  payload: data
	};
}

export function getPrepaidFeesCommissionListError(error) {
	return {
	  type: GET_PREPAID_FEES_COMMISSION_LIST_ERROR,
	  payload: error
	};
}


export function getPostpaidFeesCommissionListAction(session, resolve, reject){
	return {
	  type: GET_POSTPAID_FEES_COMMISSION_LIST,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getPostpaidFeesCommissionListSuccess(data) {
	return {
	  type: GET_POSTPAID_FEES_COMMISSION_LIST_SUCCESS,
	  payload: data
	};
}

export function getPostpaidFeesCommissionListError(error) {
	return {
	  type: GET_POSTPAID_FEES_COMMISSION_LIST_ERROR,
	  payload: error
	};
}

export function getDefaultCardAction(session, resolve, reject){
	return {
	  type: GET_DEFAULT_CARD,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getDefaultCardSuccess(data) {
	return {
	  type: GET_DEFAULT_CARD_SUCCESS,
	  payload: data
	};
}

export function getDefaultCardError(error) {
	return {
	  type: GET_DEFAULT_CARD_ERROR,
	  payload: error
	};
}

export function getInvoiceDetailsAction(session, resolve, reject){
	return {
	  type: GET_INVOICE_DETAILS,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getInvoiceDetailsSuccess(data) {
	return {
	  type: GET_INVOICE_DETAILS_SUCCESS,
	  payload: data
	};
}

export function getInvoiceDetailsError(error) {
	return {
	  type: GET_INVOICE_DETAILS_ERROR,
	  payload: error
	};
}

export function stripePaymentAction(session, resolve, reject){
	return {
	  type: STRIPE_PAYMENT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function stripePaymentSuccess(data) {
	return {
	  type: STRIPE_PAYMENT_SUCCESS,
	  payload: data
	};
}

export function stripePaymentError(error) {
	return {
	  type: STRIPE_PAYMENT_ERROR,
	  payload: error
	};
}


export function getInvoiceAppAction(session, resolve, reject){
	return {
	  type: GET_INVOICE_APP,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getInvoiceAppSuccess(data) {
	return {
	  type: GET_INVOICE_APP_SUCCESS,
	  payload: data
	};
}

export function getInvoiceAppError(error) {
	return {
	  type: GET_INVOICE_APP_ERROR,
	  payload: error
	};
}