import { combineReducers } from "redux";

import applicationReducer from "./application";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import departmentReducer from "./department";
import documentsReducer from "./documents";
import generalReducer from "./general";
import inboxReducer from "./inbox";
import profileReducer from "./profile";
import staffReducer from "./staff";

const rootReducer = combineReducers({
  applicationReducer,
  authReducer,
  dashboardReducer,
  departmentReducer,
  documentsReducer,
  generalReducer,
  inboxReducer,
  profileReducer,
  staffReducer,
});

export default rootReducer;
