import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers';
import rootSaga from './sagas';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
    const store = createStore(
        reducer,
        applyMiddleware(sagaMiddleware),
    );
    sagaMiddleware.run(rootSaga);

    return store;
}